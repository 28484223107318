const mineBotsProbs = {
	"0": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 1,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1628,
		"power_bots": 0.55,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 34.0
	},
	"1": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 1,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1542,
		"power_bots": 0.55,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 26.0
	},
	"2": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 1,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 6837,
		"power_bots": 0.55,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 22.0
	},
	"3": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 1,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 188,
		"power_bots": 0.55,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 39.0
	},
	"4": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 2,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1216,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 34.0
	},
	"5": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 2,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1966,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 27.0
	},
	"6": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 2,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 7549,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 23.0
	},
	"7": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 2,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1012,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 39.0
	},
	"8": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 2,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 969,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 48.0
	},
	"9": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 3,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1811,
		"power_bots": 0.65,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 35.0
	},
	"10": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 3,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2677,
		"power_bots": 0.65,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 27.0
	},
	"11": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 3,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8599,
		"power_bots": 0.65,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 23.0
	},
	"12": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 3,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1555,
		"power_bots": 0.65,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 40.0
	},
	"13": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 3,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1323,
		"power_bots": 0.15,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 49.0
	},
	"14": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 4,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2394,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 35.0
	},
	"15": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 4,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3317,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 28.0
	},
	"16": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 4,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8372,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 24.0
	},
	"17": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 4,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2193,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 41.0
	},
	"18": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 4,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1952,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 50.0
	},
	"19": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 5,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3283,
		"power_bots": 0.75,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 36.0
	},
	"20": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 5,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8789,
		"power_bots": 0.75,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 28.0
	},
	"21": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 5,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 1704,
		"power_bots": 0.75,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 24.0
	},
	"22": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 5,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2877,
		"power_bots": 0.75,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 41.0
	},
	"23": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 5,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2796,
		"power_bots": 0.25,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 51.0
	},
	"24": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 6,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4043,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 37.0
	},
	"25": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 6,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10296,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 28.0
	},
	"26": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 6,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3662,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 42.0
	},
	"27": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 6,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3445,
		"power_bots": 0.3,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 52.0
	},
	"28": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 7,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4482,
		"power_bots": 0.85,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 37.0
	},
	"29": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 7,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10939,
		"power_bots": 0.85,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 29.0
	},
	"30": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 7,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4333,
		"power_bots": 0.85,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 43.0
	},
	"31": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 7,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4062,
		"power_bots": 0.35,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 53.0
	},
	"32": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 8,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4857,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 38.0
	},
	"33": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 8,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 11240,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 29.0
	},
	"34": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 8,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4441,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 43.0
	},
	"35": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 8,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4468,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 54.0
	},
	"36": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 8,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 244,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 61.0
	},
	"37": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 8,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 24,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 62.0
	},
	"38": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 9,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4777,
		"power_bots": 0.95,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 38.0
	},
	"39": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 9,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10780,
		"power_bots": 0.95,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 30.0
	},
	"40": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 9,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4224,
		"power_bots": 0.95,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 44.0
	},
	"41": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 9,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4181,
		"power_bots": 0.45,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 55.0
	},
	"42": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 9,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 574,
		"power_bots": 0.45,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 62.0
	},
	"43": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 9,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 395,
		"power_bots": 0.45,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 64.0
	},
	"44": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 10,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3272,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 39.0
	},
	"45": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 10,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8109,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 30.0
	},
	"46": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 10,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5612,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 45.0
	},
	"47": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 10,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3970,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 56.0
	},
	"48": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 10,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1163,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 64.0
	},
	"49": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 10,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 805,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 65.0
	},
	"50": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 11,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 1869,
		"power_bots": 1.05,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 39.0
	},
	"51": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 11,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4555,
		"power_bots": 1.05,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 31.0
	},
	"52": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 11,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7527,
		"power_bots": 1.05,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 45.0
	},
	"53": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 11,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4387,
		"power_bots": 0.55,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 57.0
	},
	"54": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 11,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1502,
		"power_bots": 0.55,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 65.0
	},
	"55": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 11,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1298,
		"power_bots": 0.55,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 66.0
	},
	"56": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 12,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 667,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 40.0
	},
	"57": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 12,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 1810,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 31.0
	},
	"58": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 12,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 9457,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 46.0
	},
	"59": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 12,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4750,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 58.0
	},
	"60": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 12,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1836,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 66.0
	},
	"61": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 12,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1574,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 67.0
	},
	"62": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 13,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10412,
		"power_bots": 1.15,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 47.0
	},
	"63": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 13,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5001,
		"power_bots": 0.65,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 59.0
	},
	"64": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 13,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1994,
		"power_bots": 0.65,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 67.0
	},
	"65": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 13,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1825,
		"power_bots": 0.65,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 68.0
	},
	"66": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 13,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 163,
		"power_bots": 0.0722222222,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 66.0
	},
	"67": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 13,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 16,
		"power_bots": 0.0722222222,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 70.0
	},
	"68": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 14,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 10237,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 47.0
	},
	"69": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 14,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4839,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 60.0
	},
	"70": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 14,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1901,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 68.0
	},
	"71": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 14,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1693,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 69.0
	},
	"72": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 14,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 415,
		"power_bots": 0.0777777778,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 66.0
	},
	"73": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 14,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 233,
		"power_bots": 0.0777777778,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 70.0
	},
	"74": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 15,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 10504,
		"power_bots": 1.25,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 48.0
	},
	"75": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 15,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4983,
		"power_bots": 0.75,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 61.0
	},
	"76": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 15,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1688,
		"power_bots": 0.75,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 69.0
	},
	"77": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 15,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1714,
		"power_bots": 0.75,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 71.0
	},
	"78": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 15,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 613,
		"power_bots": 0.0833333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 66.0
	},
	"79": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 15,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 424,
		"power_bots": 0.0833333333,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 71.0
	},
	"80": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 16,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 10371,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 49.0
	},
	"81": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 16,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4988,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 62.0
	},
	"82": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 16,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1567,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 70.0
	},
	"83": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 16,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1524,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 72.0
	},
	"84": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 16,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 880,
		"power_bots": 0.0888888889,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 67.0
	},
	"85": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 16,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 713,
		"power_bots": 0.0888888889,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 71.0
	},
	"86": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 17,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 9127,
		"power_bots": 1.35,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 49.0
	},
	"87": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 17,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 5937,
		"power_bots": 0.85,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 63.0
	},
	"88": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 17,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1583,
		"power_bots": 0.85,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 72.0
	},
	"89": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 17,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1509,
		"power_bots": 0.85,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 73.0
	},
	"90": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 17,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 973,
		"power_bots": 0.0944444444,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 67.0
	},
	"91": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 17,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 922,
		"power_bots": 0.0944444444,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 71.0
	},
	"92": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 18,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7029,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 50.0
	},
	"93": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 18,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7971,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 64.0
	},
	"94": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 18,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1549,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 73.0
	},
	"95": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 18,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1595,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 74.0
	},
	"96": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 18,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1113,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 67.0
	},
	"97": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 18,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1049,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 71.0
	},
	"98": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 19,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5081,
		"power_bots": 1.45,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 51.0
	},
	"99": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 19,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 9988,
		"power_bots": 0.95,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 65.0
	},
	"100": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 19,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1792,
		"power_bots": 0.95,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 74.0
	},
	"101": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 19,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1675,
		"power_bots": 0.95,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 75.0
	},
	"102": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 19,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1176,
		"power_bots": 0.1055555556,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 67.0
	},
	"103": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 19,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1076,
		"power_bots": 0.1055555556,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 71.0
	},
	"104": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 20,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 3214,
		"power_bots": 1.5,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 51.0
	},
	"105": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 20,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 11694,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 66.0
	},
	"106": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 20,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1964,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 75.0
	},
	"107": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 20,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1780,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 76.0
	},
	"108": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 20,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1217,
		"power_bots": 0.1111111111,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 67.0
	},
	"109": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 20,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1183,
		"power_bots": 0.1111111111,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 71.0
	},
	"110": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 21,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 1483,
		"power_bots": 1.55,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 52.0
	},
	"111": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 21,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 13681,
		"power_bots": 1.05,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 67.0
	},
	"112": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 21,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2045,
		"power_bots": 1.05,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 76.0
	},
	"113": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 21,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1921,
		"power_bots": 1.05,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 77.0
	},
	"114": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 21,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1229,
		"power_bots": 0.1166666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 67.0
	},
	"115": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 21,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1258,
		"power_bots": 0.1166666667,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 72.0
	},
	"116": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 22,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 14981,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 68.0
	},
	"117": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 22,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2309,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 77.0
	},
	"118": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 22,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2145,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 79.0
	},
	"119": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 22,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1304,
		"power_bots": 0.1222222222,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 68.0
	},
	"120": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 22,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1222,
		"power_bots": 0.1222222222,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 72.0
	},
	"121": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 23,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 15007,
		"power_bots": 1.15,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 69.0
	},
	"122": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 23,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2427,
		"power_bots": 1.15,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 78.0
	},
	"123": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 23,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2298,
		"power_bots": 1.15,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 80.0
	},
	"124": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 23,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1336,
		"power_bots": 0.1277777778,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 68.0
	},
	"125": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 23,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1341,
		"power_bots": 0.1277777778,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 72.0
	},
	"126": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 24,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 14870,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 70.0
	},
	"127": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 24,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2520,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 79.0
	},
	"128": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 24,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2305,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 81.0
	},
	"129": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 24,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1539,
		"power_bots": 0.1333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 68.0
	},
	"130": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 24,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1393,
		"power_bots": 0.1333333333,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 72.0
	},
	"131": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 25,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 14813,
		"power_bots": 1.25,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 71.0
	},
	"132": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 25,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2664,
		"power_bots": 1.25,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 80.0
	},
	"133": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 25,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2519,
		"power_bots": 1.25,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 82.0
	},
	"134": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 25,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1573,
		"power_bots": 0.1388888889,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 68.0
	},
	"135": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 25,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1469,
		"power_bots": 0.1388888889,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 72.0
	},
	"136": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 26,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 13152,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 72.0
	},
	"137": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 26,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2587,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 82.0
	},
	"138": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 26,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2306,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 83.0
	},
	"139": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 26,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1445,
		"power_bots": 0.1444444444,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 68.0
	},
	"140": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 26,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1403,
		"power_bots": 0.1444444444,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 72.0
	},
	"141": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 26,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1203,
		"power_bots": 0.1619003157,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"142": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 26,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1235,
		"power_bots": 0.1596732138,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"143": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 27,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 13189,
		"power_bots": 1.35,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 73.0
	},
	"144": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 27,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2603,
		"power_bots": 1.35,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 83.0
	},
	"145": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 27,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2483,
		"power_bots": 1.35,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 84.0
	},
	"146": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 27,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1526,
		"power_bots": 0.15,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 68.0
	},
	"147": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 27,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1504,
		"power_bots": 0.15,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 73.0
	},
	"148": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 27,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1185,
		"power_bots": 0.1682182986,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"149": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 27,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1183,
		"power_bots": 0.1677977291,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"150": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 28,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 12900,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 74.0
	},
	"151": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 28,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2759,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 84.0
	},
	"152": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 28,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2470,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 85.0
	},
	"153": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 28,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1693,
		"power_bots": 0.1555555556,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 68.0
	},
	"154": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 28,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1610,
		"power_bots": 0.1555555556,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 73.0
	},
	"155": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 28,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1195,
		"power_bots": 0.1724260801,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"156": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 28,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1220,
		"power_bots": 0.1706661289,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"157": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 29,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 12881,
		"power_bots": 1.45,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 75.0
	},
	"158": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 29,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2761,
		"power_bots": 1.45,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 85.0
	},
	"159": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 29,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2588,
		"power_bots": 1.45,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 87.0
	},
	"160": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 29,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1750,
		"power_bots": 0.1611111111,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 69.0
	},
	"161": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 29,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1770,
		"power_bots": 0.1611111111,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 73.0
	},
	"162": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 29,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1230,
		"power_bots": 0.17589554,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"163": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 29,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1191,
		"power_bots": 0.1820576671,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"164": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 30,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 13307,
		"power_bots": 1.6,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 78.0
	},
	"165": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 30,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2765,
		"power_bots": 1.6,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 88.0
	},
	"166": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 30,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2710,
		"power_bots": 1.6,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 90.0
	},
	"167": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 30,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1824,
		"power_bots": 0.1777777778,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 69.0
	},
	"168": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 30,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1813,
		"power_bots": 0.1777777778,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 73.0
	},
	"169": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 30,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1276,
		"power_bots": 0.192575928,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"170": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 30,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1238,
		"power_bots": 0.1980315185,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"171": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 31,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 12121,
		"power_bots": 1.65,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 79.0
	},
	"172": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 31,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2567,
		"power_bots": 1.65,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 89.0
	},
	"173": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 31,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2478,
		"power_bots": 1.65,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 91.0
	},
	"174": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 31,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1819,
		"power_bots": 0.1833333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 69.0
	},
	"175": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 31,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1677,
		"power_bots": 0.1833333333,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 74.0
	},
	"176": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 31,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2399,
		"power_bots": 0.198,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"177": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 31,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2349,
		"power_bots": 0.1999270206,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"178": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 32,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 12100,
		"power_bots": 1.7,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 80.0
	},
	"179": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 32,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2558,
		"power_bots": 1.7,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 90.0
	},
	"180": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 32,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2489,
		"power_bots": 1.7,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 92.0
	},
	"181": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 32,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1832,
		"power_bots": 0.1888888889,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 69.0
	},
	"182": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 32,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1877,
		"power_bots": 0.1888888889,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 74.0
	},
	"183": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 32,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2510,
		"power_bots": 0.2013616712,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"184": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 32,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2466,
		"power_bots": 0.2093778508,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"185": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 33,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 12291,
		"power_bots": 1.75,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 81.0
	},
	"186": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 33,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2720,
		"power_bots": 1.75,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 92.0
	},
	"187": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 33,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2610,
		"power_bots": 1.75,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 93.0
	},
	"188": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 33,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1974,
		"power_bots": 0.1944444444,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 70.0
	},
	"189": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 33,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1819,
		"power_bots": 0.1944444444,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 74.0
	},
	"190": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 33,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2529,
		"power_bots": 0.2085654977,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"191": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 33,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2449,
		"power_bots": 0.209593428,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"192": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 34,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 12688,
		"power_bots": 1.8,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 82.0
	},
	"193": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 34,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2726,
		"power_bots": 1.8,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 93.0
	},
	"194": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 34,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2646,
		"power_bots": 1.8,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 94.0
	},
	"195": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 34,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1914,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 70.0
	},
	"196": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 34,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2053,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 74.0
	},
	"197": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 34,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2520,
		"power_bots": 0.2154450323,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"198": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 34,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2504,
		"power_bots": 0.2117663617,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"199": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 35,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 12593,
		"power_bots": 1.85,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 83.0
	},
	"200": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 35,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2851,
		"power_bots": 1.85,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 94.0
	},
	"201": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 35,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2732,
		"power_bots": 1.85,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 96.0
	},
	"202": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 35,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2106,
		"power_bots": 0.2055555556,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 70.0
	},
	"203": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 35,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2021,
		"power_bots": 0.2055555556,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 74.0
	},
	"204": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 35,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 2578,
		"power_bots": 0.2213140531,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"205": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 35,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2564,
		"power_bots": 0.2192621368,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 63.0
	},
	"206": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 36,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 11401,
		"power_bots": 1.9,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 84.0
	},
	"207": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 36,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2629,
		"power_bots": 1.9,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 95.0
	},
	"208": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 36,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2534,
		"power_bots": 1.9,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 97.0
	},
	"209": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 36,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1937,
		"power_bots": 0.2111111111,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 70.0
	},
	"210": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 36,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1886,
		"power_bots": 0.2111111111,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 74.0
	},
	"211": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 36,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3767,
		"power_bots": 0.2254812945,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"212": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 36,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3666,
		"power_bots": 0.2219691403,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 63.0
	},
	"213": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 37,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 11522,
		"power_bots": 1.95,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 85.0
	},
	"214": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 37,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2719,
		"power_bots": 1.95,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 96.0
	},
	"215": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 37,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2592,
		"power_bots": 1.95,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 98.0
	},
	"216": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 37,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1953,
		"power_bots": 0.2166666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 70.0
	},
	"217": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 37,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1942,
		"power_bots": 0.2166666667,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 75.0
	},
	"218": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 37,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3910,
		"power_bots": 0.2298297906,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"219": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 37,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3828,
		"power_bots": 0.2321529686,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 63.0
	},
	"220": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 38,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 11609,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 86.0
	},
	"221": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 38,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2686,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 97.0
	},
	"222": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 38,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2634,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 99.0
	},
	"223": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 38,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2082,
		"power_bots": 0.2222222222,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 70.0
	},
	"224": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 38,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2019,
		"power_bots": 0.2222222222,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 75.0
	},
	"225": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 38,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3993,
		"power_bots": 0.233392536,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"226": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 38,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3869,
		"power_bots": 0.2361576602,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 63.0
	},
	"227": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 39,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 11690,
		"power_bots": 2.05,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 87.0
	},
	"228": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 39,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2801,
		"power_bots": 2.05,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 98.0
	},
	"229": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 39,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2712,
		"power_bots": 2.05,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 100.0
	},
	"230": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 39,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2094,
		"power_bots": 0.2277777778,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 71.0
	},
	"231": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 39,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2061,
		"power_bots": 0.2277777778,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 75.0
	},
	"232": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 39,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3956,
		"power_bots": 0.2384247558,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"233": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 39,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4017,
		"power_bots": 0.2374905703,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 63.0
	},
	"234": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 40,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 11526,
		"power_bots": 4.1,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 125.0
	},
	"235": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 40,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2886,
		"power_bots": 4.1,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 142.0
	},
	"236": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 40,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2927,
		"power_bots": 4.1,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 145.0
	},
	"237": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 40,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2207,
		"power_bots": 0.4555555556,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 77.0
	},
	"238": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 40,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2133,
		"power_bots": 0.4555555556,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 82.0
	},
	"239": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 40,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4022,
		"power_bots": 0.4801068518,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 51.0
	},
	"240": {
		"Имя шахты": "Кимберлитовая шахта",
		"Этаж шахты": 40,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3883,
		"power_bots": 0.4784055397,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 69.0
	},
	"241": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 1,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1583,
		"power_bots": 0.54,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 33.0
	},
	"242": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 1,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1549,
		"power_bots": 0.54,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 26.0
	},
	"243": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 1,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 6885,
		"power_bots": 0.54,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 22.0
	},
	"244": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 1,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 202,
		"power_bots": 0.54,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 38.0
	},
	"245": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 2,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1208,
		"power_bots": 0.58,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 34.0
	},
	"246": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 2,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2009,
		"power_bots": 0.58,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 26.0
	},
	"247": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 2,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 7588,
		"power_bots": 0.58,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 23.0
	},
	"248": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 2,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 972,
		"power_bots": 0.58,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 39.0
	},
	"249": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 2,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 974,
		"power_bots": 0.08,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 48.0
	},
	"250": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 3,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1815,
		"power_bots": 0.62,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 34.0
	},
	"251": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 3,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2842,
		"power_bots": 0.62,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 27.0
	},
	"252": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 3,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8510,
		"power_bots": 0.62,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 23.0
	},
	"253": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 3,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1523,
		"power_bots": 0.62,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 40.0
	},
	"254": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 3,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1314,
		"power_bots": 0.12,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 48.0
	},
	"255": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 4,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2445,
		"power_bots": 0.66,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 35.0
	},
	"256": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 4,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3305,
		"power_bots": 0.66,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 27.0
	},
	"257": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 4,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8349,
		"power_bots": 0.66,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 23.0
	},
	"258": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 4,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2132,
		"power_bots": 0.66,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 40.0
	},
	"259": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 4,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1987,
		"power_bots": 0.16,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 49.0
	},
	"260": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 5,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3166,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 35.0
	},
	"261": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 5,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8729,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 28.0
	},
	"262": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 5,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 1720,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 24.0
	},
	"263": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 5,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3041,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 41.0
	},
	"264": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 5,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2771,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 50.0
	},
	"265": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 6,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4014,
		"power_bots": 0.74,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 36.0
	},
	"266": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 6,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10479,
		"power_bots": 0.74,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 28.0
	},
	"267": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 6,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3658,
		"power_bots": 0.74,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 41.0
	},
	"268": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 6,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3398,
		"power_bots": 0.24,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 51.0
	},
	"269": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 7,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4613,
		"power_bots": 0.78,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 36.0
	},
	"270": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 7,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10837,
		"power_bots": 0.78,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 28.0
	},
	"271": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 7,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4341,
		"power_bots": 0.78,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 42.0
	},
	"272": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 7,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4027,
		"power_bots": 0.28,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 52.0
	},
	"273": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 8,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5168,
		"power_bots": 0.82,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 37.0
	},
	"274": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 8,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 11606,
		"power_bots": 0.82,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 29.0
	},
	"275": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 8,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4853,
		"power_bots": 0.82,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 42.0
	},
	"276": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 8,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4603,
		"power_bots": 0.32,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 53.0
	},
	"277": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 8,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 24,
		"power_bots": 0.32,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 59.0
	},
	"278": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 9,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3837,
		"power_bots": 0.86,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 37.0
	},
	"279": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 9,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8633,
		"power_bots": 0.86,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 29.0
	},
	"280": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 9,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3627,
		"power_bots": 0.86,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 43.0
	},
	"281": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 9,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6354,
		"power_bots": 0.36,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 53.0
	},
	"282": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 9,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 629,
		"power_bots": 0.36,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 60.0
	},
	"283": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 9,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 485,
		"power_bots": 0.36,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 62.0
	},
	"284": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 10,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 2036,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 38.0
	},
	"285": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 10,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 4478,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 29.0
	},
	"286": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 10,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1901,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 43.0
	},
	"287": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 10,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8875,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 54.0
	},
	"288": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 10,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1178,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 61.0
	},
	"289": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 10,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1081,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 62.0
	},
	"290": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 11,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 354,
		"power_bots": 0.94,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 38.0
	},
	"291": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 11,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 871,
		"power_bots": 0.94,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 30.0
	},
	"292": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 11,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 301,
		"power_bots": 0.94,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 44.0
	},
	"293": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 11,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 11156,
		"power_bots": 0.44,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 55.0
	},
	"294": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 11,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1787,
		"power_bots": 0.44,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 62.0
	},
	"295": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 11,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1686,
		"power_bots": 0.44,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 63.0
	},
	"296": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 12,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 12006,
		"power_bots": 0.48,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 56.0
	},
	"297": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 12,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2069,
		"power_bots": 0.48,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 63.0
	},
	"298": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 12,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1879,
		"power_bots": 0.48,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 64.0
	},
	"299": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 13,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 12417,
		"power_bots": 0.52,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 57.0
	},
	"300": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 13,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2219,
		"power_bots": 0.52,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 64.0
	},
	"301": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 13,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2114,
		"power_bots": 0.52,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 65.0
	},
	"302": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 14,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 13655,
		"power_bots": 0.56,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 57.0
	},
	"303": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 14,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2210,
		"power_bots": 0.56,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 65.0
	},
	"304": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 14,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2356,
		"power_bots": 0.56,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 66.0
	},
	"305": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 14,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 20,
		"power_bots": 0.224,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 70.0
	},
	"306": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 15,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 13611,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 58.0
	},
	"307": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 15,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2105,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 66.0
	},
	"308": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 15,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2088,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 67.0
	},
	"309": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 15,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 335,
		"power_bots": 0.24,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 71.0
	},
	"310": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 15,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 157,
		"power_bots": 0.24,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 75.0
	},
	"311": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 16,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 14170,
		"power_bots": 0.64,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 59.0
	},
	"312": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 16,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1969,
		"power_bots": 0.64,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 67.0
	},
	"313": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 16,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1944,
		"power_bots": 0.64,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 68.0
	},
	"314": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 16,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 526,
		"power_bots": 0.256,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 71.0
	},
	"315": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 16,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 380,
		"power_bots": 0.256,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 76.0
	},
	"316": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 17,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 14480,
		"power_bots": 0.68,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 60.0
	},
	"317": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 17,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1730,
		"power_bots": 0.68,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 68.0
	},
	"318": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 17,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1796,
		"power_bots": 0.68,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 69.0
	},
	"319": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 17,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 797,
		"power_bots": 0.272,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 72.0
	},
	"320": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 17,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 614,
		"power_bots": 0.272,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 76.0
	},
	"321": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 18,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 14157,
		"power_bots": 0.72,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 61.0
	},
	"322": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 18,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1705,
		"power_bots": 0.72,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 69.0
	},
	"323": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 18,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1714,
		"power_bots": 0.72,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 70.0
	},
	"324": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 18,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 961,
		"power_bots": 0.288,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 72.0
	},
	"325": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 18,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 830,
		"power_bots": 0.288,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 77.0
	},
	"326": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 19,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 13937,
		"power_bots": 0.76,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 61.0
	},
	"327": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 19,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1709,
		"power_bots": 0.76,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 69.0
	},
	"328": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 19,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1645,
		"power_bots": 0.76,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 71.0
	},
	"329": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 19,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1091,
		"power_bots": 0.304,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 73.0
	},
	"330": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 19,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1061,
		"power_bots": 0.304,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 77.0
	},
	"331": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 20,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 14493,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 62.0
	},
	"332": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 20,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1765,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 70.0
	},
	"333": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 20,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1730,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 72.0
	},
	"334": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 20,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1168,
		"power_bots": 0.32,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 73.0
	},
	"335": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 20,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1129,
		"power_bots": 0.32,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 78.0
	},
	"336": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 21,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 14503,
		"power_bots": 0.84,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 63.0
	},
	"337": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 21,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1935,
		"power_bots": 0.84,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 71.0
	},
	"338": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 21,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1852,
		"power_bots": 0.84,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 73.0
	},
	"339": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 21,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1178,
		"power_bots": 0.336,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 74.0
	},
	"340": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 21,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1230,
		"power_bots": 0.336,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 78.0
	},
	"341": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 22,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 15013,
		"power_bots": 0.88,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 64.0
	},
	"342": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 22,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2060,
		"power_bots": 0.88,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 72.0
	},
	"343": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 22,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2004,
		"power_bots": 0.88,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 74.0
	},
	"344": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 22,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1205,
		"power_bots": 0.352,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 74.0
	},
	"345": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 22,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1246,
		"power_bots": 0.352,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 79.0
	},
	"346": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 23,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 15458,
		"power_bots": 0.92,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 65.0
	},
	"347": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 23,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2171,
		"power_bots": 0.92,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 73.0
	},
	"348": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 23,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2127,
		"power_bots": 0.92,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 75.0
	},
	"349": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 23,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1226,
		"power_bots": 0.368,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 75.0
	},
	"350": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 23,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1305,
		"power_bots": 0.368,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 79.0
	},
	"351": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 24,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 15585,
		"power_bots": 0.96,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 65.0
	},
	"352": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 24,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2409,
		"power_bots": 0.96,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 74.0
	},
	"353": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 24,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2227,
		"power_bots": 0.96,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 75.0
	},
	"354": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 24,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1319,
		"power_bots": 0.384,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 75.0
	},
	"355": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 24,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1289,
		"power_bots": 0.384,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 80.0
	},
	"356": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 25,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 15562,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 66.0
	},
	"357": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 25,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2422,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 75.0
	},
	"358": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 25,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2475,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 76.0
	},
	"359": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 25,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1432,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 75.0
	},
	"360": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 25,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1325,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 80.0
	},
	"361": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 26,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 14090,
		"power_bots": 1.04,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 67.0
	},
	"362": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 26,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2388,
		"power_bots": 1.04,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 76.0
	},
	"363": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 26,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2267,
		"power_bots": 1.04,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 77.0
	},
	"364": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 26,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1272,
		"power_bots": 0.416,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 76.0
	},
	"365": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 26,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1318,
		"power_bots": 0.416,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 81.0
	},
	"366": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 26,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1158,
		"power_bots": 0.1371346861,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"367": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 26,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1166,
		"power_bots": 0.1375169227,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"368": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 27,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 14118,
		"power_bots": 1.08,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 68.0
	},
	"369": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 27,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2543,
		"power_bots": 1.08,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 77.0
	},
	"370": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 27,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2306,
		"power_bots": 1.08,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 78.0
	},
	"371": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 27,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1386,
		"power_bots": 0.432,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 76.0
	},
	"372": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 27,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1346,
		"power_bots": 0.432,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 81.0
	},
	"373": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 27,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1213,
		"power_bots": 0.1453840813,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"374": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 27,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1225,
		"power_bots": 0.141837182,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"375": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 28,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 13853,
		"power_bots": 1.12,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 69.0
	},
	"376": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 28,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2553,
		"power_bots": 1.12,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 78.0
	},
	"377": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 28,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2563,
		"power_bots": 1.12,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 79.0
	},
	"378": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 28,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1444,
		"power_bots": 0.448,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 77.0
	},
	"379": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 28,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1416,
		"power_bots": 0.448,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 82.0
	},
	"380": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 28,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1231,
		"power_bots": 0.1472436364,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"381": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 28,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1174,
		"power_bots": 0.1486171429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"382": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 29,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 13869,
		"power_bots": 1.16,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 69.0
	},
	"383": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 29,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2731,
		"power_bots": 1.16,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 78.0
	},
	"384": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 29,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2586,
		"power_bots": 1.16,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 80.0
	},
	"385": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 29,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1549,
		"power_bots": 0.464,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 77.0
	},
	"386": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 29,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1498,
		"power_bots": 0.464,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 82.0
	},
	"387": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 29,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1231,
		"power_bots": 0.1534045059,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"388": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 29,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1185,
		"power_bots": 0.1531450135,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"389": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 30,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 13594,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 72.0
	},
	"390": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 30,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2666,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 82.0
	},
	"391": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 30,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2714,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 83.0
	},
	"392": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 30,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1612,
		"power_bots": 0.52,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 79.0
	},
	"393": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 30,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1634,
		"power_bots": 0.52,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 84.0
	},
	"394": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 30,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1266,
		"power_bots": 0.1664241739,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"395": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 30,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1237,
		"power_bots": 0.1709644444,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"396": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 31,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 12152,
		"power_bots": 1.34,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 73.0
	},
	"397": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 31,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2447,
		"power_bots": 1.34,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 82.0
	},
	"398": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 31,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2515,
		"power_bots": 1.34,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 84.0
	},
	"399": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 31,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1560,
		"power_bots": 0.536,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 79.0
	},
	"400": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 31,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1523,
		"power_bots": 0.536,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 84.0
	},
	"401": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 31,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 2406,
		"power_bots": 0.1732550968,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"402": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 31,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2491,
		"power_bots": 0.1734242702,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"403": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 32,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 12701,
		"power_bots": 1.38,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 74.0
	},
	"404": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 32,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2535,
		"power_bots": 1.38,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 83.0
	},
	"405": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 32,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2583,
		"power_bots": 1.38,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 85.0
	},
	"406": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 32,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1642,
		"power_bots": 0.552,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 80.0
	},
	"407": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 32,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1546,
		"power_bots": 0.552,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 85.0
	},
	"408": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 32,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 2458,
		"power_bots": 0.1772951665,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"409": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 32,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 2422,
		"power_bots": 0.1776174749,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"410": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 33,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 12500,
		"power_bots": 1.42,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 75.0
	},
	"411": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 33,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2667,
		"power_bots": 1.42,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 84.0
	},
	"412": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 33,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2610,
		"power_bots": 1.42,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 86.0
	},
	"413": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 33,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1745,
		"power_bots": 0.568,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 80.0
	},
	"414": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 33,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1616,
		"power_bots": 0.568,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 85.0
	},
	"415": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 33,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 2484,
		"power_bots": 0.1815391022,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 45.0
	},
	"416": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 33,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2461,
		"power_bots": 0.1826831117,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"417": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 34,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 12658,
		"power_bots": 1.46,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 75.0
	},
	"418": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 34,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2701,
		"power_bots": 1.46,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 85.0
	},
	"419": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 34,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2602,
		"power_bots": 1.46,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 87.0
	},
	"420": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 34,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1795,
		"power_bots": 0.584,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 81.0
	},
	"421": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 34,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1738,
		"power_bots": 0.584,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 86.0
	},
	"422": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 34,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2502,
		"power_bots": 0.1863572144,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"423": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 34,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2506,
		"power_bots": 0.1860738941,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"424": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 35,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 12918,
		"power_bots": 1.5,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 76.0
	},
	"425": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 35,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2834,
		"power_bots": 1.5,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 86.0
	},
	"426": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 35,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2711,
		"power_bots": 1.5,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 88.0
	},
	"427": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 35,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1818,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 81.0
	},
	"428": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 35,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1731,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 86.0
	},
	"429": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 35,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2552,
		"power_bots": 0.1912680931,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"430": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 35,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2645,
		"power_bots": 0.1908777154,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"431": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 36,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 11608,
		"power_bots": 1.54,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 77.0
	},
	"432": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 36,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2526,
		"power_bots": 1.54,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 87.0
	},
	"433": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 36,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2533,
		"power_bots": 1.54,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 89.0
	},
	"434": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 36,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1788,
		"power_bots": 0.616,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 81.0
	},
	"435": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 36,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1614,
		"power_bots": 0.616,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 87.0
	},
	"436": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 36,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3706,
		"power_bots": 0.1964333457,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"437": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 36,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3865,
		"power_bots": 0.1956932271,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"438": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 37,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 11960,
		"power_bots": 1.58,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 78.0
	},
	"439": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 37,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2636,
		"power_bots": 1.58,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 88.0
	},
	"440": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 37,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2461,
		"power_bots": 1.58,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 90.0
	},
	"441": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 37,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1783,
		"power_bots": 0.632,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 82.0
	},
	"442": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 37,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1760,
		"power_bots": 0.632,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 87.0
	},
	"443": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 37,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3759,
		"power_bots": 0.1995841502,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"444": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 37,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3835,
		"power_bots": 0.2008662701,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"445": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 38,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 11984,
		"power_bots": 1.62,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 78.0
	},
	"446": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 38,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2659,
		"power_bots": 1.62,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 89.0
	},
	"447": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 38,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2506,
		"power_bots": 1.62,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 90.0
	},
	"448": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 38,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1938,
		"power_bots": 0.648,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 82.0
	},
	"449": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 38,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1778,
		"power_bots": 0.648,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 88.0
	},
	"450": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 38,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3965,
		"power_bots": 0.207261081,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"451": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 38,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3844,
		"power_bots": 0.2044604143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"452": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 39,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 11819,
		"power_bots": 1.66,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 79.0
	},
	"453": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 39,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2716,
		"power_bots": 1.66,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 90.0
	},
	"454": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 39,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2760,
		"power_bots": 1.66,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 91.0
	},
	"455": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 39,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1907,
		"power_bots": 0.664,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 83.0
	},
	"456": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 39,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1902,
		"power_bots": 0.664,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 88.0
	},
	"457": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 39,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3911,
		"power_bots": 0.2111858511,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 46.0
	},
	"458": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 39,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3987,
		"power_bots": 0.2098311997,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"459": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 40,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 12224,
		"power_bots": 3.7,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 118.0
	},
	"460": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 40,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2776,
		"power_bots": 3.7,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 133.0
	},
	"461": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 40,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2774,
		"power_bots": 3.7,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 136.0
	},
	"462": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 40,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2001,
		"power_bots": 1.48,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 105.0
	},
	"463": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 40,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1894,
		"power_bots": 1.48,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 112.0
	},
	"464": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 40,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3980,
		"power_bots": 0.4661568361,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 51.0
	},
	"465": {
		"Имя шахты": "Корундовая шахта",
		"Этаж шахты": 40,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4015,
		"power_bots": 0.4684537903,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 69.0
	},
	"466": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 1,
		"id бота": 17,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 7341,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 17,
		"name": "Древний зомби",
		"level": 8,
		"real level": 8.0
	},
	"467": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 1,
		"id бота": 18,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1524,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 18,
		"name": "Скелет-стражник",
		"level": 10,
		"real level": 10.0
	},
	"468": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 1,
		"id бота": 19,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 880,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 19,
		"name": "Огонь глубин",
		"level": 16,
		"real level": 17.0
	},
	"469": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 1,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 107,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 28.0
	},
	"470": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 1,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 795,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 22.0
	},
	"471": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 1,
		"id бота": 32,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1240,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 32,
		"name": "Кровопийца",
		"level": 17,
		"real level": 18.0
	},
	"472": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 1,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 833,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 19.0
	},
	"473": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 2,
		"id бота": 19,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2086,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 19,
		"name": "Огонь глубин",
		"level": 16,
		"real level": 17.0
	},
	"474": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 2,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 983,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 29.0
	},
	"475": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 2,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1285,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 23.0
	},
	"476": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 2,
		"id бота": 32,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 7926,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 32,
		"name": "Кровопийца",
		"level": 17,
		"real level": 19.0
	},
	"477": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 2,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1433,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 20.0
	},
	"478": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 2,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 75,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 34.0
	},
	"479": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 3,
		"id бота": 19,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1870,
		"power_bots": 0.3,
		"count_battles": 5000,
		"id": 19,
		"name": "Огонь глубин",
		"level": 16,
		"real level": 18.0
	},
	"480": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 3,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1186,
		"power_bots": 0.3,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 31.0
	},
	"481": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 3,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1431,
		"power_bots": 0.3,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 24.0
	},
	"482": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 3,
		"id бота": 32,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7313,
		"power_bots": 0.3,
		"count_battles": 5000,
		"id": 32,
		"name": "Кровопийца",
		"level": 17,
		"real level": 19.0
	},
	"483": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 3,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1513,
		"power_bots": 0.3,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 20.0
	},
	"484": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 3,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 726,
		"power_bots": 0.3,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 35.0
	},
	"485": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 3,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 699,
		"power_bots": 0.3,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 52.0
	},
	"486": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 4,
		"id бота": 19,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2293,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 19,
		"name": "Огонь глубин",
		"level": 16,
		"real level": 19.0
	},
	"487": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 4,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1501,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 32.0
	},
	"488": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 4,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1808,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 25.0
	},
	"489": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 4,
		"id бота": 32,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7812,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 32,
		"name": "Кровопийца",
		"level": 17,
		"real level": 20.0
	},
	"490": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 4,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2189,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 21.0
	},
	"491": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 4,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1017,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 37.0
	},
	"492": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 4,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 897,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 54.0
	},
	"493": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 5,
		"id бота": 19,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 441,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 19,
		"name": "Огонь глубин",
		"level": 16,
		"real level": 20.0
	},
	"494": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 5,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2362,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 33.0
	},
	"495": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 5,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2669,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 26.0
	},
	"496": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 5,
		"id бота": 32,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 1422,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 32,
		"name": "Кровопийца",
		"level": 17,
		"real level": 21.0
	},
	"497": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 5,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6886,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 22.0
	},
	"498": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 5,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1588,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 38.0
	},
	"499": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 5,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1496,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 56.0
	},
	"500": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 6,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2756,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 34.0
	},
	"501": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 6,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3146,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 27.0
	},
	"502": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 6,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8410,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 23.0
	},
	"503": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 6,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2000,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 39.0
	},
	"504": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 6,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1924,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 58.0
	},
	"505": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 7,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3099,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 35.0
	},
	"506": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 7,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3557,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 28.0
	},
	"507": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 7,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8808,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 24.0
	},
	"508": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 7,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2413,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 41.0
	},
	"509": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 7,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2257,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 60.0
	},
	"510": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 8,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3422,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 37.0
	},
	"511": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 8,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3895,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 28.0
	},
	"512": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 8,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 9193,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 24.0
	},
	"513": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 8,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2740,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 42.0
	},
	"514": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 8,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2670,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 62.0
	},
	"515": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 8,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 24,
		"power_bots": 0.5714285714,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 65.0
	},
	"516": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 9,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3341,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 38.0
	},
	"517": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 9,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3941,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 29.0
	},
	"518": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 9,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8923,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 25.0
	},
	"519": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 9,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2815,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 43.0
	},
	"520": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 9,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2660,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 64.0
	},
	"521": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 9,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 340,
		"power_bots": 0.6428571429,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 67.0
	},
	"522": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 9,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 238,
		"power_bots": 0.6428571429,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 68.0
	},
	"523": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 10,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3540,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 39.0
	},
	"524": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 10,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6014,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 30.0
	},
	"525": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 10,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5299,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 26.0
	},
	"526": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 10,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2865,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 45.0
	},
	"527": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 10,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2725,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 66.0
	},
	"528": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 10,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 668,
		"power_bots": 0.7142857143,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 68.0
	},
	"529": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 10,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 517,
		"power_bots": 0.7142857143,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 70.0
	},
	"530": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 11,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3660,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 40.0
	},
	"531": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 11,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7861,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 31.0
	},
	"532": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 11,
		"id бота": 33,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 2403,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 33,
		"name": "Шахтный бес",
		"level": 18,
		"real level": 27.0
	},
	"533": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 11,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2869,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 46.0
	},
	"534": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 11,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2701,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 68.0
	},
	"535": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 11,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 942,
		"power_bots": 0.7857142857,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 70.0
	},
	"536": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 11,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 864,
		"power_bots": 0.7857142857,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 71.0
	},
	"537": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 12,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3883,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 41.0
	},
	"538": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 12,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 9652,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 32.0
	},
	"539": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 12,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2971,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 47.0
	},
	"540": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 12,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2907,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 70.0
	},
	"541": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 12,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1123,
		"power_bots": 0.8571428571,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 72.0
	},
	"542": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 12,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1087,
		"power_bots": 0.8571428571,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 73.0
	},
	"543": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 13,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3956,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 42.0
	},
	"544": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 13,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 9899,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 33.0
	},
	"545": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 13,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3109,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 49.0
	},
	"546": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 13,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2908,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 72.0
	},
	"547": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 13,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1299,
		"power_bots": 0.9285714286,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 73.0
	},
	"548": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 13,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1279,
		"power_bots": 0.9285714286,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 75.0
	},
	"549": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 14,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4040,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 44.0
	},
	"550": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 14,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 9744,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 34.0
	},
	"551": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 14,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3263,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 50.0
	},
	"552": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 14,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3028,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 74.0
	},
	"553": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 14,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1448,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 75.0
	},
	"554": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 14,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1378,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 76.0
	},
	"555": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 14,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 106,
		"power_bots": 0.4666666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 77.0
	},
	"556": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 15,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3969,
		"power_bots": 1.5,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 45.0
	},
	"557": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 15,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 9720,
		"power_bots": 1.5,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 35.0
	},
	"558": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 15,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3213,
		"power_bots": 1.5,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 51.0
	},
	"559": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 15,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3110,
		"power_bots": 1.5,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 76.0
	},
	"560": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 15,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1582,
		"power_bots": 1.0714285714,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 77.0
	},
	"561": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 15,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1452,
		"power_bots": 1.0714285714,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 78.0
	},
	"562": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 15,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 263,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 78.0
	},
	"563": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 16,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4323,
		"power_bots": 1.6,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 46.0
	},
	"564": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 16,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10022,
		"power_bots": 1.6,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 36.0
	},
	"565": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 16,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3272,
		"power_bots": 1.6,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 53.0
	},
	"566": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 16,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3246,
		"power_bots": 1.6,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 78.0
	},
	"567": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 16,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1541,
		"power_bots": 1.1428571429,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 78.0
	},
	"568": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 16,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1510,
		"power_bots": 1.1428571429,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 80.0
	},
	"569": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 16,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 438,
		"power_bots": 0.5333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 79.0
	},
	"570": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 17,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4372,
		"power_bots": 1.7,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 47.0
	},
	"571": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 17,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 10099,
		"power_bots": 1.7,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 37.0
	},
	"572": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 17,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3480,
		"power_bots": 1.7,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 54.0
	},
	"573": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 17,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3193,
		"power_bots": 1.7,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 80.0
	},
	"574": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 17,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1506,
		"power_bots": 1.2142857143,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 80.0
	},
	"575": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 17,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1613,
		"power_bots": 1.2142857143,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 81.0
	},
	"576": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 17,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 659,
		"power_bots": 0.5666666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 80.0
	},
	"577": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 18,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4377,
		"power_bots": 1.8,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 48.0
	},
	"578": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 18,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10047,
		"power_bots": 1.8,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 37.0
	},
	"579": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 18,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3387,
		"power_bots": 1.8,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 55.0
	},
	"580": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 18,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3358,
		"power_bots": 1.8,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 82.0
	},
	"581": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 18,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1650,
		"power_bots": 1.2857142857,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 81.0
	},
	"582": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 18,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1583,
		"power_bots": 1.2857142857,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 83.0
	},
	"583": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 18,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 871,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 81.0
	},
	"584": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 19,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4392,
		"power_bots": 1.9,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 49.0
	},
	"585": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 19,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 10026,
		"power_bots": 1.9,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 38.0
	},
	"586": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 19,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3559,
		"power_bots": 1.9,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 57.0
	},
	"587": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 19,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3289,
		"power_bots": 1.9,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 84.0
	},
	"588": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 19,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1739,
		"power_bots": 1.3571428571,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 83.0
	},
	"589": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 19,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1652,
		"power_bots": 1.3571428571,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 84.0
	},
	"590": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 19,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1044,
		"power_bots": 0.6333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 82.0
	},
	"591": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 20,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4742,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 50.0
	},
	"592": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 20,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 10351,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 39.0
	},
	"593": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 20,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3783,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 58.0
	},
	"594": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 20,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3639,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 86.0
	},
	"595": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 20,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1818,
		"power_bots": 1.4285714286,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 84.0
	},
	"596": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 20,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1669,
		"power_bots": 1.4285714286,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 86.0
	},
	"597": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 20,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1089,
		"power_bots": 0.6666666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 83.0
	},
	"598": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 21,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4724,
		"power_bots": 2.1,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 52.0
	},
	"599": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 21,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10382,
		"power_bots": 2.1,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 40.0
	},
	"600": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 21,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3792,
		"power_bots": 2.1,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 59.0
	},
	"601": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 21,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3663,
		"power_bots": 2.1,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 88.0
	},
	"602": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 21,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1881,
		"power_bots": 1.5,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 86.0
	},
	"603": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 21,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1806,
		"power_bots": 1.5,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 88.0
	},
	"604": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 21,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1260,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 84.0
	},
	"605": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 22,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4889,
		"power_bots": 2.2,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 53.0
	},
	"606": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 22,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 10553,
		"power_bots": 2.2,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 41.0
	},
	"607": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 22,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3817,
		"power_bots": 2.2,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 60.0
	},
	"608": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 22,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3836,
		"power_bots": 2.2,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 90.0
	},
	"609": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 22,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2065,
		"power_bots": 1.5714285714,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 88.0
	},
	"610": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 22,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1979,
		"power_bots": 1.5714285714,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 89.0
	},
	"611": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 22,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1243,
		"power_bots": 0.7333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 85.0
	},
	"612": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 23,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4927,
		"power_bots": 2.3,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 54.0
	},
	"613": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 23,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10665,
		"power_bots": 2.3,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 42.0
	},
	"614": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 23,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4016,
		"power_bots": 2.3,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 62.0
	},
	"615": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 23,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3948,
		"power_bots": 2.3,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 92.0
	},
	"616": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 23,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2057,
		"power_bots": 1.6428571429,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 89.0
	},
	"617": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 23,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1990,
		"power_bots": 1.6428571429,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 91.0
	},
	"618": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 23,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1457,
		"power_bots": 0.7666666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 86.0
	},
	"619": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 24,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 5141,
		"power_bots": 2.4,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 55.0
	},
	"620": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 24,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 10820,
		"power_bots": 2.4,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 43.0
	},
	"621": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 24,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4173,
		"power_bots": 2.4,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 63.0
	},
	"622": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 24,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4074,
		"power_bots": 2.4,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 94.0
	},
	"623": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 24,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2205,
		"power_bots": 1.7142857143,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 91.0
	},
	"624": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 24,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2134,
		"power_bots": 1.7142857143,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 93.0
	},
	"625": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 24,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1472,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 87.0
	},
	"626": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 25,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5229,
		"power_bots": 2.5,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 56.0
	},
	"627": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 25,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10957,
		"power_bots": 2.5,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 44.0
	},
	"628": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 25,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4270,
		"power_bots": 2.5,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 64.0
	},
	"629": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 25,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4187,
		"power_bots": 2.5,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 95.0
	},
	"630": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 25,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2279,
		"power_bots": 1.7857142857,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 92.0
	},
	"631": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 25,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2378,
		"power_bots": 1.7857142857,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 94.0
	},
	"632": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 25,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1524,
		"power_bots": 0.8333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 88.0
	},
	"633": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 26,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4827,
		"power_bots": 2.6,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 57.0
	},
	"634": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 26,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 9978,
		"power_bots": 2.6,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 44.0
	},
	"635": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 26,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3989,
		"power_bots": 2.6,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 66.0
	},
	"636": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 26,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3861,
		"power_bots": 2.6,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 97.0
	},
	"637": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 26,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2129,
		"power_bots": 1.8571428571,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 94.0
	},
	"638": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 26,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2146,
		"power_bots": 1.8571428571,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 96.0
	},
	"639": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 26,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1464,
		"power_bots": 0.8666666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 88.0
	},
	"640": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 26,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1546,
		"power_bots": 0.338256636,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 48.0
	},
	"641": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 26,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1543,
		"power_bots": 0.3395865844,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 66.0
	},
	"642": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 27,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4784,
		"power_bots": 2.7,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 58.0
	},
	"643": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 27,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 9910,
		"power_bots": 2.7,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 45.0
	},
	"644": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 27,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4088,
		"power_bots": 2.7,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 67.0
	},
	"645": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 27,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3872,
		"power_bots": 2.7,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 99.0
	},
	"646": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 27,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2256,
		"power_bots": 1.9285714286,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 95.0
	},
	"647": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 27,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2273,
		"power_bots": 1.9285714286,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 97.0
	},
	"648": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 27,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1586,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 89.0
	},
	"649": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 27,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1598,
		"power_bots": 0.3509885898,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 49.0
	},
	"650": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 27,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1581,
		"power_bots": 0.3539058017,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 66.0
	},
	"651": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 28,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5003,
		"power_bots": 2.8,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 59.0
	},
	"652": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 28,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 9823,
		"power_bots": 2.8,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 46.0
	},
	"653": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 28,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4151,
		"power_bots": 2.8,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 68.0
	},
	"654": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 28,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4043,
		"power_bots": 2.8,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 101.0
	},
	"655": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 28,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2417,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 97.0
	},
	"656": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 28,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2333,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 99.0
	},
	"657": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 28,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1642,
		"power_bots": 0.9333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 90.0
	},
	"658": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 28,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1575,
		"power_bots": 0.3653782343,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 49.0
	},
	"659": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 28,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1662,
		"power_bots": 0.3633589494,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 66.0
	},
	"660": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 29,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5122,
		"power_bots": 2.9,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 60.0
	},
	"661": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 29,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 10186,
		"power_bots": 2.9,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 47.0
	},
	"662": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 29,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4112,
		"power_bots": 2.9,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 69.0
	},
	"663": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 29,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4123,
		"power_bots": 2.9,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 103.0
	},
	"664": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 29,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2483,
		"power_bots": 2.0714285714,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 99.0
	},
	"665": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 29,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2477,
		"power_bots": 2.0714285714,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 100.0
	},
	"666": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 29,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1727,
		"power_bots": 0.9666666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 91.0
	},
	"667": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 29,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1679,
		"power_bots": 0.3766414413,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 49.0
	},
	"668": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 29,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1598,
		"power_bots": 0.3787512253,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 67.0
	},
	"669": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 30,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5152,
		"power_bots": 3.1,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 63.0
	},
	"670": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 30,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 10286,
		"power_bots": 3.1,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 49.0
	},
	"671": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 30,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4263,
		"power_bots": 3.1,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 72.0
	},
	"672": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 30,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4154,
		"power_bots": 3.1,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 107.0
	},
	"673": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 30,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2617,
		"power_bots": 2.2142857143,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 102.0
	},
	"674": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 30,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2565,
		"power_bots": 2.2142857143,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 104.0
	},
	"675": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 30,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1832,
		"power_bots": 1.0333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 93.0
	},
	"676": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 30,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1723,
		"power_bots": 0.4041282003,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 50.0
	},
	"677": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 30,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1683,
		"power_bots": 0.403982582,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 67.0
	},
	"678": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 31,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4789,
		"power_bots": 3.2,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 64.0
	},
	"679": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 31,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 9323,
		"power_bots": 3.2,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 50.0
	},
	"680": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 31,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3984,
		"power_bots": 3.2,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 73.0
	},
	"681": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 31,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3935,
		"power_bots": 3.2,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 109.0
	},
	"682": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 31,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2394,
		"power_bots": 2.2857142857,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 103.0
	},
	"683": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 31,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2355,
		"power_bots": 2.2857142857,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 105.0
	},
	"684": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 31,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1718,
		"power_bots": 1.0666666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 94.0
	},
	"685": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 31,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3351,
		"power_bots": 0.4145359546,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 50.0
	},
	"686": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 31,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3197,
		"power_bots": 0.4123008221,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 67.0
	},
	"687": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 32,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4758,
		"power_bots": 3.3,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 65.0
	},
	"688": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 32,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 9457,
		"power_bots": 3.3,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 50.0
	},
	"689": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 32,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3979,
		"power_bots": 3.3,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 75.0
	},
	"690": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 32,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3923,
		"power_bots": 3.3,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 111.0
	},
	"691": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 32,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2505,
		"power_bots": 2.3571428571,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 105.0
	},
	"692": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 32,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2501,
		"power_bots": 2.3571428571,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 107.0
	},
	"693": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 32,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1779,
		"power_bots": 1.1,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 95.0
	},
	"694": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 32,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3378,
		"power_bots": 0.4286657396,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 50.0
	},
	"695": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 32,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3444,
		"power_bots": 0.4236148762,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 68.0
	},
	"696": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 33,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4797,
		"power_bots": 3.4,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 66.0
	},
	"697": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 33,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 9457,
		"power_bots": 3.4,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 51.0
	},
	"698": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 33,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4057,
		"power_bots": 3.4,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 76.0
	},
	"699": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 33,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4029,
		"power_bots": 3.4,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 112.0
	},
	"700": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 33,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2586,
		"power_bots": 2.4285714286,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 106.0
	},
	"701": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 33,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2634,
		"power_bots": 2.4285714286,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 108.0
	},
	"702": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 33,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1862,
		"power_bots": 1.1333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 96.0
	},
	"703": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 33,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3356,
		"power_bots": 0.4391766893,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 50.0
	},
	"704": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 33,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3422,
		"power_bots": 0.4357727435,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 68.0
	},
	"705": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 34,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4957,
		"power_bots": 3.5,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 67.0
	},
	"706": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 34,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 9577,
		"power_bots": 3.5,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 52.0
	},
	"707": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 34,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4212,
		"power_bots": 3.5,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 77.0
	},
	"708": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 34,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4104,
		"power_bots": 3.5,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 114.0
	},
	"709": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 34,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2645,
		"power_bots": 2.5,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 108.0
	},
	"710": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 34,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2656,
		"power_bots": 2.5,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 110.0
	},
	"711": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 34,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1948,
		"power_bots": 1.1666666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 97.0
	},
	"712": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 34,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3560,
		"power_bots": 0.4538510829,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 50.0
	},
	"713": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 34,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3566,
		"power_bots": 0.4512324393,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 68.0
	},
	"714": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 35,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5052,
		"power_bots": 3.6,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 68.0
	},
	"715": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 35,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 9621,
		"power_bots": 3.6,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 53.0
	},
	"716": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 35,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4246,
		"power_bots": 3.6,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 78.0
	},
	"717": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 35,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4147,
		"power_bots": 3.6,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 116.0
	},
	"718": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 35,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2740,
		"power_bots": 2.5714285714,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 109.0
	},
	"719": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 35,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2753,
		"power_bots": 2.5714285714,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 112.0
	},
	"720": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 35,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2032,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 98.0
	},
	"721": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 35,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3515,
		"power_bots": 0.46091904,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 51.0
	},
	"722": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 35,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3642,
		"power_bots": 0.4615479399,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 69.0
	},
	"723": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 36,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4545,
		"power_bots": 3.7,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 69.0
	},
	"724": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 36,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8655,
		"power_bots": 3.7,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 54.0
	},
	"725": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 36,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3828,
		"power_bots": 3.7,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 80.0
	},
	"726": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 36,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3841,
		"power_bots": 3.7,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 118.0
	},
	"727": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 36,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2433,
		"power_bots": 2.6428571429,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 111.0
	},
	"728": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 36,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2551,
		"power_bots": 2.6428571429,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 113.0
	},
	"729": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 36,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1997,
		"power_bots": 1.2333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 99.0
	},
	"730": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 36,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5189,
		"power_bots": 0.4729428683,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 51.0
	},
	"731": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 36,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5227,
		"power_bots": 0.4733033304,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 69.0
	},
	"732": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 37,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4665,
		"power_bots": 3.8,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 70.0
	},
	"733": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 37,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8785,
		"power_bots": 3.8,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 55.0
	},
	"734": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 37,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4000,
		"power_bots": 3.8,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 81.0
	},
	"735": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 37,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3956,
		"power_bots": 3.8,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 120.0
	},
	"736": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 37,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2621,
		"power_bots": 2.7142857143,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 112.0
	},
	"737": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 37,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2558,
		"power_bots": 2.7142857143,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 115.0
	},
	"738": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 37,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2055,
		"power_bots": 1.2666666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 100.0
	},
	"739": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 37,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5203,
		"power_bots": 0.4846446914,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 51.0
	},
	"740": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 37,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5299,
		"power_bots": 0.4876726255,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 69.0
	},
	"741": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 38,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4722,
		"power_bots": 3.9,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 71.0
	},
	"742": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 38,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 8801,
		"power_bots": 3.9,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 56.0
	},
	"743": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 38,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4093,
		"power_bots": 3.9,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 82.0
	},
	"744": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 38,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4038,
		"power_bots": 3.9,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 122.0
	},
	"745": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 38,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2706,
		"power_bots": 2.7857142857,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 114.0
	},
	"746": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 38,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2744,
		"power_bots": 2.7857142857,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 116.0
	},
	"747": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 38,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2075,
		"power_bots": 1.3,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 100.0
	},
	"748": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 38,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5335,
		"power_bots": 0.4988042697,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 51.0
	},
	"749": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 38,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5483,
		"power_bots": 0.4984882394,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 70.0
	},
	"750": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 39,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4763,
		"power_bots": 4.0,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 73.0
	},
	"751": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 39,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 8921,
		"power_bots": 4.0,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 56.0
	},
	"752": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 39,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4157,
		"power_bots": 4.0,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 83.0
	},
	"753": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 39,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4013,
		"power_bots": 4.0,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 124.0
	},
	"754": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 39,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2675,
		"power_bots": 2.8571428571,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 116.0
	},
	"755": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 39,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2822,
		"power_bots": 2.8571428571,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 118.0
	},
	"756": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 39,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2135,
		"power_bots": 1.3333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 101.0
	},
	"757": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 39,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5554,
		"power_bots": 0.5094317661,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 52.0
	},
	"758": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 39,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5517,
		"power_bots": 0.5064542826,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 70.0
	},
	"759": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 40,
		"id бота": 23,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4888,
		"power_bots": 6.1,
		"count_battles": 5000,
		"id": 23,
		"name": "Хранитель подземелий",
		"level": 27,
		"real level": 95.0
	},
	"760": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 40,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8930,
		"power_bots": 6.1,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 74.0
	},
	"761": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 40,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4368,
		"power_bots": 6.1,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 109.0
	},
	"762": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 40,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4042,
		"power_bots": 6.1,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 162.0
	},
	"763": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 40,
		"id бота": 65,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2844,
		"power_bots": 4.3571428571,
		"count_battles": 5000,
		"id": 65,
		"name": "Подземный трупоед",
		"level": 52,
		"real level": 147.0
	},
	"764": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 40,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2728,
		"power_bots": 4.3571428571,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 150.0
	},
	"765": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 40,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2246,
		"power_bots": 2.0333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 120.0
	},
	"766": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 40,
		"id бота": 116,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5755,
		"power_bots": 0.7764018745,
		"count_battles": 5000,
		"id": 116,
		"name": "Послушник тени",
		"level": 42,
		"real level": 56.0
	},
	"767": {
		"Имя шахты": "Предгорная шахта",
		"Этаж шахты": 40,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5571,
		"power_bots": 0.7775509005,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 77.0
	},
	"768": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 1,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1308,
		"power_bots": 0.0285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 69.0
	},
	"769": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 1,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1277,
		"power_bots": 0.0285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 67.0
	},
	"770": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 1,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 3813,
		"power_bots": 0.0142857143,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 73.0
	},
	"771": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 1,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1187,
		"power_bots": 0.0142857143,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 74.0
	},
	"772": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 2,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 983,
		"power_bots": 0.0571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 70.0
	},
	"773": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 2,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1028,
		"power_bots": 0.0571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 68.0
	},
	"774": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 2,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 971,
		"power_bots": 0.0571428571,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 58.0
	},
	"775": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 2,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 4968,
		"power_bots": 0.0285714286,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 74.0
	},
	"776": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 2,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1025,
		"power_bots": 0.0285714286,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 75.0
	},
	"777": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 3,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 504,
		"power_bots": 37.5714285714,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 161.0
	},
	"778": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 3,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1019,
		"power_bots": 0.0857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 71.0
	},
	"779": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 3,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 960,
		"power_bots": 0.0857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 69.0
	},
	"780": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 3,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 990,
		"power_bots": 0.0857142857,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 59.0
	},
	"781": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 3,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 5229,
		"power_bots": 0.0428571429,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 74.0
	},
	"782": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 3,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1259,
		"power_bots": 0.0428571429,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 75.0
	},
	"783": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 4,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 823,
		"power_bots": 38.4285714286,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 165.0
	},
	"784": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 4,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 987,
		"power_bots": 0.1142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 71.0
	},
	"785": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 4,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 980,
		"power_bots": 0.1142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 69.0
	},
	"786": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 4,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1025,
		"power_bots": 0.1142857143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 60.0
	},
	"787": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 4,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 5434,
		"power_bots": 0.0571428571,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 75.0
	},
	"788": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 4,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1412,
		"power_bots": 0.0571428571,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 76.0
	},
	"789": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 5,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 963,
		"power_bots": 39.2857142857,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 168.0
	},
	"790": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 5,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1168,
		"power_bots": 0.1428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 72.0
	},
	"791": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 5,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1174,
		"power_bots": 0.1428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 70.0
	},
	"792": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 5,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1000,
		"power_bots": 0.1428571429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"793": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 5,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 5010,
		"power_bots": 0.0714285714,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 75.0
	},
	"794": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 5,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1934,
		"power_bots": 0.0714285714,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 76.0
	},
	"795": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 6,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 945,
		"power_bots": 40.1428571429,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 171.0
	},
	"796": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 6,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1299,
		"power_bots": 0.1714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 73.0
	},
	"797": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 6,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1386,
		"power_bots": 0.1714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 71.0
	},
	"798": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 6,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1058,
		"power_bots": 0.1714285714,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 61.0
	},
	"799": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 6,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 4906,
		"power_bots": 0.0857142857,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 76.0
	},
	"800": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 6,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2112,
		"power_bots": 0.0857142857,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 77.0
	},
	"801": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 7,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 980,
		"power_bots": 41.0,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 174.0
	},
	"802": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 7,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1537,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 74.0
	},
	"803": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 7,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1595,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 72.0
	},
	"804": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 7,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1155,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 62.0
	},
	"805": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 7,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 4929,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 76.0
	},
	"806": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 7,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2254,
		"power_bots": 0.1,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 77.0
	},
	"807": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 8,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1049,
		"power_bots": 41.8571428571,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 177.0
	},
	"808": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 8,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1699,
		"power_bots": 0.2285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 75.0
	},
	"809": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 8,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1794,
		"power_bots": 0.2285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 73.0
	},
	"810": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 8,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1347,
		"power_bots": 0.2285714286,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 63.0
	},
	"811": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 8,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 5099,
		"power_bots": 0.1142857143,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 77.0
	},
	"812": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 8,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2261,
		"power_bots": 0.1142857143,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 78.0
	},
	"813": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 9,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1111,
		"power_bots": 42.7142857143,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 180.0
	},
	"814": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 9,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1836,
		"power_bots": 0.2571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 76.0
	},
	"815": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 9,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1796,
		"power_bots": 0.2571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 74.0
	},
	"816": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 9,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1506,
		"power_bots": 0.2571428571,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 64.0
	},
	"817": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 9,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5284,
		"power_bots": 0.1285714286,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 77.0
	},
	"818": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 9,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2394,
		"power_bots": 0.1285714286,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 78.0
	},
	"819": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 10,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1378,
		"power_bots": 43.5714285714,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 183.0
	},
	"820": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 10,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1878,
		"power_bots": 0.2857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 77.0
	},
	"821": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 10,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1959,
		"power_bots": 0.2857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 74.0
	},
	"822": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 10,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 1656,
		"power_bots": 0.2857142857,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 64.0
	},
	"823": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 10,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5484,
		"power_bots": 0.1428571429,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 78.0
	},
	"824": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 10,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2468,
		"power_bots": 0.1428571429,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 79.0
	},
	"825": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 11,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1538,
		"power_bots": 44.4285714286,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 186.0
	},
	"826": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 11,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2020,
		"power_bots": 0.3142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 78.0
	},
	"827": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 11,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2043,
		"power_bots": 0.3142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 75.0
	},
	"828": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 11,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1766,
		"power_bots": 0.3142857143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 65.0
	},
	"829": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 11,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5845,
		"power_bots": 0.1571428571,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 78.0
	},
	"830": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 11,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2457,
		"power_bots": 0.1571428571,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 79.0
	},
	"831": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 12,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 1592,
		"power_bots": 45.2857142857,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 190.0
	},
	"832": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 12,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2094,
		"power_bots": 0.3428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 78.0
	},
	"833": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 12,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2182,
		"power_bots": 0.3428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 76.0
	},
	"834": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 12,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 1877,
		"power_bots": 0.3428571429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 66.0
	},
	"835": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 12,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5917,
		"power_bots": 0.1714285714,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 79.0
	},
	"836": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 12,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2561,
		"power_bots": 0.1714285714,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 80.0
	},
	"837": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 13,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1720,
		"power_bots": 46.1428571429,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 193.0
	},
	"838": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 13,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2244,
		"power_bots": 0.3714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 79.0
	},
	"839": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 13,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2128,
		"power_bots": 0.3714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 77.0
	},
	"840": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 13,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2024,
		"power_bots": 0.3714285714,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 66.0
	},
	"841": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 13,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5988,
		"power_bots": 0.1857142857,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 79.0
	},
	"842": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 13,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2838,
		"power_bots": 0.1857142857,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 80.0
	},
	"843": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 14,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1840,
		"power_bots": 47.0,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 196.0
	},
	"844": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 14,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2343,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 80.0
	},
	"845": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 14,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2311,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 78.0
	},
	"846": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 14,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2072,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 67.0
	},
	"847": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 14,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 6104,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 80.0
	},
	"848": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 14,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2860,
		"power_bots": 0.2,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 81.0
	},
	"849": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 15,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 1983,
		"power_bots": 47.8571428571,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 199.0
	},
	"850": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 15,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2440,
		"power_bots": 0.4285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 81.0
	},
	"851": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 15,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2419,
		"power_bots": 0.4285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 79.0
	},
	"852": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 15,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2325,
		"power_bots": 0.4285714286,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 68.0
	},
	"853": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 15,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 6096,
		"power_bots": 0.2142857143,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 80.0
	},
	"854": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 15,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 3072,
		"power_bots": 0.2142857143,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 81.0
	},
	"855": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 16,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2051,
		"power_bots": 48.7142857143,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 202.0
	},
	"856": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 16,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2590,
		"power_bots": 0.4571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 82.0
	},
	"857": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 16,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2623,
		"power_bots": 0.4571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 79.0
	},
	"858": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 16,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2241,
		"power_bots": 0.4571428571,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 69.0
	},
	"859": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 16,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 6254,
		"power_bots": 0.2285714286,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 80.0
	},
	"860": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 16,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 3153,
		"power_bots": 0.2285714286,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 82.0
	},
	"861": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 17,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 2143,
		"power_bots": 49.5714285714,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 205.0
	},
	"862": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 17,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2695,
		"power_bots": 0.4857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 83.0
	},
	"863": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 17,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2716,
		"power_bots": 0.4857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 80.0
	},
	"864": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 17,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2373,
		"power_bots": 0.4857142857,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 69.0
	},
	"865": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 17,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 6288,
		"power_bots": 0.2428571429,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 81.0
	},
	"866": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 17,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 3337,
		"power_bots": 0.2428571429,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 82.0
	},
	"867": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 18,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 2330,
		"power_bots": 50.4285714286,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 208.0
	},
	"868": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 18,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2764,
		"power_bots": 0.5142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 84.0
	},
	"869": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 18,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2790,
		"power_bots": 0.5142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 81.0
	},
	"870": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 18,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2517,
		"power_bots": 0.5142857143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 70.0
	},
	"871": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 18,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6395,
		"power_bots": 0.2571428571,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 81.0
	},
	"872": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 18,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3487,
		"power_bots": 0.2571428571,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 83.0
	},
	"873": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 19,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 2414,
		"power_bots": 51.2857142857,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 211.0
	},
	"874": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 19,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2952,
		"power_bots": 0.5428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 84.0
	},
	"875": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 19,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 2980,
		"power_bots": 0.5428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 82.0
	},
	"876": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 19,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2627,
		"power_bots": 0.5428571429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 71.0
	},
	"877": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 19,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6545,
		"power_bots": 0.2714285714,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 82.0
	},
	"878": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 19,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3470,
		"power_bots": 0.2714285714,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 83.0
	},
	"879": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 20,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 2459,
		"power_bots": 52.1428571429,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 214.0
	},
	"880": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 20,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2999,
		"power_bots": 0.5714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 85.0
	},
	"881": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 20,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3149,
		"power_bots": 0.5714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 83.0
	},
	"882": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 20,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2750,
		"power_bots": 0.5714285714,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 71.0
	},
	"883": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 20,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6677,
		"power_bots": 0.2857142857,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 82.0
	},
	"884": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 20,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3580,
		"power_bots": 0.2857142857,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 83.0
	},
	"885": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 21,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 2684,
		"power_bots": 53.0,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 217.0
	},
	"886": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 21,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3244,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 86.0
	},
	"887": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 21,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3114,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 84.0
	},
	"888": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 21,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 2808,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 72.0
	},
	"889": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 21,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6905,
		"power_bots": 0.3,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 83.0
	},
	"890": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 21,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3609,
		"power_bots": 0.3,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 84.0
	},
	"891": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 22,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 2668,
		"power_bots": 53.8571428571,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 220.0
	},
	"892": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 22,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3356,
		"power_bots": 0.6285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 87.0
	},
	"893": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 22,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3247,
		"power_bots": 0.6285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 84.0
	},
	"894": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 22,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3039,
		"power_bots": 0.6285714286,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 73.0
	},
	"895": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 22,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6950,
		"power_bots": 0.3142857143,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 83.0
	},
	"896": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 22,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3794,
		"power_bots": 0.3142857143,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 84.0
	},
	"897": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 23,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 2798,
		"power_bots": 54.7142857143,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 223.0
	},
	"898": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 23,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3400,
		"power_bots": 0.6571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 88.0
	},
	"899": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 23,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3341,
		"power_bots": 0.6571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 85.0
	},
	"900": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 23,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3058,
		"power_bots": 0.6571428571,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 74.0
	},
	"901": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 23,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6889,
		"power_bots": 0.3285714286,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 84.0
	},
	"902": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 23,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4066,
		"power_bots": 0.3285714286,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 85.0
	},
	"903": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 24,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3035,
		"power_bots": 55.5714285714,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 226.0
	},
	"904": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 24,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3441,
		"power_bots": 0.6857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 89.0
	},
	"905": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 24,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3551,
		"power_bots": 0.6857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 86.0
	},
	"906": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 24,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3243,
		"power_bots": 0.6857142857,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 74.0
	},
	"907": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 24,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 7162,
		"power_bots": 0.3428571429,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 84.0
	},
	"908": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 24,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4001,
		"power_bots": 0.3428571429,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 85.0
	},
	"909": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 25,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3019,
		"power_bots": 56.4285714286,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 229.0
	},
	"910": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 25,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3631,
		"power_bots": 0.7142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 90.0
	},
	"911": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 25,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3599,
		"power_bots": 0.7142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 87.0
	},
	"912": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 25,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3376,
		"power_bots": 0.7142857143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 75.0
	},
	"913": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 25,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 7200,
		"power_bots": 0.3571428571,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 85.0
	},
	"914": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 25,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4123,
		"power_bots": 0.3571428571,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 86.0
	},
	"915": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 26,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3176,
		"power_bots": 57.2857142857,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 232.0
	},
	"916": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 26,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3686,
		"power_bots": 0.7428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 90.0
	},
	"917": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 26,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3694,
		"power_bots": 0.7428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 88.0
	},
	"918": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 26,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3516,
		"power_bots": 0.7428571429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 76.0
	},
	"919": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 26,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 7373,
		"power_bots": 0.3714285714,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 85.0
	},
	"920": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 26,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4203,
		"power_bots": 0.3714285714,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 86.0
	},
	"921": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 27,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3351,
		"power_bots": 58.1428571429,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 235.0
	},
	"922": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 27,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3849,
		"power_bots": 0.7714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 91.0
	},
	"923": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 27,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3774,
		"power_bots": 0.7714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 89.0
	},
	"924": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 27,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3440,
		"power_bots": 0.7714285714,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 76.0
	},
	"925": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 27,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 7461,
		"power_bots": 0.3857142857,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 86.0
	},
	"926": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 27,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4441,
		"power_bots": 0.3857142857,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 87.0
	},
	"927": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 28,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3251,
		"power_bots": 59.0,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 238.0
	},
	"928": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 28,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3909,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 92.0
	},
	"929": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 28,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3987,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 89.0
	},
	"930": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 28,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3511,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 77.0
	},
	"931": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 28,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7701,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 86.0
	},
	"932": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 28,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4422,
		"power_bots": 0.4,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 87.0
	},
	"933": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 29,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 3495,
		"power_bots": 59.8571428571,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 241.0
	},
	"934": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 29,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4089,
		"power_bots": 0.8285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 93.0
	},
	"935": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 29,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4055,
		"power_bots": 0.8285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 90.0
	},
	"936": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 29,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3701,
		"power_bots": 0.8285714286,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 78.0
	},
	"937": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 29,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7748,
		"power_bots": 0.4142857143,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 86.0
	},
	"938": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 29,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4517,
		"power_bots": 0.4142857143,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 88.0
	},
	"939": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 30,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3531,
		"power_bots": 60.7142857143,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 244.0
	},
	"940": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 30,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4169,
		"power_bots": 0.8571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 94.0
	},
	"941": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 30,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4167,
		"power_bots": 0.8571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 91.0
	},
	"942": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 30,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 3871,
		"power_bots": 0.8571428571,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 79.0
	},
	"943": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 30,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7797,
		"power_bots": 0.4285714286,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 87.0
	},
	"944": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 30,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4669,
		"power_bots": 0.4285714286,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 88.0
	},
	"945": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 31,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3724,
		"power_bots": 61.5714285714,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 247.0
	},
	"946": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 31,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4371,
		"power_bots": 0.8857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 95.0
	},
	"947": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 31,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4241,
		"power_bots": 0.8857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 92.0
	},
	"948": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 31,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4020,
		"power_bots": 0.8857142857,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 79.0
	},
	"949": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 31,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7849,
		"power_bots": 0.4428571429,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 87.0
	},
	"950": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 31,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4771,
		"power_bots": 0.4428571429,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 89.0
	},
	"951": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 32,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3732,
		"power_bots": 62.4285714286,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 250.0
	},
	"952": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 32,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4351,
		"power_bots": 0.9142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 95.0
	},
	"953": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 32,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4241,
		"power_bots": 0.9142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 93.0
	},
	"954": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 32,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4032,
		"power_bots": 0.9142857143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 80.0
	},
	"955": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 32,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8004,
		"power_bots": 0.4571428571,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 88.0
	},
	"956": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 32,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4969,
		"power_bots": 0.4571428571,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 89.0
	},
	"957": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 33,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 3905,
		"power_bots": 63.2857142857,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 253.0
	},
	"958": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 33,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4620,
		"power_bots": 0.9428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 96.0
	},
	"959": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 33,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4458,
		"power_bots": 0.9428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 93.0
	},
	"960": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 33,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4187,
		"power_bots": 0.9428571429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 81.0
	},
	"961": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 33,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8144,
		"power_bots": 0.4714285714,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 88.0
	},
	"962": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 33,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4939,
		"power_bots": 0.4714285714,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 90.0
	},
	"963": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 34,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4002,
		"power_bots": 64.1428571429,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 256.0
	},
	"964": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 34,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4556,
		"power_bots": 0.9714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 97.0
	},
	"965": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 34,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4561,
		"power_bots": 0.9714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 94.0
	},
	"966": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 34,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4281,
		"power_bots": 0.9714285714,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 81.0
	},
	"967": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 34,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8366,
		"power_bots": 0.4857142857,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 89.0
	},
	"968": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 34,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5002,
		"power_bots": 0.4857142857,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 90.0
	},
	"969": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 35,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4135,
		"power_bots": 65.0,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 259.0
	},
	"970": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 35,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4789,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 98.0
	},
	"971": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 35,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4670,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 95.0
	},
	"972": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 35,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4417,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 82.0
	},
	"973": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 35,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8259,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 89.0
	},
	"974": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 35,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5197,
		"power_bots": 0.5,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 90.0
	},
	"975": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 36,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4181,
		"power_bots": 65.8571428571,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 262.0
	},
	"976": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 36,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4729,
		"power_bots": 1.0285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 99.0
	},
	"977": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 36,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 4772,
		"power_bots": 1.0285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 96.0
	},
	"978": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 36,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4546,
		"power_bots": 1.0285714286,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 83.0
	},
	"979": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 36,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8287,
		"power_bots": 0.5142857143,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 90.0
	},
	"980": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 36,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5439,
		"power_bots": 0.5142857143,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 91.0
	},
	"981": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 37,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4419,
		"power_bots": 66.7142857143,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 265.0
	},
	"982": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 37,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4843,
		"power_bots": 1.0571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 100.0
	},
	"983": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 37,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4824,
		"power_bots": 1.0571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 97.0
	},
	"984": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 37,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4580,
		"power_bots": 1.0571428571,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 84.0
	},
	"985": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 37,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8622,
		"power_bots": 0.5285714286,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 90.0
	},
	"986": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 37,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5411,
		"power_bots": 0.5285714286,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 91.0
	},
	"987": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 38,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4424,
		"power_bots": 67.5714285714,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 268.0
	},
	"988": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 38,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5031,
		"power_bots": 1.0857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 100.0
	},
	"989": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 38,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4878,
		"power_bots": 1.0857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 98.0
	},
	"990": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 38,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4733,
		"power_bots": 1.0857142857,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 84.0
	},
	"991": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 38,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8602,
		"power_bots": 0.5428571429,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 91.0
	},
	"992": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 38,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5638,
		"power_bots": 0.5428571429,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 92.0
	},
	"993": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 39,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4593,
		"power_bots": 68.4285714286,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 271.0
	},
	"994": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 39,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5029,
		"power_bots": 1.1142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 101.0
	},
	"995": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 39,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5036,
		"power_bots": 1.1142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 98.0
	},
	"996": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 39,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4749,
		"power_bots": 1.1142857143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 85.0
	},
	"997": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 39,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8910,
		"power_bots": 0.5571428571,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 91.0
	},
	"998": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 39,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5601,
		"power_bots": 0.5571428571,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 92.0
	},
	"999": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 40,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4714,
		"power_bots": 69.2857142857,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 274.0
	},
	"1000": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 40,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5159,
		"power_bots": 1.1428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 102.0
	},
	"1001": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 40,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5318,
		"power_bots": 1.1428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 99.0
	},
	"1002": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 40,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4894,
		"power_bots": 1.1428571429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 86.0
	},
	"1003": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 40,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8845,
		"power_bots": 0.5714285714,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 92.0
	},
	"1004": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 40,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5723,
		"power_bots": 0.5714285714,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 93.0
	},
	"1005": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 41,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4827,
		"power_bots": 70.1428571429,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 277.0
	},
	"1006": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 41,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5202,
		"power_bots": 1.1714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 103.0
	},
	"1007": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 41,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5327,
		"power_bots": 1.1714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 100.0
	},
	"1008": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 41,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4966,
		"power_bots": 1.1714285714,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 86.0
	},
	"1009": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 41,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8965,
		"power_bots": 0.5857142857,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 92.0
	},
	"1010": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 41,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5931,
		"power_bots": 0.5857142857,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 93.0
	},
	"1011": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 42,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4848,
		"power_bots": 71.0,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 280.0
	},
	"1012": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 42,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5315,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 104.0
	},
	"1013": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 42,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5394,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 101.0
	},
	"1014": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 42,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5173,
		"power_bots": 1.2,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 87.0
	},
	"1015": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 42,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 9128,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 92.0
	},
	"1016": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 42,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5985,
		"power_bots": 0.6,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 94.0
	},
	"1017": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 43,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4947,
		"power_bots": 71.8571428571,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 283.0
	},
	"1018": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 43,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5403,
		"power_bots": 1.2285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 105.0
	},
	"1019": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 43,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5615,
		"power_bots": 1.2285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 102.0
	},
	"1020": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 43,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5206,
		"power_bots": 1.2285714286,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 88.0
	},
	"1021": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 43,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 9222,
		"power_bots": 0.6142857143,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 93.0
	},
	"1022": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 43,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6047,
		"power_bots": 0.6142857143,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 94.0
	},
	"1023": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 44,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5177,
		"power_bots": 72.7142857143,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 286.0
	},
	"1024": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 44,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5695,
		"power_bots": 1.2571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 105.0
	},
	"1025": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 44,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5564,
		"power_bots": 1.2571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 102.0
	},
	"1026": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 44,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 5395,
		"power_bots": 1.2571428571,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 88.0
	},
	"1027": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 44,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 9246,
		"power_bots": 0.6285714286,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 93.0
	},
	"1028": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 44,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6159,
		"power_bots": 0.6285714286,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 95.0
	},
	"1029": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 45,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5237,
		"power_bots": 73.5714285714,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 289.0
	},
	"1030": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 45,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5657,
		"power_bots": 1.2857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 106.0
	},
	"1031": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 45,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5702,
		"power_bots": 1.2857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 103.0
	},
	"1032": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 45,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5581,
		"power_bots": 1.2857142857,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 89.0
	},
	"1033": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 45,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 9315,
		"power_bots": 0.6428571429,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 94.0
	},
	"1034": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 45,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6310,
		"power_bots": 0.6428571429,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 95.0
	},
	"1035": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 46,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5185,
		"power_bots": 74.4285714286,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 292.0
	},
	"1036": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 46,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5927,
		"power_bots": 1.3142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 107.0
	},
	"1037": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 46,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5777,
		"power_bots": 1.3142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 104.0
	},
	"1038": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 46,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5609,
		"power_bots": 1.3142857143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 90.0
	},
	"1039": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 46,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 9407,
		"power_bots": 0.6571428571,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 94.0
	},
	"1040": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 46,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6362,
		"power_bots": 0.6571428571,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 96.0
	},
	"1041": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 47,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 5308,
		"power_bots": 75.2857142857,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 295.0
	},
	"1042": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 47,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6003,
		"power_bots": 1.3428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 108.0
	},
	"1043": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 47,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 5942,
		"power_bots": 1.3428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 105.0
	},
	"1044": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 47,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5558,
		"power_bots": 1.3428571429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 91.0
	},
	"1045": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 47,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 9533,
		"power_bots": 0.6714285714,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 95.0
	},
	"1046": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 47,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6499,
		"power_bots": 0.6714285714,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 96.0
	},
	"1047": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 48,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5586,
		"power_bots": 76.1428571429,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 298.0
	},
	"1048": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 48,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5958,
		"power_bots": 1.3714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 109.0
	},
	"1049": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 48,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6043,
		"power_bots": 1.3714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 106.0
	},
	"1050": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 48,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5713,
		"power_bots": 1.3714285714,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 91.0
	},
	"1051": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 48,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 9736,
		"power_bots": 0.6857142857,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 95.0
	},
	"1052": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 48,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6573,
		"power_bots": 0.6857142857,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 96.0
	},
	"1053": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 49,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 5502,
		"power_bots": 77.0,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 301.0
	},
	"1054": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 49,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6133,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 110.0
	},
	"1055": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 49,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6140,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 106.0
	},
	"1056": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 49,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5887,
		"power_bots": 1.4,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 92.0
	},
	"1057": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 49,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 9761,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 96.0
	},
	"1058": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 49,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6700,
		"power_bots": 0.7,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 97.0
	},
	"1059": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 50,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 5803,
		"power_bots": 77.8571428571,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 304.0
	},
	"1060": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 50,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6234,
		"power_bots": 1.4285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 110.0
	},
	"1061": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 50,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6242,
		"power_bots": 1.4285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 107.0
	},
	"1062": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 50,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5970,
		"power_bots": 1.4285714286,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 93.0
	},
	"1063": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 50,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 9924,
		"power_bots": 0.7142857143,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 96.0
	},
	"1064": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 50,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6756,
		"power_bots": 0.7142857143,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 97.0
	},
	"1065": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 51,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 5959,
		"power_bots": 78.7142857143,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 307.0
	},
	"1066": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 51,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6277,
		"power_bots": 1.4571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 111.0
	},
	"1067": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 51,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6226,
		"power_bots": 1.4571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 108.0
	},
	"1068": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 51,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6036,
		"power_bots": 1.4571428571,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 93.0
	},
	"1069": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 51,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10042,
		"power_bots": 0.7285714286,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 97.0
	},
	"1070": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 51,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6938,
		"power_bots": 0.7285714286,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 98.0
	},
	"1071": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 52,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5943,
		"power_bots": 79.5714285714,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 310.0
	},
	"1072": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 52,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6383,
		"power_bots": 1.4857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 112.0
	},
	"1073": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 52,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6484,
		"power_bots": 1.4857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 109.0
	},
	"1074": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 52,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6076,
		"power_bots": 1.4857142857,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 94.0
	},
	"1075": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 52,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10134,
		"power_bots": 0.7428571429,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 97.0
	},
	"1076": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 52,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7041,
		"power_bots": 0.7428571429,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 98.0
	},
	"1077": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 53,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 6027,
		"power_bots": 80.4285714286,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 313.0
	},
	"1078": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 53,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6573,
		"power_bots": 1.5142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 113.0
	},
	"1079": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 53,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6586,
		"power_bots": 1.5142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 110.0
	},
	"1080": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 53,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6107,
		"power_bots": 1.5142857143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 95.0
	},
	"1081": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 53,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10256,
		"power_bots": 0.7571428571,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 97.0
	},
	"1082": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 53,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7085,
		"power_bots": 0.7571428571,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 99.0
	},
	"1083": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 54,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6067,
		"power_bots": 81.2857142857,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 315.0
	},
	"1084": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 54,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6500,
		"power_bots": 1.5428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 114.0
	},
	"1085": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 54,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6647,
		"power_bots": 1.5428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 110.0
	},
	"1086": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 54,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6277,
		"power_bots": 1.5428571429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 95.0
	},
	"1087": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 54,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10375,
		"power_bots": 0.7714285714,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 98.0
	},
	"1088": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 54,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7310,
		"power_bots": 0.7714285714,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 99.0
	},
	"1089": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 55,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 6226,
		"power_bots": 82.1428571429,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 318.0
	},
	"1090": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 55,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6749,
		"power_bots": 1.5714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 115.0
	},
	"1091": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 55,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6860,
		"power_bots": 1.5714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 111.0
	},
	"1092": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 55,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 6533,
		"power_bots": 1.5714285714,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 96.0
	},
	"1093": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 55,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10414,
		"power_bots": 0.7857142857,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 98.0
	},
	"1094": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 55,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7164,
		"power_bots": 0.7857142857,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 100.0
	},
	"1095": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 56,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 6417,
		"power_bots": 83.0,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 321.0
	},
	"1096": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 56,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6939,
		"power_bots": 1.6,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 115.0
	},
	"1097": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 56,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6870,
		"power_bots": 1.6,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 112.0
	},
	"1098": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 56,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6549,
		"power_bots": 1.6,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 97.0
	},
	"1099": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 56,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10606,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 99.0
	},
	"1100": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 56,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7286,
		"power_bots": 0.8,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 100.0
	},
	"1101": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 57,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 6452,
		"power_bots": 83.8571428571,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 324.0
	},
	"1102": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 57,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6824,
		"power_bots": 1.6285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 116.0
	},
	"1103": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 57,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6923,
		"power_bots": 1.6285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 113.0
	},
	"1104": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 57,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6774,
		"power_bots": 1.6285714286,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 97.0
	},
	"1105": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 57,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10679,
		"power_bots": 0.8142857143,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 99.0
	},
	"1106": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 57,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7505,
		"power_bots": 0.8142857143,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 101.0
	},
	"1107": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 58,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 6481,
		"power_bots": 84.7142857143,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 327.0
	},
	"1108": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 58,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7062,
		"power_bots": 1.6571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 117.0
	},
	"1109": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 58,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7021,
		"power_bots": 1.6571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 114.0
	},
	"1110": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 58,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 6820,
		"power_bots": 1.6571428571,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 98.0
	},
	"1111": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 58,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10696,
		"power_bots": 0.8285714286,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 100.0
	},
	"1112": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 58,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7622,
		"power_bots": 0.8285714286,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 101.0
	},
	"1113": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 59,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 6707,
		"power_bots": 85.5714285714,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 330.0
	},
	"1114": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 59,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7242,
		"power_bots": 1.6857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 118.0
	},
	"1115": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 59,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7034,
		"power_bots": 1.6857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 114.0
	},
	"1116": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 59,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 6871,
		"power_bots": 1.6857142857,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 99.0
	},
	"1117": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 59,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10770,
		"power_bots": 0.8428571429,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 100.0
	},
	"1118": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 59,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7745,
		"power_bots": 0.8428571429,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 102.0
	},
	"1119": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 60,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 6655,
		"power_bots": 86.4285714286,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 333.0
	},
	"1120": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 60,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7200,
		"power_bots": 1.7142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 119.0
	},
	"1121": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 60,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7305,
		"power_bots": 1.7142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 115.0
	},
	"1122": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 60,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7086,
		"power_bots": 1.7142857143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 100.0
	},
	"1123": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 60,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10956,
		"power_bots": 0.8571428571,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 101.0
	},
	"1124": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 60,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7739,
		"power_bots": 0.8571428571,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 102.0
	},
	"1125": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 61,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 6802,
		"power_bots": 87.2857142857,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 336.0
	},
	"1126": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 61,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7334,
		"power_bots": 1.7428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 120.0
	},
	"1127": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 61,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7432,
		"power_bots": 1.7428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 116.0
	},
	"1128": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 61,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7100,
		"power_bots": 1.7428571429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 100.0
	},
	"1129": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 61,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10980,
		"power_bots": 0.8714285714,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 101.0
	},
	"1130": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 61,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7910,
		"power_bots": 0.8714285714,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 102.0
	},
	"1131": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 62,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7199,
		"power_bots": 88.1428571429,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 339.0
	},
	"1132": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 62,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7474,
		"power_bots": 1.7714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 120.0
	},
	"1133": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 62,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7377,
		"power_bots": 1.7714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 117.0
	},
	"1134": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 62,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7151,
		"power_bots": 1.7714285714,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 101.0
	},
	"1135": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 62,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 11224,
		"power_bots": 0.8857142857,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 102.0
	},
	"1136": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 62,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7900,
		"power_bots": 0.8857142857,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 103.0
	},
	"1137": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 63,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 6919,
		"power_bots": 89.0,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 342.0
	},
	"1138": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 63,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7533,
		"power_bots": 1.8,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 121.0
	},
	"1139": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 63,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7482,
		"power_bots": 1.8,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 118.0
	},
	"1140": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 63,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7270,
		"power_bots": 1.8,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 102.0
	},
	"1141": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 63,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 11128,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 102.0
	},
	"1142": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 63,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8262,
		"power_bots": 0.9,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 103.0
	},
	"1143": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 64,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 7018,
		"power_bots": 89.8571428571,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 345.0
	},
	"1144": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 64,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7585,
		"power_bots": 1.8285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 122.0
	},
	"1145": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 64,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7665,
		"power_bots": 1.8285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 118.0
	},
	"1146": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 64,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7359,
		"power_bots": 1.8285714286,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 102.0
	},
	"1147": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 64,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 11195,
		"power_bots": 0.9142857143,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 102.0
	},
	"1148": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 64,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8345,
		"power_bots": 0.9142857143,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 104.0
	},
	"1149": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 65,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 7194,
		"power_bots": 90.7142857143,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 347.0
	},
	"1150": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 65,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7819,
		"power_bots": 1.8571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 123.0
	},
	"1151": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 65,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7671,
		"power_bots": 1.8571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 119.0
	},
	"1152": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 65,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7632,
		"power_bots": 1.8571428571,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 103.0
	},
	"1153": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 65,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 11369,
		"power_bots": 0.9285714286,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 103.0
	},
	"1154": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 65,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8284,
		"power_bots": 0.9285714286,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 104.0
	},
	"1155": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 66,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7326,
		"power_bots": 91.5714285714,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 350.0
	},
	"1156": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 66,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7855,
		"power_bots": 1.8857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 124.0
	},
	"1157": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 66,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7911,
		"power_bots": 1.8857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 120.0
	},
	"1158": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 66,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7611,
		"power_bots": 1.8857142857,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 104.0
	},
	"1159": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 66,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 11343,
		"power_bots": 0.9428571429,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 103.0
	},
	"1160": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 66,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8457,
		"power_bots": 0.9428571429,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 105.0
	},
	"1161": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 67,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7483,
		"power_bots": 92.4285714286,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 353.0
	},
	"1162": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 67,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8086,
		"power_bots": 1.9142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 124.0
	},
	"1163": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 67,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8041,
		"power_bots": 1.9142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 121.0
	},
	"1164": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 67,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7731,
		"power_bots": 1.9142857143,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 104.0
	},
	"1165": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 67,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 11500,
		"power_bots": 0.9571428571,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 104.0
	},
	"1166": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 67,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8455,
		"power_bots": 0.9571428571,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 105.0
	},
	"1167": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 68,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7609,
		"power_bots": 93.2857142857,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 356.0
	},
	"1168": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 68,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7931,
		"power_bots": 1.9428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 125.0
	},
	"1169": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 68,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8196,
		"power_bots": 1.9428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 122.0
	},
	"1170": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 68,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7813,
		"power_bots": 1.9428571429,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 105.0
	},
	"1171": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 68,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 11752,
		"power_bots": 0.9714285714,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 104.0
	},
	"1172": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 68,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8579,
		"power_bots": 0.9714285714,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 106.0
	},
	"1173": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 69,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7579,
		"power_bots": 94.1428571429,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 359.0
	},
	"1174": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 69,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 8203,
		"power_bots": 1.9714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 126.0
	},
	"1175": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 69,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8082,
		"power_bots": 1.9714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 122.0
	},
	"1176": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 69,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7706,
		"power_bots": 1.9714285714,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 106.0
	},
	"1177": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 69,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 11908,
		"power_bots": 0.9857142857,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 105.0
	},
	"1178": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 69,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8725,
		"power_bots": 0.9857142857,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 106.0
	},
	"1179": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 70,
		"id бота": 22,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7530,
		"power_bots": 95.0,
		"count_battles": 5000,
		"id": 22,
		"name": "Скелет-лучник",
		"level": 11,
		"real level": 362.0
	},
	"1180": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 70,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8243,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 127.0
	},
	"1181": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 70,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8239,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 123.0
	},
	"1182": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 70,
		"id бота": 117,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7857,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 117,
		"name": "Жрец тени",
		"level": 57,
		"real level": 106.0
	},
	"1183": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 70,
		"id бота": 170,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 11957,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 170,
		"name": "Ледяной охотник",
		"level": 73,
		"real level": 105.0
	},
	"1184": {
		"Имя шахты": "Титанитовая шахта",
		"Этаж шахты": 70,
		"id бота": 171,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8851,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 171,
		"name": "Баньши",
		"level": 74,
		"real level": 107.0
	},
	"1185": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 1,
		"id бота": 107,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3923,
		"power_bots": 0.1111111111,
		"count_battles": 5000,
		"id": 107,
		"name": "Кобольд-землекоп",
		"level": 37,
		"real level": 39.0
	},
	"1186": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 1,
		"id бота": 108,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 3816,
		"power_bots": 0.1111111111,
		"count_battles": 5000,
		"id": 108,
		"name": "Кобольд-шаман",
		"level": 44,
		"real level": 46.0
	},
	"1187": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 1,
		"id бота": 109,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 3958,
		"power_bots": 0.1111111111,
		"count_battles": 5000,
		"id": 109,
		"name": "Кобольд-вожак",
		"level": 49,
		"real level": 51.0
	},
	"1188": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 2,
		"id бота": 107,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5400,
		"power_bots": 0.2222222222,
		"count_battles": 5000,
		"id": 107,
		"name": "Кобольд-землекоп",
		"level": 37,
		"real level": 41.0
	},
	"1189": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 2,
		"id бота": 108,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5233,
		"power_bots": 0.2222222222,
		"count_battles": 5000,
		"id": 108,
		"name": "Кобольд-шаман",
		"level": 44,
		"real level": 48.0
	},
	"1190": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 2,
		"id бота": 109,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5492,
		"power_bots": 0.2222222222,
		"count_battles": 5000,
		"id": 109,
		"name": "Кобольд-вожак",
		"level": 49,
		"real level": 54.0
	},
	"1191": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 3,
		"id бота": 107,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6886,
		"power_bots": 0.3333333333,
		"count_battles": 5000,
		"id": 107,
		"name": "Кобольд-землекоп",
		"level": 37,
		"real level": 43.0
	},
	"1192": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 3,
		"id бота": 108,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6817,
		"power_bots": 0.3333333333,
		"count_battles": 5000,
		"id": 108,
		"name": "Кобольд-шаман",
		"level": 44,
		"real level": 51.0
	},
	"1193": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 3,
		"id бота": 109,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6913,
		"power_bots": 0.3333333333,
		"count_battles": 5000,
		"id": 109,
		"name": "Кобольд-вожак",
		"level": 49,
		"real level": 56.0
	},
	"1194": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 4,
		"id бота": 107,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8141,
		"power_bots": 0.4444444444,
		"count_battles": 5000,
		"id": 107,
		"name": "Кобольд-землекоп",
		"level": 37,
		"real level": 44.0
	},
	"1195": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 4,
		"id бота": 108,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8481,
		"power_bots": 0.4444444444,
		"count_battles": 5000,
		"id": 108,
		"name": "Кобольд-шаман",
		"level": 44,
		"real level": 53.0
	},
	"1196": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 4,
		"id бота": 109,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 8257,
		"power_bots": 0.4444444444,
		"count_battles": 5000,
		"id": 109,
		"name": "Кобольд-вожак",
		"level": 49,
		"real level": 59.0
	},
	"1197": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 5,
		"id бота": 107,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 9751,
		"power_bots": 0.5555555556,
		"count_battles": 5000,
		"id": 107,
		"name": "Кобольд-землекоп",
		"level": 37,
		"real level": 46.0
	},
	"1198": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 5,
		"id бота": 108,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 9774,
		"power_bots": 0.5555555556,
		"count_battles": 5000,
		"id": 108,
		"name": "Кобольд-шаман",
		"level": 44,
		"real level": 55.0
	},
	"1199": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 5,
		"id бота": 109,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 9720,
		"power_bots": 0.5555555556,
		"count_battles": 5000,
		"id": 109,
		"name": "Кобольд-вожак",
		"level": 49,
		"real level": 61.0
	},
	"1200": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 6,
		"id бота": 107,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 11190,
		"power_bots": 0.6666666667,
		"count_battles": 5000,
		"id": 107,
		"name": "Кобольд-землекоп",
		"level": 37,
		"real level": 48.0
	},
	"1201": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 6,
		"id бота": 108,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 11220,
		"power_bots": 0.6666666667,
		"count_battles": 5000,
		"id": 108,
		"name": "Кобольд-шаман",
		"level": 44,
		"real level": 57.0
	},
	"1202": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 6,
		"id бота": 109,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 11226,
		"power_bots": 0.6666666667,
		"count_battles": 5000,
		"id": 109,
		"name": "Кобольд-вожак",
		"level": 49,
		"real level": 63.0
	},
	"1203": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 7,
		"id бота": 107,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 12761,
		"power_bots": 0.7777777778,
		"count_battles": 5000,
		"id": 107,
		"name": "Кобольд-землекоп",
		"level": 37,
		"real level": 50.0
	},
	"1204": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 7,
		"id бота": 108,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 12595,
		"power_bots": 0.7777777778,
		"count_battles": 5000,
		"id": 108,
		"name": "Кобольд-шаман",
		"level": 44,
		"real level": 59.0
	},
	"1205": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 7,
		"id бота": 109,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 12678,
		"power_bots": 0.7777777778,
		"count_battles": 5000,
		"id": 109,
		"name": "Кобольд-вожак",
		"level": 49,
		"real level": 66.0
	},
	"1206": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 8,
		"id бота": 107,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 13933,
		"power_bots": 0.8888888889,
		"count_battles": 5000,
		"id": 107,
		"name": "Кобольд-землекоп",
		"level": 37,
		"real level": 52.0
	},
	"1207": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 8,
		"id бота": 108,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 13952,
		"power_bots": 0.8888888889,
		"count_battles": 5000,
		"id": 108,
		"name": "Кобольд-шаман",
		"level": 44,
		"real level": 61.0
	},
	"1208": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 8,
		"id бота": 109,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 14290,
		"power_bots": 0.8888888889,
		"count_battles": 5000,
		"id": 109,
		"name": "Кобольд-вожак",
		"level": 49,
		"real level": 68.0
	},
	"1209": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 9,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4123,
		"power_bots": 7.0,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 120.0
	},
	"1210": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 9,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4244,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 22.0
	},
	"1211": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 9,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4286,
		"power_bots": 2.5,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 110.0
	},
	"1212": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 9,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4266,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 92.0
	},
	"1213": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 9,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4319,
		"power_bots": 1.0,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 98.0
	},
	"1214": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 10,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4612,
		"power_bots": 7.7777777778,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 129.0
	},
	"1215": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 10,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4702,
		"power_bots": 1.1111111111,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 22.0
	},
	"1216": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 10,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 4656,
		"power_bots": 2.7777777778,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 116.0
	},
	"1217": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 10,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4550,
		"power_bots": 1.1111111111,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 95.0
	},
	"1218": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 10,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4633,
		"power_bots": 1.1111111111,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 101.0
	},
	"1219": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 11,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 4885,
		"power_bots": 8.5555555556,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 139.0
	},
	"1220": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 11,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 4937,
		"power_bots": 1.2222222222,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 23.0
	},
	"1221": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 11,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5017,
		"power_bots": 3.0555555556,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 122.0
	},
	"1222": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 11,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4929,
		"power_bots": 1.2222222222,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 98.0
	},
	"1223": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 11,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5022,
		"power_bots": 1.2222222222,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 104.0
	},
	"1224": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 12,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5344,
		"power_bots": 9.3333333333,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 148.0
	},
	"1225": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 12,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5253,
		"power_bots": 1.3333333333,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 24.0
	},
	"1226": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 12,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5248,
		"power_bots": 3.3333333333,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 128.0
	},
	"1227": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 12,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5358,
		"power_bots": 1.3333333333,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 101.0
	},
	"1228": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 12,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5345,
		"power_bots": 1.3333333333,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 108.0
	},
	"1229": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 13,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5691,
		"power_bots": 10.1111111111,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 157.0
	},
	"1230": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 13,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5794,
		"power_bots": 1.4444444444,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 24.0
	},
	"1231": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 13,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 5816,
		"power_bots": 3.6111111111,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 134.0
	},
	"1232": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 13,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5624,
		"power_bots": 1.4444444444,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 104.0
	},
	"1233": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 13,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5659,
		"power_bots": 1.4444444444,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 111.0
	},
	"1234": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 14,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 5951,
		"power_bots": 10.8888888889,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 166.0
	},
	"1235": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 14,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6052,
		"power_bots": 1.5555555556,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 25.0
	},
	"1236": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 14,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 6021,
		"power_bots": 3.8888888889,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 140.0
	},
	"1237": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 14,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6106,
		"power_bots": 1.5555555556,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 107.0
	},
	"1238": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 14,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6031,
		"power_bots": 1.5555555556,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 114.0
	},
	"1239": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 15,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 6388,
		"power_bots": 11.6666666667,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 175.0
	},
	"1240": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 15,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 6413,
		"power_bots": 1.6666666667,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 26.0
	},
	"1241": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 15,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 6409,
		"power_bots": 4.1666666667,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 146.0
	},
	"1242": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 15,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6243,
		"power_bots": 1.6666666667,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 110.0
	},
	"1243": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 15,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6548,
		"power_bots": 1.6666666667,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 117.0
	},
	"1244": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 16,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 6703,
		"power_bots": 12.4444444444,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 184.0
	},
	"1245": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 16,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 6744,
		"power_bots": 1.7777777778,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 27.0
	},
	"1246": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 16,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 6832,
		"power_bots": 4.4444444444,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 152.0
	},
	"1247": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 16,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6769,
		"power_bots": 1.7777777778,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 113.0
	},
	"1248": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 16,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 6719,
		"power_bots": 1.7777777778,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 121.0
	},
	"1249": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 17,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7221,
		"power_bots": 13.2222222222,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 193.0
	},
	"1250": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 17,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 7158,
		"power_bots": 1.8888888889,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 27.0
	},
	"1251": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 17,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7065,
		"power_bots": 4.7222222222,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 158.0
	},
	"1252": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 17,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 7129,
		"power_bots": 1.8888888889,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 116.0
	},
	"1253": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 17,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 7046,
		"power_bots": 1.8888888889,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 124.0
	},
	"1254": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 18,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7309,
		"power_bots": 14.0,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 201.0
	},
	"1255": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 18,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7530,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 28.0
	},
	"1256": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 18,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7509,
		"power_bots": 5.0,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 164.0
	},
	"1257": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 18,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7487,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 119.0
	},
	"1258": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 18,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7478,
		"power_bots": 2.0,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 127.0
	},
	"1259": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 19,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7681,
		"power_bots": 14.7777777778,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 210.0
	},
	"1260": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 19,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 7721,
		"power_bots": 2.1111111111,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 29.0
	},
	"1261": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 19,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 7947,
		"power_bots": 5.2777777778,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 169.0
	},
	"1262": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 19,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7846,
		"power_bots": 2.1111111111,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 122.0
	},
	"1263": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 19,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7819,
		"power_bots": 2.1111111111,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 130.0
	},
	"1264": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 20,
		"id бота": 34,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8113,
		"power_bots": 15.5555555556,
		"count_battles": 5000,
		"id": 34,
		"name": "Ужас глубин",
		"level": 31,
		"real level": 219.0
	},
	"1265": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 20,
		"id бота": 44,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 8295,
		"power_bots": 2.2222222222,
		"count_battles": 5000,
		"id": 44,
		"name": "Огненная стена",
		"level": 15,
		"real level": 29.0
	},
	"1266": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 20,
		"id бота": 66,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8142,
		"power_bots": 5.5555555556,
		"count_battles": 5000,
		"id": 66,
		"name": "Око бездны",
		"level": 53,
		"real level": 175.0
	},
	"1267": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 20,
		"id бота": 85,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8198,
		"power_bots": 2.2222222222,
		"count_battles": 5000,
		"id": 85,
		"name": "Хозяин глубин",
		"level": 64,
		"real level": 125.0
	},
	"1268": {
		"Имя шахты": "Шахта кобольдов",
		"Этаж шахты": 20,
		"id бота": 86,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 8223,
		"power_bots": 2.2222222222,
		"count_battles": 5000,
		"id": 86,
		"name": "Пламя бездны",
		"level": 68,
		"real level": 133.0
	},
	"1269": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 1,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 13861,
		"power_bots": 1.9047619048,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 38.0
	},
	"1270": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 1,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 5786,
		"power_bots": 1.4761904762,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 76.0
	},
	"1271": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 2,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 12753,
		"power_bots": 3.8095238095,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 55.0
	},
	"1272": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 2,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5261,
		"power_bots": 2.9523809524,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 104.0
	},
	"1273": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 2,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1320,
		"power_bots": 0.0952380952,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 71.0
	},
	"1274": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 2,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1304,
		"power_bots": 0.0952380952,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 69.0
	},
	"1275": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 3,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 14521,
		"power_bots": 5.7142857143,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 71.0
	},
	"1276": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 3,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6330,
		"power_bots": 4.4285714286,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 132.0
	},
	"1277": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 3,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 1956,
		"power_bots": 0.1428571429,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 72.0
	},
	"1278": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 3,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2072,
		"power_bots": 0.1428571429,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 70.0
	},
	"1279": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 4,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 16046,
		"power_bots": 7.619047619,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 86.0
	},
	"1280": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 4,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 7901,
		"power_bots": 5.9047619048,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 158.0
	},
	"1281": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 4,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 1,
		"count_bots": 2553,
		"power_bots": 0.1904761905,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 74.0
	},
	"1282": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 4,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 2633,
		"power_bots": 0.1904761905,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 72.0
	},
	"1283": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 5,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 16588,
		"power_bots": 9.5238095238,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 102.0
	},
	"1284": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 5,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 8539,
		"power_bots": 7.380952381,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 185.0
	},
	"1285": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 5,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 3431,
		"power_bots": 0.2380952381,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 75.0
	},
	"1286": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 5,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 3471,
		"power_bots": 0.2380952381,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 73.0
	},
	"1287": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 6,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 17358,
		"power_bots": 11.4285714286,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 117.0
	},
	"1288": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 6,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 9108,
		"power_bots": 8.8571428571,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 211.0
	},
	"1289": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 6,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 2,
		"count_bots": 4308,
		"power_bots": 0.2857142857,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 77.0
	},
	"1290": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 6,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 4250,
		"power_bots": 0.2857142857,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 74.0
	},
	"1291": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 7,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 11,
		"count_bots": 18065,
		"power_bots": 13.3333333333,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 131.0
	},
	"1292": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 7,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 10062,
		"power_bots": 10.3333333333,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 236.0
	},
	"1293": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 7,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5016,
		"power_bots": 0.3333333333,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 78.0
	},
	"1294": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 7,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5126,
		"power_bots": 0.3333333333,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 76.0
	},
	"1295": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 8,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 16,
		"count_bots": 18889,
		"power_bots": 15.2380952381,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 146.0
	},
	"1296": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 8,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 10832,
		"power_bots": 11.8095238095,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 262.0
	},
	"1297": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 8,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 5846,
		"power_bots": 0.380952381,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 80.0
	},
	"1298": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 8,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 5934,
		"power_bots": 0.380952381,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 77.0
	},
	"1299": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 9,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 14,
		"count_bots": 19873,
		"power_bots": 17.1428571429,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 160.0
	},
	"1300": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 9,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 11,
		"count_bots": 11677,
		"power_bots": 13.2857142857,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 287.0
	},
	"1301": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 9,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 3,
		"count_bots": 6634,
		"power_bots": 0.4285714286,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 81.0
	},
	"1302": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 9,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 6734,
		"power_bots": 0.4285714286,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 79.0
	},
	"1303": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 10,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 12,
		"count_bots": 20744,
		"power_bots": 19.0476190476,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 175.0
	},
	"1304": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 10,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 12271,
		"power_bots": 14.7619047619,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 312.0
	},
	"1305": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 10,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 7565,
		"power_bots": 0.4761904762,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 82.0
	},
	"1306": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 10,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 7358,
		"power_bots": 0.4761904762,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 80.0
	},
	"1307": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 11,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 12,
		"count_bots": 21228,
		"power_bots": 20.9523809524,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 189.0
	},
	"1308": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 11,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 13158,
		"power_bots": 16.2380952381,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 336.0
	},
	"1309": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 11,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 8312,
		"power_bots": 0.5238095238,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 84.0
	},
	"1310": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 11,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 8300,
		"power_bots": 0.5238095238,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 81.0
	},
	"1311": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 12,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 16,
		"count_bots": 22238,
		"power_bots": 22.8571428571,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 203.0
	},
	"1312": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 12,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 14151,
		"power_bots": 17.7142857143,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 361.0
	},
	"1313": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 12,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 4,
		"count_bots": 8930,
		"power_bots": 0.5714285714,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 85.0
	},
	"1314": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 12,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 9204,
		"power_bots": 0.5714285714,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 83.0
	},
	"1315": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 13,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 15,
		"count_bots": 23441,
		"power_bots": 24.7619047619,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 217.0
	},
	"1316": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 13,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 14841,
		"power_bots": 19.1904761905,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 385.0
	},
	"1317": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 13,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 9913,
		"power_bots": 0.619047619,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 87.0
	},
	"1318": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 13,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 9739,
		"power_bots": 0.619047619,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 84.0
	},
	"1319": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 14,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 15,
		"count_bots": 23648,
		"power_bots": 26.6666666667,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 231.0
	},
	"1320": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 14,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 10,
		"count_bots": 15745,
		"power_bots": 20.6666666667,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 409.0
	},
	"1321": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 14,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 10819,
		"power_bots": 0.6666666667,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 88.0
	},
	"1322": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 14,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 10509,
		"power_bots": 0.6666666667,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 86.0
	},
	"1323": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 15,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 16,
		"count_bots": 24603,
		"power_bots": 28.5714285714,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 244.0
	},
	"1324": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 15,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 11,
		"count_bots": 16458,
		"power_bots": 22.1428571429,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 433.0
	},
	"1325": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 15,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 5,
		"count_bots": 11406,
		"power_bots": 0.7142857143,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 90.0
	},
	"1326": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 15,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 11623,
		"power_bots": 0.7142857143,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 87.0
	},
	"1327": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 16,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 15,
		"count_bots": 25093,
		"power_bots": 30.4761904762,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 258.0
	},
	"1328": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 16,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 14,
		"count_bots": 17129,
		"power_bots": 23.619047619,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 457.0
	},
	"1329": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 16,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 12321,
		"power_bots": 0.7619047619,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 91.0
	},
	"1330": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 16,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 12393,
		"power_bots": 0.7619047619,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 88.0
	},
	"1331": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 17,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 15,
		"count_bots": 26101,
		"power_bots": 32.3809523809,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 272.0
	},
	"1332": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 17,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 15,
		"count_bots": 17947,
		"power_bots": 25.0952380952,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 480.0
	},
	"1333": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 17,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 13166,
		"power_bots": 0.8095238095,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 92.0
	},
	"1334": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 17,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 13098,
		"power_bots": 0.8095238095,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 90.0
	},
	"1335": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 18,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 15,
		"count_bots": 27133,
		"power_bots": 34.2857142857,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 285.0
	},
	"1336": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 18,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 13,
		"count_bots": 19038,
		"power_bots": 26.5714285714,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 504.0
	},
	"1337": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 18,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 13858,
		"power_bots": 0.8571428571,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 94.0
	},
	"1338": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 18,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 8,
		"count_bots": 13838,
		"power_bots": 0.8571428571,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 91.0
	},
	"1339": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 19,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 16,
		"count_bots": 27664,
		"power_bots": 36.1904761905,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 299.0
	},
	"1340": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 19,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 15,
		"count_bots": 19734,
		"power_bots": 28.0476190476,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 527.0
	},
	"1341": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 19,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 6,
		"count_bots": 14552,
		"power_bots": 0.9047619048,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 95.0
	},
	"1342": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 19,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 14962,
		"power_bots": 0.9047619048,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 92.0
	},
	"1343": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 20,
		"id бота": 29,
		"count_bots_min": 1,
		"count_bots_max": 16,
		"count_bots": 28867,
		"power_bots": 38.0952380952,
		"count_battles": 5000,
		"id": 29,
		"name": "Проклятье",
		"level": 21,
		"real level": 312.0
	},
	"1344": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 20,
		"id бота": 64,
		"count_bots_min": 1,
		"count_bots_max": 13,
		"count_bots": 20406,
		"power_bots": 29.5238095238,
		"count_battles": 5000,
		"id": 64,
		"name": "Призрачный ужас",
		"level": 46,
		"real level": 550.0
	},
	"1345": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 20,
		"id бота": 111,
		"count_bots_min": 1,
		"count_bots_max": 7,
		"count_bots": 15424,
		"power_bots": 0.9523809524,
		"count_battles": 5000,
		"id": 111,
		"name": "Тень смерти",
		"level": 68,
		"real level": 97.0
	},
	"1346": {
		"Имя шахты": "Шахта призраков",
		"Этаж шахты": 20,
		"id бота": 112,
		"count_bots_min": 1,
		"count_bots_max": 9,
		"count_bots": 15635,
		"power_bots": 0.9523809524,
		"count_battles": 5000,
		"id": 112,
		"name": "Тень забвения",
		"level": 66,
		"real level": 94.0
	}
};
export default mineBotsProbs;
