import React, {useState, useEffect} from 'react';
import MetaTags from 'react-meta-tags';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


const Price = () => {
	
	const [itemid, set_itemid] = useState('')
	const [results, set_results] = useState({price: 0, effects: []})
	
	
	useEffect(() => {
		
		let id = itemid.replace(':', '').replace('item', '').replace(':', '')
		if (id === '') {
			return
		}
		console.log(id)
	
		fetch(`https://psychea.space/avatars/api/mfp?id_item=${id}`, {mode: 'cors'})
		.then(data=>data.json())
		.then(data=>{
			
			console.log(data)
			set_results(data)
		})
		.catch(e=>console.log(e));
		
	}, [itemid]);
	
	
	
    return (
       <div>
		<MetaTags>
            <title>Ценообразование</title>
            <meta name="description" content="Аватариум - экосистема приложений для игры Аватары: Эпоха хаоса. Описание квестом, аналитика, статьи и многое другое" />
            <meta property="og:title" content="Аватариум" />
            <meta property="og:image" content="../images/favicon.ico" />
	  	</MetaTags>
		<h1>Ценообразование</h1>
		<div >
			<Grid container spacing={1} alignItems="center" justify="center" direction="column">
			  <Grid item >
				<TextField style={{width: 350}} label="ID вещи (формат :item123: или 123)" value={itemid} onChange={(e) => set_itemid(e.target.value)}/>
			  </Grid>
			  {results && (
				<Grid item>
				  Рекомендуемая цена: {results['price']}
				</Grid>
				)}
				
				{results['effects'].map((item, index) => (
					<Grid item key={index}>
					  <Typography variant="body1" 
						 style={{
						  backgroundColor: item[1] < 0 ? `rgba(255, 0, 0, ${Math.abs(10*item[1] / 100)})` : `rgba(0, 255, 0, ${Math.abs(10*item[1] / 100)})`
						}}>
						{item[0]}: {item[1]}
					  </Typography>
					</Grid>
				  ))}
			  
			</Grid>
		</div>
       </div>
    );
}
 
export default Price;