import React from 'react';
import './App.css';
import 'typeface-roboto';
import Menu from './components/Menu';

import { YMInitializer } from 'react-yandex-metrika';
import ReactGA from 'react-ga';

const trackingId = "UA-105739566-3"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);



function App() {
  return (
    <div>
	  	<YMInitializer accounts={[70820362]} options={{
											  clickmap: true,
											  trackLinks: true,
											  accurateTrackBounce: true,
											  webvisor: true,
											  trackHash: true,
											}} />
        <Menu/>
  	</div>
      
  );
}

export default App;
