import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const AddThingShop = ({hash}) => {
	const [id_add_thing, set_id_add_thing] = useState('');
	const [price_add_thing, set_price_add_thing] = useState('');
	
	const [status, set_status] = useState('');
	
	const [open_load, set_open_load] = useState(false);
	const [process, set_process] = useState(false);
	
	const handle_open_load_click = () => {
		set_open_load(true);
		
	  };
	const handle_open_load = () => {
		set_open_load(false);
		
	  };
	const add = () => {
		
		set_process(true)
		
		if (price_add_thing === '') {
			set_status('Вы не указали цену')
			set_process(false)
		} else if (id_add_thing === '') {
			set_status('Вы не id вещи')
			set_process(false)
		} else {
			fetch('https://psychea.space/avatars/api/shop/add/', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"hash": hash,
						"id": id_add_thing,
						"typeShop": "Вещь из магазина",
						"description": "Очень крутая штука",
						"price": price_add_thing
					})

				})
				.then(e => {
					return e.json()
					// Устанавливаем имя на 0

				})
				.then(d => {

					// Получили ответ
					console.log(d)
					set_status(d.status)
					set_id_add_thing('')
					set_price_add_thing('')
					set_process(false)

				})
				.catch(e => console.log(e))
		}


	};
  return (
    <div>
	  <Button variant="outlined" color="primary" onClick={handle_open_load_click}>
		Добавить вещь
	  </Button>
	  <Dialog open={open_load} onClose={handle_open_load} aria-labelledby="form-dialog-title">
		<DialogTitle id="form-dialog-title">Добавление вещи</DialogTitle>
		<DialogContent dividers>
		  <Typography gutterBottom>
            Для добавления своей вещи в базу, введите её id (можно вместе с :itemXXXX:) и Вашу цену продажи (в золоте)
          </Typography>
          
		  <TextField
			autoFocus
			margin="dense"
			id="id_item"
			onChange={(e) => set_id_add_thing(e.target.value)}
			label="id предмета"
			fullWidth={true}
			value={id_add_thing}
		  /> 
		  <TextField
			autoFocus
			margin="dense"
			id="price_item"
			onChange={(e) => set_price_add_thing(e.target.value)}
			label="цена"
			fullWidth={true}
			value={price_add_thing}
		  />
		  
		  <Typography gutterBottom>
            {status}
          </Typography>
			{process && <LinearProgress />}
		</DialogContent>
		<DialogActions>
		  <Button onClick={handle_open_load} color="primary">
			Отмена
		  </Button>
		  <Button onClick={add} color="primary">
			Добавить
		  </Button>
		</DialogActions>
	  </Dialog>
	</div>
  );
};


export default AddThingShop;
