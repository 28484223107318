import React from 'react';
 
const Error = () => {
    return (
       <div>
          <p>Нет такой странички, ухади!</p>
       </div>
    );
}
 
export default Error;