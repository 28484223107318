import React, {useState, useEffect} from 'react';
import ym from 'react-yandex-metrika';
import Solver from 'javascript-lp-solver';
import ReactEcharts from 'echarts-for-react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';

import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';

import { splitNumber } from '../functions/Helpers'

const useStyles = makeStyles((theme) => ({
	root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

var img_res = {
	'Кость скелета': 'https://chaosage.ru/images/skeletBone.gif', 
	'Ноготь зомби': 'https://chaosage.ru/images/zombiNail.gif',
   'Клык вампира': 'https://chaosage.ru/images/vampireFang.gif',
   'Ромашка полевая': 'https://chaosage.ru/images/meadowCamomile.gif',
   'Древесный гриб': 'https://chaosage.ru/images/woodenMushroom.gif',
   'Кувшинка прыгучая': 'https://chaosage.ru/images/waterLily.gif',
   'Паучий хитин': 'https://chaosage.ru/images/spiderChitin.gif',
   'Жало скорпиона': 'https://chaosage.ru/images/scorpionSting.gif',
   'Перо грифона': 'https://chaosage.ru/images/grifonFeather.gif',
   'Рог минотавра': 'https://chaosage.ru/images/minotaurHorn.gif',
   'Прах призрака': 'https://chaosage.ru/images/ghostAshe.gif'
}

var db_res = {
	'Кость скелета': 'sceleton', 
	'Ноготь зомби': 'zombi',
   'Клык вампира': 'vampire',
   'Ромашка полевая': 'romashka',
   'Древесный гриб': 'grib',
   'Кувшинка прыгучая': 'kuvshinka',
   'Паучий хитин': 'hitin',
   'Жало скорпиона': 'scorpion',
   'Перо грифона': 'grif',
   'Рог минотавра': 'minos',
   'Прах призрака': 'ghost'
}

var arr_res = [
   'Кость скелета', 'Ноготь зомби',
   'Клык вампира',
   'Ромашка полевая',
   'Древесный гриб',
   'Кувшинка прыгучая',
   'Паучий хитин',
   'Жало скорпиона',
   'Перо грифона',
   'Рог минотавра',
   'Прах призрака']

var alchemy_spheres = ['Вода', 'Огонь', 'Астрал', 'Тьма', 'Песок', 'Сияние']
var alchemy_powerups = ['Пыль (8)', 'Капля (16)', 'Поток (24)', 'Вихрь (32)', 'Шквал (40)', 'Буря (48)', 'Ураган (56)', 'Торнадо (64)', 'Циклон (72)', 'Катаклизм (80)']

var alchemy_summary = [
	["Вода", "Пыль", 50, 10, "Белая эссенция(1), Черная эссенция(2), Желтая эссенция(1), Красная эссенция(1), Синяя эссенция(3), Серая эссенция(1)"],
	["Вода", "Капля", 50, 20, "Белая эссенция(3), Черная эссенция(6), Желтая эссенция(3), Красная эссенция(3), Синяя эссенция(9), Серая эссенция(3)"],
	["Вода", "Поток", 50, 40, "Белая эссенция(5), Черная эссенция(10), Желтая эссенция(5), Красная эссенция(5), Синяя эссенция(15), Серая эссенция(5)"],
	["Вода", "Вихрь", 50, 70, "Белая эссенция(7), Черная эссенция(14), Желтая эссенция(7), Красная эссенция(7), Синяя эссенция(21), Серая эссенция(7)"],
	["Вода", "Шквал", 50, 110, "Белая эссенция(9), Черная эссенция(18), Желтая эссенция(9), Красная эссенция(9), Синяя эссенция(27), Серая эссенция(9)"],
	["Вода", "Буря", 50, 160, "Белая эссенция(11), Черная эссенция(22), Желтая эссенция(11), Красная эссенция(11), Синяя эссенция(33), Серая эссенция(11)"],
	["Вода", "Ураган", 50, 220, "Белая эссенция(13), Черная эссенция(26), Желтая эссенция(13), Красная эссенция(13), Синяя эссенция(39), Серая эссенция(13)"],
	["Вода", "Торнадо", 50, 290, "Белая эссенция(15), Черная эссенция(30), Желтая эссенция(15), Красная эссенция(15), Синяя эссенция(45), Серая эссенция(15)"],
	["Вода", "Циклон", 50, 370, "Белая эссенция(17), Черная эссенция(34), Желтая эссенция(17), Красная эссенция(17), Синяя эссенция(51), Серая эссенция(17)"],
	["Вода", "Катаклизм", 50, 460, "Белая эссенция(19), Черная эссенция(38), Желтая эссенция(19), Красная эссенция(19), Синяя эссенция(57), Серая эссенция(19)"],
    
    ["Огонь", "Пыль", 50, 10, "Белая эссенция(1), Черная эссенция(1), Желтая эссенция(2), Красная эссенция(3), Синяя эссенция(1), Серая эссенция(1)"],
	["Огонь", "Капля", 50, 20, "Белая эссенция(3), Черная эссенция(3), Желтая эссенция(6), Красная эссенция(9), Синяя эссенция(3), Серая эссенция(3)"],
	["Огонь", "Поток", 50, 40, "Белая эссенция(5), Черная эссенция(5), Желтая эссенция(10), Красная эссенция(15), Синяя эссенция(5), Серая эссенция(5)"],
	["Огонь", "Вихрь", 50, 70, "Белая эссенция(7), Черная эссенция(7), Желтая эссенция(14), Красная эссенция(21), Синяя эссенция(7), Серая эссенция(7)"],
	["Огонь", "Шквал", 50, 110, "Белая эссенция(9), Черная эссенция(9), Желтая эссенция(18), Красная эссенция(27), Синяя эссенция(9), Серая эссенция(9)"],
	["Огонь", "Буря", 50, 160, "Белая эссенция(11), Черная эссенция(11), Желтая эссенция(22), Красная эссенция(33), Синяя эссенция(11), Серая эссенция(11)"],
	["Огонь", "Ураган", 50, 220, "Белая эссенция(13), Черная эссенция(13), Желтая эссенция(26), Красная эссенция(39), Синяя эссенция(13), Серая эссенция(13)"],
	["Огонь", "Торнадо", 50, 290, "Белая эссенция(15), Черная эссенция(15), Желтая эссенция(30), Красная эссенция(45), Синяя эссенция(15), Серая эссенция(15)"],
	["Огонь", "Циклон", 50, 370, "Белая эссенция(17), Черная эссенция(17), Желтая эссенция(34), Красная эссенция(51), Синяя эссенция(17), Серая эссенция(17)"],
	["Огонь", "Катаклизм", 50, 460, "Белая эссенция(19), Черная эссенция(19), Желтая эссенция(38), Красная эссенция(57), Синяя эссенция(19), Серая эссенция(19)"],
    
    ["Астрал", "Пыль", 50, 10, "Белая эссенция(3), Черная эссенция(1), Желтая эссенция(1), Красная эссенция(1), Синяя эссенция(1), Серая эссенция(2)"],
	["Астрал", "Капля", 50, 20, "Белая эссенция(9), Черная эссенция(3), Желтая эссенция(3), Красная эссенция(3), Синяя эссенция(3), Серая эссенция(6)"],
	["Астрал", "Поток", 50, 40, "Белая эссенция(15), Черная эссенция(5), Желтая эссенция(5), Красная эссенция(5), Синяя эссенция(5), Серая эссенция(10)"],
	["Астрал", "Вихрь", 50, 70, "Белая эссенция(21), Черная эссенция(7), Желтая эссенция(7), Красная эссенция(7), Синяя эссенция(7), Серая эссенция(14)"],
	["Астрал", "Шквал", 50, 110, "Белая эссенция(27), Черная эссенция(9), Желтая эссенция(9), Красная эссенция(9), Синяя эссенция(9), Серая эссенция(18)"],
	["Астрал", "Буря", 50, 160, "Белая эссенция(33), Черная эссенция(11), Желтая эссенция(11), Красная эссенция(11), Синяя эссенция(11), Серая эссенция(22)"],
	["Астрал", "Ураган", 50, 220, "Белая эссенция(39), Черная эссенция(13), Желтая эссенция(13), Красная эссенция(13), Синяя эссенция(13), Серая эссенция(26)"],
	["Астрал", "Торнадо", 50, 290, "Белая эссенция(45), Черная эссенция(15), Желтая эссенция(15), Красная эссенция(15), Синяя эссенция(15), Серая эссенция(30)"],
	["Астрал", "Циклон", 50, 370, "Белая эссенция(51), Черная эссенция(17), Желтая эссенция(17), Красная эссенция(17), Синяя эссенция(17), Серая эссенция(34)"],
	["Астрал", "Катаклизм", 50, 460, "Белая эссенция(57), Черная эссенция(19), Желтая эссенция(19), Красная эссенция(19), Синяя эссенция(19), Серая эссенция(38)"],
    
    ["Тьма", "Пыль", 50, 10, "Белая эссенция(1), Черная эссенция(3), Желтая эссенция(1), Красная эссенция(1), Синяя эссенция(2), Серая эссенция(1)"],
	["Тьма", "Капля", 50, 20, "Белая эссенция(3), Черная эссенция(9), Желтая эссенция(3), Красная эссенция(3),  Синяя эссенция(6), Серая эссенция(3)"],
	["Тьма", "Поток", 50, 40, "Белая эссенция(5), Черная эссенция(15), Желтая эссенция(5), Красная эссенция(5), Синяя эссенция(10), Серая эссенция(5)"],
	["Тьма", "Вихрь", 50, 70, "Белая эссенция(7), Черная эссенция(21), Желтая эссенция(7), Красная эссенция(7), Синяя эссенция(14), Серая эссенция(7)"],
	["Тьма", "Шквал", 50, 110, "Белая эссенция(9), Черная эссенция(27), Желтая эссенция(9), Красная эссенция(9), Синяя эссенция(18), Серая эссенция(9)"],
	["Тьма", "Буря", 50, 160, "Белая эссенция(11), Черная эссенция(33), Желтая эссенция(11), Красная эссенция(11), Синяя эссенция(22), Серая эссенция(11)"],
	["Тьма", "Ураган", 50, 220, "Белая эссенция(13), Черная эссенция(39), Желтая эссенция(13), Красная эссенция(13), Синяя эссенция(26), Серая эссенция(13)"],
	["Тьма", "Торнадо", 50, 290, "Белая эссенция(15), Черная эссенция(45), Желтая эссенция(15), Красная эссенция(15), Синяя эссенция(30), Серая эссенция(15)"],
	["Тьма", "Циклон", 50, 370, "Белая эссенция(17), Черная эссенция(51), Желтая эссенция(17), Красная эссенция(17), Синяя эссенция(34), Серая эссенция(17)"],
	["Тьма", "Катаклизм", 50, 460, "Белая эссенция(19), Черная эссенция(57), Желтая эссенция(19), Красная эссенция(19), Синяя эссенция(38), Серая эссенция(19)"],
    
    ["Песок", "Пыль", 50, 10, "Белая эссенция(1), Черная эссенция(1), Желтая эссенция(3), Красная эссенция(2), Синяя эссенция(1), Серая эссенция(1)"],
	["Песок", "Капля", 50, 20, "Белая эссенция(3), Черная эссенция(3), Желтая эссенция(9), Красная эссенция(6), Синяя эссенция(3), Серая эссенция(3)"],
	["Песок", "Поток", 50, 40, "Белая эссенция(5), Черная эссенция(5), Желтая эссенция(15), Красная эссенция(10), Синяя эссенция(5), Серая эссенция(5)"],
	["Песок", "Вихрь", 50, 70, "Белая эссенция(7), Черная эссенция(7), Желтая эссенция(21), Красная эссенция(14), Синяя эссенция(7), Серая эссенция(7)"],
	["Песок", "Шквал", 50, 110, "Белая эссенция(9), Черная эссенция(9), Желтая эссенция(27), Красная эссенция(18), Синяя эссенция(9), Серая эссенция(9)"],
	["Песок", "Буря", 50, 160, "Белая эссенция(11), Черная эссенция(11), Желтая эссенция(33), Красная эссенция(22), Синяя эссенция(11), Серая эссенция(11)"],
	["Песок", "Ураган", 50, 220, "Белая эссенция(13), Черная эссенция(13), Желтая эссенция(39), Красная эссенция(26), Синяя эссенция(13), Серая эссенция(13)"],
	["Песок", "Торнадо", 50, 290, "Белая эссенция(15), Черная эссенция(15), Желтая эссенция(45), Красная эссенция(30), Синяя эссенция(15), Серая эссенция(15)"],
	["Песок", "Циклон", 50, 370, "Белая эссенция(17), Черная эссенция(17), Желтая эссенция(51), Красная эссенция(34), Синяя эссенция(17), Серая эссенция(17)"],
	["Песок", "Катаклизм", 50, 460, "Белая эссенция(19), Черная эссенция(19), Желтая эссенция(57), Красная эссенция(38), Синяя эссенция(19), Серая эссенция(19)"],
    
    ["Сияние", "Пыль", 50, 10, "Белая эссенция(2), Черная эссенция(1), Желтая эссенция(1), Красная эссенция(1), Синяя эссенция(1), Серая эссенция(3)"],
	["Сияние", "Капля", 50, 20, "Белая эссенция(6), Черная эссенция(3), Желтая эссенция(3), Красная эссенция(3), Синяя эссенция(3), Серая эссенция(9)"],
	["Сияние", "Поток", 50, 40, "Белая эссенция(10), Черная эссенция(5), Желтая эссенция(5), Красная эссенция(5), Синяя эссенция(5), Серая эссенция(15)"],
	["Сияние", "Вихрь", 50, 70, "Белая эссенция(14), Черная эссенция(7), Желтая эссенция(7), Красная эссенция(7), Синяя эссенция(7), Серая эссенция(21)"],
	["Сияние", "Шквал", 50, 110, "Белая эссенция(18), Черная эссенция(9), Желтая эссенция(9), Красная эссенция(9), Синяя эссенция(9), Серая эссенция(27)"],
	["Сияние", "Буря", 50, 160, "Белая эссенция(22), Черная эссенция(11), Желтая эссенция(11), Красная эссенция(11), Синяя эссенция(11), Серая эссенция(33)"],
	["Сияние", "Ураган", 50, 220, "Белая эссенция(26), Черная эссенция(13), Желтая эссенция(13), Красная эссенция(13), Синяя эссенция(13), Серая эссенция(39)"],
	["Сияние", "Торнадо", 50, 290, "Белая эссенция(30), Черная эссенция(15), Желтая эссенция(15), Красная эссенция(15), Синяя эссенция(15), Серая эссенция(45)"],
	["Сияние", "Циклон", 50, 370, "Белая эссенция(34), Черная эссенция(17), Желтая эссенция(17), Красная эссенция(17), Синяя эссенция(17), Серая эссенция(51)"],
	["Сияние", "Катаклизм", 50, 460, "Белая эссенция(38), Черная эссенция(19), Желтая эссенция(19), Красная эссенция(19), Синяя эссенция(19), Серая эссенция(57)"],
]



const Alchemy = () => {
	
	const classes = useStyles();
	const [results, set_results] = useState({})
	
	const [dynamic, set_dynamic] = useState([])
	
	const [results_price, set_results_price] = useState(0)
	const [results_count, set_results_count] = useState(0)
	const [results_text, set_results_text] = useState('')
	
	const [optimize, set_optimize] = useState('Стоимость')
	const [type_alchemy, set_type_alchemy] = useState('Астрал')
	const [power_alchemy, set_power_alchemy] = useState('Пыль (8)')
	const [alchemy_count, set_alchemy_count] = useState(0)
	const [resources_price, set_resources_price] = useState('')
	const [resources_price_all, set_resources_price_all] = useState('')
	
	
	const [white_essential, set_white_essential] = useState(0)
	const [black_essential, set_black_essential] = useState(0)
	const [yellow_essential, set_yellow_essential] = useState(0)
	const [red_essential, set_red_essential] = useState(0)
	const [blue_essential, set_blue_essential] = useState(0)
	const [gray_essential, set_gray_essential] = useState(0)
	
	const [alchemy_external_essential, set_alchemy_external_essential] = useState('')
	
	const [open, setOpen] = useState(false);
	
	const handleClose = (event) => {
		setOpen(false);
	  };
	
	const copyToClipboard = (event) => {
		event.target.focus();
		event.target.select();
	   	document.execCommand('copy');
    	setOpen(true)
	  };
	
	useEffect(() => {

		fetch('https://chaosage.app/resources/apiGetPricesOnRes.php?set=last')
			.then(response => response.json())
			.then(result => {

				let d = JSON.parse(result.out)
				console.log(d)
				
				return d

			})
		.then(d=>set_resources_price(d[0]))
		.catch(e => {
			console.log(e)
		});

		fetch('https://chaosage.app/resources/apiGetPricesOnRes.php?set=all')
			.then(response => response.json())
			.then(result => {

				let d = JSON.parse(result.out)
				
				// Нужно бы подрезать массив, чтобы он на 100 делился
				for (let i = 0; i< 120; i++) {
					if (d.length % 100 != 0) {
						d.shift()
					}
				}
				
				return d

			})
		.then(d=>set_resources_price_all(d))
		.catch(e => {
			console.log(e)
		});

	}, []);
	
	
	const change_type = (event) => {
		set_type_alchemy(event.target.value);
	  };
	
	const change_optimize = (event) => {
		set_optimize(event.target.value);
	  };
	
	const change_power = (event) => {
		set_power_alchemy(event.target.value);
	  };
	
	// Ввод количества эссенций из расширения
	const change_external_essential = (event) => {
		set_alchemy_external_essential(event.target.value);
		
		// Далее перераспределяем ресурсы по эссенциям
		
		var text = event.target.value.split(', ')
		
		for (var i in text) {
			let essential_name = text[i].split(': ')[0]
			let essential_count = text[i].split(': ')[1]*1
			
			if (essential_name === 'Белая эссенция') {
				set_white_essential(essential_count)
			}
			if (essential_name === 'Черная эссенция') {
				set_black_essential(essential_count)
			}
			else if (essential_name === 'Желтая эссенция') {
				set_yellow_essential(essential_count)
			}
			else if (essential_name === 'Красная эссенция') {
				set_red_essential(essential_count)
			}
			else if (essential_name === 'Синяя эссенция') {
				set_blue_essential(essential_count)
			}
			else if (essential_name === 'Серая эссенция') {
				set_gray_essential(essential_count)
			}
		}
	 };
	
	
	const change_count = (event) => {
		set_alchemy_count(event.target.value);
	  };
	
	
	
	const getOption = () => {
		
		var data_date = []
		var data_price = []
		
		
		for (let i =0; i< dynamic.length; i++) {
			data_date.push(dynamic[i]['date'])
			data_price.push(Math.round(10*dynamic[i]['result']/20)/10)
		}
		
		
		const option = {
			title: {
				text: `Динамика цен на варку`,
				top: 0
			},
			legend: {
				show: false,
//				orient: 'vertical',
//				align: 'right',
				right: 0,
				top: 20,
				selectedMode: 'single',
				//				formatter: (name) => name === 'folowers' ? 'Всего' : 'Динамика',
				//				right: 0
			},
			dataZoom: [{
				type: 'inside',
//				start: 0,
//				end: 10
			}, {
//				start: 0,
//				end: 10
			}],
			tooltip: {
				trigger: 'axis',
				//				formatter: function (params, ticket, callback) {
				////					console.log(params)
				//					return `Подписчиков: ${params[0].data[params[0].seriesName]}`
				//				}, 
				//				axisPointer: {
				//					type: 'cross',
				//					label: {
				//						backgroundColor: '#6a7985'
				//					}
				//				}
			},
			grid: {
				top: 80,
//				right: '30%',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: data_date,
				name: 'Дата',
				nameLocation: 'center',
				nameGap: 25
			},
			yAxis: {
				type: 'value',
				scale: true,
				name: 'Стоимость (1 банка)',
				nameLocation: 'end',
				nameGap: 0
			},

			series: {
				type: 'line',
				name: 'Стоимость ресурсов',
				smooth: true,
				showSymbol: false,
				data: data_price,
				markPoint: {
					data: [
						{type: 'max', name: 'Максимум'},
						{type: 'min', name: 'Минимум'}
					]
				},
				markLine: {
					data: [
						{type: 'average', name: 'Средняя'}
					]
				}
			}
		};

		return option
	}

	
	
	
	
	const calculcate = () => {
		
		ym('reachGoal', 'alchemy_deal');
//		Для начала определим, сколько необходимо эссенций
		
		var id = -1
		for (let i in alchemy_summary) {
			if (type_alchemy === alchemy_summary[i][0]) {
				let power_alchemy_local = power_alchemy.split(' ')[0]
				
				if (alchemy_summary[i][1] === power_alchemy_local) {
					id = i
					break
				}
			}
		}
		
		console.log(resources_price)
		
		var model = {
			"optimize": optimize,
			"opType": "min",
			"constraints": {
				"Белая эссенция": {min: alchemy_summary[id][4].split('(')[1].split(')')[0]*alchemy_count - white_essential},
				"Черная эссенция": {min: alchemy_summary[id][4].split('(')[2].split(')')[0]*alchemy_count - black_essential},
				"Желтая эссенция": {min: alchemy_summary[id][4].split('(')[3].split(')')[0]*alchemy_count - yellow_essential},
				"Красная эссенция": {min: alchemy_summary[id][4].split('(')[4].split(')')[0]*alchemy_count - red_essential},
				"Синяя эссенция": {min: alchemy_summary[id][4].split('(')[5].split(')')[0]*alchemy_count - blue_essential},
				"Серая эссенция": {min: alchemy_summary[id][4].split('(')[6].split(')')[0]*alchemy_count - gray_essential},
			},
			"variables": {
				"Кость скелета": {
					"Стоимость": resources_price['sceleton'],

					"Белая эссенция": 0,
					"Черная эссенция": 3,
					"Желтая эссенция": 2,
					"Красная эссенция": 0,
					"Синяя эссенция": 0,
					"Серая эссенция": 0,

					"Количество": 1
				}, 
				"Ноготь зомби": {
					"Стоимость": resources_price['zombi'],

					"Белая эссенция": 0,
					"Черная эссенция": 0,
					"Желтая эссенция": 0,
					"Красная эссенция": 1,
					"Синяя эссенция": 0,
					"Серая эссенция": 4,

					"Количество": 1
				}, 
				"Клык вампира": {
					"Стоимость": resources_price['vampire'],

					"Белая эссенция": 0,
					"Черная эссенция": 0,
					"Желтая эссенция": 0,
					"Красная эссенция": 5,
					"Синяя эссенция": 0,
					"Серая эссенция": 0,

					"Количество": 1
				}, 
				"Ромашка полевая": {
					"Стоимость": resources_price['romashka'],

					"Белая эссенция": 3,
					"Черная эссенция": 0,
					"Желтая эссенция": 0,
					"Красная эссенция": 0,
					"Синяя эссенция": 0,
					"Серая эссенция": 0,

					"Количество": 1
				}, 
				"Древесный гриб": {
					"Стоимость": resources_price['grib'],

					"Белая эссенция": 0,
					"Черная эссенция": 0,
					"Желтая эссенция": 2,
					"Красная эссенция": 0,
					"Синяя эссенция": 1,
					"Серая эссенция": 0,

					"Количество": 1
				}, 
				"Кувшинка прыгучая": {
					"Стоимость": resources_price['kuvshinka'],

					"Белая эссенция": 0,
					"Черная эссенция": 0,
					"Желтая эссенция": 0,
					"Красная эссенция": 0,
					"Синяя эссенция": 3,
					"Серая эссенция": 0,

					"Количество": 1
				}, 
				"Паучий хитин": {
					"Стоимость": resources_price['hitin'],

					"Белая эссенция": 0,
					"Черная эссенция": 0,
					"Желтая эссенция": 4,
					"Красная эссенция": 2,
					"Синяя эссенция": 2,
					"Серая эссенция": 0,

					"Количество": 1
				}, 
				"Жало скорпиона": {
					"Стоимость": resources_price['scorpion'],

					"Белая эссенция": 0,
					"Черная эссенция": 9,
					"Желтая эссенция": 0,
					"Красная эссенция": 0,
					"Синяя эссенция": 2,
					"Серая эссенция": 0,

					"Количество": 1
				}, 
				"Перо грифона": {
					"Стоимость": resources_price['grif'],

					"Белая эссенция": 5,
					"Черная эссенция": 0,
					"Желтая эссенция": 0,
					"Красная эссенция": 0,
					"Синяя эссенция": 0,
					"Серая эссенция": 4,

					"Количество": 1
				}, 
				"Рог минотавра": {
					"Стоимость": resources_price['minos'],

					"Белая эссенция": 2,
					"Черная эссенция": 1,
					"Желтая эссенция": 0,
					"Красная эссенция": 3,
					"Синяя эссенция": 0,
					"Серая эссенция": 1,

					"Количество": 1
				}, 
				"Прах призрака": {
					"Стоимость": resources_price['ghost'],

					"Белая эссенция": 0,
					"Черная эссенция": 4,
					"Желтая эссенция": 2,
					"Красная эссенция": 2,
					"Синяя эссенция": 1,
					"Серая эссенция": 4,

					"Количество": 1
				}
			},
			"ints": {"Кость скелета": 1, "Ноготь зомби": 1, "Клык вампира": 1, "Ромашка полевая": 1, "Древесный гриб": 1, "Кувшинка прыгучая": 1, "Паучий хитин": 1, "Жало скорпиона": 1, "Перо грифона": 1, "Рог минотавра": 1, "Прах призрака": 1},
			"options": {
				"timeout": 3000
			}
		};
		var results_local = Solver.Solve(model);
		
		console.log(model["variables"]);
		console.log(results_local);

		var local_price = 0
		var local_count = 0
		
		var text_for_clip_bar = []
		
		for (let i in results_local) {
			if (db_res.hasOwnProperty(i)) {
				// есть такой ресурс, считаем его стоимость и количество
				local_count += Math.ceil(results_local[i])
				local_price += Math.ceil(results_local[i]*resources_price[db_res[i]])
				
				text_for_clip_bar.push(i + ': ' + Math.ceil(results_local[i]))
			}
		}
		
		text_for_clip_bar = text_for_clip_bar.join(', ')
		
		set_results_text(text_for_clip_bar)
		set_results_price(local_price)
		set_results_count(local_count)
		set_results(results_local)
	}
	const calculcate_all = () => {
		
		ym('reachGoal', 'alchemy_deal');
//		Для начала определим, сколько необходимо эссенций
		
		var id = -1
		for (let i in alchemy_summary) {
			if (type_alchemy === alchemy_summary[i][0]) {
				let power_alchemy_local = power_alchemy.split(' ')[0]
				
				if (alchemy_summary[i][1] === power_alchemy_local) {
					id = i
					break
				}
			}
		}
		
		var out = []
		// Подрежем массив с ценами
		// Смотрим каждые 25 дней
		for (let id_ = 0; id_ < resources_price_all.length; id_ += 1) {
			if ((id_ + 1) % 100 != 0) {
				continue
			}
			
			var model = {
				"optimize": 'Стоимость',
				"opType": "min",
				"constraints": {
					"Белая эссенция": {min: alchemy_summary[id][4].split('(')[1].split(')')[0]*2 - 0},
					"Черная эссенция": {min: alchemy_summary[id][4].split('(')[2].split(')')[0]*2 - 0},
					"Желтая эссенция": {min: alchemy_summary[id][4].split('(')[3].split(')')[0]*2 - 0},
					"Красная эссенция": {min: alchemy_summary[id][4].split('(')[4].split(')')[0]*2 - 0},
					"Синяя эссенция": {min: alchemy_summary[id][4].split('(')[5].split(')')[0]*2 - 0},
					"Серая эссенция": {min: alchemy_summary[id][4].split('(')[6].split(')')[0]*2 - 0},
				},
				"variables": {
					"Кость скелета": {
						"Стоимость": resources_price_all[id_]['sceleton'],

						"Белая эссенция": 0,
						"Черная эссенция": 3,
						"Желтая эссенция": 2,
						"Красная эссенция": 0,
						"Синяя эссенция": 0,
						"Серая эссенция": 0,

						"Количество": 1
					}, 
					"Ноготь зомби": {
						"Стоимость": resources_price_all[id_]['zombi'],

						"Белая эссенция": 0,
						"Черная эссенция": 0,
						"Желтая эссенция": 0,
						"Красная эссенция": 1,
						"Синяя эссенция": 0,
						"Серая эссенция": 4,

						"Количество": 1
					}, 
					"Клык вампира": {
						"Стоимость": resources_price_all[id_]['vampire'],

						"Белая эссенция": 0,
						"Черная эссенция": 0,
						"Желтая эссенция": 0,
						"Красная эссенция": 5,
						"Синяя эссенция": 0,
						"Серая эссенция": 0,

						"Количество": 1
					}, 
					"Ромашка полевая": {
						"Стоимость": resources_price_all[id_]['romashka'],

						"Белая эссенция": 3,
						"Черная эссенция": 0,
						"Желтая эссенция": 0,
						"Красная эссенция": 0,
						"Синяя эссенция": 0,
						"Серая эссенция": 0,

						"Количество": 1
					}, 
					"Древесный гриб": {
						"Стоимость": resources_price_all[id_]['grib'],

						"Белая эссенция": 0,
						"Черная эссенция": 0,
						"Желтая эссенция": 2,
						"Красная эссенция": 0,
						"Синяя эссенция": 1,
						"Серая эссенция": 0,

						"Количество": 1
					}, 
					"Кувшинка прыгучая": {
						"Стоимость": resources_price_all[id_]['kuvshinka'],

						"Белая эссенция": 0,
						"Черная эссенция": 0,
						"Желтая эссенция": 0,
						"Красная эссенция": 0,
						"Синяя эссенция": 3,
						"Серая эссенция": 0,

						"Количество": 1
					}, 
					"Паучий хитин": {
						"Стоимость": resources_price_all[id_]['hitin'],

						"Белая эссенция": 0,
						"Черная эссенция": 0,
						"Желтая эссенция": 4,
						"Красная эссенция": 2,
						"Синяя эссенция": 2,
						"Серая эссенция": 0,

						"Количество": 1
					}, 
					"Жало скорпиона": {
						"Стоимость": resources_price_all[id_]['scorpion'],

						"Белая эссенция": 0,
						"Черная эссенция": 9,
						"Желтая эссенция": 0,
						"Красная эссенция": 0,
						"Синяя эссенция": 2,
						"Серая эссенция": 0,

						"Количество": 1
					}, 
					"Перо грифона": {
						"Стоимость": resources_price_all[id_]['grif'],

						"Белая эссенция": 5,
						"Черная эссенция": 0,
						"Желтая эссенция": 0,
						"Красная эссенция": 0,
						"Синяя эссенция": 0,
						"Серая эссенция": 4,

						"Количество": 1
					}, 
					"Рог минотавра": {
						"Стоимость": resources_price_all[id_]['minos'],

						"Белая эссенция": 2,
						"Черная эссенция": 1,
						"Желтая эссенция": 0,
						"Красная эссенция": 3,
						"Синяя эссенция": 0,
						"Серая эссенция": 1,

						"Количество": 1
					}, 
					"Прах призрака": {
						"Стоимость": resources_price_all[id_]['ghost'],

						"Белая эссенция": 0,
						"Черная эссенция": 4,
						"Желтая эссенция": 2,
						"Красная эссенция": 2,
						"Синяя эссенция": 1,
						"Серая эссенция": 4,

						"Количество": 1
					}
				},
				"ints": {"Кость скелета": 1, "Ноготь зомби": 1, "Клык вампира": 1, "Ромашка полевая": 1, "Древесный гриб": 1, "Кувшинка прыгучая": 1, "Паучий хитин": 1, "Жало скорпиона": 1, "Перо грифона": 1, "Рог минотавра": 1, "Прах призрака": 1}
			};
			var results_local = Solver.Solve(model);
			results_local['date'] = resources_price_all[id_]['date']
				
			
			out.push(results_local)
			
		}


		set_dynamic(out)
	}
    return (
       <Container  maxWidth="xl">
          <h1>Алхимия</h1>
		  <Grid
			  container
			  direction="row"
			  justify="center"
			  alignItems="flex-start"
			>
         <Grid item xs={12} md={4} style={{textAlign: 'center'}}>
         <div >
         	<FormControl className={classes.formControl}>
			<InputLabel id='type_alch'>Тип зелья</InputLabel>
			<Select
			  labelId="type_alch"
			  value={type_alchemy}
			  onChange={change_type}
			>
				{alchemy_spheres.map(sphere=>{
					return (<MenuItem key={sphere} value={sphere}>{sphere}</MenuItem>)
				})}
			</Select>
		  </FormControl>
		  <FormControl className={classes.formControl}>
			<InputLabel id='power_alch'>Сила зелья</InputLabel>
			<Select
			  labelId="power_alch"
			  value={power_alchemy}
			  onChange={change_power}
			>
				{alchemy_powerups.map(powerup=>{
					return (<MenuItem key={powerup} value={powerup}>{powerup}</MenuItem>)
				})}
			</Select>
		  </FormControl>
		  <FormControl className={classes.formControl}>
			<InputLabel id='opt_alch'>Минимизировать</InputLabel>
			<Select
			  labelId="opt_alch"
			  value={optimize}
			  onChange={change_optimize}
			>
				<MenuItem value={'Количество'}>Количество</MenuItem>
				<MenuItem value={'Стоимость'}>Стоимость</MenuItem>
			</Select>
		  </FormControl>
         </div>
          <TextField style={{margin: 5}} id="outlined-basic" label="Количество" variant="outlined" size="small" value={alchemy_count} onChange={change_count}/>
           <Button style={{margin: 5, width: '150px'}} variant="contained" color="primary" size='medium' onClick={() => calculcate()}>
			  Посчитать
			</Button>
        <h2>Дополнительно</h2>
        <div>
			<div className={classes.margin}>
				<Grid container spacing={1} alignItems="flex-end">
				  <Grid item>
					<img src='https://chaosage.ru/images/essenceWhiteSmall.png' alt='Белая эссенция'></img>
				  </Grid>
				  <Grid item>
					<TextField label="Белая эссенция" value={white_essential} onChange={(e) => set_white_essential(e.target.value)}/>
				  </Grid>
				</Grid>
			</div>
			<div className={classes.margin}>
				<Grid container spacing={1} alignItems="flex-end">
				  <Grid item>
					<img src='https://chaosage.ru/images/essenceBlackSmall.png' alt='Черная эссенция'></img>
				  </Grid>
				  <Grid item>
					<TextField label="Черная эссенция" value={black_essential} onChange={(e) => set_black_essential(e.target.value)}/>
				  </Grid>
				</Grid>
			</div>
			<div className={classes.margin}>
				<Grid container spacing={1} alignItems="flex-end">
				  <Grid item>
					<img src='https://chaosage.ru/images/essenceYellowSmall.png' alt='Желтая эссенция'></img>
				  </Grid>
				  <Grid item>
					<TextField label="Желтая эссенция" value={yellow_essential} onChange={(e) => set_yellow_essential(e.target.value)}/>
				  </Grid>
				</Grid>
			</div>
			<div className={classes.margin}>
				<Grid container spacing={1} alignItems="flex-end">
				  <Grid item>
					<img src='https://chaosage.ru/images/essenceRedSmall.png' alt='Красная эссенция'></img>
				  </Grid>
				  <Grid item>
					<TextField label="Красная эссенция" value={red_essential} onChange={(e) => set_red_essential(e.target.value)}/>
				  </Grid>
				</Grid>
			</div>
			<div className={classes.margin}>
				<Grid container spacing={1} alignItems="flex-end">
				  <Grid item>
					<img src='https://chaosage.ru/images/essenceBlueSmall.png' alt='Синяя эссенция'></img>
				  </Grid>
				  <Grid item>
					<TextField label="Синяя эссенция" value={blue_essential} onChange={(e) => set_blue_essential(e.target.value)}/>
				  </Grid>
				</Grid>
			</div>
			<div className={classes.margin}>
				<Grid container spacing={1} alignItems="flex-end">
				  <Grid item>
					<img src='https://chaosage.ru/images/essenceGraySmall.png' alt='Серая эссенция'></img>
				  </Grid>
				  <Grid item>
					<TextField label="Серая эссенция" value={gray_essential} onChange={(e) => set_gray_essential(e.target.value)}/>
				  </Grid>
				</Grid>
			</div>
       		<TextField style={{margin: 5}} id="outlined-basic-2" label="Строка для эссенций" variant="outlined" size="small" value={alchemy_external_essential} onChange={change_external_essential}/>
        </div>
         </Grid>
	  <Grid item xs={12}  md={5}>
	  	<Grid item>
	  	
			{results_text !== '' && <TextField onClick={copyToClipboard} 
				InputProps={{
					readOnly: true,
				  }} 
				size={'small'} 
				id={"outlined-basic-"+1} 
				label="Скопировать" variant="outlined" value={results_text}/>}
		  <p>Общая стоимость: {splitNumber(Math.round(results_price*1)/1)} сер.</p>
		  <p>Всего ресурсов нужно купить: {results_count} шт.</p>
		  <p>Зелий на выходе: {alchemy_count*10} шт.</p>
	  	</Grid>
	  	<Grid item>
	  	
			
           	<Button style={{margin: 5, width: '100%'}} variant="contained" color="primary" size='medium' onClick={() => calculcate_all()}>
			  Посчитать динамику стоимости
			</Button>
			<Paper className={classes.paper} style={{width: '100%'}}>
				<ReactEcharts style = {{width: '100%', height: 400}} option = { getOption() }/> 
			</Paper>
	  	</Grid>
	 </Grid>
    
	  <Grid item xs={12}  md={3} style={{textAlign: 'center'}}>
			<List className={classes.root} dense>
			  {arr_res.filter(res=>results.hasOwnProperty(res)).map((res, id) => {
				  return (
						<ListItem key={res}>
							<ListItemAvatar>
							  <Avatar alt={res} src={img_res[res]} />
							</ListItemAvatar>
							<ListItemText primary={`${res}: ${splitNumber(Math.ceil(results[res]))} шт.`} secondary={`Цена покупки: ${splitNumber(Math.round(results[res]*resources_price[db_res[res]]*1)/1)} сер.`} />
						  </ListItem>
						 )
			  })}

			</List>
			  </Grid>
     </Grid>
      
      
      <Snackbar
			open={open}
			onClose={handleClose}
			autoHideDuration={700}
			message="Успешно скопировано"
		  />
       </Container>
    );
}
 
export default Alchemy;