// Кланы и слава
import React, {
	useEffect,
	useState
} from 'react';

import ReactEcharts from 'echarts-for-react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
//import Tooltip from '@material-ui/core/Tooltip';
import { Scrollbars } from 'react-custom-scrollbars';
//import Skeleton from '@material-ui/lab/Skeleton';

import LinearProgress from '@material-ui/core/LinearProgress';

import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';

import ResBigPrice from './additional/ResBigPrice.js'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));




const Clans = () => {
	
	const classes = useStyles();
	
	const [isLoaded, setIsLoaded] = useState(false)
	
	const [clans_data, set_clans_data] = useState([])
	const [glory_data, set_glory_data] = useState([])
	
	const [agg_glory_data, set_agg_glory_data] = useState([])
	
	const [dateLast, setDateLast] = useState('23.02.2020')
	
	const [clan_name, set_clan_name] = useState('Уничтожители')
	const [clan_data_type, set_clan_data_type] = useState('Слава')
	const [char_name, set_char_name] = useState('Материя')
	
	
	useEffect(() => {

		fetch('https://psychea.space/avatars/api/avatarium/clans')
			.then(response => response.json())
			.then(result => {

				let clans_data = result.clans
				let glory_data = result.glory
				
				// Нужно агрегировать данные  по славе для игроков
				var agg_glory_data = []
				for (let i in glory_data) {
					
					// перебираем нашу агрегацию и ищем данного человека
					var find = false
					for (let j in agg_glory_data) {
						if ((agg_glory_data[j].name === glory_data[i].name) && (agg_glory_data[j].clans === glory_data[i].clans)) {
							agg_glory_data[j]['max(gloryDaily)'] += glory_data[i]['max(gloryDaily)']
							agg_glory_data[j]['count'] += 1
							find = true
						}
					}
					
					// Если впервые, создаем
					if (!find) {
						let d = JSON.parse(JSON.stringify(glory_data[i]))
						d.count = 1
						agg_glory_data.push(d)
					}
					
				}
				
			
				
				
				set_agg_glory_data(agg_glory_data)
				
				set_clans_data(clans_data)
				set_glory_data(glory_data)
				setDateLast(glory_data[glory_data.length-1].week_name.replace('/', ', неделя '))
				setIsLoaded(true)

			})
			.catch(e => {
				setIsLoaded(true)
				console.log(e);
			});

	}, []);
	
	// Рисуем динамику цены на данный чертеж
	const draw_graph = (item) => {
		
		
		console.log('мяу')
	}
	
	
	const get_option_char = () => {
		const option = {
			title: {
				text: `Динамика славы ${char_name}`,
				top: 0
			},
			legend: {
				show: true,
				right: 0,
				top: 20,
				selectedMode: 'single',
			},
			tooltip: {
				trigger: 'axis',
			},
			grid: {
				top: 80,
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: (() => {
					   	let d = glory_data.filter(d=>(d['name']===char_name)).map(d=>d['week_name'])
						return d
					   })(),
				name: 'Дата',
				nameLocation: 'center',
				nameGap: 25
			},
			yAxis: {
				type: 'value',
				name: 'Слава',
				nameLocation: 'end',
				nameGap: 25,
				scale: false,
			},
			dataZoom: [{
				type: 'inside',
//				start: 0,
//				end: 10
			}, {
//				start: 0,
//				end: 10
			}],
			series: [{
				type: 'line',
				name: 'Слава',
				smooth: false,
				showSymbol: false,
				data: (() => {
					   	let d = glory_data.filter(d=>(d['name']===char_name)).map(d=>d['max(gloryDaily)'])
						return d
					   })(),
				markPoint: {
					data: [
						{
							type: 'max',
							name: 'Максимум'
						},
						{
							type: 'min',
							name: 'Минимум'
						}
							]
				},
				markLine: {
					data: [
						{
							type: 'average',
							name: 'Средняя'
						}
							]
				}
					}]
		};

		return option
	}
	
	
	
	const get_option_clan = () => {
		const option = {
			title: {
				text: `Динамика параметра ${clan_data_type} клана ${clan_name}`,
				top: 0
			},
			legend: {
				show: true,
				right: 0,
				top: 20,
				selectedMode: 'single',
			},
			tooltip: {
				trigger: 'axis',
			},
			grid: {
				top: 80,
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: (() => {
					   	let d = clans_data.filter(d=>(d['name']===clan_name)).map(d=>d['date'])
						return d
					   })(),
				name: 'Дата',
				nameLocation: 'center',
				nameGap: 25
			},
			yAxis: {
				type: 'value',
				name: clan_data_type,
				nameLocation: 'end',
				nameGap: 25,
				scale: true,
			},
			dataZoom: [{
				type: 'inside',
//				start: 0,
//				end: 10
			}, {
//				start: 0,
//				end: 10
			}],
			series: [{
				type: 'line',
				name: clan_data_type,
				smooth: false,
				showSymbol: false,
				data: (() => {
						
						var f = 'glory'
						if (clan_data_type === 'Слава') {
							f = 'glory'
						} else if (clan_data_type === 'Общий опыт') {
							f = 'exp_sum'
						}  else if (clan_data_type === 'Средний опыт') {
							f = 'exp_avg'
						} else if (clan_data_type === 'Состав') {
							f = 'avatars_count'
						}
					   	let d = clans_data.filter(d=>(d['name']===clan_name)).map(d=>d[f])
						
						return d
					   })(),
				markPoint: {
					data: [
						{
							type: 'max',
							name: 'Максимум'
						},
						{
							type: 'min',
							name: 'Минимум'
						}
							]
				},
				markLine: {
					data: [
						{
							type: 'average',
							name: 'Средняя'
						}
							]
				}
					}]
		};

		return option
	}
	
	
	
    return (
       <Container  maxWidth="xl" disableGutters>
		{!isLoaded && <LinearProgress />}
         <Grid
			container
			direction="row"  
		  	spacing={1}
			>
				<Grid item  xs={12} sm={12} md={12} lg={12}>
					<Card style={{height: "100%"}}>
					  <CardContent>
							<Typography variant="h6" component="h6" gutterBottom>
								Динамика развития кланов
							</Typography>
							<Typography variant="body2" gutterBottom>
								Статистика получения славы, опыта кланами. Данные о количестве активных участников и индекс активности кланов
							</Typography>
							<Typography variant="overline" display="block" gutterBottom>
								Актуально на {dateLast}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item  xs={12} sm={6} md={5} lg={4}>
					<Paper className={classes.paper} style={{width: '100%'}}>
						<FormControl className={classes.formControl}>
								<InputLabel id="demo-simple-select-helper-label">Клан</InputLabel>
								<Select
								  labelId="demo-simple-select-helper-label"
								  id="demo-simple-select-helper"
								  value={clan_name}
								  onChange={(e, value) => set_clan_name(value.props.value)}
								>
							  	{[...new Set(clans_data.map(item => item.name))].map(d=>{
										return(
											<MenuItem key={d} value={d}>{d}</MenuItem>
										)
									})}
								  
								</Select>
					  	</FormControl>
							  <List className={classes.root} dense={true}>
									<ListItem alignItems="flex-start"  >
										<ListItemAvatar>
										  <Avatar>{clan_name[0]}</Avatar>
										</ListItemAvatar>
										<ListItemText
										  primary={clan_name}
										  secondary={
											<React.Fragment>
											  <Typography
												component="span"
												variant="body2"
												className={classes.inline}
												color="textPrimary"
											  >
												В среднем сокланы приносят:  
											  </Typography>
												{' '} {agg_glory_data.filter(d=>(d['clans']===clan_name)).reduce(function(sum, current) {
														  return Math.round(1000*(sum + current['max(gloryDaily)']/current['count']))/1000;
														}, 0)} {' славы/неделю'}
											</React.Fragment>
										  }
										/>
								  </ListItem>

							</List>
						<Divider/>
						
						
						
						<Scrollbars style={{ height: 400 }}>
							<List className={classes.root} dense={true}>
								  {[].concat(agg_glory_data)
    								.sort((a, b) => a['max(gloryDaily)']/a['count'] < b['max(gloryDaily)']/b['count'] ? 1 : -1)
									.filter(d=>(d['clans']===clan_name))
									.map((l) =>
									<React.Fragment key={l['name']+l['id']} >
												{/*
													<Tooltip title={l.help}>
													*/
												}
											<ListItem alignItems="flex-start" button onClick={()=>draw_graph(l)}>
												<ListItemAvatar>
												  <Avatar>{l['name'][0]}</Avatar>
												</ListItemAvatar>
												<ListItemText
												  primary={l['name']}
												  secondary={
													<React.Fragment>
													  <Typography
														component="span"
														variant="body2"
														className={classes.inline}
														color="textPrimary"
													  >
														Слава: 
													  </Typography>
													  {' '} {Math.round(l['max(gloryDaily)']*1000/l['count'])/1000} {' в неделю и '} {Math.round(l['max(gloryDaily)']*1000)/1000} {' всего'}
													</React.Fragment>
												  }
												/>
										  </ListItem>
													{/*
													</Tooltip>
													*/
												}
									<Divider variant="inset" component="li" />
								</React.Fragment >
							  )}
							</List>
						</Scrollbars>
					</Paper>
				</Grid>
				<Grid item  xs={12} sm={6} md={7} lg={8}>
				{<Paper className={classes.paper} style={{width: '100%', textAlign: 'left', marginBottom: 5, color: 'black', padding: 15}}>
						
						<FormControl className={classes.formControl}>
								<InputLabel id="demo-simple-select-helper-label">Параметр</InputLabel>
								<Select
								  labelId="demo-simple-select-helper-label"
								  id="demo-simple-select-helper_2"
								  value={clan_data_type}
								  onChange={(e, value) => set_clan_data_type(value.props.value)}
								>
								  <MenuItem value={'Слава'}>Слава</MenuItem>
								  <MenuItem value={'Общий опыт'}>Общий опыт</MenuItem>
								  <MenuItem value={'Средний опыт'}>Средний опыт</MenuItem>
								  <MenuItem value={'Состав'}>Состав</MenuItem>
								</Select>
					  	</FormControl>
					</Paper>}
					
					<Paper className={classes.paper} style={{width: '100%'}}>
						<ReactEcharts style = {{width: '100%', height: 400}} 
 									option = { get_option_clan() } 
 									notMerge={false}
  									lazyUpdate={false}/> 
					</Paper>
				</Grid>
		</Grid>
       </Container>
    );
}
 
export default Clans;