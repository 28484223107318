import React, {
	useEffect,
	useState
} from 'react';

import ReactEcharts from 'echarts-for-react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
//import Tooltip from '@material-ui/core/Tooltip';
import { Scrollbars } from 'react-custom-scrollbars';
//import Skeleton from '@material-ui/lab/Skeleton';

import LinearProgress from '@material-ui/core/LinearProgress';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import ResBigPrice from './additional/ResBigPrice.js'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

//import {sampleStandardDeviation, interquartileRange, linearRegression } from 'simple-statistics'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Res = () => {
	const classes = useStyles();

	const [isLoaded, setIsLoaded] = useState(false)
//	const [data, setData] = useState(0)
	const [date, setDate] = useState([0])
	
	const [dateLast, setDateLast] = useState('16.02.2020')
	
	const [scale, setScale] = useState(true)
	const [buy, setBuy] = useState('Покупка')
	
	const [lines, selLines] = useState([])
	
	const [list, selList] = useState([])
	
	const [res, setRes] = useState('Обсидиан')
	
	
	const [infoMax, setInfoMax] = useState([0])
	const [infoMin, setInfoMin] = useState([0])
	

	
//	useEffect(() => {
////		console.log('Тут')
////		console.log(lines)
//		console.log(buy)
//		//						// Применяем покупку/продажу
//		
//		if (buy === 'Покупка') {
//			console.log('Тт')
//			for (let i =0; i<lines.length; i++) {
//				for (let yu = 0; yu<lines[i].data.length; yu++) {
//					lines[i].data[yu] /= 0.9
//					lines[i].data[yu] = lines[i].data[yu].toFixed(4)*1
//				}
//			}
//			
//		} else {
//			for (let i =0; i<lines.length; i++) {
//				for (let yu = 0; yu<lines[i].data.length; yu++) {
//					lines[i].data[yu] *= 0.9
//					lines[i].data[yu] = lines[i].data[yu].toFixed(4)*1
//				}
//			}
//			
//		}
//		
//	}, [buy]);

	
//	 const calculateMA = (dayCount, data0) => {
//		var result = [];
//		for (var i = 0, len = data0.values.length; i < len; i++) {
//			if (i < dayCount) {
//				result.push('-');
//				continue;
//			}
//			var sum = 0;
//			for (var j = 0; j < dayCount; j++) {
//				sum += data0.values[i - j][1];
//			}
//			result.push(sum / dayCount);
//		}
//		return result;
//	}
//	
	useEffect(() => {

		fetch('https://chaosage.app/resources/apiGetPricesOnRes.php?set=all')
			.then(response => response.json())
			.then(result => {

				let d = JSON.parse(result.out)

				var dateLocal = []
				var linesLocal = {}
				
				var urlImg = ['https://chaosage.ru/images/obsidian.gif', 'https://chaosage.ru/images/skin1.gif', 
							  'https://chaosage.ru/images/wood.gif', 'https://chaosage.ru/images/quartz.gif', 
							  'https://chaosage.ru/images/corundum.gif', 'https://chaosage.ru/images/diamond.gif', 
							  'https://chaosage.ru/images/ironOre.gif', 'https://chaosage.ru/images/copperOre.gif', 
							  'https://chaosage.ru/images/skeletBone.gif', 'https://chaosage.ru/images/zombiNail.gif', 
							  'https://chaosage.ru/images/vampireFang.gif', 'https://chaosage.ru/images/meadowCamomile.gif',
							  'https://chaosage.ru/images/woodenMushroom.gif', 'https://chaosage.ru/images/waterLily.gif', 
							  'https://chaosage.ru/images/spiderChitin.gif', 'https://chaosage.ru/images/scorpionSting.gif', 
							  'https://chaosage.ru/images/grifonFeather.gif', 'https://chaosage.ru/images/minotaurHorn.gif', 
							  'https://chaosage.ru/images/ghostAshe.gif']
				
				// Важен порядок ключей
				let resArr = ['Обсидиан', 'Грубая шкура',
				   'Бревно', 'Кварц',
				   'Корунд', 'Алмаз',
				   'Железная руда', 'Медная руда',
				   'Кость скелета', 'Ноготь зомби',
				   'Клык вампира',
				   'Ромашка полевая',
				   'Древесный гриб',
				   'Кувшинка прыгучая',
				   'Паучий хитин',
				   'Жало скорпиона',
				   'Перо грифона',
				   'Рог минотавра',
				   'Прах призрака']
				
				// Описание ресурсов
				let h = ['Добывается в Предгорной, Кобольдовой, Титанитовой и Призрачной шахтах',
						'Для снятия шкур необходимо купить Нож для сннятия шкур (-14; 14)',
						'Бревна добываются при рубке леса. Необходимо купить топор Лесоруба (-6; 11)',
						'Кварц добывается в пустыне',
						'Корунд добывается в Корундовой, Кобольдовой и Титанитовой шахтах',
						'Алмазы можно найти в Кимберлитовой, Призрачной и Титанитовой шахтах',
						'Железная руда добывается в Кимберлитовой и Предгорной шахтах',
						'Медная руда добывается в Корундовой и Предгорной шахтах',
						'Кость скелета падает при убийстве невызванных Скелетов',
						'Ноготь зомби можно получить при убийстве невызванных Зомби',
						'Клык вампира можно получить при убийстве невызванных Вампиров',
						'Ромашка полевая растет на полях и в степях',
						'Древесный гриб растет в лесу',
						'Кувшинка прыгучая растет на речках и в болотистой местности',
						'Паучий хитин можно получить при убийстве невызванных Черных пауков и Пауков-прядильщиков',
						'Жало скорпиона падает при убийстве скорпионов',
						'Перо грифона можно получить при убийстве грифонов',
						'Рог минотавра добывается при убийстве Минотавров',
						'Прах призрака можно обнаружить при убийстве монстров с расой Призрак',
						];
				
				// Заполняем данные
				for (let k =0; k<resArr.length; k++) {
					linesLocal[resArr[k]] = []
				}
				let resForDB = ['obsid', 'shkura', 'brevno',
					'kvarz', 'korund', 'diamond',
					'zruda', 'mruda', 'sceleton',
					'zombi', 'vampire', 'romashka',
					'grib', 'kuvshinka', 'hitin',
					'scorpion', 'grif', 'minos', 'ghost']
				
				for (let i = 0; i < d.length; i++) {
					
					dateLocal.push(d[i].date)
					
					for (let k = 0; k < resArr.length; k++) {
						
						linesLocal[resArr[k]].push((1*d[i][resForDB[k]]).toFixed(6)*1)
						
					}
					
					// Последний ресурс, определяем самый дешевый и самый дорогой
					if (i === (d.length-1)) {
						let max = [-1, '', 0]
						let min = [10000, '', 0]
						
						// перебираем ресурсы
						for (let k = 0; k < resArr.length; k++) {
							
							if ((d[i][resForDB[k]]*1) > max[0]) {
								
								max[0] = d[i][resForDB[k]]*1
								max[1] = resArr[k]
								max[2] = urlImg[k]
								
							}
								
							if ((d[i][resForDB[k]]*1) < min[0]) {
								
								min[0] = d[i][resForDB[k]]*1
								min[1] = resArr[k]
								min[2] = urlImg[k]
								
							}
							
						}
//						console.log(min)
//						console.log(max)
						
						
						setInfoMax(max)
						setInfoMin(min)
						
					}
					
				}
				
				var l = []
				// ФОрмируем графики
				for (var j in linesLocal) {
					
					l.push({
						type: 'line',
						name: j,
						smooth: true,
						showSymbol: false,
						data: linesLocal[j],
						markPoint: {
							data: [
								{type: 'max', name: 'Максимум'},
								{type: 'min', name: 'Минимум'}
							]
						},
						markLine: {
							data: [
								{type: 'average', name: 'Средняя'}
							]
						}
					})
				}

				var s = []
				// Список ресурсов с изображениями
				for (let n = 0; n<urlImg.length; n++) {

					s.push({
						name: resArr[n],
						urls: urlImg[n],
						price: linesLocal[resArr[n]][linesLocal[resArr[n]].length-1],
						help: h[n]
					})
					
				}
//				
				// Даты
				setDate(dateLocal)
				setDateLast(dateLocal[dateLocal.length-1])
				
				selLines([...l])
				selList([...s])
				
//				setData(d)
				setIsLoaded(true)
				return d

			}).then(res => {
				//				console.log(res[0])
			})
			.catch(e => {
				setIsLoaded(true)
				//				console.log(e);
			});

	}, []);

	const getOption = () => {
		const option = {
			title: {
				text: `Цены на ресурсы (${buy})`,
				top: 0
			},
			legend: {
				show: true,
//				orient: 'vertical',
//				align: 'right',
				right: 0,
				top: 20,
				selectedMode: 'single',
				//				formatter: (name) => name === 'folowers' ? 'Всего' : 'Динамика',
				//				right: 0
			},
			dataZoom: [{
				type: 'inside',
//				start: 0,
//				end: 10
			}, {
//				start: 0,
//				end: 10
			}],
			tooltip: {
				trigger: 'axis',
				//				formatter: function (params, ticket, callback) {
				////					console.log(params)
				//					return `Подписчиков: ${params[0].data[params[0].seriesName]}`
				//				}, 
				//				axisPointer: {
				//					type: 'cross',
				//					label: {
				//						backgroundColor: '#6a7985'
				//					}
				//				}
			},
			grid: {
				top: 80,
//				right: '30%',
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: date,
				name: 'Дата',
				nameLocation: 'center',
				nameGap: 25
			},
			yAxis: {
				type: 'value',
				scale: scale,
				name: 'Серебро',
				nameLocation: 'end',
				nameGap: 25
			},

			series: (function() {
				for (let i =0; i< lines.length; i++) {
					if (lines[i].name === res) {
						
						
						return lines[i];
					}
				}
			})()
		};

		return option
	}

	
	const handleChange = event => {
		
		let localBuy = event.target.value
		
		if (localBuy === 'Покупка') {
			for (let i =0; i<lines.length; i++) {
				for (let yu = 0; yu<lines[i].data.length; yu++) {
					lines[i].data[yu] /= 0.9
					lines[i].data[yu] = lines[i].data[yu].toFixed(4)*1
				}
			}
			
		} else {
			for (let i =0; i<lines.length; i++) {
				for (let yu = 0; yu<lines[i].data.length; yu++) {
					lines[i].data[yu] *= 0.9
					lines[i].data[yu] = lines[i].data[yu].toFixed(4)*1
				}
			}
			
		}
		
		
		setBuy(localBuy);
	  };
	
	return ( 
		
		<Container  maxWidth="xl" disableGutters>
		{!isLoaded && <LinearProgress />}
		<Grid
			container
			direction="row"  
		  	spacing={1}
			>
				<Grid item  xs={12} sm={6} md={6} lg={3}>
					<Card style={{height: "100%"}}>
					 <CardActionArea>
						  <CardContent>
								<Typography variant="h6" component="h6" gutterBottom>
									Цены на основные ресурсы
								</Typography>
								<Typography variant="body2" gutterBottom>
									Динамика цен на основные игровые ресурсы. Сбор данных происходит каждые 6 часов.
								</Typography>
								<Typography variant="overline" display="block" gutterBottom>
									Актуально на {dateLast}
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item  xs={12} sm={6} md={6} lg={3}>
					<Card  style={{height: "100%"}}>
					 <CardActionArea>
						  <CardContent>
								<FormControl component="fieldset">
								  <FormLabel component="legend">Настройки</FormLabel>
								  <FormGroup>
									<FormControlLabel
									  control={<Switch checked={scale} onChange={() => setScale(!scale)} value="scale" color="primary" />}
									  label="Скалирование" 
									/>
								  	<FormLabel component="legend">Отобразить</FormLabel>
										<RadioGroup aria-label="gender" name="gender1" value={buy} onChange={handleChange}>
										  <FormControlLabel value='Покупка' control={<Radio  color="primary" />} label="Покупка" />
										  <FormControlLabel value='Продажа' control={<Radio  color="primary" />} label="Продажа" />
										</RadioGroup>
								  </FormGroup>
								  
								</FormControl>
						 </CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item  xs={12} sm={6} md={6} lg={3}>
					<ResBigPrice type={'Дорогой'} res={infoMax}/>
				</Grid>
				<Grid item  xs={12} sm={6} md={6} lg={3}>
					<ResBigPrice type={'Дешевый'} res={infoMin} />
				</Grid>
				
				
				<Grid item  xs={12} sm={6} md={5} lg={4}>
					<Paper className={classes.paper} style={{width: '100%', height: "100%"}}>
						<Scrollbars style={{ height: 400 }}>
							<List className={classes.root} dense={true}>
								  {list.map((l) =>
									<React.Fragment key={l.price} >
												{/*
													<Tooltip title={l.help}>
													*/
												}
											<ListItem alignItems="flex-start" button onClick={()=>setRes(l.name)} selected={res===l.name}>
												<ListItemAvatar>
												  <Avatar alt={l.name} src={l.urls} />
												</ListItemAvatar>
												<ListItemText
												  primary={l.name}
												  secondary={
													<React.Fragment>
													  <Typography
														component="span"
														variant="body2"
														className={classes.inline}
														color="textPrimary"
													  >
														Цена {buy === 'Покупка' ? 'покупки' : 'продажи'}: 
													  </Typography>
													  {' '} {buy === 'Покупка' ? l.price.toFixed(4) : (l.price*0.9).toFixed(4)} {' сер.'}
													</React.Fragment>
												  }
												/>
										  </ListItem>
													{/*
													</Tooltip>
													*/
												}
									<Divider variant="inset" component="li" />
								</React.Fragment >
							  )}
							</List>
						</Scrollbars>
					</Paper>
				</Grid>

				<Grid item  xs={12} sm={6} md={7} lg={8}>
					<Paper className={classes.paper} style={{width: '100%'}}>
						<ReactEcharts style = {{width: '100%', height: 400}} option = { getOption() }/> 
					</Paper>
				</Grid>
		</Grid>
		</Container>
	);
}

export default Res;
