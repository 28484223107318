import React from 'react';
 
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ButtonBase } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ColoredLinearProgress from './ColoredLinearProgress';

const useStyles = makeStyles(theme => ({
	root: {
		width: 75,
		height: 75,
		display: 'inline-block',
		borderRadius: 40,
		backgroundSize: 'cover',
		position: 'relative',
		margin: 7,
		transform: 'scale(0.85)',
	},
	bars: {
		top: 70,
		left: 9,
		position: 'absolute',
		width: 60
	},
	name: {
		top: -4,
		left: 0,
		position: 'absolute',
		color: 'white',
		background: '#000000a6',
		width: 75,
		textAlign: 'center',
		fontSize: 10,
	}
}));
const MKCell = ({content, callback, tooltip_text}) => {
	
	const classes = useStyles();
	
    return (
	<Tooltip
        title={
          <React.Fragment>
            {tooltip_text}
          </React.Fragment>
        }
      >
		   <ButtonBase onClick={()=>callback(content.id, content.side)} 
			className={classes.root} 
			style={{backgroundImage: content.name !== '' ? `url(${content.img})` : `url()`, boxShadow: content.side === 1 ? '0px 3px 3px 3px rgba(255, 0, 0, 0.68) inset' : '0px -3px 3px 3px rgba(0, 0, 255, 0.68) inset'}}>
				{content.name !== '' && <div className={classes.name}>
					{content.name}
			   </div>}
			   {content.name !== '' && 
			   <div className={classes.bars}>
					<LinearProgress color={'secondary'} variant="determinate" value={100*content.hp_current/content.hp_max} />
					<LinearProgress variant="determinate" value={100*content.mp_current/content.mp_max} />
					<ColoredLinearProgress variant="determinate"  value={100*content.od/(content.params.baseAP > 5 ? content.params.baseAP : 5)} />
				</div>}
		   </ButtonBase>
	</Tooltip>
    );
	
	
}
 
export default MKCell;