import React, {useState} from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import ReactEcharts from 'echarts-for-react';
import ym from 'react-yandex-metrika';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import { makeStyles, useTheme  } from '@material-ui/core/styles';

const publicIp = require('public-ip');

const useStyles = makeStyles({
	question: {
		minWidth: 300,
		maxWidth: 400,
		border: '1px black solid',
		minHeight: 200,
		margin: 'auto',
		textAlign: 'center',
		display: 'table',
		borderRadius: 11,
		overflow: 'hidden',
		boxShadow: '0px 5px 1px 12px #ABA9E8'
	},
	question_text: {
		display: 'table-cell',
		verticalAlign: 'middle',
		background: '#dde6ff2e'
	},
	answers: {
		minWidth: 300,
		maxWidth: 400,
		margin: 'auto',
		textAlign: 'center',
		display: 'table',
		marginTop: 35
	},
	answers_buttons_block: {
		display: 'table-cell',
		verticalAlign: 'middle'
	},
	button: {
		width: '100%',
		margin: 5,
		borderRadius: 10
	},
	card: {
		
	},
	top: {
		height: 238,
		background: 'black',
		textAlign: 'center',
		borderRadius: 5,
	},
	card_image: {
		height: 200
	},
	card_block: {
		border: '1px #00000012 solid',
		borderRadius: 15,
		minHeight: 600,
		padding: 7
	}, card_description: {
		padding: 10
	}
});

const quiz = {
	name: 'Опросник Бартла',
	description: <>
	<div>Тест Психологии Геймера по Бартлу (Bartle Test of Gamer Psychology) представляет серию вопросов и формулу для подсчёта результата, классифицирующего игроков многопользовательских онлайновых игр (включая MUD и MMORPG) по категориям, в зависимости от их предпочтений в стиле игры.
Тест основан на статье 1996 года за авторством Ричарда Бартла и был создан в 1999-2000 гг. Эрвином Андерсеном и Брэндоном Дауни.</div>
	<div style={{marginTop: 15, marginBottom: 10}}>Постарайтесь отвечать на вопросы теста максимально честно - здесь нет правильных или неправильных ответов. Сами вопросы затрагивают только игровую жизнь. Результаты тестирования будут использованы для развития игры и игрового взаимодействия, проходя данное тестирование Вы помогаете проекту. Спасибо!</div>
	
	</>,
	questions_list: [
		{
			q: 'Что для Вас хуже?',
			a_list: {
					achievers: 'Быть как все',
					killers: 'Быть жертвой других игроков',
					explorers: 'Быть пассивным',
					socializes: 'Быть одиноким',
				}
		}, {
			q: 'Что для Вас наиболее интересно?',
			a_list: {
					achievers: 'Сложная битва',
					killers: 'Хаот на 20 человек',
					explorers: 'Поиск прохода через горы в Долину',
					socializes: 'Душевно поболтать в чате',
				}
			
		}, {
			q: 'Вы бы скорее вступили в гильдию?',
			a_list: {
					achievers: 'Воинов',
					killers: 'Убийц',
					explorers: 'Магов',
					socializes: 'Бардов, когда Rewad её сделает',
				}
			
		}, {
			q: 'Вы скорее склонны',
			a_list: {
					achievers: 'Обгонять врагов по силе',
					killers: 'Уничтожать врагов',
					explorers: 'Изучать слабые стороны врага',
					socializes: 'Договориться до мира с врагом',
				}
			
		}, {
			q: 'Вы идете в новую шахту, кого вы возьмете с собой?',
			a_list: {
					achievers: 'Игрока с особой печатью, увеличивающей общий дроп',
					killers: 'Воина, который сможет скинуть с шахты других шахтеров',
					explorers: 'Игрока, знающего короткий путь',
					socializes: 'Своего друга',
				}
			
		}, {
			q: 'Лучше быть на слуху у других игроков за:',
			a_list: {
					achievers: 'Свой уникальный древний шмот',
					killers: 'Свою силу',
					explorers: 'Свои знания',
					socializes: 'За юмор',
				}
			
		}, {
			q: 'Вам нравится, когда Вас?',
			a_list: {
					achievers: 'Уважают',
					killers: 'Боятся',
					explorers: 'Спрашивают',
					socializes: 'Любят',
				}
			
		}, {
			q: 'Вы только что закончили новый квест в Северных землях. Что Вы возьмете в качестве награды?',
			a_list: {
					achievers: '500 000 серебра',
					killers: 'Особое оружие для PvP боёв',
					explorers: 'Информацию о тайном проходе в новое подземелье',
					socializes: 'Уникальную ачивку в профиль',
				}
			
		}, {
			q: 'Что важнее знать?',
			a_list: {
					achievers: 'Где достать редкие вещи',
					killers: 'Как убивать противников',
					explorers: 'Где находятся игровые объекты, квесты, секреты',
					socializes: 'Как писать в общем чате, чтобы ответил Rewad',
				}
			
		}, {
			q: 'Что для Вас интереснее?',
			a_list: {
					achievers: 'Убить Снеговика-генерала',
					killers: 'Вступить на стороне Снеговика-генерала и убить нападающих игроков',
					explorers: 'Посмотреть параметры Снеговика-генерала',
					socializes: 'Обсудить убийство Снеговика-генерала в чате',
				}
			
		}, {
			q: 'Вы бы хотели быть?',
			a_list: {
					achievers: 'Богатеем',
					killers: 'Кошмаром всех Аватаров',
					explorers: 'Странником',
					socializes: 'Популярным',
				}
			
		}, {
			q: 'Что больше всего Вам нравится в квестах?',
			a_list: {
					achievers: 'Получить хорошую награду',
					killers: 'PvP составляющая квеста',
					explorers: 'Побывать в новых локациях',
					socializes: 'Само совместное приключение',
				}
			
		}, {
			q: 'Вы открыли особый ларец с наградами на выбор, что возьмете?',
			a_list: {
					achievers: 'Руну +25% опыта',
					killers: 'Руну +32% урона по другим игрокам',
					explorers: 'Индивидуальную карту-загадку с информацией о зарытом кладе',
					socializes: 'Уникальный образ персонажа',
				}
			
		}, {
			q: 'Вас преследует убийца, Ваши действия?',
			a_list: {
					achievers: 'Если бой ничего не даст мне - проигнорирую его',
					killers: 'Буду сражаться в попытке победить',
					explorers: 'Зайду в место, где запрещены нападения',
					socializes: 'Позовете клан и друзей на помощь',
				}
			
		}, {
			q: 'Вам нагрубили в общем чате:',
			a_list: {
					achievers: 'Отомщу в будущем за неуважение',
					killers: 'Возьму убийцу и нападу на грубияна',
					explorers: 'Сообщу грубияну о его низком уровне интеллекта',
					socializes: 'Постараюсь узнать причину наезда',
				}
			
		}, {
			q: 'Вам больше нравится',
			a_list: {
					achievers: 'Получить вещь из нового сета',
					killers: 'Победить всех в БИ',
					explorers: 'Найти сундук, который никто никогда не находил до Вас',
					socializes: 'Посплетничать, узнать игровые новости',
				}
			
		}, {
			q: 'Rewad решил наградить Вас особым подарком, что Вы возьмете?',
			a_list: {
					achievers: '+200 000 000 опыта персонажу',
					killers: 'Руну на +30% урона по Игрокам',
					explorers: 'Узнаете формулу расчета параметров заклинания трансформация и формулы опыта в игре',
					socializes: 'Уникальный цвет сообщений в чате',
				}
			
		}, {
			q: 'Чаще всего Вы говорите о:',
			a_list: {
					achievers: 'Надетых на Вас предметах',
					killers: 'Ваших победах на турнирах и в ОС',
					explorers: 'Интересных квестах, механиках игры',
					socializes: 'Просто так болтаю обо всём',
				}
			
		}, {
			q: 'Вы больше всего хотите',
			a_list: {
					achievers: 'Набрать максимальный уровень в игре',
					killers: 'Выиграть первый глобальный турнир среди топов на Севере',
					explorers: 'Первым в игре найти скрытое подземелье',
					socializes: 'Это всё не интересно',
				}
			
		}, {
			q: 'Чаще всего Вы',
			a_list: {
					achievers: 'Держите в инвентаре вещи, которые мало у кого есть',
					killers: 'Имеете больше недругов, чем другие игроки',
					explorers: 'Знаете об игровом мире больше, чем многие',
					socializes: 'Не имеете врагов - только друзья и знакомые в игре',
				}
			
		}, {
			q: 'Больше всего Вам нравится',
			a_list: {
					achievers: 'Иметь очень много серебра',
					killers: 'Руководить боевым отрядом игроков на турнире',
					explorers: 'Рисовать карту нового подземелья',
					socializes: 'Рассказывать клану о своих приключениях в жизни',
				}
			
		}, {
			q: 'Что вам хочется?',
			a_list: {
					achievers: 'Получить уникальный редкий доспех',
					killers: 'Победить очередного игрока в дуэли',
					explorers: 'Обнаружить нового монстра',
					socializes: 'Встретить интересного собеседника',
				}
			
		}, {
			q: 'Что для Вас важно при выборе Игрового мира?',
			a_list: {
					achievers: 'Сложность боев и возможность накопления всякого',
					killers: 'Интересные PvP бои и рейтинговые PvP таблицы',
					explorers: 'Количество скрытых механик, формул, билдов',
					socializes: 'Количество игроков',
				}
			
		}, {
			q: 'Вы хотите:',
			a_list: {
					achievers: 'Иметь самый мощный замод на Вашем оружии',
					killers: 'Чтобы Вас все боялись',
					explorers: 'Знать короткую дорогу до Северных земель',
					socializes: 'Создать и возглавить новый клан с друзьями',
				}
			
		}, {
			q: 'Вы не против?',
			a_list: {
					achievers: 'Иметь сет лучше, чем у Ваших друзей',
					killers: 'Быть более известным, чем Ваши друзья',
					explorers: 'Знать больше, чем Ваши друзья',
					socializes: 'Против всего этого',
				}
			
		}, {
			q: 'В Аватары зашел новичок. Он для Вас:',
			a_list: {
					achievers: 'Нубас, ноунейм',
					killers: 'Мишень для битья',
					explorers: 'Он скучен и неинтересен',
					socializes: 'Слушатель, который нуждается в Вашем совете по раскачке персонажа',
				}
			
		}, {
			q: 'Лучшая награда это',
			a_list: {
					achievers: 'Дополнительный опыт на неделю',
					killers: 'Несколько свитков нападения, действующих на 100 клеток',
					explorers: 'Полная карта новой шахты',
					socializes: 'Анимированные смайлики',
				}
			
		}, {
			q: 'Лучше быть известным как:',
			a_list: {
					achievers: 'Аватар с самым мощным сетом',
					killers: 'Аватар, которого практически не убить в PvP',
					explorers: 'Гуру игрового мира, знающий всё о механиках, квестах и т.д.',
					socializes: 'Лидер мирной гильдии, которому сам Rewad отправляет смайлы в общий чат',
				}
			
		}, {
			q: 'Вы спустились на последний этаж нового подземелья и на вас уже 10 шагов никто не напал. Чат не работает, не видно, есть ли тут другие игроки',
			a_list: {
					achievers: 'Отлично, быстрее найду здесь награду',
					killers: 'Буду ждать нападения от других игроков',
					explorers: 'Буду спокойно всё исследовать',
					socializes: 'Здесь не работает чат - нечего тут делать',
				}
			
		}, {
			q: 'Rewad выпустил обновление, в котором Вы должны охотиться на Дракона. Это огромный, на 4 клетки бот, очень сильный',
			a_list: {
					achievers: 'Подожду, пока его победят и посмотрю тактику его убийства',
					killers: 'Буду пинать тех, кто бегает за драконом',
					explorers: 'С помощью приказов атаки и защиты попробую определить его параметры',
					socializes: 'Соберу толпу людей и пойдем в 20 человек его бить',
				}
			
		}, {
			q: 'На форуме запланирована новогодняя Викторина - будете учавствовать?',
			a_list: {
					achievers: 'Если награда достойная - да',
					killers: 'Постараюсь набрать больше баллов, чем остальные игроки',
					explorers: 'Да, хорошая возможность проверить свои игровые знания',
					socializes: 'Конечно, обожаю эту викторину',
				}
			
		}, {
			q: 'Чего на Ваш вгляд больше всего не хватает в игре?',
			a_list: {
					achievers: 'Достойной награды за квесты и рутинные действия. Хочу весомую награду',
					killers: 'Баланса в PvP. Хочу интересные войны против игроков, PvP шмот',
					explorers: 'Новых интересных квестов и внутриигрового сюжета. Хочу понять, что тут происходит',
					socializes: 'Новых смайлов, уникальных образов, индивидуальных ачивок. Хочу выделяться',
				}
			
		}, {
			q: 'Кто-то на форуме снова начал ругать игру, Ваши действия?',
			a_list: {
					achievers: 'Мне нет до этого дела',
					killers: 'Форум мертв',
					explorers: 'Постараюсь защитить игру',
					socializes: 'Хорошая возможность пофлудить',
				}
			
		}, {
			q: 'В награду за преданность проекту Вы получили сет артефактов, как используете?',
			a_list: {
					achievers: 'Спущусь глубже в шахту',
					killers: 'Уничтожу всех врагов',
					explorers: 'Пойду проходить контент, который раньше не мог',
					socializes: 'Помогу своим друзьям с квестами',
				}
			
		}
	]
};


const Quiz = () => {
	
	const classes = useStyles();
	const theme = useTheme();
	
	const [question_num, set_question_num] = useState(0)
	
	// 0 - не начат
	// 1 - в процессе
	// 2 - завершен
	const [status, set_status] = useState(0)
	
	const [start_time, set_start_time] = useState(0)
	const [end_time, set_end_time] = useState(0)
	
	const [user_answers, set_user_answers] = useState([])
	
	
	const shuffle = (array) => {
	  var currentIndex = array.length, temporaryValue, randomIndex;

	  while (0 !== currentIndex) {

		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	  }

	  return array;
	}
	
	
	const get_count_of_psy = () => {
		var out = {
			achievers: 0,
			killers: 0,
			socializes: 0,
			explorers: 0
		}
		for (let i in user_answers) {
			out[user_answers[i]] += 1
		}
		return out
	}
	
	const draw_graph = () => {
		
		var options = {
			title: {
				text: 'Результаты тестирования'
			},
			tooltip: {},
//			legend: {
//				data: ['Результат']
//			},
			radar: {
				shape: 'circle',
				splitArea: {
					show: false
				},
				name: {
					textStyle: {
						color: '#fff',
						backgroundColor: '#999',
						borderRadius: 3,
						padding: [3, 5]
					}
				},
				indicator: [
					{ name: 'Накопитель（Achievers）', max: Math.max(...Object.values(get_count_of_psy()))},
					{ name: 'Киллер（Killers）', max: Math.max(...Object.values(get_count_of_psy()))},
					{ name: 'Социальщик（Socializes）', max: Math.max(...Object.values(get_count_of_psy()))},
					{ name: 'Исследователь（Explorers）', max: Math.max(...Object.values(get_count_of_psy()))}
				]
			},
			series: [{
				name: 'Результат',
				type: 'radar',
				itemStyle: {
					color: '#755cff'
				},
				areaStyle: {
					opacity: 0.1
				},
				data: [
					{
						value: Object.values(get_count_of_psy()),
						name: 'Ваши результаты'
					}
				]
			}]
		};
		
		return options
	}
	
	const getAnswer = (a) => {
		console.log(a)
		if (user_answers[question_num] === undefined) {
			console.log('Создаем')
			set_user_answers([...user_answers, a])
		} else {
			console.log('Заменяем')
			user_answers[question_num] = a
			set_user_answers([...user_answers])
		}
		
		if ((question_num+1) < quiz.questions_list.length) {
			console.log('Следующий вопрос')
			set_question_num(question_num+1)
		} else {
			console.log('Всёёё')
			set_status(2)
		}
		
	}
	
	const handleNext = () => {
	set_question_num((question_num) => question_num + 1);
  };

  const handleBack = () => {
	set_question_num((question_num) => question_num - 1);
  };
	
	
	const [names, set_names] = useState('');
	const [names_array, set_names_array] = useState([]);
	const handleChange = (event) => {
		set_names(event.target.value);
		set_names_array([...event.target.value.trim().split(' ')]);
		
	};
	
	const [hash, set_hash] = useState('');
	const [login, set_login] = useState('');
	const [ip, set_ip] = useState(0);
	const [hash_error, set_hash_error] = useState(1);
	
	const set_status_handle = (s) => {
		
		if (s === 1) {
			let t = new Date().getTime();
			set_start_time(t);
			ym('reachGoal','start_test');
			// Сохраняем ip
			
			(async () => {
				set_ip(await publicIp.v4());
			})();
		} else {
			let t = new Date().getTime();
			set_end_time(t)
			
			var x = Object.values(get_count_of_psy())
			
			
			// Готовим json 
			var out = {
				duration: Math.round((t - start_time)/1000),
				ip: ip,
				hash: hash,
				login: login,
				additional_chars: names,
				user_answers: user_answers.join(', '),
				achievers: x[0],
				killers: x[1],
				socializes: x[2],
				explorers: x[3]
				
			}
			ym('reachGoal','get_test_results')
			console.log(out)
			
			fetch("https://psychea.space/avatars/api/quize/", {
			  "method": "POST",
			  "headers": {
				"Content-Type": "application/json"
			  },
			  "body": JSON.stringify(out)
			})
			.then(response => {
			  console.log(response);
			})
			.catch(err => {
			  console.error(err);
			});
		}
		set_status(s)
		
	}
	
	const handleChangeHash = (event) => {
		
		var h = event.target.value
		fetch(`https://psychea.space/avatars/api/auth/${h}`)
			.then(d=>d.json())
			.then(d=> {
			
				if (d.status*1 !== 0) {
					set_hash_error(0)
					set_login(d.login)
				} else {
//					console.log(d)
//					console.log(hash)
					set_hash_error(1)
				}
			
			})
		set_hash(h);
		
	};
    return (
		<Container maxWidth="md">
			{status === 0 &&
				<>
					<Typography variant="h6">
						{quiz.name}
					</Typography>
					<Typography variant="subtitle2">
						{quiz.description}
					</Typography>
					<TextField
					  id="outlined-full-width-0"
					  label="Hash из комады !get"
					  style={{ margin: 12 }}
					  size="small" 
					  placeholder="Введите hash вашего основого персонажа. Hash можно получить по команде !get у чат бота"
					  fullWidth
					  InputLabelProps={{
						shrink: true,
					  }}
					  value={hash}
					  onChange={handleChangeHash}
					  variant="outlined"
					  error={((hash_error === 1) && (hash !== ''))}
					  helperText={((hash_error === 1) && (hash !== '')) ? 'Введен неправильный hash' : ''}
					/>
					<Button  variant="contained" color="primary" onClick={()=>set_status_handle(1)} disabled={hash_error === 1}>
						Начать тестирование
					</Button>
				</>
			}
			{status === 1 &&
				<>
					<div className={classes.question}>
						<Typography variant="h6" style={{position: 'absolute', transform: 'translate(-50%, 10px)', left: '50%'}}>
							{question_num+1} из {quiz.questions_list.length}
						</Typography>
						<div className={classes.question_text}>
							{quiz.questions_list[question_num].q}
						</div>
					</div>
					<div className={classes.answers}>
						<div className={classes.answers_buttons_block}>
							{shuffle(Object.entries(quiz.questions_list[question_num].a_list)).map(answer => {
								return (
									<Button key={answer[1]} variant={(user_answers[question_num] === answer[0]) ? "contained" : "outlined"} color="primary" className={classes.button} onClick={()=>getAnswer(answer[0])}>
										{answer[1]}
									</Button>
								)
							})}

						</div>
					</div>
					<MobileStepper
						  variant="progress"
						  steps={quiz.questions_list.length}
						  position="static"
						  activeStep={question_num}
						  className={classes.paginator}
						  nextButton={
							<Button size="small" onClick={handleNext} disabled={question_num === (quiz.questions_list.length-1)}>
							  След.
							  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
							</Button>
						  }
						  backButton={
							<Button size="small" onClick={handleBack} disabled={question_num === 0}>
							  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
							  Назад
							</Button>
						  }
						/>
			</>}
		 	{status === 2 && <div>
		 		<div style={{marginTop: 15, marginBottom: 10}}>Если у вас больше 1 активного персонажа в игре, пожалуйста, укажите их точные ники в поле ниже через пробел. Активными являются персонажи, которые в последние несколько месяцев участвовали в игровом процессе - писали сообщения в чат, фармили шахту, ругались на форуме и т.д. Достаточно 2-3 Ваших самых активных мультов, всех перечислять не обязательно.</div>
		 		<TextField
				  id="outlined-full-width"
				  label="Ники Ваших персонажей"
				  style={{ margin: 12 }}
				  size="small" 
				  placeholder="Ники персонажей через пробел"
				  fullWidth
				  InputLabelProps={{
					shrink: true,
				  }}
				  value={names}
				  onChange={handleChange}
				  variant="outlined"
				/>
				{names_array.map(name => {
					if (name !== '') {
						return <Chip key={name} label={name} color="primary" />
					}
					return <React.Fragment key={Math.random()}></React.Fragment>
				})}
				<div></div>
				<Button variant="contained" color="primary" onClick={()=>set_status_handle(3)} style={{marginTop: 25}}>
					Получить результаты!
				</Button>
		 	</div>}
		 	{status === 3 && <div>
		 		<div>Большое спасибо за предоставленную информацию! Она пойдет на благое для проекта дело.</div>
		 		<div style={{marginBottom: 25}}>Большая часть описаний психотипов взята из <a target="_blank" href='https://habr.com/ru/company/mailru/blog/263839/'>данной статьи</a></div>
				<ReactEcharts style = {{width: '100%', maxHeight: 400, minHeight: 200}} option = { draw_graph() }/> 
				<Grid
				  container
				  direction="row"
				  justify="space-between"
				  alignItems="center"
				  spacing={5}
				>
					<Grid md={6} sm={6} xs={12} item className={classes.card}>
						<div className={classes.card_block}>
							<div className={classes.top} style={{background: 'rgb(166 195 54)'}}>
								<Typography variant="h6">
									Киллер: {Math.round(100*get_count_of_psy()['killers']/quiz.questions_list.length)}%
								</Typography>
								<img className={classes.card_image} src={require('../images/killers.png')} alt='Киллеры'></img>
							</div>
							<div className={classes.card_description}>
								<Typography variant="body2" gutterBottom display="block" style={{textAlign: 'center'}}>
									Превосходство над другими игроками, доминирование, властвование. Они жаждут только победы
								</Typography>
								<Typography variant="caption">
									Киллеры гордятся доминированием над другими. Любят соревнования, турниры и рейтинги. Это позволяет им доказывать, что они круче других, что они кого-то превосходят. Поэтому им нравится всё, что связано с непосредственным взаимодействием с другими игроками. В ММО-играх Киллеры обожают PvP, в более мирных «фермах» они без ума от рейтингов соседей и сравнения с друзьями. 
								</Typography>
								<Typography variant="caption" display="block" style={{marginTop: 25}}>
									Киллеров раздражают энциклопедии, гайды, обучалки, социальное взаимодействие, рутина. По натуре одиночки.
								</Typography>
							</div>
						</div>
					</Grid>
					<Grid md={6} sm={6} xs={12} item className={classes.card}>
						<div className={classes.card_block}>
							<div className={classes.top} style={{background: 'rgb(130 156 215)'}}>
								<Typography variant="h6">
									Накопитель: {Math.round(100*get_count_of_psy()['achievers']/quiz.questions_list.length)}%
								</Typography>
								<img className={classes.card_image} src={require('../images/achiewer.png')} alt='Киллеры'></img>
							</div>
							<div className={classes.card_description}>
								<Typography variant="body2" gutterBottom display="block" style={{textAlign: 'center'}}>
									Накопление мощи, денег, крутых артефактов — любых игровых благ и ресурсов.
								</Typography>
								<Typography variant="caption">
									Карьеристы любят получать всевозможные игровые блага. Они добывают много ресурсов, производят массу вещей. Но наибольшее удовлетворение получают от различных наград, медалей, нашивок за всевозможные достижения. Достижение поставленных целей, решение все более сложных и сложных задач, которые приводят к долгожданной награде — вот что приносит им удовольствие. То есть Карьеристов стимулирует внутриигровой рост, прогресс.
								</Typography>
								<Typography variant="caption" display="block" style={{marginTop: 25}}>
									Не любят докупать за реальные деньги что-то достижимое внутриигровыми средствами. Они воспринимают это как элемент нечестной игры и бесполезную трату ресурсов
								</Typography>
							</div>
						</div>
					</Grid>
					<Grid md={6} sm={6} xs={12} item className={classes.card}>
						<div className={classes.card_block}>
							<div className={classes.top} style={{background: 'rgb(180 72 142)'}}>
								<Typography variant="h6">
									Исследователь: {Math.round(100*get_count_of_psy()['explorers']/quiz.questions_list.length)}%
								</Typography>
								<img className={classes.card_image} src={require('../images/discovery.png')} alt='Киллеры'></img>
							</div>
							<div className={classes.card_description}>
								<Typography variant="body2" gutterBottom display="block" style={{textAlign: 'center'}}>
									Интересно изучать игровой мир и раскрывать его тайны. Они не гонятся за активными действиями и сражениями
								</Typography>
								<Typography variant="caption">
									Исследователи гордятся тем, что умнее других. Класс ориентирован на знание, на поглощение игрового контента, на изучение игровой механики и возможностей игры. Чем больше игровой мир, тем лучше. Для Исследователей важно развивать уникальные наборы талантов, чтобы иметь возможность изучать все допустимые сочетания, скрытые аспекты, нюансы и т.д. Они также любят квесты, диалоги, сюжет, который подогревает их интерес.
								</Typography>
								<Typography variant="caption" display="block" style={{marginTop: 25}}>
									Не любят клановые бои, марафонные активности. С точки зрения Социальщика, исследователь — это такой нелюдимый, угрюмый человек, который презирает общество, социофоб. Исследователи не любят рейтинги. Любопытная деталь: многие представители этого класса считают Киллеров недоразвитыми
								</Typography>
							</div>
						</div>
					</Grid>
					<Grid md={6} sm={6} xs={12} item className={classes.card}>
						<div className={classes.card_block}>
							<div className={classes.top} style={{background: '#70cd70'}}>
								<Typography variant="h6">
									Социальщик: {Math.round(100*get_count_of_psy()['socializes']/quiz.questions_list.length)}%
								</Typography>
								<img className={classes.card_image} src={require('../images/social.png')} alt='Киллеры'></img>
							</div>
							<div className={classes.card_description}>
								<Typography variant="body2" gutterBottom display="block" style={{textAlign: 'center'}}>
									Общение с другими игроками, социальное взаимодействие и взаимопонимание.
								</Typography>
								<Typography variant="caption">
									Социальщики гордятся тем, что популярнее других. Они любят общение, отношения, популярность. Они любят выделиться. Социальщикам обязательно нужны конкурсы, чаты, сходки, ивенты, форумы. Всё, что касается массового взаимодействия. Получают удовольствие от общения с другими людьми, они собираются в группы и кланы, активно обмениваются информацией и опытом. Все проблемы стремятся решить сообща.
								</Typography>
								<Typography variant="caption" display="block" style={{marginTop: 25}}>
									Как и Карьеристы, Социальщики не любят платить, ускорять что-то за деньги.
								</Typography>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>}
		</Container>
    );
}
 
export default Quiz;