// Крафт вещей и зелий
import React, {
	useEffect,
	useState
} from 'react';

import ReactEcharts from 'echarts-for-react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
//import Tooltip from '@material-ui/core/Tooltip';
import { Scrollbars } from 'react-custom-scrollbars';
//import Skeleton from '@material-ui/lab/Skeleton';

import LinearProgress from '@material-ui/core/LinearProgress';

import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';

import ResBigPrice from './additional/ResBigPrice.js'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';




import {craftItems, splitNumber} from '../functions/Helpers.js'



//import {sampleStandardDeviation, interquartileRange, linearRegression } from 'simple-statistics'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



const images_type = {
	'Альматея': 'https://chaosage.ru/images/almateaArmor.gif',
	'Белиар': 'https://chaosage.ru/images/beliarArmor.gif',
	'Воин крови': 'https://chaosage.ru/images/bloodWarriorArmor.gif',
	'Магистр': 'https://chaosage.ru/images/grandmasterRobe.gif',
	'Комплекты Солнца': 'https://chaosage.ru/images/guardOfSunArmor.png',
	'Обычные предметы': 'https://chaosage.ru/images/gracefulElfCloak.gif',
	'Рецепты': 'https://chaosage.ru/images/potionOfMana.gif'
}
//"Обсидиан", "Грубая шкура", "Бревно", "Кварц", "Корунд", "Алмаз", "Железная руда", "Медная руда", "Кость скелета", "Ноготь зомби", "Клык вампира", "Ромашка полевая", "Древесный гриб", "Кувшинка прыгучая", "Паучий хитин", "Жало скорпиона", "Перо грифона", "Рог минотавра", "Прах призрака"

const urlImg = ['https://chaosage.ru/images/obsidian.gif', 
				'https://chaosage.ru/images/skin1.gif', 
				'https://chaosage.ru/images/wood.gif', 
				'https://chaosage.ru/images/quartz.gif', 
				'https://chaosage.ru/images/corundum.gif', 
				'https://chaosage.ru/images/diamond.gif', 
							  'https://chaosage.ru/images/ironOre.gif', 'https://chaosage.ru/images/copperOre.gif', 
							  'https://chaosage.ru/images/skeletBone.gif', 'https://chaosage.ru/images/zombiNail.gif', 
							  'https://chaosage.ru/images/vampireFang.gif', 'https://chaosage.ru/images/meadowCamomile.gif',
							  'https://chaosage.ru/images/woodenMushroom.gif', 'https://chaosage.ru/images/waterLily.gif', 
							  'https://chaosage.ru/images/spiderChitin.gif', 'https://chaosage.ru/images/scorpionSting.gif', 
							  'https://chaosage.ru/images/grifonFeather.gif', 'https://chaosage.ru/images/minotaurHorn.gif', 
							  'https://chaosage.ru/images/ghostAshe.gif']

var resArr = [
					'Обсидиан', 
					'Грубая шкура',
					'Бревно', 
					'Кварц',
					'Корунд', 
					'Алмаз',
					'Железная руда', 
					'Медная руда',
					'Кость скелета', 
					'Ноготь зомби',
					'Клык вампира',
					'Ромашка полевая',
					'Древесный гриб',
					'Кувшинка прыгучая',
					'Паучий хитин',
					'Жало скорпиона',
					'Перо грифона',
					'Рог минотавра',
					'Прах призрака'
				]


const Craft = () => {
	const classes = useStyles();

	const [isLoaded, setIsLoaded] = useState(false)
	const [dateLast, setDateLast] = useState('23.02.2020')
	const [date, setDate] = useState([])
	const [data, setData] = useState({})
	
	const [itemsPrices, setItemsPrices] = useState({})
	
	const [item_list_prices, set_item_list_prices] = useState([0])
	const [item_selected, set_item_selected] = useState('Выберите предмет')
	const [item_selected_obj, set_item_selected_obj] = useState([])
	
	
	const [type, setType] = useState('Альматея')
	
	const [open, setOpen] = useState(false);
	
	const [results_text, set_results_text] = useState('');
	
	const handleClose = (event) => {
		setOpen(false);
	  };
	
	const itemsDescription = {
		'Пояс': 'dss'
	}
	const copyToClipboard = (event) => {
		event.target.focus();
		event.target.select();
	   	document.execCommand('copy');
    	setOpen(true)
	  };
	
//	console.log(craftItems)
	
	useEffect(() => {

		fetch('https://chaosage.app/resources/apiGetPricesOnRes.php?set=all')
			.then(response => response.json())
			.then(result => {

				let d = JSON.parse(result.out)

				var dateLocal = []
				var dataLocal = {}
				
				
				
				// Важен порядок ключей
				

				// Заполняем данные
				for (let k =0; k<resArr.length; k++) {
					dataLocal[resArr[k]] = []
				}
				let resForDB = ['obsid', 'shkura', 'brevno',
					'kvarz', 'korund', 'diamond',
					'zruda', 'mruda', 'sceleton',
					'zombi', 'vampire', 'romashka',
					'grib', 'kuvshinka', 'hitin',
					'scorpion', 'grif', 'minos', 'ghost']
				
				
				
				
				for (let i = 0; i < d.length; i++) {
					
					dateLocal.push(d[i].date)
					
					
					// Добавление цены каждого ресурса
					for (let k = 0; k < resArr.length; k++) {
						dataLocal[resArr[k]].push((1*d[i][resForDB[k]]).toFixed(6)*1)
					}
					
					
					
					
				}
				
			
			
				
			
				// Даты
				setDate(dateLocal)
				
//				
//				console.log(dataLocal)
//				console.log(craftItems)
//				
//				let localPricesByItem = {
//					
//				}
//				
//				var priceSum = 0
//				// перебираем вещи
//				for (let i = 1; i<craftItems.length; i++) {
//					
//					
//					let name = craftItems[i][0]
//					if (localPricesByItem[name] === undefined) {
//						// создаем объект
//						localPricesByItem[name] = {
//							price: []
//						}
//					}
//					
//					
//					// перебираем все даты с ресурсами для отслеживания динамики
//					// Это массив всех цен
//					let localPriceArray = dataLocal['Обсидиан']
//					
//					for (let t = 0; t<localPriceArray.length; t++) {
//						
//						priceSum = 0
//						
//						// переираем состав чертежа
//						for (let j = 1; j<20; j++) {
//
//							let localPrice = dataLocal[craftItems[0][j]]
//							
//							priceSum+=craftItems[i] * localPrice[t]
//
//						}
//						localPricesByItem[name].price.push(priceSum)
//					}
//					
//				}
//				
//			console.log(localPricesByItem)
			
				setData({...dataLocal})
				
				setDateLast(dateLocal[dateLocal.length-1])

				
				setIsLoaded(true)
				return d

			}).then(res => {
//								console.log(res[0])
			})
			.catch(e => {
				setIsLoaded(true)
				//				console.log(e);
			});

	}, []);
	
	// Рисуем динамику цены на данный чертеж
	const draw_graph = (item) => {
		
		var p = 0
		var prices_list = []
		// Сначала перебираем все цены на ресурсы
		for (var i_ in data[craftItems[0][1]]) {
			// Перебираем все составы предмета
			for (let i = 1; i<20; i++) {

				let j = data[craftItems[0][i]]

				if (j !== undefined) {
					p += item[i] * j[i_]

				}
			}
			p = Math.round(p*100)/100
			prices_list.push(p)
			p = 0
		}
		
//		create_text_for_clipboard()
//		console.log(prices_list)
		set_item_list_prices(prices_list)
		set_item_selected(item[0])
		set_item_selected_obj(item)
		
		var text_for_clip_bar = []
		
		
		
		for (let i = 1; i<20; i++) {
			if (item[i] > 0) {
				text_for_clip_bar.push(resArr[i-1] + ': ' + item[i])
			}
			
		}

		text_for_clip_bar = text_for_clip_bar.join(', ')
	
		set_results_text(text_for_clip_bar)
	}
	
	
	// Вернем среднюю цену для данного набора сетов
	const get_price_form_list = () => {
//		console.log(data)

		//		craftItems.filter(d=>(d[23]===type)

		var count = 0
		var sum = 0
		// Перебираем все предметы
		for (let i in craftItems) {
			
			// Юерем только те, которые нужно считать
			if (craftItems[i][23] === type) {
				count += 1
				// перебираем их состав
				for (let i_ = 1; i_<20; i_++) {
					// Массив цен на конкретный ресурс
					let j = data[craftItems[0][i_]]

					if (j !== undefined) {
						sum += craftItems[i][i_] * j[j.length -1]

					}
				}
			}
		}
		
		return Math.round(sum/count*100)/100
	}
	const getPriceItem = (item) => {
		let p = 0
		
		
		// Перебираем все составы предмета
		for (let i = 1; i<20; i++) {
			
			let j = data[craftItems[0][i]]
			
			if (j !== undefined) {
				p += item[i] * j[j.length -1]

			}
		}
		
		return splitNumber(Math.round(p*100)/100)
	}
	
	const getOption = () => {
		const option = {
			title: {
				text: `Динамика стоимости`,
				top: 0
			},
			legend: {
				show: true,
				right: 0,
				top: 20,
				selectedMode: 'single',
			},
			tooltip: {
				trigger: 'axis',
			},
			grid: {
				top: 80,
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: date,
				name: 'Дата',
				nameLocation: 'center',
				nameGap: 25
			},
			yAxis: {
				type: 'value',
				scale: true,
				name: 'Серебро',
				nameLocation: 'end',
				nameGap: 25
			},
			dataZoom: [{
				type: 'inside',
//				start: 0,
//				end: 10
			}, {
//				start: 0,
//				end: 10
			}],
			series: [{
				type: 'line',
				name: item_selected,
				smooth: true,
				showSymbol: false,
				data: item_list_prices,
				markPoint: {
					data: [
						{
							type: 'max',
							name: 'Максимум'
						},
						{
							type: 'min',
							name: 'Минимум'
						}
							]
				},
				markLine: {
					data: [
						{
							type: 'average',
							name: 'Средняя'
						}
							]
				}
					}]
		};

		return option
	}
	
	
	return ( 
		
		
		<Container  maxWidth="xl" disableGutters>
		{!isLoaded && <LinearProgress />}
		<Grid
			container
			direction="row"  
		  	spacing={1}
			alignItems="stretch"
			>
				<Grid item  xs={12} sm={12} md={12} lg={12}>
					<Card style={{height: "100%"}}>
					  <CardContent>
							<Typography variant="h6" component="h6" gutterBottom>
								Информация по крафту предметов и зелий
							</Typography>
							<Typography variant="body2" gutterBottom>
								Динамика стоимости крафта, состав чертежей, рекомендации по получению чертежей
							</Typography>
							<Typography variant="overline" display="block" gutterBottom>
								Актуально на {dateLast}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item  xs={12} sm={6} md={5} lg={4}>
					<Paper className={classes.paper} style={{width: '100%', height: '100%'}}>
						<FormControl className={classes.formControl}>
								<InputLabel id="demo-simple-select-helper-label">Комплекты</InputLabel>
								<Select
								  labelId="demo-simple-select-helper-label"
								  id="demo-simple-select-helper"
								  value={type}
								  onChange={(e, value) => setType(value.props.value)}
								>
								  <MenuItem value={'Альматея'}>Альматея</MenuItem>
								  <MenuItem value={'Белиар'}>Белиар</MenuItem>
								  <MenuItem value={'Воин крови'}>Воин Крови</MenuItem>
								  <MenuItem value={'Магистр'}>Магистр</MenuItem>
								  <MenuItem value={'Комплекты Солнца'}>Комплекты Солнца</MenuItem>
								  <MenuItem value={'Обычные предметы'}>Обычные предметы</MenuItem>
								  <MenuItem value={'Рецепты'}>Рецепты</MenuItem>
								</Select>
							  </FormControl>
							  <List className={classes.root} dense={true}>
									<ListItem alignItems="flex-start"  >
										<ListItemAvatar>
										  <Avatar alt={type} src={images_type[type]} />
										</ListItemAvatar>
										<ListItemText
										  primary={type}
										  secondary={
											<React.Fragment>
											  <Typography
												component="span"
												variant="body2"
												className={classes.inline}
												color="textPrimary"
											  >
												Cредняя стоимость:  
											  </Typography>
												{' '} {splitNumber(get_price_form_list())}
											</React.Fragment>
										  }
										/>
								  </ListItem>

							</List>
						<Divider/>
						<Scrollbars style={{ height: 400 }}>
							<List className={classes.root} dense={true}>
								  {craftItems.filter(d=>(d[23]===type)).map((l) =>
									<React.Fragment key={l[0]} >
												{/*
													<Tooltip title={l.help}>
													*/
												}
											<ListItem alignItems="flex-start" button onClick={()=>draw_graph(l)}>
												<ListItemAvatar>
												  <Avatar alt={l[0]} src={l[24]} />
												</ListItemAvatar>
												<ListItemText
												  primary={l[0]}
												  secondary={
													<React.Fragment>
													  <Typography
														component="span"
														variant="body2"
														className={classes.inline}
														color="textPrimary"
													  >
														Цена крафта: 
													  </Typography>
													  {' '} {getPriceItem(l)} {' сер.'}
													</React.Fragment>
												  }
												/>
										  </ListItem>
													{/*
													</Tooltip>
													*/
												}
									<Divider variant="inset" component="li" />
								</React.Fragment >
							  )}
							</List>
						</Scrollbars>
					</Paper>
				</Grid>
				<Grid item  xs={12} sm={6} md={7} lg={8}>
				{item_selected !== 'Выберите предмет' && <Paper className={classes.paper} style={{width: '100%', textAlign: 'left', marginBottom: 5, color: 'black', padding: 15}}>
						<Typography variant="h6" component="h6" gutterBottom>
							{`Информация по ${item_selected}`}
						</Typography>
						<Typography variant="body2">
							Стоимость чертежа: {item_selected_obj[20] === 0 ? '-' : (item_selected_obj[20] + ' сер.')}
						</Typography>
						<Typography variant="body2">
							Где взять: {item_selected_obj[25]}
						</Typography>
						<Typography variant="body2" gutterBottom>
							Требуются ресурсы: {item_selected_obj.map((item, i) => {
								
								
									if (i >= 1) {
										if (i < 20) {
											if (item > 0) {
												return <React.Fragment key={urlImg[i-1]}>
													<img src={urlImg[i-1]} alt={resArr[i-1]} height={20}></img> ({item}), 
												</React.Fragment>
											}
										}
									}
								
								return <React.Fragment key={'im'+i}></React.Fragment>
							})}
						</Typography>
						{results_text !== '' && <TextField onClick={copyToClipboard} style={{marginTop: 10}}
							InputProps={{
								readOnly: true,
							  }} 
							size={'small'} 
							id={"outlined-basic-"+1} 
							label="Скопировать" variant="outlined" value={results_text}/>}
					</Paper>}
					
					<Paper className={classes.paper} style={{width: '100%'}}>
						<ReactEcharts style = {{width: '100%', height: 400}} option = { getOption() }/> 
					</Paper>
				</Grid>
		</Grid>
		<Snackbar
			open={open}
			onClose={handleClose}
			autoHideDuration={700}
			message="Успешно скопировано"
		  />
		</Container>
	);
}

export default Craft;
