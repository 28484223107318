import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import AdjustIcon from '@material-ui/icons/Adjust';
import HelpIcon from '@material-ui/icons/Help';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Link from '@material-ui/core/Link';


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  input_item_id: {
    marginBottom: 2
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
//    color: theme.palette.text.secondary,
  },
	avatars: {
		width: 30,
	}
}));


const items_type_mods_settings = [
	{
		mod_name: 'Интеллект',
		koeff: 1,
		type: 'Магическая'
	},{
		mod_name: 'Воля',
		koeff: 1.5,
		type: 'Магическая'
	},{
		mod_name: 'Броня',
		koeff: 5,
		type: 'Магическая'
	},{
		mod_name: 'Сопротивление',
		koeff: 6,
		type: 'Магическая'
	},{
		mod_name: 'Реген. маны',
		koeff: 5,
		type: 'Магическая'
	},{
		mod_name: 'Устойчивость',
		koeff: 3,
		type: 'Магическая'
	},{
		mod_name: 'ОД на действие',
		koeff: -0.08,
		type: 'Магическая'
	},{
		mod_name: 'Здоровье',
		koeff: 17,
		type: 'Магическая'
	},{
		mod_name: 'Мана',
		koeff: 17,
		type: 'Магическая'
	},{
		mod_name: 'Сила',
		koeff: 1,
		type: 'Воинская'
	},{
		mod_name: 'Телосложение',
		koeff: 1,
		type: 'Воинская'
	},{
		mod_name: 'Ловкость',
		koeff: 1,
		type: 'Воинская'
	},{
		mod_name: 'Выносливость',
		koeff: 1.5,
		type: 'Воинская'
	},{
		mod_name: 'Атака',
		koeff: 9,
		type: 'Воинская'
	},{
		mod_name: 'Защита',
		koeff: 9,
		type: 'Воинская'
	},{
		mod_name: 'Броня',
		koeff: 5,
		type: 'Воинская'
	},{
		mod_name: 'Крит. шанс',
		koeff: 3,
		type: 'Воинская'
	},{
		mod_name: 'Сопротивление',
		koeff: 6,
		type: 'Воинская'
	},{
		mod_name: 'Парирование',
		koeff: 1.5,
		type: 'Воинская'
	},{
		mod_name: 'Реакция',
		koeff: 2,
		type: 'Воинская'
	},{
		mod_name: 'Устойчивость',
		koeff: 3,
		type: 'Воинская'
	},{
		mod_name: 'Пробой брони',
		koeff: 5,
		type: 'Воинская'
	},{
		mod_name: 'Реген. здоровья',
		koeff: 8,
		type: 'Воинская'
	},{
		mod_name: 'ОД на действие',
		koeff: -0.08,
		type: 'Воинская'
	},{
		mod_name: 'Здоровье',
		koeff: 17,
		type: 'Воинская'
	}
]


const Fotaf = () => {
	
	const classes = useStyles();
	
	const [item_selected_id, set_item_selected_id] = useState('')
	const [type_item, set_type_item] = useState('Магическая')
	
	const [items_list, set_items_list] = useState([
		{id: 1084784, mfp: 0, item: {}},
		{id: '', mfp: 0, item: {}},
		{id: '', mfp: 0, item: {}},
		{id: '', mfp: 0, item: {}},
		{id: '', mfp: 0, item: {}},
		{id: '', mfp: 0, item: {}},
	]);
	
	
	
	const change_item_mfp_by_id = (value, item_list_id) => {
		
		var l_ = [...items_list]
		l_[item_list_id].id = value
		
		l_[item_list_id].mfp = 0
		l_[item_list_id].item = {}
		
		var id_ = parseInt(value.match(/\d+/))
		
		// Если есть число в строке
		if (id_) {
//			console.log(id_)
			
			fetch(`https://psychea.space/avatars/api/mfp?id_item=${id_}`, {
			  "method": "GET",
			})
			.then(response => {
			  return response.json()
			})
			.then(response => {
				
			  l_[item_list_id].mfp = response.status*1
			  l_[item_list_id].item = response.item
			
//			  console.log(l_)
			  set_items_list([...l_])
			})
			.catch(err => {
			  console.error(err);
			});
		} else {
			set_items_list([...l_])
		}
		
		
	}
	
	const get_start_mfp = () => {
		
		var initialValue = 0;
		
		var sum = items_list.reduce(
			(accumulator, currentValue) => accumulator + currentValue.mfp,
			initialValue
		);
		
		var max_durability_current = items_list.reduce(
			(accumulator, currentValue) => {
				
				try {
					if (currentValue.item.durabilityMax) {
						return accumulator + currentValue.item.durabilityMax*1
					}
				} catch (e) {
					return accumulator + 0
				} 

				return accumulator + 0
				
			},
			initialValue
		);
		
		var max_durability = items_list.reduce(
			(accumulator, currentValue) => {
				
				try {
					if (currentValue.item.needLevel) {
						return accumulator + currentValue.item.needLevel*1+20
					}
				} catch (e) {
					return accumulator + 0
				} 

				return accumulator + 0
				
			},
			initialValue
		);
		
		var penality = 1 - (1 - max_durability_current/max_durability)/2
		
		
		
		var sum_with_penality = sum * penality
		
//		console.log(sum, max_durability_current, max_durability, penality, sum_with_penality)
		
		if (!sum_with_penality) {
			sum_with_penality = 0
		}
		
		return {sum, max_durability_current, max_durability, penality, sum_with_penality}
		
	}
	
	
	const get_effect = () => {
		// Эффективность плавки строится из того, на скольок получаемая вещь моощнее самой сильной в загрузке
		
		// Найдем макс. значение mfp среди вещей
		var max_ = -1
		for (let i in items_list) {
			if (items_list[i].mfp > max_) {
				max_ = items_list[i].mfp
			}
		}
		
		return max_
	}
	
	
	const get_mean_value_mods_by_mod = (mod) => {
		
		// Считаем начальную mfp
		
		var mfp_ = Math.pow(get_start_mfp().sum_with_penality, 0.86)
		
		// Считаем дискретность
		
//		var d_ = (Math.ceil(mfp_/20)+Math.ceil(mfp_/3))/2
		
		// Определяем количество модификаций
		
		var c_ = 0
		if (type_item === 'Воинская') {
			c_ = 16
		} else {
			c_ = 9
		}
		
		// На каждый из 16/9 замодов идет поровну дискретности
		var mod_value = mfp_/c_*0.9*mod.koeff
		if (mod.mod_name === 'ОД на действие') {
			mod_value = Math.round(100*mod_value)/100
		} else {
			mod_value = Math.round(1*mod_value)/1
		}
		
		
		
		return mod_value
	}
	
	
    return (
		
		<Container maxWidth="xl" style={{height: '100%'}} disableGutters>
		   <Grid
			container
			direction="row"  
		  	spacing={1}
		  	alignItems="stretch"
			>
				<Grid item  xs={12} sm={12} md={12} lg={12}>
					<Card style={{height: "100%"}}>
					  <CardContent>
							<Typography variant="h6" component="h6" gutterBottom>
								Кузница древнего пламени
							</Typography>
							<Typography variant="body2" gutterBottom>
								Программа-помощник в плавке древних вещей в Кузнице древнего пламени. Расчет mfp вещей на входе и выходе, штрафы за прочность, уровень получаемой вещи, варианты замодов.
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item  xs={12} sm={6} md={4} lg={4}>
					<Paper className={classes.paper} style={{width: '100%', height: "100%", padding: 15}}>
						<Typography variant="h6" component="h6" gutterBottom>
							Введите id вещей
						</Typography>
						<Typography variant="subtitle2" component="h6" gutterBottom>
							(в виде :item11: или просто 11)
						</Typography>
						<Grid
							container
							direction="column"  
							spacing={1}
							>
							{items_list.map((item, i) => {
								return(
									<Grid key={'item_'+i} item  xs={12} sm={12} md={12} lg={12}>
										<TextField label="id вещи" variant="outlined" size="small" 
											value={item.id}
											onMouseEnter={()=>set_item_selected_id(item.id)}
											onChange={(e, value) => change_item_mfp_by_id(e.target.value, i)}
											InputProps={{
												endAdornment: <InputAdornment position="end">{item.mfp}</InputAdornment>
											}}/>
									</Grid>
								)
							})}
						</Grid>
						
					</Paper>
				</Grid>
				<Grid item  xs={12} sm={6} md={4} lg={4}>
				<Paper className={classes.paper} style={{width: '100%', textAlign: 'left', height: "100%", padding: 15}}>
						<Typography variant="h6" component="h6" gutterBottom   style={{textAlign: 'center'}}>
							Метрики плавки
						</Typography>
						<Typography variant="subtitle2" component="h6" gutterBottom  style={{textAlign: 'center'}}>
							(более подробно о метриках <Link href="https://daily.chaosage.app/?p=385" target="_blank">
														Здесь
													  </Link>)
						</Typography>
						{get_start_mfp().sum_with_penality > 0 && <>
								<Typography variant="caption" display="block" gutterBottom>
									<AdjustIcon style={{ fontSize: 8, marginRight: 3 }}/>Mfp на входе: {Math.round(get_start_mfp().sum_with_penality)}, {get_start_mfp().penality < 1 ? `с учетом штрафа за поломку ${Math.round(100*get_start_mfp().penality)/100}` : 'штрафа за поломку нет'}
								</Typography>
								<Typography variant="caption" display="block" gutterBottom>
									<AdjustIcon style={{ fontSize: 8, marginRight: 3 }}/>Mfp на выходе: {Math.round(0.8 * Math.pow(get_start_mfp().sum_with_penality, 0.86))}-{Math.round(Math.pow(get_start_mfp().sum_with_penality, 0.86))} {Math.round(get_start_mfp().sum_with_penality) >= 100 ? '(золотая рамка)' : ''}
								</Typography>
								<Typography variant="caption" display="block" gutterBottom>
									<AdjustIcon style={{ fontSize: 8, marginRight: 3 }}/>Эффективность преобразования: {Math.round((0.8 * Math.pow(get_start_mfp().sum_with_penality, 0.86))/get_effect()*100)}-{Math.round((1 * Math.pow(get_start_mfp().sum_with_penality, 0.86))/get_effect()*100)}% 
									<Tooltip title="Если больше 100%, то выплавляемая вещь сильнее самой сильной из расплавляемых вещей." aria-label="add"><HelpIcon style={{ fontSize: 12}}/></Tooltip>
								</Typography>
								<Typography variant="caption" display="block" gutterBottom>
									<AdjustIcon style={{ fontSize: 8, marginRight: 3 }}/>Дискретность: {Math.ceil(Math.pow(get_start_mfp().sum_with_penality, 0.86)/20)}-{Math.ceil(Math.pow(get_start_mfp().sum_with_penality, 0.86)/3)}
								</Typography>
							</>
						}
						{!get_start_mfp().sum_with_penality && <> 
							Добавьте минимум одну вещь на расплавление.
							</>
						}
						<List dense>
					  	{items_list.filter(item=>{
								try {
									if (item.item.name) {
										return true
									}
								} catch (e) {
									return false
								} 
								
								return false
							}).map((item, i) => {
							return(
							  <ListItem key={'item_'+i} selected={item_selected_id === item.id}>
								<ListItemAvatar>
								  <Avatar variant="rounded">
									<img src={`https://chaosage.ru/images/${item.item.image}`} alt={item.item.name} className={classes.avatars}></img>
								  </Avatar>
								</ListItemAvatar>
								<ListItemText primary={item.item.name} secondary={`Mfp: ${item.mfp}, макс. прочность: ${item.item.durabilityMax}/${item.item.needLevel*1+20}`} />
							  </ListItem>
								)
								})}
						</List>
						
						
						
						
					</Paper>
					
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={4}>
					<Paper className={classes.paper} style={{width: '100%', textAlign: 'left', height: "100%", padding: 15}}>
						<Typography variant="h6" component="h6" gutterBottom   style={{textAlign: 'center'}}>
							Средние значения модификаторов
						</Typography>
						<Typography variant="subtitle2" component="h6" gutterBottom  style={{textAlign: 'center'}}>
							приблизительные значения <Tooltip title="Более точные значения при высоких mfp и дискретности 16+" aria-label="add"><HelpIcon style={{ fontSize: 12}}/></Tooltip>
						</Typography>
						<FormControl style={{marginBottom: 10}}>
							<InputLabel id="get-type">Тип вещи</InputLabel>
								<Select
								  labelId="demo-simple-select-helper-label"
								  value={type_item}
								  onChange={(e, value) => set_type_item(value.props.value)}
								>
							  	
									<MenuItem value={'Магическая'}>Магическая</MenuItem>
									<MenuItem value={'Воинская'}>Воинская</MenuItem>
									
								  
								</Select>
					  	</FormControl>
					  	{items_type_mods_settings
							.filter(mod=>{
								if (mod.type === type_item) return true
								return false
							})
							.map(mod=>{
								return(
									<div key={mod.mod_name}>
										<p style={{float: 'left', width: '50%', margin: 0, marginBottom: 3}}>
											<AdjustIcon style={{ fontSize: 8, marginRight: 3 }}/>
											{mod.mod_name}:
										</p>
										<p style={{float: 'left', width: '50%', textAlign: 'right', margin: 0, marginBottom: 3}}>
											{get_mean_value_mods_by_mod(mod)}
										</p>
									</div>
								)
							})
						}
					</Paper>
			   </Grid>
		</Grid>
       </Container>
    );
}
 
export default Fotaf;