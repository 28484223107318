import React, { useState, useEffect } from 'react';

import ReactEcharts from 'echarts-for-react';
//import {MainContext} from '../contexts/MainContext'
//
import {getResourceFromMine, sort_unique, chanseForBattle, restorePower} from '../functions/Helpers'
import mineBotsProbs from './additional/mineBotsProbs'

import { Scrollbars } from 'react-custom-scrollbars';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';

import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';

import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';




//console.log(mineBotsProbs)

// Список шахт
const miners_list = [
    {name: 'Корундовая шахта', coords: ' (2, -37)'},
    {name: 'Кимберлитовая шахта', coords: ' (-48, -44)'},
    {name: 'Предгорная шахта', coords: ' (50, 10)'},
    {name: 'Шахта кобольдов', coords: ' (110, -24)'},
    {name: 'Шахта призраков', coords: ' (102, -14)'},
    {name: 'Титанитовая шахта', coords: ' (144, 132)'}
]

// Для слайдера уровня профессии
const marks = [
  {
    value: 0,
    label: 'Нет',
  },
  {
    value: 80,
    label: '80 уровень профессии',
  },

];

function valuetext(value) {
  return `${value}° уровень`;
}


const useStyles = makeStyles(theme => ({
  root: {
//    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const data = []

data['Этажи'] = (() => {
	var arr = []
	for (let i = 1; i < 71; i++) {
		arr.push(i)
	}
	return arr;
})()

var minersArr = ['Корундовая шахта', 'Предгорная шахта', 'Кимберлитовая шахта', 'Шахта кобольдов', 'Шахта призраков', 'Титанитовая шахта']

const Miners = () => {

	const classes = useStyles();
	
	const [profession, setProfession] = useState(0);
	const [empty, setEmpty] = useState(0);
	
	const [partyCount, setPartyCount] = useState(1);
	const [golemRestore, setGolemRestore] = useState(0);
	const [restoreThing, setRestoreThing] = useState(false);
	const [status, setStatus] = useState(false);
    
	const [mine, set_mine] = useState('Корундовая шахта');
	const [chanseType, setChanseType] = useState('Монстры');
	const [chansesList, setChansesList] = useState([]);
	
	const [price, setPrice] = useState({
								'Медная руда': 0,
								'Железная руда': 0,
								'Обсидиан': 0,
								'Корунд': 0,
								'Алмаз': 0,
							});
	
	const [bonusFromLuck, setBonus] = useState(false);
	const [bonusFromGas, setEvent] = useState(false);
	const [findTable, setFindTable] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false)
	const [typeOfdimensions, setType] = useState(0);
	const [additionalChanse, setAdditionalChanse] = useState(0);
	const [additionalChanseGolem, setAdditionalChanseGolem] = useState(0);
	
	const [destruction, setDestruction] = useState(1);
	
	const [lines, setLines] = useState([]);
	
//	console.log(destruction)
	

	
	
	useEffect(() => {
		var lines_ = []
		var chanses_ = []
		
		for (var i =0; i<minersArr.length; i++) {
			let res = getResourceFromMine(minersArr[i], profession, 
													 bonusFromGas, bonusFromLuck, 
													 empty, 'Общий доход', destruction, 
													 typeOfdimensions, findTable, price, 
													 additionalChanse, additionalChanseGolem,
										 golemRestore, restoreThing, partyCount, status)
			
			data[minersArr[i]] = res[0]
			chanses_.push(res[1])
			
			lines_.push({
				type: 'line',
				name: minersArr[i],
	//			smooth: true,
				showSymbol: false,
				data: data[minersArr[i]]
			})
		}
		
		setLines([...lines_])
		setChansesList([...chanses_])
		
	}, [destruction, additionalChanseGolem, additionalChanse, typeOfdimensions, isLoaded, findTable, bonusFromGas, bonusFromLuck, profession, empty, golemRestore, restoreThing, partyCount, status]);
	
	
	useEffect(() => {

		fetch('https://chaosage.app/resources/apiGetPricesOnRes.php?set=last')
			.then(response => response.json())
			.then(result => {

				let d = JSON.parse(result.out)[0]
				
				let p = {
					'Медная руда': d.mruda*1,
					'Железная руда': d.zruda*1,
					'Обсидиан': d.obsid*1,
					'Корунд': d.korund*1,
					'Алмаз': d.diamond*1,
				}
				

				
				setPrice({...p})
				setIsLoaded(true)
				return d

			}).then(res => {
				console.log(res)
				
			})
			.catch(e => {
//				setIsLoaded(true)
					console.log(e);
				setIsLoaded(true)
			});

	}, []);
	
	
	const getOption = () => {
		const option = {
			title: {
				text: 'Средний заработок в шахтах'
			},
			legend: {
				show: true,
				orient: 'vertical',
				align: 'right',
				right: 0,
				top: 20,
//				selectedMode: 'single',
//				formatter: (name) => name === 'folowers' ? 'Всего' : 'Динамика',
//				right: 0
			},
			tooltip: {
				trigger: 'axis',
//				formatter: function (params, ticket, callback) {
////					console.log(params)
//					return `Подписчиков: ${params[0].data[params[0].seriesName]}`
//				}, 
//				axisPointer: {
//					type: 'cross',
//					label: {
//						backgroundColor: '#6a7985'
//					}
//				}
			},
			grid: {
//				top: 2,
				containLabel: true
			},
			xAxis: {
				type: 'category',
				data: data['Этажи'],
				name: 'Этажи',
				nameLocation: 'center',
				nameGap: 25
			},
			yAxis: {
				type: 'value',
				scale: false,
				name: 'Серебро',
				nameLocation: 'end',
				nameGap: 25
			},

			series: lines
		};

		return option
	}
	
	
	
	const get_level_bots = (botName, stage) => {
		
		
		
		for (var i in mineBotsProbs) {

			if (mineBotsProbs[i]['Имя шахты'] == mine) {
				if ((mineBotsProbs[i]['Этаж шахты']) == stage) {
					if ((mineBotsProbs[i]['name']) == botName) {
						return mineBotsProbs[i]['real level']
					}
				}
			}
			
		}
		
		return 0
	}
	const get_max_bots = (botName, stage) => {
		
		
		
		for (var i in mineBotsProbs) {

			if (mineBotsProbs[i]['Имя шахты'] == mine) {
				if ((mineBotsProbs[i]['Этаж шахты']) == stage) {
					if ((mineBotsProbs[i]['name']) == botName) {
						return mineBotsProbs[i]['count_bots_max']
					}
				}
			}
			
		}
		
		return 0
	}
	
	const getOptionProbs = () => {
		
		var all_bots_lines = []
		var stages = []
		
		if (chanseType == 'Монстры') {
			// Перебираем json со статистикой
			for (var i in mineBotsProbs) {

				if (mineBotsProbs[i]['Имя шахты'] == mine) {
					// У нас данные отсортированы, смело все делаем как надо

					stages.push(mineBotsProbs[i]['Этаж шахты'])

					// Смотрим, добавлен ли этот бот у нас в список линий
					var have_ = -1
					for (var j in all_bots_lines) {

						if (all_bots_lines[j]['name'] == mineBotsProbs[i]['name']) {
							have_ = j
							break
						}
					}

					var chanse = Math.round(1000*mineBotsProbs[i]['count_bots']/mineBotsProbs[i]['count_battles'])/1000

					if (have_ == -1) {

						var d_ = []
						d_[mineBotsProbs[i]['Этаж шахты']-1] = chanse

						var local_line = {
						  name: mineBotsProbs[i]['name'],
						  data: d_,
						  type: 'line',
						  symbolSize: 1
						}

						all_bots_lines.push(local_line)
					} else {
						all_bots_lines[j].data[mineBotsProbs[i]['Этаж шахты']-1] = chanse
					}

				}
			}






			const option = {
				title: {
					text: 'Состав ботов на этажах'
				},
				legend: {
					show: true,
					orient: 'vertical',
					align: 'right',
					right: 0,
					top: 20,
				},
				tooltip: {
					trigger: 'axis',
					formatter: function (params, ticket, callback) {

						var l_ = '<table style="text-align: left">'
						var all_bots = 0;

						var params_sorted = params.sort((a,b) => (a['value'] > b['value']) ? -1 : ((b['value'] > a['value']) ? 1 : 0))

						
						var stage_ = 0
						for (var t = 0; t<params_sorted.length; t++) {
							if (params[t]['value']) {
								
								l_ += `<tr><td>${params_sorted[t]['seriesName']}</td> <td>[${get_level_bots(params_sorted[t]['seriesName'], params_sorted[t]['axisValue'])}]:</td> <td>${params_sorted[t]['value']}</td><td>до ${get_max_bots(params_sorted[t]['seriesName'], params_sorted[t]['axisValue'])} шт</td>`

								all_bots += params_sorted[t]['value']
								stage_ = params_sorted[t]['axisValue']
								
								l_ += `</tr>`
							}

						}
						
						l_ += `</table>`
						
						var type_ = 1;
						if (mine == 'Титанитовая шахта') {
							type_ = 6
						}
						
						l_ += `<br />В среднем ботов в бою: ${Math.round(10*all_bots)/10}`
						l_ += `<br />Шанс нападения: ${chanseForBattle(stage_, type_, partyCount, typeOfdimensions)}%`
						return l_
					}, 
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				grid: {
	//				top: 2,
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: sort_unique(stages),
					name: 'Этажи',
					nameLocation: 'center',
					nameGap: 25
				},
				yAxis: {
					type: 'value',
					scale: false,
					name: 'Ботов в одном бою',
					nameLocation: 'end',
					nameGap: 25
				},

				series: all_bots_lines
			};
			return option

		} else {
			// Режим для отображения шансов ресурсов
			var id_ = 0;
			for (let h_ in minersArr) {
				if (minersArr[h_] == mine) {
					id_ = h_
					break
				}
			}
			
			var chansesListLocal = chansesList[id_]
			
			// Перебираем json со статистикой вероятностями ресурсов
			for (var res in chansesListLocal[0]) {
				var d_ = []
				if (res != 'chanse') {
					for (var stage in chansesListLocal) {
						stages.push(parseInt(stage)+1)
						d_.push(Math.round(10000*chansesListLocal[stage][res]*chansesListLocal[stage]['chanse'])/10000)
						
					}
				}
				var local_line = {
				  name: res,
				  data: d_,
				  type: 'line',
				  symbolSize: 1
				}

				all_bots_lines.push(local_line)
				
			}

			
			const option = {
				title: {
					text: 'Шансы выпадения ресурсов'
				},
				legend: {
					show: true,
					orient: 'vertical',
					align: 'right',
					right: 0,
					top: 20,
				},
				tooltip: {
					trigger: 'axis',
					formatter: function (params, ticket, callback) {

						var l_ = ''
						var all_bots = 0;

						var params_sorted = params.sort((a,b) => (a['value'] > b['value']) ? -1 : ((b['value'] > a['value']) ? 1 : 0))

						var max_ = 0
						for (var t = 0; t<params_sorted.length; t++) {
							if (params[t]['value']) {
								l_ += `${params_sorted[t]['seriesName']}: ${params_sorted[t]['value']}%<br />`

								all_bots += params_sorted[t]['value']
							}

						}
						l_ += `<br />Шанс найти хоть что-то: ${Math.round(all_bots)}%`
						return l_
					}, 
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				grid: {
	//				top: 2,
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: sort_unique(stages),
					name: 'Этажи',
					nameLocation: 'center',
					nameGap: 25
				},
				yAxis: {
					type: 'value',
					scale: false,
					name: 'Шанс найти ресурс на этаже',
					nameLocation: 'end',
					nameGap: 25
				},

				series: all_bots_lines
			};
			return option
		}

	}
// theme={'my_theme'}
  return (
	  <Container  maxWidth="xl" disableGutters>
	  <div className={classes.root}>
		{!isLoaded && <LinearProgress />}
		  <Grid
			container
			direction="row"  
	  		justify="space-around"
		  	spacing={1}
	  		alignItems="stretch"
			>
			<Grid item xs={12} sm={6} md={4} lg={4}>

			  <Paper className={classes.paper} style={{width: '100%'}}>

	 			<Grid
					container
					direction="column"  
					spacing={0}
					justify="space-evenly"
  					alignItems="center"
					>
					<Grid item>
						<FormControl className={classes.formControl}>
							<InputLabel id="demo-simple-select-helper-label">Расчеты</InputLabel>
							<Select
							  labelId="demo-simple-select-helper-label"
							  id="demo-simple-select-helper"
							  value={typeOfdimensions}
							  onChange={(e, value) => setType(value.props.value)}
							>
							  <MenuItem value={0}>В среднем серебра/шаг</MenuItem>
							  <MenuItem value={1}>В среднем серебра/бой</MenuItem>
							  <MenuItem value={2}>Относительно поломки</MenuItem>
							</Select>
							<FormHelperText>Относительно чего считать прибыль</FormHelperText>
						  </FormControl>
					</Grid>
					<Grid item>
					  {(typeOfdimensions===2) && <TextField
							style={{marginTop: 10}}
						  label="Прочность"
						  type="number"

						  size={'small'}
						  defaultValue={destruction}
						  onChange={(e) => setDestruction(e.target.value)}
						  InputLabelProps={{
							shrink: true,
						  }}
						  helperText="Сколько сломаем прочности?"
						  variant="filled"
						/>}
					</Grid>
				  	<Grid item>
					  {(typeOfdimensions>0) && <Tooltip title="Каждый новый аватар +7% шанс нападения, +50% поломки"><TextField
							style={{marginTop: 10}}
						  label="Аватаров"
						  type="number"

						  size={'small'}
						  defaultValue={partyCount}
						  onChange={(e) => setPartyCount(e.target.value)}
						  InputLabelProps={{
							shrink: true,
						  }}
						  helperText="Сколько аватаров в отряде?"
						  variant="filled"
						/></Tooltip>}
					</Grid>
				</Grid>
				<Divider style={{marginBottom: 20, marginTop: 20}}/>

			 	

				
				
				<FormControl component="fieldset">
				  <FormGroup>
			  			<Typography id="discrete-slider-always" gutterBottom style={{marginBottom: 35}}>
							Уровень профессии "Рейдер"
						</Typography>
						  <Slider
							defaultValue={0}
							getAriaValueText={valuetext}
							aria-labelledby="discrete-slider-always"
							step={1}
							marks={marks}
							valueLabelDisplay="on"
							min={0}
							max={100}
							onChange={(e, value) => setProfession(value)}
						  />
				  		<Tooltip title="Увеличивается при подъеме ресурсов с клетки. Восстанавливается на 1 единицу в день">
							<Typography id="discrete-slider-always-empty" gutterBottom style={{marginBottom: 35}}>
								Выкопанность
							</Typography>
						</Tooltip>
						  <Slider
							defaultValue={0}
							getAriaValueText={valuetext}
							aria-labelledby="discrete-slider-always-empty"
							step={1}
							valueLabelDisplay="on"
							min={0}
							max={100}
							onChange={(e, value) => setEmpty(value)}
						  />
					  <Tooltip title="Газ или активированный Альтарь на этаже">
							<FormControlLabel
							  control={<Switch checked={bonusFromGas} onChange={(e, value) => setEvent(value)} value="gilad" />}
							  label="Активное событие в шахте"
							/>
					  </Tooltip>
				  	<Tooltip title="Можно получить за участие в Событиях за городом">
						<FormControlLabel
						  control={<Switch checked={bonusFromLuck} onChange={(e, value) => setBonus(value)} value="jason" />}
						  label="Печать удачи"
						/>
					</Tooltip>
			  		{(typeOfdimensions===2) && <Tooltip title="Можно получить за участие в Событиях за городом">
						<FormControlLabel
						  control={<Switch checked={restoreThing} onChange={(e, value) => setRestoreThing(value)} value="jason" />}
						  label="Печать стали"
						/>
					</Tooltip>}
			  		{(typeOfdimensions===2) && <Tooltip title="Можно купить в Кошельке за золото">
						<FormControlLabel
						  control={<Switch checked={status} onChange={(e, value) => setStatus(value)} value="jason" />}
						  label="Золотой+ статус"
						/>
					</Tooltip>}
				  	<Tooltip title="Можно найти в Юго-Западной части первого этажа Снежной башни">
						<FormControlLabel
						  control={<Switch checked={findTable} onChange={(e, value) => setFindTable(value)} value="jason" />}
						  label="Включенный Шахтерский фонарь"
						/>
					</Tooltip>
				  	<Tooltip title="Бонусы от событий. Вводить целое число (например 30 для бонуса 30%)">
						<TextField
							style={{marginTop: 10}}
						  label="Доп. шанс от событий"
						  type="number"

						  size={'small'}
						  defaultValue={additionalChanse}
						  onChange={(e) => setAdditionalChanse(e.target.value)}
						  InputLabelProps={{
							shrink: true,
						  }}
						/>
					</Tooltip>
				  	<Tooltip title="Бонус особого режима Спутника-Голема. Вводить целое число (например 30 для бонуса 30%)">
						<TextField
							style={{marginTop: 10}}
						  label="Доп. шанс от Голема"
						  type="number"

						  size={'small'}
						  defaultValue={additionalChanseGolem}
						  onChange={(e) => setAdditionalChanseGolem(e.target.value)}
						  InputLabelProps={{
							shrink: true,
						  }}
						/>
					</Tooltip>
			  		{(typeOfdimensions===2) && 
				  	<Tooltip title="Бонус особого режима Спутника-Голема. Вводить целое число (например 30 для бонуса 30%)">
						<TextField
							style={{marginTop: 10}}
						  label="Ремонт Голема"
						  type="number"

						  size={'small'}
						  defaultValue={golemRestore}
						  onChange={(e) => setGolemRestore(e.target.value)}
						  InputLabelProps={{
							shrink: true,
						  }}
						/>
					</Tooltip>}
					{(typeOfdimensions===2) && <div>Коэффициент изменения поломки: {Math.round(100*restorePower(golemRestore, restoreThing, partyCount, status))/100}</div>}
				  </FormGroup>
				  
				  <FormHelperText>Временные эффекты и предметы</FormHelperText>
				</FormControl>
			 </Paper>

			</Grid>
			<Grid item  xs={12} sm={6} md={8} lg={8}>

			  <Paper className={classes.paper} style={{height: '100%', minHeight: 400, width: '99%'}}>
	  				<ReactEcharts style={{width: '97%', height: '99%'}} option={getOption()}/>

			 </Paper>
																				
			</Grid>
            

		  </Grid>
	   </div>
	   <Grid
			container
			direction="row"  
	  		justify="space-around"
		  	spacing={1}
	  		alignItems="stretch"
			>
		   <Grid item  xs={12} sm={6} md={5} lg={4}>
				<Paper className={classes.paper} style={{width: '100%', height: "100%"}}>
					<Scrollbars style={{ height: 400 }}>
						<List className={classes.root} dense={true}>
							  {miners_list.map((l) =>
								<React.Fragment key={l.name} >
											{/*
												<Tooltip title={l.help}>
												*/
											}
										<ListItem alignItems="flex-start" button onClick={()=>set_mine(l.name)} selected={mine===l.name}>
											<ListItemAvatar>
											  <Avatar alt={l.name} src={l.urls} />
											</ListItemAvatar>
											<ListItemText
											  primary={l.name}
											  secondary={
												<React.Fragment>
												  <Typography
													component="span"
													variant="body2"
													className={classes.inline}
													color="textPrimary"
												  >
													Координаты: 
												  </Typography>
												  {l.coords}
												</React.Fragment>
											  }
											/>
									  </ListItem>
												{/*
												</Tooltip>
												*/
											}
								<Divider variant="inset" component="li" />
							</React.Fragment >
						  )}
						</List>
					</Scrollbars>
				</Paper>
			</Grid>

			<Grid item  xs={12} sm={6} md={7} lg={8}>
				
				<Paper className={classes.paper} style={{width: '100%'}}>
				
				   <ButtonGroup size="large" color="primary" aria-label="Переключатель режима">
					  <Button onClick={(e) => setChanseType('Монстры')} variant={chanseType == 'Монстры' ? 'contained' : 'outlined'}>Монстры</Button>
					  <Button onClick={(e) => setChanseType('Ресурсы')} variant={chanseType == 'Ресурсы' ? 'contained' : 'outlined'}>Ресурсы</Button>
					</ButtonGroup>
					<ReactEcharts  notMerge={true} style = {{width: '100%', height: 400}} option = { getOptionProbs() }/> 
				</Paper>
			</Grid>
		</Grid>
	  </Container>
  );
}

export default Miners;


