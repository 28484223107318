import React from 'react';
//import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar, CardActionArea } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
	  fontSize: 12,
	  maxWidth: 150
  },
  avatar: {
//    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const ResBigPrice = props => {
//  const { className, ...rest } = props;

  const classes = useStyles();
//	console.log(props)
	
  return (
    <Card  style={{height: "100%"}}>
     <CardActionArea>
		  <CardContent>
			<Grid
			  container
			  justify="space-between"
			>
			  <Grid item>
				<Typography
				  className={classes.title}
				  color="textSecondary"
				  gutterBottom
				  variant="body2"

				>
					Самый {props.type==='Дорогой'? 'дорогой' : 'дешевый'}:  {props.res[1]}

				</Typography>
				<Typography variant="h5">{Math.round(100*props.res[0])/100} сер.</Typography>
			  </Grid>
			  <Grid item>
				<Avatar className={classes.avatar}>
				   <Avatar alt={props.res[1]} src={props.res[2]} className={classes.icon} />
				</Avatar>
			  </Grid>
			</Grid>
		  </CardContent>
      </CardActionArea>
    </Card>
  );
};

ResBigPrice.propTypes = {
  className: PropTypes.string
};

export default ResBigPrice;
