import React, {
	useState, useEffect
} from 'react';

import ym from 'react-yandex-metrika';

import Typography from '@material-ui/core/Typography';
//import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';

//import FormLabel from '@material-ui/core/FormLabel';
//import FormControl from '@material-ui/core/FormControl';
//import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import { Scrollbars } from 'react-custom-scrollbars';

import { findMinCostMods, splitNumber } from '../functions/Helpers'
const Mod = () => {
//	findMinCostMods()
	
	const [summaryCost, setSummaryCost] = useState(0)
	const [readyMods, setReadyMods] = useState([])
	
	const [list, setList] = useState({
		'Амулеты': false,
		'Пояс': false,
		'Наручи': false,
		'Перчатки': false,
		'Ботинки': false,
		'Шлем': false,
		'Кольцо 1': false,
		'Кольцо 2': false,
		'Доспех': false,
		'Посох': false,
		'Мечи 1': false,
		'Топоры 1': false,
		'Мечи 2': false,
		'Топоры 2': false,
		'Копья': false,
		'Дубины 1': false,
		'Дубины 2': false,
		'Луки': false,
		'Арбалеты': false,
		'Кловеры': false,
		'Щиты': false,
	  });
	
	const [level, setLevel] = useState({
		'Амулеты': 10,
		'Пояс': 10,
		'Наручи': 10,
		'Перчатки': 10,
		'Ботинки': 10,
		'Шлем': 10,
		'Кольцо 1': 10,
		'Кольцо 2': 10,
		'Доспех': 10,
		'Посох': 10,
		'Мечи 1': 10,
		'Мечи 2': 10,
		'Топоры 1': 10,
		'Топоры 2': 10,
		'Копья': 10,
		'Дубины 1': 10,
		'Дубины 2': 10,
		'Луки': 10,
		'Арбалеты': 10,
		'Кловеры': 10,
		'Щиты': 10,
	  });
	
	const [mods, setMods] = useState({
		'Крит': 0,
		'Пробой': 0,
		'Броня': 0,
		'Атака': 0,
		'Призыватель': 0,
		'Заклинатель': 0,
		'Разрушитель': 0,
		'Защита': 0,
		'Минус ОД': 0,
		'Макс. ОД': 0,
		'Устойчивость': 0,
		'Здоровье': 0,
		'Мана': 0,
		'Сопротивление': 0,
		'Парирование': 0,
		'Реакция': 0,
		'Сила': 0,
		'Телосложение': 0,
		'Ловкость': 0,
		'Интеллект': 0,
		'Выносливость': 0,
		'Воля': 0,
	  });

	const [modsAval, setModsAval] = useState({
		'Крит': true,
		'Пробой': false,
		'Броня': false,
		'Атака': false,
		'Призыватель': false,
		'Заклинатель': false,
		'Разрушитель': false,
		'Защита': false,
		'Минус ОД': false,
		'Макс. ОД': false,
		'Устойчивость': false,
		'Здоровье': false,
		'Мана': false,
		'Сопротивление': false,
		'Парирование': false,
		'Реакция': false,
		'Сила': false,
		'Телосложение': false,
		'Ловкость': false,
		'Интеллект': false,
		'Выносливость': false,
		'Воля': false,
	  });

	// Установка предметов
	  const handleChange = event => {
		setList({ ...list, [event.target.name]: event.target.checked });
		  
	  };
		
		
	  const aval = {
			'Щиты': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Парирование': 1,
				'Сопротивление': 1,
				'Реакция': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Кловеры': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Парирование': 1,
				'Сопротивление': 1,
				'Реакция': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Луки': {
				'Атака': 1,
				'Защита': 1,
				'Крит': 1,
				'Пробой': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Реакция': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Арбалеты': {
				'Атака': 1,
				'Защита': 1,
				'Крит': 1,
				'Пробой': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Реакция': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Дубины 1': {
				'Атака': 1,
				'Защита': 1,
				'Крит': 1,
				'Пробой': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Реакция': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Дубины 2': {
				'Атака': 1,
				'Защита': 1,
				'Крит': 1,
				'Пробой': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Реакция': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Копья': {
				'Атака': 1,
				'Защита': 1,
				'Крит': 1,
				'Пробой': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Парирование': 1,
				'Реакция': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Топоры 1': {
				'Атака': 1,
				'Защита': 1,
				'Крит': 1,
				'Пробой': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Здоровье': 1,
				'Парирование': 1,
				'Реакция': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Топоры 2': {
				'Атака': 1,
				'Защита': 1,
				'Крит': 1,
				'Пробой': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Здоровье': 1,
				'Парирование': 1,
				'Реакция': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Наручи': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Парирование': 1,
				'Сопротивление': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Мечи 1': {
				'Крит': 1,
				'Атака': 1,
				'Защита': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Парирование': 1,
				'Реакция': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Мечи 2': {
				'Крит': 1,
				'Атака': 1,
				'Защита': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Парирование': 1,
				'Реакция': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Перчатки': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Парирование': 1,
				'Сопротивление': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Пояс': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Парирование': 1,
				'Сопротивление': 1,
				'Реакция': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Ботинки': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Сопротивление': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Шлем': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Сопротивление': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Амулеты': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Сопротивление': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Кольцо 1': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Парирование': 1,
				'Сопротивление': 1,
				'Реакция': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Кольцо 2': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Парирование': 1,
				'Сопротивление': 1,
				'Реакция': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Доспех': {
				'Атака': 1,
				'Защита': 1,
				'Броня': 1,
				'Крит': 1,
				'Пробой': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Парирование': 1,
				'Сопротивление': 1,
				'Реакция': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			},
			'Посох': {
				'Крит': 1,
				'Защита': 1,
				'Броня': 1,
				'Пробой': 1,
				'Минус ОД': 1,
				'Макс. ОД': 1,
				'Устойчивость': 1,
				'Здоровье': 1,
				'Мана': 1,
				'Парирование': 1,
				'Сопротивление': 1,
				'Реакция': 1,
				'Призыватель': 1,
				'Заклинатель': 1,
				'Разрушитель': 1,
				'Сила': 1,
				'Телосложение': 1,
				'Ловкость': 1,
				'Интеллект': 1,
				'Выносливость': 1,
				'Воля': 1,
			}
		}
	useEffect(() => {

		  // Перебираем лист с доступными замодами
		  var h = {
			'Крит': false,
			'Пробой': false,
			'Броня': false,
			'Атака': false,
			'Призыватель': false,
			'Заклинатель': false,
			'Разрушитель': false,
			'Защита': false,
			'Минус ОД': false,
			'Макс. ОД': false,
			'Устойчивость': false,
			'Здоровье': false,
			'Мана': false,
			'Сопротивление': false,
			'Парирование': false,
			'Реакция': false,
			'Сила': false,
			'Телосложение': false,
			'Ловкость': false,
			'Интеллект': false,
			'Выносливость': false,
			'Воля': false,
		  }
		  for (var i in h) {
				  // Перебираем список предметов
				  for (let j in list) {
					  if (!h[i]) {
						  // Предмет включен
						  if (list[j]) {
							  // Перебираем доступные предметы
							  for (var k in aval[j]) {
								  if (k === i) {
									  h[i] = true
									  break;
								  }
							  }
						  }
					  }
				  }


		  }
		  setModsAval({ ...h });

	}, [list]);
	
	  const changeModifire = (event, i) => {
		setMods({ ...mods, [i]: event });
	  };

	  const changeLevel = (event, i) => {
		setLevel({ ...level, [i]: event });
	  };

	// Запускаем кальцуляцию
	const  goComp = () => {
		ym('reachGoal', 'mod_deal');
		// Баним недоступное нам
		for (var i in mods) {
			if (!modsAval[i]) {
				mods[i] = 0
			}
		}

		findMinCostMods(list, mods, level).then((e)=>{
//			console.log(e)
			setSummaryCost(e[1]*1)
			setReadyMods([ ...e[0] ])
		})

	}

	const itemsTypes = ['Амулеты', 'Пояс', 'Наручи', 'Перчатки', 'Ботинки', 'Шлем', 'Кольцо 1', 'Кольцо 2', 'Доспех', 'Посох', 'Мечи 1', 'Мечи 2', 'Топоры 1', 'Топоры 2', 'Копья', 'Дубины 1', 'Дубины 2', 'Луки', 'Арбалеты', 'Кловеры', 'Щиты']
	
	const modTypes = ['Крит', 'Пробой', 'Броня', 'Атака', 'Призыватель', 'Заклинатель', 'Разрушитель', 'Защита', 'Минус ОД', 'Макс. ОД', 'Парирование', 'Реакция', 'Здоровье', 'Устойчивость', 'Мана', 'Сопротивление', 'Сила', 'Телосложение', 'Ловкость', 'Интеллект', 'Выносливость', 'Воля']
	
	return ( 
		<Grid
			container
			direction="row"  
		  	spacing={1}
			>
				<Grid item  xs={12} sm={12} md={12} lg={12}>
					<Card style={{height: "100%"}}>
					 <CardActionArea>
						  <CardContent>
								<Typography variant="h6" component="h6" gutterBottom>
									Модификационная
								</Typography>
								<Typography variant="body2" gutterBottom>
									Рассчет минимальной цены пользовательких сценариев модификаций
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item  xs={12} sm={6} md={6} lg={4}>
					<Card style={{height: "100%"}}>
						  <CardContent>
								<Typography variant="h6" component="h6">
									Выберите вещи для замода
								</Typography>
								<Typography  variant="caption" display="block" >
									И введите их уровень. Программа установит замоды на выбранные вещи
								</Typography>
							  	<Scrollbars style={{ height: 400 }}>
										{itemsTypes.map(item => {
												return (
													
													<Grid key={item}
														container
														direction="row"  
														spacing={0}
														justify="space-between"
														alignItems="center"
														>
														<Grid item  lg={6}>
															<FormControlLabel
																control={<Checkbox checked={list[item]} onChange={handleChange} name={item} />}
																label={item}
															  />
														</Grid>
														<Grid item  lg={6}>
															<Fade in={list[item]}>
																<TextField
																		style={{margin: 5, minWidth: 50}}
																		  label={'Уровень'}
																		  type="number"
																		  size={'small'}
																		  defaultValue={level[item]}
																		  onChange={(e) => changeLevel(e.target.value, item)}
																		  InputLabelProps={{
																			shrink: true,
																			min: 0
																		  }}
																		/>
															</Fade>
														</Grid>
													</Grid>

												)
											})}
							  </Scrollbars>
							</CardContent>
					</Card>
				</Grid>
				<Grid item  xs={12} sm={6} md={6} lg={8}>
					<Card style={{height: "100%"}}>
						  <CardContent>
								<Typography variant="h6" component="h6">
									Заполните желаемые модификации
								</Typography>
								<Typography  variant="caption" display="block"  >
									Эти модификации программа распределит на выбранных вещах
								</Typography>
								
								{modTypes.filter(item=> modsAval[item]).map(item => {
								  
				
									  return (
										  <TextField key = {item}
											style={{margin: 5}}
											  label={item}
											  type="number"
											  size={'small'}
											  defaultValue={mods[item]}
											  onChange={(e) => changeModifire(e.target.value, item)}
											  InputLabelProps={{
												shrink: true,
												min: 0
											  }}
											/>
										)
								  

							  	})}
							  	<Divider style={{marginBottom: 20, marginTop: 20}}/>
							  	<Button variant="contained" color="primary" onClick={()=>goComp()}>
									Посчитать
								  </Button>
								
							</CardContent>
					</Card>
				</Grid>
				<Grid item  xs={12} sm={12} md={12} lg={12} style={{marginTop: 15}}>
					<Card style={{height: "100%"}}>
						  <CardContent>
								<Typography variant="h6" component="h6">
									Результаты
								</Typography>
								<Typography  variant="caption" display="block"  >
									Суммарная стоимость модификаций {splitNumber(Math.round(summaryCost))}
								</Typography>

							</CardContent>
					</Card>
				</Grid>
				
				 {readyMods.filter(i=>i['Стоимость замода суммарная']>0).map(item => {
						

							return(
								<Grid  key={item['Предмет']} item   xs={12} sm={3} md={3} lg={3}>
									<Card style={{height: "100%"}}>
										  <CardContent>
												<Typography variant="h6" component="h6">
													{item['Предмет'].replace(' ', '_') + ': ' + splitNumber(Math.round(item['Стоимость замода суммарная'])) + ' сер.'}
												</Typography>
												
												{[].concat(item['Порядок замодов Порядковый'])
												    .sort((a, b) => a['Порядок'] < b['Порядок'])
													.filter(j=>j['Количество']>0)
													  .map(i=> {
												  
														return(
														
															<Typography  variant="caption" display="block"  >
																{'Замод №' + i['Порядок'] + ' ' + i['Тип'] + ' на ' + i['Количество'] + ' | ' + splitNumber(Math.round(i['Стоимость'])) + ' сер.'}
															</Typography>
														)
														
													  })
											  
											  	}
												
												
												
											</CardContent>
									</Card>
								</Grid>
							)
						
						
					})}
					
				
		</Grid>
	);
}

export default Mod;
