
import React, {
	useEffect,
	useState
} from 'react';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import ReactEcharts from 'echarts-for-react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  formControl: {
    margin: 2,
  },
	heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const replacer = {
	"absorbtion": 'Поглощение урона',
	"armor": "Броня",
	"attack": "Атака",
	"baseAP": "ОД на действие",
	"bleedingChance": "Шанс кровотечения",
	"critical": "Крит. шанс",
	"criticalDamage": "Крит. урон",
	"defence": "Защита",
	"dualHit": "Двойной удар",
	"fastness": "Устойчивость",
	"maxDamage": "Макс. урон",
	"maxHP": "Здоровье",
	"maxMP": "Мана",
	"minDamage": "Мин. урон",
	"parry": "Парирование",
	"pierce": "Пробой брони",
	"poisonResist": "Сопрот. врем. эффектам",
	"reaction": "Реакция",
	"resistance": "Сопротивление",
	"shieldBlock": "Блок. щитом"
}

const order_ = ["maxHP", "maxMP", 
				"baseAP", "minDamage", "maxDamage", 
				"attack", 'defence', 
				'armor', 'pierce', 
				"critical", "criticalDamage", "bleedingChance", 'fastness',
				'parry', 'reaction', 
				'resistance', 
				'absorbtion', 'dualHit', 'shieldBlock', 'poisonResist'];


const TolminPower = () => {
	
	const classes = useStyles();
	
	const [isLoaded, setIsLoaded] = useState(false)
	
	const [show_level, set_show_level] = useState(false)
	const [enemy_level, set_enemy_level] = useState(10)
	
	const [default_enemy_level, set_default_enemy_level] = useState(0)
	
	const [tolmin_power, set_tolmin_power] = useState('-')
	
	const [gamer_data, set_gamer_data] = useState('{"name":"Игрок","maxHP":47121,"maxMP":5052,"baseAP":5.1,"attack":2789,"defence":2459,"armor":3949,"minDamage":1741,"maxDamage":2021,"critical":955,"criticalDamage":2.81,"bleedingChance":84,"resistance":5483,"parry":75,"pierce":2038,"fastness":659,"reaction":125,"absorbtion":0,"shieldBlock":0,"dualHit":0,"poisonResist":42,"vamp_physical":0,"fire_shield":0}')
	const [enemy_data, set_enemy_data] = useState('{}')
	
	const [tolmin_enemy_list, set_tolmin_enemy_list] = useState([])
	
	const [enemy_name, set_enemy_name] = useState('Паук')
	
	
	const [tolmin_power_to, set_tolmin_power_to] = useState({})
	const [tolmin_power_from, set_tolmin_power_from] = useState({})
	
	const [table_data, set_table_data] = useState([])
	
	
	const [expanded, setExpanded] = useState(false);

	  const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	  };
	
	
	useEffect(() => {

		fetch('https://psychea.space/avatars/api/gettolminenemy')
				.then(e => {
					return e.json()
					// Устанавливаем имя на 0

				}).then(d=>{
					console.log(d)
					setIsLoaded(true)
					set_tolmin_enemy_list(d.data)
				})
				.catch(e => {
					setIsLoaded(true)
					console.log(e)
				})

	}, []);
	
	
	const create_data_for_table = (params_name, player_side, enemy_side, some_number) => {
	  return { params_name, player_side, enemy_side, some_number };
	}
	
	
	
	useEffect(() => {
		setIsLoaded(false)
		const timeOutId = setTimeout(() => {
			try {
				var data = JSON.parse(gamer_data)
				
				if (data.criticalDamage*1 > 10) {
					data.criticalDamage /= 100
				}

			} catch(e) {
				set_tolmin_power('Ошибка в введенных данных')
			}
			try {

				if (enemy_data != '{}') {
					var data_enemy = JSON.parse(enemy_data)
				
					if (data_enemy.criticalDamage*1 > 10) {
						data_enemy.criticalDamage /= 100
					}
				} else {
					var data_enemy = '{}'
				}
				
			} catch(e) {
				
			}
			// Перебираем массив
			// С учетом того, что игрок ввел свои данные
			if ((data !== undefined) && (typeof(data) !== "number")) {
				
				// Если не были введены параметры противника
				if (data_enemy == '{}') {
					
				
					// перебираем список и ищем нужного противника
					for (let i in tolmin_enemy_list) {

						// Нашли противника
						if (tolmin_enemy_list[i].name === enemy_name) {

							let data_enemy_params = JSON.parse(tolmin_enemy_list[i].data)


							if (data_enemy_params.hasOwnProperty('level')) {

								/*
								// Это бот, выведем на экран ввод его уровня						
								// Формируем объект
								for (let key in data_enemy_params) {
									if (typeof(data_enemy_params[key]) === 'object') {
										data_enemy_params[key] = data_enemy_params[key].k*((enemy_level === 0) ? data_enemy_params.level*1 : enemy_level) + data_enemy_params[key].c*1
									}
								}

								*/
								if (enemy_level === 0) {
									set_enemy_level(data_enemy_params.level)


								}
								set_default_enemy_level(data_enemy_params.level)
								set_show_level(true)

							} else {
								set_show_level(false)
							}

							data['enemy params'] = data_enemy_params
							break;
						}
					}


					if (data.hasOwnProperty('maxHP') && (data.hasOwnProperty('enemy params'))) {
						
						console.log(data)
						console.log(data['enemy params'])
						
						if (data['enemy params']['type'] !== 'Бот') {
							fetch('https://psychea.space/avatars/api/gettolminpower', {
									method: 'POST',
									headers: {
										'Content-Type': 'application/json'
									},
									body: JSON.stringify(data)

								})
								.then(e => {
									return e.json()
									// Устанавливаем имя на 0

								}).then(d=>{
									console.log('Результат', d)

									let rows = [];



									for (let i_ in order_) {

										let n_ = order_[i_]
										let new_n_ = replacer[n_]

										rows.push(create_data_for_table(new_n_, data[n_], data['enemy params'][n_], data['enemy params'][n_] - data[n_]))
									}

									set_table_data(rows)
									set_tolmin_power(d.status)
									set_tolmin_power_to(d.damage_factor_to)
									set_tolmin_power_from(d.damage_factor_from)
									setIsLoaded(true)
								})
								.catch(e => console.log(e))
						} 
						else {

							fetch(`https://chaosage.ru/sAPI2.php?request=bot_params&bot_id=${data['enemy params']['id']}&bot_level=${enemy_level}`)
								.then(d=>d.json())
								.then(d=>{

									if (d.criticalDamage*1 > 10) {
										d.criticalDamage /= 100
									}
									data['enemy params'] = d

									fetch('https://psychea.space/avatars/api/gettolminpower', {
										method: 'POST',
										headers: {
											'Content-Type': 'application/json'
										},
										body: JSON.stringify(data)

									})
									.then(e => {
										return e.json()
										// Устанавливаем имя на 0

									}).then(d=>{
										console.log('Результат', d)

										let rows = [];



										for (let i_ in order_) {

											let n_ = order_[i_]
											let new_n_ = replacer[n_]

											rows.push(create_data_for_table(new_n_, data[n_], data['enemy params'][n_], data['enemy params'][n_] - data[n_]))
										}

										set_table_data(rows)
										set_tolmin_power(d.status)
										set_tolmin_power_to(d.damage_factor_to)
										set_tolmin_power_from(d.damage_factor_from)
										setIsLoaded(true)
									})
									.catch(e => console.log(e))
								})

							console.log('мяу')
						}

					} 
					else {

						console.log('Введены неверные данные')
						setIsLoaded(true)

					}
				} else {
					// Ввели противника из примерки
					data['enemy params'] = data_enemy
					
					fetch('https://psychea.space/avatars/api/gettolminpower', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(data)

					})
					.then(e => {
						return e.json()
						// Устанавливаем имя на 0

					}).then(d=>{
						console.log('Результат', d)

						let rows = [];



						for (let i_ in order_) {

							let n_ = order_[i_]
							let new_n_ = replacer[n_]

							rows.push(create_data_for_table(new_n_, data[n_], data['enemy params'][n_], data['enemy params'][n_] - data[n_]))
						}

						set_table_data(rows)
						set_tolmin_power(d.status)
						set_tolmin_power_to(d.damage_factor_to)
						set_tolmin_power_from(d.damage_factor_from)
						setIsLoaded(true)
					})
					.catch(e => console.log(e))
				}
			}
			
			
		}, 700);
		
		
		return () => clearTimeout(timeOutId);
		
		

	}, [gamer_data, enemy_name, enemy_level, enemy_data]);
	
	
	const get_option_tp = () => {

		
		// Отсортируем значения
		var to_ = []
		var from_ = []
		
		for (let i in tolmin_power_to) {
			to_.push({
				name: i,
				value: tolmin_power_to[i]
			})
		}

		for (let i in tolmin_power_from) {
			from_.push({
				name: i,
				value: tolmin_power_from[i]
			})
		}

		to_ = to_.sort((a, b) => a.value < b.value ? -1 : 1)
		from_ = from_.sort((a, b) => a.value < b.value ? 1 : -1)
		
		var keys_to = []
		var values_to = []
		for (let i in to_) {
			keys_to.push(to_[i].name)
			values_to.push(Math.round(10000*(to_[i].value-1))/10000)
		}
		

		var keys_from = []
		var values_from = []
		for (let i in from_) {
			keys_from.push(from_[i].name)
			values_from.push(Math.round(10000*(from_[i].value-1))/10000)
		}
		


		var option = {

			tooltip: {},
			title: [{
				text: 'Коэффициенты воздействия',
				subtext: 'Ваша атака по цели',
				left: '50%',
				textAlign: 'center'
					}, {
				subtext: 'Атака цели по вам',
				left: '50%',
				top: '55%',
				textAlign: 'center'
					}],
			grid: [{
				top: 50,
//				width: '50%',
				bottom: '45%',
				left: 10,
				containLabel: true
					}, {
				top: '65%',
//				width: '50%',
				bottom: 0,
				left: 10,
				containLabel: true
					}],
			xAxis: [{
				min: -1,
				max: 1,
				type: 'value',
				splitLine: {
					show: false
				}
					}, {
				min: -1,
				max: 1,
				type: 'value',
				gridIndex: 1,
				splitLine: {
					show: false
				}
					}],
			yAxis: [{
				type: 'category',
				data: keys_to,
				axisLabel: {
					interval: 0,
				},
				splitLine: {
					show: false
				}
					}, {
				gridIndex: 1,
				type: 'category',
				data: keys_from,
				axisLabel: {
					interval: 0,
				},
				splitLine: {
					show: false
				}
					}],
			series: [{
					type: 'bar',
					z: 3,
					label: {
						normal: {
							position: 'right',
							show: true
						}
					},
					data: values_to
					},
				{
					type: 'bar',
					xAxisIndex: 1,
					yAxisIndex: 1,
					z: 3,
					label: {
						normal: {
							position: 'right',
							show: true
						}
					},
					data: values_from
					}]
		};

		return option
	}
	
	
	const handle_set_gamer_data = (d) => {
		if (d.criticalDamage*1 > 10) {
			d.criticalDamage /= 100
		}
		set_gamer_data(d)
	}	
	const handle_set_enemy_data = (d) => {
		if (d.criticalDamage*1 > 10) {
			d.criticalDamage /= 100
		}
		set_enemy_data(d)
	}	
    return (
       <Container  maxWidth="lg">
       {!isLoaded && <LinearProgress />}
        <Grid
			container
			direction="row"  
		  	spacing={3}
		  	style={{marginTop: isLoaded ? 4 : 0}}
			>
				<Grid item  xs={12} sm={12} md={7} lg={7}>
				
				  <h1>Ваша сила в толминах: {(isNaN(tolmin_power/2)) ? tolmin_power : Math.round(100000*tolmin_power)/100000}</h1>
					<TextField
					style={{margin: 5, minWidth: 50}}
					  label={'Ваши параметры'}
					  type="text"
					  size={'small'}
					  defaultValue={'{"name":"Игрок","maxHP":47121,"maxMP":5052,"baseAP":5.1,"attack":2789,"defence":2459,"armor":3949,"minDamage":1741,"maxDamage":2021,"critical":955,"criticalDamage":2.81,"bleedingChance":84,"resistance":5483,"parry":75,"pierce":2038,"fastness":659,"reaction":125,"absorbtion":0,"shieldBlock":0,"dualHit":0,"poisonResist":42,"vamp_physical":0,"fire_shield":0}'}
					  onChange={(e) => handle_set_gamer_data(e.target.value)}
					/>
					<TextField
					style={{margin: 5, minWidth: 50}}
					  label={'Параметры противника'}
					  type="text"
					  size={'small'}
					  defaultValue={''}
					  onChange={(e) => handle_set_enemy_data(e.target.value)}
					/>
					<FormControl className={classes.formControl}>
						<InputLabel id="demo-simple-select-helper-label">Или выберите бота</InputLabel>
						<Select
					  	  style={{width: 150}}
						  labelId="demo-simple-select-helper-label"
						  id="demo-simple-select-helper"
						  value={enemy_name}
						  onChange={(e, value) => set_enemy_name(value.props.value)}
						>
						{tolmin_enemy_list.map(item => [item.name, item.data]).map(d=>{
								
								let level_ = JSON.parse(d[1])
								level_ = level_.level
								if (level_ === undefined) {
									level_ = ''
								} else {
									level_ = ' (' + level_ + ')'
								}
								
								return(
									<MenuItem key={d[0]} value={d[0]}>{d[0] + level_}</MenuItem>
								)
							})}

						</Select>
						
				</FormControl>
					{show_level && <TextField style={{margin: 5, maxWidth: 102}} label="Уровень бота" type="number" size={'small'} value={enemy_level}
					  onChange={(e) => set_enemy_level(e.target.value)}/>}
					  
					  
				  <TableContainer component={Paper}>
					  <Table  size="small" aria-label="a dense table">
						<TableHead>
						  <TableRow>
							<TableCell>Параметр</TableCell>
							<TableCell align="right">Игрок</TableCell>
							<TableCell align="right">Соперник</TableCell>
							<TableCell align="right">Разница</TableCell>
						  </TableRow>
						</TableHead>
						<TableBody>
						  {table_data.map((row) => (
							<TableRow key={row.params_name}>
							  <TableCell component="th" scope="row">
								{row.params_name}
							  </TableCell>
							  <TableCell align="right">{row.player_side}</TableCell>
							  <TableCell align="right">{row.enemy_side}</TableCell>
							  <TableCell style={{background: (row.params_name === 'ОД на действие' ? (row.some_number < 0 ? '#ff00009c' : 'rgba(0, 128, 0, 0.63)') : (row.some_number > 0 ? '#ff00009c' : 'rgba(0, 128, 0, 0.63)'))}} align="right">{Math.round(100*row.some_number)/100}</TableCell>
							</TableRow>
						  ))}
						</TableBody>
					  </Table>
					</TableContainer>
					
				  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
					<AccordionSummary
					  expandIcon={<ExpandMoreIcon />}
					  aria-controls="panel1bh-content"
					  id="panel1bh-header"
					>
					  <Typography className={classes.heading}>Описание</Typography>
					  <Typography className={classes.secondaryHeading}>Как это работает</Typography>
					</AccordionSummary>
					<AccordionDetails>
					  <Typography style={{fontSize: 12}}>
						На основе введенных вами данных (параметры персонажа и выбранный противник) алгоритм рассчитывает силу вашего персонажа относительно силы выбранного противника. Дополнительно к агрегированному значению силы происходит расчет важности основных параметров персонажа в бою с данным соперником.<br></br><br></br>
						Расчет значений происходит на основе мат. ожидания боя, то есть числа в программе актуальны при проведении большого числа боев. Всегда есть вероятность промахнуться 100 раз подряд в 97% шанс попасть и выйти за пределы значений, указанных в этой программе.<br></br><br></br>
						Название "сила толмина" - отсылка к сообщению Rewad'а.
					  </Typography>
					</AccordionDetails>
				  </Accordion>
				  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
					<AccordionSummary
					  expandIcon={<ExpandMoreIcon />}
					  aria-controls="panel2bh-content"
					  id="panel2bh-header"
					>
					  <Typography className={classes.heading}>Как интерпретировать</Typography>
					  <Typography className={classes.secondaryHeading}>
						Что означают числа
					  </Typography>
					</AccordionSummary>
					<AccordionDetails>
					  <Typography style={{fontSize: 12}}>
						Сила толмина - агрегированный показатель результата боя между выбранным соперником и вами. Если показатель равен 1, значит вы победите соперника в тот же момент, как он победит вас (например, сражение против себя самого).<br></br><br></br>
						Если значение меньше 1, то вы слабее выбранного соперника (например, при значении 0.4 вы нанесете противнику урона на 40% его здоровья, прежде чем он вас убьет).<br></br>
						Если значение больше 1, то вы сильнее соперника (например, при значении 2 вы сможете убить 2-х выбранных соперников в 1 бою).<br></br><br></br>
						Значения в графиках "Коэффициенты воздействия" показывают слабые и сильные стороны ваших и  вражеских параметров.<br></br><br></br>
						"Ваша атака по цели" - значения в графиках определяют коэффициенты, влияющие на ваш урон по выбранному сопернику. Например, если "Устой" = 1.25, значит ваш урон по противнику увеличивается на 25% за счет крит. ударов (с учетом их рандома, крит. урона, парирования и т.д.). Если значение меньше 1, значит ваш урон снижается от данного параметра.<br></br>
						"Атака цели по вам" - значения в графиках определяют степень вашей защищенности от урона соперника. Если значения больше 1, значит соперник наносит вам увеличенный урон за счет данного параметра, если меньше 1 - вы получаете меньше урона. Например, защита 0.8 показывает, что благодаря защите вы получаете только 80% от общего урона врага.
					  </Typography>
					</AccordionDetails>
				  </Accordion>
				  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
					<AccordionSummary
					  expandIcon={<ExpandMoreIcon />}
					  aria-controls="panel3bh-content"
					  id="panel3bh-header"
					>
					  <Typography className={classes.heading}>Где брать параметры</Typography>
					  <Typography className={classes.secondaryHeading}>
						Что вводить в поле "Параметры"
					  </Typography>
					</AccordionSummary>
					<AccordionDetails>
					  <Typography style={{fontSize: 12}}>
						Поле "Параметры" можно заполнить с помощью данных из ссылки <a href="https://chaosage.ru/sAPI2.php?request=self_params" target='_blank'>внутриигрового api</a> или через примерочную. У каждого из методов свои особенности и данные могут незначительно отличаться.
					  </Typography>
					</AccordionDetails>
				  </Accordion>
				  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
					<AccordionSummary
					  expandIcon={<ExpandMoreIcon />}
					  aria-controls="panel4bh-content"
					  id="panel4bh-header"
					>
					  <Typography className={classes.heading}>Развитие</Typography>
					  <Typography className={classes.secondaryHeading}>
						Как помочь в развитии программы
					  </Typography>
					</AccordionSummary>
					<AccordionDetails>
					  <Typography style={{fontSize: 12}}>
						Для развития программы необходимы различные слепки параметров игроков и ботов игрового мира. Если есть желание помочь в развитии программы - напишите представителям клана Уничтожители.
					  </Typography>
					</AccordionDetails>
				  </Accordion>
				</Grid>
        
        		<Grid item  xs={12} sm={12} md={5} lg={5}>
					<ReactEcharts style = {{width: '100%', height: 550}} 
										option = { get_option_tp() } 
										notMerge={false}
										lazyUpdate={false}/> 
				</Grid>
				</Grid>
         
          
       </Container>
    );
}
 
export default TolminPower;