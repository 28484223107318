// Здесь функции

// Функция сортировки массива обхектов по ксвойству
export const compare = ( a, b, proper ) => {
  if ( a[proper] < b[proper] ){
    return -1;
  }
  if ( a[proper] > b[proper] ){
    return 1;
  }
  return 0;
}

// Вернет шанс нападения монстров в локации
export const chanseForBattle = (stage, type, partyCount, typeOfdimensions) => {
	
	var prob_ = 0;
	
	stage = parseInt(stage)
	// Шахты
	if (type < 6) {
		// Старые шахты и кобольды с призраками
		
		prob_ = stage + 40
		
		
	} 
	if (type == 6) {
		prob_ = Math.round((Math.pow(40 + stage/2, 0.95)))
		
	}
	
	// Тут багуля у ревада
	if (partyCount < 2) {
		partyCount = 0
	}
	
	if (typeOfdimensions > 0) {
		prob_ += Math.round(7*prob_*partyCount/100)
	}
	
	if (prob_ > 100) {
		prob_ = 100
	}
	
	return prob_
}

// Вернет% снижения поломки
export const restorePower = (golemRestore, restoreThing, partyCount, status) => {
	
	// Посчитаем поломОчку
	// destruction - сколько сломаем, но если у нас есть печать стали, то 10 поломки превратятся в 10/0.5=20 прочности
	// Печать стали
	var destructionReducer = 0.5
	// Количество игроков в отряде
	if (partyCount > 0) {
		destructionReducer += (partyCount*0.5)
	}
	if (restoreThing) {
		destructionReducer = destructionReducer*0.5
	}
	// статус
	if (status) {
		destructionReducer = destructionReducer*0.7
	}
	// големусик
	if (golemRestore > 0) {
		destructionReducer -= destructionReducer*(golemRestore/100)
	}
	
	return destructionReducer
}

// Возвращает поэтажно цепь мат ожидания прибыли
export const getResourceFromMine = (mineName, professionLevel, bonusFromGas, bonusFromLuck, empty, settings, destruction, typeOfdimensions, findTable, p, additionalChanse, additionalChanseGolem, golemRestore, restoreThing, partyCount, status) => {


	// Коэффициент увеличения прибыли от различных типов расчета, 0 - база
	// На самом деле тут плашка првоерки на то, что толмин уебок хы-хы-хы
	// Вероятность нападения монстров тут, если 100%, то каждый шаг - бой
	// Мы должны делить на значение вероятности боя, если 10%, значит и дроп за бой в 10 раз больше!
	var k = 1;

	
	var d_ = restorePower(golemRestore, restoreThing, partyCount, status)
	
	// Ну и делаем все дело
	destruction = destruction/d_
	
	var bonus = 1;

	if (bonusFromLuck) {
		bonus = 1.7
	}
	// Здесь голем
	bonus += additionalChanseGolem/100

	// Уголь
	var chanceForTable = 0;

	if (bonusFromGas) {
		if (bonus >= 1.7) {
			bonus = bonus * 1.5
		} else {
			bonus = bonus * 1.8
		}
	}
	
	// А тут от событий
	bonus += additionalChanse/100
	
	var mineArr = []
	var chansesArr = []

	// Шанс найти хоть что-то
	var chanceFind = 0;

	// Шансы конкретных ресурсов
	var chance = {
		'Медная руда': 0,
		'Железная руда': 0,
		'Обсидиан': 0,
		'Алмаз': 0,
		'Корунд': 0,
		'Искристый уголь': 0,
		'Титанитовая руда': 0
	}

	// Цены на ресурсы
	var price = p
//	console.log(price)

	var priceLocal = 0;

	if (mineName === 'Корундовая шахта') {

		// Локальное мат ожидание прибыди
		priceLocal = 0;

		for (let i = 0; i < 41; i++) {

			chanceFind = 10 + i * 2

			// Шанс найти корунд
			chance['Корунд'] = i - 2;


			if (i > 30) {
				chance['Корунд'] -= 3;
			} else if (i > 20) {
				chance['Корунд'] -= 2;
			} else if (i > 10) {
				chance['Корунд'] -= 1;
			}

			if (chance['Корунд'] < 0) {
				chance['Корунд'] = 0
			}
			// Добавляем шанс найти корунд от бонусов (газ, печать и т.д.)
			chance['Корунд'] = chance['Корунд'] * (bonus);
			chance['Медная руда'] = 100 - chance['Корунд']


			chance['Корунд'] = chance['Корунд'] / 100
			chance['Медная руда'] = chance['Медная руда'] / 100


			chanceFind += Math.round(chanceFind * (professionLevel * 1.4 / 100))
			chanceFind -= Math.round(chanceFind * (empty * 1 / 100))
			chanceFind = chanceFind > 100 ? 100 : chanceFind

			priceLocal = (chanceFind / 100) * (chance['Корунд'] * price['Корунд'] + chance['Медная руда'] * price['Медная руда']) * k
			
			if (typeOfdimensions === 1) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 1, partyCount, typeOfdimensions)/100)
			}
			if (typeOfdimensions === 2) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 1, partyCount, typeOfdimensions)/100) * destruction * 50
			}
			mineArr.push(Math.round(priceLocal * 10) / 10)
			
			chansesArr.push({...chance, "chanse": chanceFind})
			
		}


	}
	if (mineName === 'Кимберлитовая шахта') {

		// Локальное мат ожидание прибыди
		priceLocal = 0;

		for (let i = 0; i < 41; i++) {

			chanceFind = 10 + i * 2

			// Шанс найти корунд
			chance['Алмаз'] = i - 3;


			if (i > 30) {
				chance['Алмаз'] -= 3;
			} else if (i > 20) {
				chance['Алмаз'] -= 2;
			} else if (i > 10) {
				chance['Алмаз'] -= 1;
			}

			if (chance['Алмаз'] < 0) {
				chance['Алмаз'] = 0
			}
			// Добавляем шанс найти корунд от бонусов (газ, печать и т.д.)
			chance['Алмаз'] = chance['Алмаз'] * (bonus);
			chance['Железная руда'] = 100 - chance['Алмаз']


			chance['Алмаз'] = chance['Алмаз'] / 100
			chance['Железная руда'] = chance['Железная руда'] / 100

			chanceFind += Math.round(chanceFind * (professionLevel * 1.4 / 100))
			chanceFind -= Math.round(chanceFind * (empty * 1 / 100))
			chanceFind = chanceFind > 100 ? 100 : chanceFind

			priceLocal = (chanceFind / 100) * (chance['Алмаз'] * price['Алмаз'] + chance['Железная руда'] * price['Железная руда']) * k

			if (typeOfdimensions === 1) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 1, partyCount, typeOfdimensions)/100)
			}
			if (typeOfdimensions === 2) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 1, partyCount, typeOfdimensions)/100) * destruction * 50
			}
			
			
			
			mineArr.push(Math.round(priceLocal * 10) / 10)
			
			chansesArr.push({...chance, "chanse": chanceFind})
		}


	}
	if (mineName === 'Предгорная шахта') {

		// Локальное мат ожидание прибыди
		priceLocal = 0;

		for (let i = 0; i < 41; i++) {

			chanceFind = 10 + i * 2

			chance['Обсидиан'] = 0
			// Найти обсидиан шанс
			if (i >= 10) {
				chance['Обсидиан'] = Math.round((i / 3) * bonus)
			}

			chance['Железная руда'] = (100 - 60) * ((100 - chance['Обсидиан']) / 100)
			chance['Медная руда'] = (100 - 40) * ((100 - chance['Обсидиан']) / 100)

			chance['Железная руда'] = chance['Железная руда'] < 0 ? 0 : chance['Железная руда']
			chance['Медная руда'] = chance['Медная руда'] < 0 ? 0 : chance['Медная руда']

			chance['Обсидиан'] = chance['Обсидиан'] / 100
			chance['Железная руда'] = chance['Железная руда'] / 100
			chance['Медная руда'] = chance['Медная руда'] / 100

			chanceFind += Math.round(chanceFind * (professionLevel * 1.4 / 100))
			chanceFind -= Math.round(chanceFind * (empty * 1 / 100))
			chanceFind = chanceFind > 100 ? 100 : chanceFind

			priceLocal = (chanceFind / 100) * ((chance['Обсидиан']) * price['Обсидиан'] + chance['Железная руда'] * price['Железная руда'] + chance['Медная руда'] * price['Медная руда']) * k

			
			if (typeOfdimensions === 1) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 1, partyCount, typeOfdimensions)/100)
			}
			if (typeOfdimensions === 2) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 1, partyCount, typeOfdimensions)/100) * destruction * 50
			}
			
			mineArr.push(Math.round(priceLocal * 10) / 10)
			
			chansesArr.push({...chance, "chanse": chanceFind})
		}


	}
	if (mineName === 'Шахта кобольдов') {

		// Локальное мат ожидание прибыди
		priceLocal = 0;

		// Шанс найти уголь
		chanceForTable = 0;

		for (let i = 0; i < 21; i++) {


			chanceFind = 0
			if (i >= 4) {
				chanceFind = i * bonus
			}

			
			if ((i >= 4) && (findTable)) {
				chanceForTable = i
			}
			
			chance['Искристый уголь'] = chanceForTable/100
			
			chance['Обсидиан'] = 0.5 * (1 - chance['Искристый уголь'])
			chance['Корунд'] = 0.5 * (1 - chance['Искристый уголь'])

			

			chanceFind += Math.round(chanceFind * (professionLevel * 1.4 / 100))
			chanceFind -= Math.round(chanceFind * (empty * 1 / 100))
			chanceFind = chanceFind > 100 ? 100 : chanceFind

			// Если ищем уголь
//			chanceFind = chanceFind * (100 - chanceForTable) / 100

			priceLocal = (chanceFind / 100) * (chance['Обсидиан'] * price['Обсидиан'] + chance['Корунд'] * price['Корунд']) * k

			if (typeOfdimensions === 1) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 1, partyCount, typeOfdimensions)/100)
			}
			if (typeOfdimensions === 2) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 1, partyCount, typeOfdimensions)/100) * destruction * 50
			}
			
			
			mineArr.push(Math.round(priceLocal * 10) / 10)
			
			chansesArr.push({...chance, "chanse": chanceFind})
		}


	}
	if (mineName === 'Шахта призраков') {

		// Локальное мат ожидание прибыди
		priceLocal = 0;

		// Шанс найти уголь
		chanceForTable = 0;

		for (let i = 0; i < 21; i++) {

			chanceFind = 0

			chanceFind = i * bonus

			if ((i >= 3) && (findTable)) {
				chanceForTable = i
			}
			
			chance['Искристый уголь'] = chanceForTable/100

			// Снижаем на уголь
			chance['Обсидиан'] = 0.5 * (1 - chance['Искристый уголь'])
			chance['Алмаз'] = 0.5 * (1 - chance['Искристый уголь'])

			chanceFind += Math.round(chanceFind * (professionLevel * 1.4 / 100))
			chanceFind -= Math.round(chanceFind * (empty * 1 / 100))
			chanceFind = chanceFind > 100 ? 100 : chanceFind


			// Если ищем уголь
//			chanceFind = chanceFind * (100 - chanceForTable) / 100

			priceLocal = (chanceFind / 100) * (chance['Обсидиан'] * price['Обсидиан'] + chance['Алмаз'] * price['Алмаз']) * k

			if (typeOfdimensions === 1) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 1, partyCount, typeOfdimensions)/100)
			}
			if (typeOfdimensions === 2) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 1, partyCount, typeOfdimensions)/100) * destruction * 50
			}
			
			
			mineArr.push(Math.round(priceLocal * 10) / 10)
			
			chansesArr.push({...chance, "chanse": chanceFind})
		}


	}

	if (mineName === 'Титанитовая шахта') {



		// Локальное мат ожидание прибыди
		priceLocal = 0;

		for (let i = 0; i < 70; i++) {

			chanceFind = 3 + Math.pow(i / 1.4, 0.87) * bonus;

			
			chance['Титанитовая руда'] = chanceFind/400
			chance['Искристый уголь'] = (chanceFind/200) * (1 - chanceFind/400)
			
			
			chance['Обсидиан'] = (1 / 3) * (1 - chanceFind/200) * (1 - chanceFind/400)
			chance['Алмаз'] = (1 / 3) * (1 - chanceFind/200) * (1 - chanceFind/400)
			chance['Корунд'] = (1 / 3) * (1 - chanceFind/200) * (1 - chanceFind/400)


			chanceFind += Math.round(chanceFind * (professionLevel * 1.4 / 100))
			chanceFind -= Math.round(chanceFind * (empty * 1 / 100))
			chanceFind = chanceFind > 100 ? 100 : chanceFind

			priceLocal = (chanceFind / 100) * (chance['Обсидиан'] * price['Обсидиан'] + chance['Алмаз'] * price['Алмаз'] + chance['Корунд'] * price['Корунд']) * k

			if (typeOfdimensions === 1) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 6, partyCount, typeOfdimensions)/100)
			}
			if (typeOfdimensions === 2) {
				// Если за бой
				priceLocal = priceLocal/(chanseForBattle(i, 6, partyCount, typeOfdimensions)/100) * destruction * 50
			}
			
			
			mineArr.push(Math.round(priceLocal * 10) / 10)
			
			chansesArr.push({...chance, "chanse": chanceFind})
		}


	}


	if (settings === 'Общий доход') {
		

		
		return [mineArr, chansesArr]
	} else {

	}
}


// Сортировщик, который вернет уникальные значения в виде массива
export const  sort_unique = (arr) => {
  if (arr.length === 0) return arr;
  arr = arr.sort(function (a, b) { return a*1 - b*1; });
  var ret = [arr[0]];
  for (var i = 1; i < arr.length; i++) { //Start loop at 1: arr[0] can never be a duplicate
    if (arr[i-1] !== arr[i]) {
      ret.push(arr[i]);
    }
  }
  return ret;
}

//function randomInteger(min, max) {
//	// случайное число от min до (max+1)
//	let rand = min + Math.random() * (max + 1 - min);
//	return Math.floor(rand);
//}


export const findMinCostMods = (list, mods, level) => {


	return new Promise(function (resolve, reject) {


		//		console.log(list, mods, level)
		// Коэффициент, базовая сила и нагнетатель
		var needed = {
			'Крит': 0,
			'Пробой': 0,
			'Броня': 0,
			'Атака': 0,
			'Защита': 0,
			'Минус ОД': 0,
			'Макс. ОД': 0,
			'Устойчивость': 0,
			'Здоровье': 0,
			'Мана': 0,
			'Сопротивление': 0,
			'Парирование': 0,
			'Реакция': 0,
			'Призыватель': 0,
			'Заклинатель': 0,
			'Разрушитель': 0,
			'Сила': 0,
			'Телосложение': 0,
			'Ловкость': 0,
			'Интеллект': 0,
			'Выносливость': 0,
			'Воля': 0,
		}

		// Устанавливаем требуемые замоды
		for (var i in mods) {
			needed[i] = mods[i] * 1
		}

		// Начальное состояние
		var nFixed = {}



		//		var itemsAvalible = ['Амулеты', 'Пояс', 'Наручи', 'Перчатки', 'Ботинки', 'Шлем', 'Кольцо 1', 'Кольцо 2', 'Доспех', 'Посох']
		var itemsAvalible = []

		for (let g in list) {
			if (list[g]) {
				itemsAvalible.push(g)
			}
		}

		var allPoints = 0;
		for (let i in needed) {
			allPoints += needed[i]
			nFixed[i] = needed[i]
		}

		// Итоговые замоды
		// первое - value, второе - порядок
		var ready = {
			'Кловеры': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Парирование': [0, 1],
				'Сопротивление': [0, 1],
				'Реакция': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Луки': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Реакция': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Арбалеты': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Реакция': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Дубины 1': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Реакция': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Дубины 2': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Реакция': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Копья': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Парирование': [0, 1],
				'Реакция': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Топоры 1': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Здоровье': [0, 1],
				'Парирование': [0, 1],
				'Реакция': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Топоры 2': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Здоровье': [0, 1],
				'Парирование': [0, 1],
				'Реакция': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Наручи': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Парирование': [0, 1],
				'Сопротивление': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],

			},
			'Мечи 1': {
				'Всего замодов': 0,
				'Крит': [0, 1],
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Парирование': [0, 1],
				'Реакция': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Мечи 2': {
				'Всего замодов': 0,
				'Крит': [0, 1],
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Парирование': [0, 1],
				'Реакция': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Перчатки': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Парирование': [0, 1],
				'Сопротивление': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
				
			},
			'Пояс': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Парирование': [0, 1],
				'Сопротивление': [0, 1],
				'Реакция': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Ботинки': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Сопротивление': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Шлем': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Сопротивление': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],

			},
			'Амулеты': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Сопротивление': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Кольцо 1': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Парирование': [0, 1],
				'Сопротивление': [0, 1],
				'Реакция': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Кольцо 2': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Парирование': [0, 1],
				'Сопротивление': [0, 1],
				'Реакция': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Доспех': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Парирование': [0, 1],
				'Сопротивление': [0, 1],
				'Реакция': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Посох': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Парирование': [0, 1],
				'Сопротивление': [0, 1],
				'Реакция': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			},
			'Щиты': {
				'Всего замодов': 0,
				'Атака': [0, 1],
				'Защита': [0, 1],
				'Броня': [0, 1],
				'Крит': [0, 1],
				'Пробой': [0, 1],
				'Минус ОД': [0, 1],
				'Макс. ОД': [0, 1],
				'Устойчивость': [0, 1],
				'Здоровье': [0, 1],
				'Мана': [0, 1],
				'Парирование': [0, 1],
				'Сопротивление': [0, 1],
				'Реакция': [0, 1],
				'Призыватель': [0, 1],
				'Заклинатель': [0, 1],
				'Разрушитель': [0, 1],
				
				'Сила': [0, 1],
				'Телосложение': [0, 1],
				'Ловкость': [0, 1],
				'Интеллект': [0, 1],
				'Выносливость': [0, 1],
				'Воля': [0, 1],
			}
		}

		var getCost = (item, modType, value) => {
			// Предмет
			// Тип мода
			// На сколько модим
//			// Уровень предмета
//			console.log(item, modType, value)
//			console.log(ready[item][modType][1])
//			console.log(level[item])
//			console.log(costs[item][modType][0])
//			console.log(costs[item][modType][2])
//			console.log(costs[item][modType][1])
			
			return (ready[item][modType][1]) * value * (level[item] / 100 + 1) * costs[item][modType][0] * (costs[item][modType][2] / 2 * (value - 1) + costs[item][modType][1])

		}

		var costs = {
			'Кловеры': {
				'Атака': [1, 15, 8],
				'Защита': [1, 23, 11],
				'Броня': [1, 70, 15],
				'Крит': [1, 65, 15],
				'Пробой': [1, 70, 15],
				'Минус ОД': [1, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [1, 1, 1],
				'Парирование': [1, 90, 40],
				'Сопротивление': [1, 45, 13],
				'Реакция': [1, 50, 20],
				'Призыватель': [1, 77, 77],
				'Заклинатель': [1, 77, 77],
				'Разрушитель': [1, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Луки': {
				'Атака': [1, 15, 8],
				'Защита': [3, 23, 11],
				'Крит': [3, 65, 15],
				'Пробой': [3, 70, 15],
				'Минус ОД': [1, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Реакция': [1, 50, 20],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Арбалеты': {
				'Атака': [2, 15, 8],
				'Защита': [2, 23, 11],
				'Крит': [1, 65, 15],
				'Пробой': [1, 70, 15],
				'Минус ОД': [2, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Реакция': [1, 50, 20],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Дубины 1': {
				'Атака': [2, 15, 8],
				'Защита': [2, 23, 11],
				'Крит': [2, 65, 15],
				'Пробой': [1, 70, 15],
				'Минус ОД': [2, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Реакция': [1, 50, 20],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Дубины 2': {
				'Атака': [2, 15, 8],
				'Защита': [2, 23, 11],
				'Крит': [2, 65, 15],
				'Пробой': [1, 70, 15],
				'Минус ОД': [2, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Реакция': [1, 50, 20],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Копья': {
				'Атака': [1, 15, 8],
				'Защита': [1, 23, 11],
				'Крит': [2, 65, 15],
				'Пробой': [1, 70, 15],
				'Минус ОД': [2, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Парирование': [1, 90, 40],
				'Реакция': [1, 50, 20],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Топоры 1': {
				'Атака': [2, 15, 8],
				'Защита': [3, 23, 11],
				'Крит': [1, 65, 15],
				'Пробой': [2, 70, 15],
				'Минус ОД': [2, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Здоровье': [1, 1, 1],
				'Парирование': [1, 90, 40],
				'Реакция': [1, 50, 20],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Топоры 2': {
				'Атака': [2, 15, 8],
				'Защита': [3, 23, 11],
				'Крит': [1, 65, 15],
				'Пробой': [2, 70, 15],
				'Минус ОД': [2, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Здоровье': [1, 1, 1],
				'Парирование': [1, 90, 40],
				'Реакция': [1, 50, 20],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Мечи 1': {
				'Атака': [1, 15, 8],
				'Защита': [1, 23, 11],
				'Крит': [3, 65, 15],
				'Минус ОД': [2, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Парирование': [1, 90, 40],
				'Реакция': [1, 50, 20],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Мечи 2': {
				'Атака': [1, 15, 8],
				'Защита': [1, 23, 11],
				'Крит': [3, 65, 15],
				'Минус ОД': [2, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Парирование': [1, 90, 40],
				'Реакция': [1, 50, 20],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Наручи': {
				'Атака': [2, 15, 8],
				'Защита': [2, 23, 11],
				'Броня': [2, 70, 15],
				'Крит': [3, 65, 15],
				'Пробой': [3, 70, 15],
				'Макс. ОД': [2, 5, 2],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [1, 1, 1],
				'Парирование': [1, 90, 40],
				'Сопротивление': [2, 45, 13],
				'Призыватель': [2, 77, 77],
				'Заклинатель': [2, 77, 77],
				'Разрушитель': [2, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],

			},
			'Перчатки': {
				'Атака': [2, 15, 8],
				'Защита': [2, 23, 11],
				'Броня': [2, 70, 15],
				'Крит': [2, 65, 15],
				'Пробой': [2, 70, 15],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [2, 1, 1],
				'Парирование': [1, 90, 40],
				'Сопротивление': [1, 45, 13],
				'Призыватель': [1, 77, 77],
				'Заклинатель': [1, 77, 77],
				'Разрушитель': [1, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Пояс': {
				'Атака': [1, 15, 8],
				'Защита': [1, 23, 11],
				'Броня': [1, 70, 15],
				'Крит': [1, 65, 15],
				'Пробой': [1, 70, 15],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [1, 1, 1],
				'Парирование': [1, 90, 40],
				'Сопротивление': [1, 45, 13],
				'Реакция': [1, 50, 20],
				'Призыватель': [2, 77, 77],
				'Заклинатель': [2, 77, 77],
				'Разрушитель': [2, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Ботинки': {
				'Атака': [2, 15, 8],
				'Защита': [1, 23, 11],
				'Броня': [1, 70, 15],
				'Крит': [2, 65, 15],
				'Пробой': [3, 70, 15],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [2, 1, 1],
				'Сопротивление': [1, 45, 13],
				'Призыватель': [2, 77, 77],
				'Заклинатель': [2, 77, 77],
				'Разрушитель': [2, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Шлем': {
				'Атака': [1, 15, 8],
				'Защита': [2, 23, 11],
				'Броня': [2, 70, 15],
				'Крит': [2, 65, 15],
				'Пробой': [2, 70, 15],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [2, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [1, 1, 1],
				'Сопротивление': [2, 45, 13],
				'Призыватель': [1, 77, 77],
				'Заклинатель': [1, 77, 77],
				'Разрушитель': [1, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Амулеты': {
				'Атака': [1, 15, 8],
				'Защита': [1, 23, 11],
				'Броня': [1, 70, 15],
				'Крит': [1, 65, 15],
				'Пробой': [1, 70, 15],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [2, 50, 14],
				'Здоровье': [2, 1, 1],
				'Мана': [1, 1, 1],
				'Сопротивление': [1, 45, 13],
				'Призыватель': [2, 77, 77],
				'Заклинатель': [2, 77, 77],
				'Разрушитель': [2, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Кольцо 1': {
				'Атака': [1, 15, 8],
				'Защита': [1, 23, 11],
				'Броня': [2, 70, 15],
				'Крит': [2, 65, 15],
				'Пробой': [2, 70, 15],
				'Макс. ОД': [2, 5, 2],
				'Устойчивость': [2, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [1, 1, 1],
				'Парирование': [1, 90, 40],
				'Сопротивление': [1, 45, 13],
				'Реакция': [2, 50, 20],
				'Призыватель': [2, 77, 77],
				'Заклинатель': [2, 77, 77],
				'Разрушитель': [2, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Кольцо 2': {
				'Атака': [1, 15, 8],
				'Защита': [1, 23, 11],
				'Броня': [2, 70, 15],
				'Крит': [2, 65, 15],
				'Пробой': [2, 70, 15],
				'Макс. ОД': [2, 5, 2],
				'Устойчивость': [2, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [1, 1, 1],
				'Парирование': [1, 90, 40],
				'Сопротивление': [1, 45, 13],
				'Реакция': [2, 50, 20],
				'Призыватель': [2, 77, 77],
				'Заклинатель': [2, 77, 77],
				'Разрушитель': [2, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Доспех': {
				'Атака': [2, 15, 8],
				'Защита': [1, 23, 11],
				'Броня': [1, 70, 15],
				'Крит': [2, 65, 15],
				'Пробой': [2, 70, 15],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [1, 1, 1],
				'Парирование': [2, 90, 40],
				'Сопротивление': [1, 45, 13],
				'Реакция': [1, 50, 20],
				'Призыватель': [2, 77, 77],
				'Заклинатель': [2, 77, 77],
				'Разрушитель': [2, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Посох': {
				'Атака': [1, 15, 8],
				'Защита': [1, 23, 11],
				'Броня': [1, 70, 15],
				'Крит': [1, 65, 15],
				'Пробой': [1, 70, 15],
				'Минус ОД': [1, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [1, 1, 1],
				'Парирование': [1, 90, 40],
				'Сопротивление': [1, 45, 13],
				'Реакция': [1, 50, 20],
				'Призыватель': [1, 77, 77],
				'Заклинатель': [1, 77, 77],
				'Разрушитель': [1, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			},
			'Щиты': {
				'Атака': [2, 15, 8],
				'Защита': [1, 23, 11],
				'Броня': [1, 70, 15],
				'Крит': [1, 65, 15],
				'Пробой': [2, 70, 15],
				'Минус ОД': [3, 110, 55],
				'Макс. ОД': [1, 5, 2],
				'Устойчивость': [1, 50, 14],
				'Здоровье': [1, 1, 1],
				'Мана': [2, 1, 1],
				'Парирование': [1, 90, 40],
				'Сопротивление': [1, 45, 13],
				'Реакция': [1, 50, 20],
				'Призыватель': [2, 77, 77],
				'Заклинатель': [2, 77, 77],
				'Разрушитель': [2, 77, 77],
				
				'Сила': [1, 2, 2],
				'Телосложение': [1, 2, 2],
				'Ловкость': [1, 2, 2],
				'Интеллект': [1, 2, 2],
				'Выносливость': [1, 2, 2],
				'Воля': [1, 2, 2],
			}
		}


		// Далее мы должны пройтись по списку необходимого и по всем доступным предметам и найти максимально-минимальную цену.
		// Цена должна быть минимальной для данного замода относительно предметов
		// Но цена должн абыть максимальной для замода относительно других предметов. МАКС МИН

		// Перебираем нужное



		// Формируем массив с мин ценами
		const getMin = () => {



			// Статистика модов, ищем максимально минимальную цену

			// Минимальная цена на какой-то из шмоток
			// На каком предмете этот минимум
			// Сколько суммарно потенциал замода у данной шмотки
			// На скольки можно модить
			// Коэффициент стоимости
			var modsStat = {
				'Крит': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Пробой': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Броня': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Атака': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Призыватель': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Заклинатель': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Разрушитель': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Защита': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Минус ОД': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Макс. ОД': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Парирование': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Реакция': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Здоровье': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Устойчивость': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Мана': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Сопротивление': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Сила': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Телосложение': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Ловкость': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Интеллект': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Выносливость': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
				'Воля': {
					minCost: Infinity,
					item: '',
					sumCost: 0,
					count: 0,
					k: 1
				},
			}

			// Перебираем замоды
			for (var i in needed) {
				// перебираем предметы


				// Сначала посчитаем количество вещей и коэффициенты стоимость КАЖДОЙ вещи на каждом моде
				for (var j in costs) {

					// Если такой замод на шмотке доступен в принципе
					if ((ready[j][i] !== undefined) && (itemsAvalible.indexOf(j) !== -1)) {

						// Количество вещей, то есть сколько можно модить в целом
						modsStat[i].count += 1

						// Суммируем все i
						var r = getAllMods()
						var val = nFixed[i] - r[i]

						//						console.log(j, i, val)

						modsStat[i].sumCost += getCost(j, i, val)
						modsStat[i].k = getCost(j, i, val)

					}
				}

				// Считаем среднее
				modsStat[i].sumCost = modsStat[i].sumCost / modsStat[i].count
				//			console.log(i, modsStat[i].sumCost)

				// Теперь считаем стоимость
				for (let j in costs) {

					// Если такой замод на шмотке доступен в принципе
					if ((ready[j][i] !== undefined) && (itemsAvalible.indexOf(j) !== -1)) {



						let now = ready[j][i][0];


						// Это стоимость замода даннойго типа мода на данной вещи до максимума
						let cost = getCost(j, i, now + 1) - getCost(j, i, now)


						if (cost < modsStat[i].minCost) {
							modsStat[i].minCost = cost
							modsStat[i].item = j
						}
					}
				}

			}

			//		console.log(modsStat)
			return modsStat
		}

		const getAllMods = () => {
			var readyYet = {
				'Крит': 0,
				'Пробой': 0,
				'Броня': 0,
				'Атака': 0,
				'Защита': 0,
				'Минус ОД': 0,
				'Макс. ОД': 0,
				'Парирование': 0,
				'Реакция': 0,
				'Призыватель': 0,
				'Заклинатель': 0,
				'Разрушитель': 0,
				'Здоровье': 0,
				'Мана': 0,
				'Сопротивление': 0,
				'Устойчивость': 0,
				
				'Сила': 0,
				'Телосложение': 0,
				'Ловкость': 0,
				'Интеллект': 0,
				'Выносливость': 0,
				'Воля': 0,
			}
			// перебираем каждую вещь
			for (var i in ready) {
				// и каждый замод в ней
				for (var j in ready[i]) {
					readyYet[j] += ready[i][j][0]
				}

			}
			return readyYet
		}
		// Функция поиска макс мин
		// Принимает на вход все минимальные цены
		const findMax = (m) => {

			var out = {
				maxCost: 0,
				avarageCost: 0,
				mod: '',
				item: ''
			}
			for (var i in m) {

				// Средняя стоимость замодов для данной вещи
				//			var localAvarage = m[i].sumCost / m[i].count
				//
				// Лучше все-таки работает не этот алгоритм, а следющий
				//			if (m[i].sumCost > out.maxCost) {
				//				out.maxCost = m[i].sumCost
				//				out.item = m[i].item
				//				out.mod = i
				//			}

				// Основной
				if (m[i].k > out.maxCost) {
					out.maxCost = m[i].k
					out.item = m[i].item
					out.mod = i
				}
			}

			// На этом этапе мы определили минимальную стоимость, а теперь проверяем среднюю.
			for (let i in m) {

				// Если равны максимальной цене
				if (m[i].k === out.maxCost) {

					// Перебираем вещи по средней цены
					for (var j in m) {
						if (m[j].sumCost > out.avarageCost) {


							out.avarageCost = m[j].sumCost
							out.item = m[j].item
							out.mod = j


						}
					}

				}
			}
			//	console.log(out)
			return out
		}


		for (var hi = 0; hi < allPoints; hi++) {
			//	for (var hi = 0; hi < 2; hi++) {
			// Ищем то, что модим первым
			var modifire = findMax(getMin())
			//					console.log('Принято решение модить ' + modifire.item + ' на ' + modifire.mod)
			// Устанавливаем данной вещи новый пунктик

			ready[modifire.item][modifire.mod][0] += 1

			// И определяем ей порядок, если его нет
			// Только если текущий порядок болье текущего замода
			if (ready[modifire.item][modifire.mod][1] > ready[modifire.item]['Всего замодов']) {
				//		console.log('Устанавливаем новый порядок')
				//		console.log(modifire.item, modifire.mod)

				ready[modifire.item]['Всего замодов'] += 1

				// Обновляем порядок
				for (let i in ready[modifire.item]) {
					if ((i !== 'Всего замодов') && (i !== modifire.mod)) {
						if (ready[modifire.item][i][1] === ready[modifire.item]['Всего замодов']) {
							ready[modifire.item][i][1] += 1
						}
					}
				}
			}


			// Забираем у needed
			needed[modifire.mod] -= 1
		}


		// Преобразуем для удобного восприятия на другой стороне

		var out = []
		for (let i in ready) {
			var allCostL = 0;
			// перебираем предметы
			out.push({
				'Предмет': i,
				'Стоимость замода суммарная': 0,
				'Всего замодов': 0,
				'Порядок замодов Порядковый': [],
				'Порядок замодов': [
					{
						'Тип': 'Крит',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Пробой',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Броня',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Атака',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Защита',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Минус ОД',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Макс. ОД',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Парирование',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Реакция',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Призыватель',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Заклинатель',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Разрушитель',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Здоровье',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Устойчивость',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Мана',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Сопротивление',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Сила',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Телосложение',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Ловкость',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Интеллект',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Выносливость',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					},
					{
						'Тип': 'Воля',
						'Количество': 0,
						'Порядок': 1,
						'Стоимость': 0
					}
				]
			})
			for (var j in costs[i]) {

				let read = ready[i][j][0]

				let cost = getCost(i, j, read)

				for (let t in out[out.length - 1]['Порядок замодов']) {
					if (out[out.length - 1]['Порядок замодов'][t]['Тип'] === j) {

						out[out.length - 1]['Порядок замодов'][t]['Количество'] = ready[i][j][0]

						// Пока порядок оставим на 0, если что, вернем на базу ready[i][j][1]
						out[out.length - 1]['Порядок замодов'][t]['Порядок'] = 0
//						out[out.length - 1]['Порядок замодов'][t]['Порядок'] = ready[i][j][1]

						// Разделим порядок, чтобы сравнять
//						out[out.length - 1]['Порядок замодов'][t]['Стоимость'] = cost
						out[out.length - 1]['Порядок замодов'][t]['Стоимость'] = cost / ready[i][j][1]

					}
				}
				allCostL += cost
			}
			out[out.length - 1]['Стоимость замода суммарная'] = allCostL
		}

		// Просматриваем порядок замодов, чтобы пофиксить возможный баг
		// Сначала перебираем вещи
		var stepMaximum = 0;
		var localCosts = 0;
		for (let i in out) {
			
			out[i]['Стоимость замода суммарная'] = 0
			
			
			stepMaximum = 0
			localCosts = 0
			
			for (let h in out[i]['Порядок замодов']) {
				
				let maximum = 0
				
				for (let j in out[i]['Порядок замодов']) {
					if ((out[i]['Порядок замодов'][j]['Стоимость'] > maximum) && (out[i]['Порядок замодов'][j]['Порядок'] === 0)) {
						maximum = out[i]['Порядок замодов'][j]['Стоимость']
					}
					
				}
				stepMaximum++
				for (let j in out[i]['Порядок замодов']) {
					// Еще не установили порядок
					if ((out[i]['Порядок замодов'][j]['Стоимость'] === maximum) && (out[i]['Порядок замодов'][j]['Порядок'] === 0)) {
						
						
						out[i]['Порядок замодов'][j]['Порядок'] = stepMaximum
						out[i]['Порядок замодов'][j]['Стоимость'] = out[i]['Порядок замодов'][j]['Стоимость'] * stepMaximum
						
						localCosts += out[i]['Порядок замодов'][j]['Стоимость']
						
						
						// Пробуем сохранить порядок
						out[i]['Порядок замодов Порядковый'].push(out[i]['Порядок замодов'][j])
						break;
					}
				}
				
//				console.log(maximum)
			}
			
			out[i]['Стоимость замода суммарная'] += localCosts
		}

		// Расчет 1
//		const getAllCost = () => {
//			var allCost = 0;
//			for (var i in ready) {
//				// перебираем предметы
//				for (var j in costs[i]) {
//
//					// Сколько осталось минус столько, сколько вмодили
//					let read = ready[i][j][0]
//
//					let cost = getCost(i, j, read)
//					allCost += cost
//				}
//
//			}
//			return allCost
//		}
		// Расчет 2
		const getAllCost = () => {
			var allCost = 0;
			for (var i in out) {
				// перебираем предметы
				for (var j in out[i]['Порядок замодов']) {

					// Сколько осталось минус столько, сколько вмодили
					allCost += out[i]['Порядок замодов'][j]['Стоимость']
				}

			}
			return allCost
		}

		resolve([out, getAllCost()]);
	})
}

// Разделяем число по тысячам
export const splitNumber = (t) => {
	if (t !== undefined) {
		if (!isNaN(t)) {
			// Просто число

			return (Math.round(t * 10) / 10).toLocaleString('ru-RU')

		} else if ((t.indexOf('%') !== -1) && (t.indexOf('+') === -1)) {
			// Число с %
			t = t.split('%')

			return (Math.round(t[0] * 10) / 10).toLocaleString('ru-RU') + '%'

		}
	}

	return t
}

export const craftItems = [
["Чертеж", "Обсидиан", "Грубая шкура", "Бревно", "Кварц", "Корунд", "Алмаз", "Железная руда", "Медная руда", "Кость скелета", "Ноготь зомби", "Клык вампира", "Ромашка полевая", "Древесный гриб", "Кувшинка прыгучая", "Паучий хитин", "Жало скорпиона", "Перо грифона", "Рог минотавра", "Прах призрака", "Цена Чертежа", "Цена предмета", "Долговечность", "Комплект", "img", "Где найти"],
	
["Чертеж Щита воина крови", 461, 382, 326, 759, 11, 147, 677, 2156, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120000, 1, "Воин крови", "https://chaosage.ru/images/bloodWarriorShield.gif", "Находится в сундуке Подземелья гномов в комнате 2. Проход к сундуку закрыт барьером, который необходимо отключить. В сундуке можно выбрать 1 конкретный или 2 случайных чертежа оружия (может выпасть 1 чертеж)."],
	
["Чертеж Кловеров магистра", 208, 467, 316,  1267 , 267, 157, 756, 367, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120000, 1, "Магистр", "https://chaosage.ru/images/grandmasterKlovers.gif", "Находится в сундуке Подземелья гномов в комнате 2. Проход к сундуку закрыт барьером, который необходимо отключить. В сундуке можно выбрать 1 конкретный или 2 случайных чертежа оружия (может выпасть 1 чертеж)."],
["Плавка вещей в Кузне", 3, 0, 5, 30, 0, 0, 5, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 'Обычные предметы', "https://chaosage.ru/images/outerworldFragment.png", "Кузница Древнего Пламени (71, 21)"],
["Чертеж Меча быстрого взмаха", 0, 0, 7, 9, 2, 0, 23, 18, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 534, 534, 10, 'Обычные предметы', "https://chaosage.ru/images/drafts/d_swordOfFastStroke.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Клинка застывшего пламени", 0, 0, 8, 14, 0, 0, 42, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 359, 359, 5, 'Обычные предметы', "https://chaosage.ru/images/drafts/d_bladeOfFrozenFlame.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Дубины гиганта", 0, 0, 27, 0, 0, 0, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 219, 219, 10, 'Обычные предметы', "https://chaosage.ru/images/drafts/d_giantClub.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Лука тихой смерти", 5, 3, 15, 93, 23, 12, 20, 20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8923, 8923, 9, 'Обычные предметы', "https://chaosage.ru/images/drafts/d_bowOfSilentDeath.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Кинжального меча", 0, 0, 6, 7, 0, 0, 18, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 159, 159, 11, 'Обычные предметы', "https://chaosage.ru/images/drafts/d_daggerSword.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Пояса Альматеи", 26, 80, 120, 520, 138, 6, 170, 920, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4000, 20000, 1, "Альматея", "https://chaosage.ru/images/drafts/d_almateaBelt.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Кольца магистра (темный)", 183, 362, 315, 1231, 97, 276, 854, 657, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10000, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterRing1.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Осадного арбалета", 0, 5, 46, 15, 2, 0, 11, 239, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1514, 1514, 6, 'Обычные предметы', "https://chaosage.ru/images/drafts/d_siegeCrossbow.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Наручей Альматеи", 16, 13, 977, 105, 130, 8, 140, 190, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4000, 20000, 1, "Альматея", "https://chaosage.ru/images/drafts/d_almateaArms.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Наручей Белиара", 22, 10, 1200, 140, 153, 12, 230, 216, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarArms.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Перчаток Альматеи", 37, 106, 275, 3680, 81, 2, 34, 30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4000, 20000, 1, "Альматея", "https://chaosage.ru/images/drafts/d_almateaGloves.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Перчаток Белиара", 35, 140, 377, 3118, 111, 16, 45, 50, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarGloves.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Пояса Белиара", 30, 86, 250, 872, 150, 8, 540, 1177, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarBelt.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Сапог Альматеи", 40, 196, 340, 252, 66, 30, 412, 530, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4000, 20000, 1, "Альматея", "https://chaosage.ru/images/drafts/d_almateaBoots.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Сапог Белиара", 47, 238, 380, 307, 73, 38, 500, 655, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarBoots.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Закрытого шлема умений", 0, 0, 8, 15, 4, 0, 18, 19, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 879, 879, 10, 0, "https://chaosage.ru/images/drafts/d_closedSkillsHelm.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Диадемы Альматеи", 81, 5, 54, 208, 31, 38, 180, 551, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4000, 20000, 1, "Альматея", "https://chaosage.ru/images/drafts/d_almateaDiadem.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Шлема Белиара", 74, 8, 90, 240, 47, 42, 616, 722, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarHelm.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Амулета огненной капли", 0, 1, 4, 89, 0, 0, 5, 97, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 662, 662, 5, 0, "https://chaosage.ru/images/drafts/d_fireDropAmulet.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Амулета Альматеи", 56, 12, 117, 296, 15, 65, 140, 80, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4000, 20000, 1, "Альматея", "https://chaosage.ru/images/drafts/d_almateaAmulet.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Амулета Белиара", 58, 18, 140, 240, 20, 80, 152, 160, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarAmulet.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Легкого ростового щита", 1, 5, 67, 29, 3, 2, 130, 55, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3270, 3433.5, 4, 'Обычные предметы', "https://chaosage.ru/images/drafts/d_lightTowerShield.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Кольца Альматеи", 29, 0, 24, 370, 80, 45, 85, 250, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4000, 20000, 1, "Альматея", "https://chaosage.ru/images/drafts/d_almateaRing.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Кольца Белиара", 35, 0, 102, 302, 87, 53, 240, 288, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarRing.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Элегантного эльфийского плаща", 0, 33, 29, 0, 0, 0, 10, 21, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 828, 828, 6, 'Обычные предметы', "https://chaosage.ru/images/drafts/d_gracefulElfCloak.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Доспеха Альматеи", 28, 56, 480, 452, 87, 18, 1023, 612, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4000, 20000, 1, "Альматея", "https://chaosage.ru/images/drafts/d_almateaArmor.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Доспеха Белиара", 32, 64, 700, 616, 94, 20, 1413, 850, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarArmor.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Кольца воина крови (сила)", 281, 421, 357, 867, 231, 172, 364, 367, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12000, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorRing1.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Кольца воина крови (тело)", 143, 563, 296, 2491, 298, 169, 544, 1557, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12000, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorRing2.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Кольца воина крови (ловкость)", 134, 427, 429, 1666, 371, 144, 357, 1257, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12000, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorRing3.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Кольца магистра (разрушитель)", 99, 512, 319, 1001, 179, 288, 687, 975, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10000, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterRing2.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Чертеж Кольца магистра (светлый)", 65, 472, 276, 1375, 321, 200, 983, 540, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10000, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterRing3.gif", "Чертежная мастерская Больших комаров (0, 0)"],
["Рецепт Зелья маны", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 64, 0.6, 210, 'Рецепты', "https://chaosage.ru/images/recipes/rManaPotion.gif", "Лавка мага Больших комаров (0, 0)"],
["Зелье лечения", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 64, 0.7, 210, 'Рецепты', "https://chaosage.ru/images/recipes/rHealingPotion.gif", "Лавка мага Больших комаров (0, 0)"],
["Рецепт Эликсира поглощения", 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 1, 0, 0, 0, 0, 0, 0, 150, 7, 47, 'Рецепты', "https://chaosage.ru/images/recipes/rPotionOfAbsorption.gif", "Лавка мага Больших комаров (0, 0)"],
["Рецепт Зелья забытого времени", 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 0, 1, 0, 1, 0, 0, 0, 460, 40, 31, 'Рецепты', "https://chaosage.ru/images/recipes/rPotionOfForgottenTime.gif", "Лавка мага Больших комаров (0, 0)"],
["Рецепт Эликсира неуязвимости", 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0, 2, 2, 0, 2, 0, 0, 0, 0, 568, 50, 28, 'Рецепты', "https://chaosage.ru/images/recipes/rPotionOfInvulnerability.gif", "Лавка мага Больших комаров (0, 0)"],
["Рецепт Эликсира вихря", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 3, 2, 0, 1, 2, 0, 0, 1015, 100, 24, 'Рецепты', "https://chaosage.ru/images/recipes/rPotionOfVortex.gif", "Лавка мага Больших комаров (0, 0)"],
["Чертеж Доспеха воина крови", 311, 469, 279,  1354 , 143, 175, 257, 1478, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorArmor.gif", "Находится на последнем этаже Шпиля Черной башни."],
["Чертеж Шлема воина крови", 361, 472, 311,  689 , 90, 151, 587, 2187, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorHelm.gif", "Награда за выполнение квеста Хозяин песков"],
["Чертеж Сапог воина крови", 100, 516, 246,  1200 , 180, 374, 254, 466, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorBoots.gif", "Находится в сундуке 3 этажа Могильников. Для доступа к сундуку необходимо решить загадку Знаков зодиака в свитке, который можно купить в Окололеске (123, 62)."],
["Чертеж Амулета воина крови", 82, 517, 405,  1010 , 460, 79, 2564, 993, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorAmulet.gif", "Награда за выполнение квеста Исполнитель желаний"],
["Чертеж Перчаток воина крови", 220, 387, 424,  950 , 201, 201, 1517, 542, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorGloves.gif", "Награда за выполнение квеста Забытое соглашение"],
["Чертеж Наручей воина крови", 344, 292, 440, 800, 190,  120 , 851, 1567, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorArms.gif", "Награда за выполнение квеста Проблемы с кобольдами"],
["Чертеж Пояса воина крови", 301, 534, 290,  953 , 167, 213, 675, 245, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorBelt.gif", "Находится в сундуке подземелья Старого замка"],
["Чертеж Молота воина крови", 106, 525, 374,  2164 , 243, 236, 961, 1671, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorMace.gif", "Находится в сундуке Подземелья гномов в комнате 2. Проход к сундуку закрыт барьером, который необходимо отключить. В сундуке можно выбрать 1 конкретный или 2 случайных чертежа оружия (может выпасть 1 чертеж)."],
["Чертеж Топора воина крови", 117, 519, 431,  1427 , 287,   246, 667, 864, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorAxe.gif", "Находится в сундуке Подземелья гномов в комнате 2. Проход к сундуку закрыт барьером, который необходимо отключить. В сундуке можно выбрать 1 конкретный или 2 случайных чертежа оружия (может выпасть 1 чертеж)."],
["Чертеж Копья воина крови", 152, 543, 368,  1267 , 297, 167, 234, 2462, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorSpear.gif", "Находится в сундуке Подземелья гномов в комнате 2. Проход к сундуку закрыт барьером, который необходимо отключить. В сундуке можно выбрать 1 конкретный или 2 случайных чертежа оружия (может выпасть 1 чертеж)."],
["Чертеж Меча воина крови", 111, 472, 216,  649 , 284, 273, 964, 167, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 130000, 1, "Воин крови", "https://chaosage.ru/images/drafts/d_bloodWarriorSword.gif", "Находится в сундуке Подземелья гномов в комнате 2. Проход к сундуку закрыт барьером, который необходимо отключить. В сундуке можно выбрать 1 конкретный или 2 случайных чертежа оружия (может выпасть 1 чертеж)."],
["Чертеж Доспеха магистра", 119, 477, 431,  988 , 289, 221, 354, 231, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterRobe.gif", "Находится на последнем этаже Шпиля Черной башни."],
["Чертеж Шлема магистра", 69, 468, 284,  1729 , 300, 231, 575, 457, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterChasuble.gif", "Награда за выполнение квеста Хозяин песков"],
["Чертеж Сапог магистра", 71, 347, 286,  1276 , 342, 179, 234, 1698, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterBoots.gif", "Находится в сундуке 3 этажа Могильников. Для доступа к сундуку необходимо решить загадку Знаков зодиака в свитке, который можно купить в Окололеске (123, 62)."],
["Чертеж Амулета магистра", 113, 450, 364,  867 , 223, 193, 2112, 567, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterAmulet.gif", "Награда за выполнение квеста Исполнитель желаний"],
["Чертеж Перчаток магистра", 112, 415, 241,  867 , 97, 355, 698, 597, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterGloves.gif", "Награда за выполнение квеста Забытое соглашение"],
["Чертеж Наручей магистра", 124, 472, 415,  948 , 202, 254, 357, 1164, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterArms.gif", "Награда за выполнение квеста Проблемы с кобольдами"],
["Чертеж Пояса магистра", 72, 537, 385,  1349 , 142, 276, 2555, 354, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterBelt.gif", "Находится в сундуке подземелья Старого замка"],
["Чертеж Посоха магистра", 175, 520, 299,  1142 , 197, 179, 697, 1577, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 120000, 1, "Магистр", "https://chaosage.ru/images/drafts/d_grandmasterStaff.gif", "Находится в сундуке Подземелья гномов в комнате 2. Проход к сундуку закрыт барьером, который необходимо отключить. В сундуке можно выбрать 1 конкретный или 2 случайных чертежа оружия (может выпасть 1 чертеж)."],
["Чертеж Меча Белиара", 87, 15, 520, 140, 36,  33 , 1350, 1100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarSword.gif", "Чертежная мастерская Больших комаров (0, 0) после прохождения квеста Забытое знание"],
["Чертеж Топора Белиара", 40, 28, 719, 340, 47,  45 , 1320, 1070, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarAxe.gif", "Чертежная мастерская Больших комаров (0, 0) после прохождения квеста Забытое знание"],
["Чертеж Копья Белиара", 140, 31, 850, 230, 37,  5 , 516, 400, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarSpear.gif", "Чертежная мастерская Больших комаров (0, 0) после прохождения квеста Забытое знание"],
["Чертеж Палицы Белиара", 62, 55, 815, 205, 40,  35 , 1200, 1340, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarMace.gif", "Чертежная мастерская Больших комаров (0, 0) после прохождения квеста Забытое знание"],
["Чертеж Лука Белиара", 78, 170, 850, 172, 38,  38 , 350, 331, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarBow.gif", "Чертежная мастерская Больших комаров (0, 0) после прохождения квеста Забытое знание"],
["Чертеж Арбалета Белиара", 53, 190, 880, 61, 73,  29 , 465, 530, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 25000, 1, "Белиар", "https://chaosage.ru/images/drafts/d_beliarCrossbow.gif", "Чертежная мастерская Больших комаров (0, 0) после прохождения квеста Забытое знание"],
["Чертеж Посоха Альматеи", 128, 12, 570, 24, 5,  7 , 940, 1018, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4000, 20000, 1, "Альматея", "https://chaosage.ru/images/drafts/d_almateaStaff.gif", "Чертежная мастерская Больших комаров (0, 0) после прохождения квеста Забытое знание"],
["Чертеж Солнца", 280*0.9, 500*0.9, 500*0.9, 1100*0.9, 360*0.9,  360*0.9 , 800*0.9, 1100*0.9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, "Комплекты Солнца", "https://chaosage.ru/images/guardOfForestsArmor.png", "Можно выплавить в Кузнице Солнца (-68, -45). Необходимо иметь при себе 10 Монет Солнца, которые можно найти в сокровищницах Храма Солнца"],
["Улучшение предмета Солнца", 280/2*0.9, 500/2*0.9, 500/2*0.9, 1100/2*0.9, 360/2*0.9,  360/2*0.9 , 800/2*0.9, 1100/2*0.9, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, "Комплекты Солнца", "https://chaosage.ru/images/guardOfSunArmor.png", "Можно улучшить в Кузнице Солнца (-68, -45). Необходимо иметь при себе 5 Монет Солнца, которые можно найти в сокровищницах Храма Солнца"],
]
//
//
//"Чертеж", "Обсидиан", "Грубая шкура", "Бревно", "Кварц", "Корунд", "Алмаз", "Железная руда", "Медная руда", "Кость скелета", "Ноготь зомби", "Клык вампира", "Ромашка полевая", "Древесный гриб", "Кувшинка прыгучая", "Паучий хитин", "Жало скорпиона", "Перо грифона", "Рог минотавра", "Прах призрака", "Цена Чертежа", "Цена предмета", "Долговечность", "Комплект", "img"
