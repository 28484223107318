
import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import ym from 'react-yandex-metrika';

import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';
import Container from '@material-ui/core/Container';
import Badge from '@material-ui/core/Badge';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Scrollbars } from 'react-custom-scrollbars';
import Fade from '@material-ui/core/Fade';

import DeleteIcon from '@material-ui/icons/Delete';


import Chip from '@material-ui/core/Chip';

import Select from '@material-ui/core/Select';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'; 

import AdjustIcon from '@material-ui/icons/Adjust';

import Snackbar from '@material-ui/core/Snackbar';


import Pagination from '@material-ui/lab/Pagination';

import AddThingShop from './additional/AddThingShop'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'center',
//    color: theme.palette.text.secondary,
  },
	baseDescription: {
		textAlign: 'left'
  },
	type: {
		textAlign: 'right',
  },
  formControl: {
    margin: theme.spacing(1),
  },formControl_2: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chip: {
    margin: 2,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },formControlSelect: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));


const item_type = {
	"1": "Мечи",
	"2": "Топоры",
	"3": "Копья",
	"4": "Дубины",
	"5": "Луки",
	"6": "Арбалеты",
	"7": "Наручи",
	"8": "Перчатки",
	"9": "Пояса",
	"10": "Ботинки",
	"11": "Шлемы",
	"12": "Амулеты",
	"13": "Щиты",
	"14": "Кольца",
	"15": "Доспехи",
	"16": "Кловеры",
	"21": "Посохи",
	"32": "Руны",
	
}

// Информация о том, поломаны моды или нет
var mods_item_set = {
		'Кловеры': {
			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'ОД на действие': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Парирование': [0, 1],
			'Сопротивление': [0, 1],
			'Реакция': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1],
		},
		'Луки': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'ОД на действие': [0, 1],
			'Очки действий': [0, 1],
			'Реакция': [0, 1],
		},
		'Арбалеты': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'ОД на действие': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Реакция': [0, 1],
		},
		'Дубины': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'ОД на действие': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Реакция': [0, 1],
		},
		'Копья': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'ОД на действие': [0, 1],
			'Очки действий': [0, 1],
			'Парирование': [0, 1],
			'Реакция': [0, 1],
		},
		'Топоры': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'ОД на действие': [0, 1],
			'Очки действий': [0, 1],
			'Здоровье': [0, 1],
			'Парирование': [0, 1],
			'Реакция': [0, 1],
		},
		'Наручи': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Парирование': [0, 1],
			'Сопротивление': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1],

		},
		'Мечи': {

			'Крит. удар': [0, 1],
			'Атака': [0, 1],
			'Защита': [0, 1],
			'ОД на действие': [0, 1],
			'Очки действий': [0, 1],
			'Парирование': [0, 1],
			'Реакция': [0, 1],
		},
		'Перчатки': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Парирование': [0, 1],
			'Сопротивление': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1],
		},
		'Пояса': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Парирование': [0, 1],
			'Сопротивление': [0, 1],
			'Реакция': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1],
		},
		'Ботинки': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Сопротивление': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1]
		},
		'Шлемы': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Сопротивление': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1],

		},
		'Амулеты': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Сопротивление': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1],
		},
		'Кольца': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Парирование': [0, 1],
			'Сопротивление': [0, 1],
			'Реакция': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1],
		},
		'Доспехи': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Парирование': [0, 1],
			'Сопротивление': [0, 1],
			'Реакция': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1],
		},
		'Посохи': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'ОД на действие': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Парирование': [0, 1],
			'Сопротивление': [0, 1],
			'Реакция': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1],
		},
		'Щиты': {

			'Атака': [0, 1],
			'Защита': [0, 1],
			'Броня': [0, 1],
			'Крит. удар': [0, 1],
			'Пробой брони': [0, 1],
			'ОД на действие': [0, 1],
			'Очки действий': [0, 1],
			'Устойчивость': [0, 1],
			'Здоровье': [0, 1],
			'Мана': [0, 1],
			'Парирование': [0, 1],
			'Сопротивление': [0, 1],
			'Реакция': [0, 1],
			'Призыватель': [0, 1],
			'Заклинатель': [0, 1],
			'Разрушитель': [0, 1],
		}
	}

const Shop = () => {
	
	
	
	
	
	const classes = useStyles();
	const theme = useTheme();
	
	const [isLoaded, setIsLoaded] = useState(false);
	
	const [filter_logic, set_filter_logic] = useState(false);
	
	const [filter_my, set_filter_my] = useState(false);
	const [filter_break, set_filter_break] = useState(true);
	

	
	const [auth, set_auth] = useState(false);
	const [login, set_login] = useState('Rewad');
	
	
	const [item_for_sale, set_item_for_sale] = useState([]);
	
	const [items_in_base, set_items_in_base] = useState(0);
	
	const [category, setCategory] = useState('Вещи');


	const [open, setOpen] = useState(false);
	const [setting_sort, set_setting_sort] = useState('powerMods');


	
	// Состояния
	const [filter_chars, set_filter_chars] = useState({
		giveStrength: ['Сила', false],
		giveConstitution: ['Телосложение', false],
		giveDexterity: ['Ловкость', false],
		giveIntelligence: ['Интеллект', false],
		giveEndurance: ['Выносливость', false],
		giveWill: ['Воля', false],
	  });	
	const [complect_item, set_complect_item] = useState({
		"Белиара": false,
		"Альматеи": false,
		"Стальных небес": false,
		"стража Солнца": false,
		"стража лесов": false,
		"стража зари": false,
		"жреца лесов": false,
		"жреца зари": false,
		"жреца Солнца": false,
	  });	
	const [complect_item_count, set_complect_item_count] = useState({
		"Белиара": 0,
		"Альматеи": 0,
		"Стальных небес": 0,
		"стража Солнца": 0,
		"стража лесов": 0,
		"стража зари": 0,
		"жреца лесов": 0,
		"жреца зари": 0,
		"жреца Солнца": 0,
	  });
	
	const [static_complect_item_count, set_static_complect_item_count] = useState({
		"Белиара": 0,
		"Альматеи": 0,
		"Стальных небес": 0,
		"стража Солнца": 0,
		"стража лесов": 0,
		"стража зари": 0,
		"жреца лесов": 0,
		"жреца зари": 0,
		"жреца Солнца": 0,
	  });
	
	const [counts_things, set_counts_things] = useState({
		giveStrength: 0,
		giveConstitution: 0,
		giveDexterity: 0,
		giveIntelligence: 0,
		giveEndurance: 0,
		giveWill: 0,
		HP: 0,
		MP: 0,
		APexp: 0,
		AP: 0,
		attack: 0,
		defence: 0,
		armor: 0,
		critical: 0,
		resistance: 0,
		parry: 0,
		pierce: 0,
		fastness: 0,
		reaction: 0,
		MPreg: 0,
		HPreg: 0,
		summonFactor: 0,
		damageFactor: 0,
		charmFactor: 0,
		
		absorbtion: 0,
		poisonResist: 0,
		shieldBlock: 0,
		dualHit: 0,
		rage: 0,
		vamp_magical: 0,
		vamp_physical: 0,
		summons_ap_abs: 0,
		summons_ap_perc: 0,
		summons_ap_start: 0,
		ap_start: 0,
		rage_gain: 0,
		evasion: 0,
		reflectionResist: 0,
		exorcism: 0,
		manaExpDecrease_elem: 0,
		manaExpDecrease_mind: 0,
		manaExpDecrease_light: 0,
		manaExpDecrease_dark: 0,
		manaExpDecrease_faith: 0,
		manaExpDecrease_alch: 0,
		defiler: 0,
		counterstrike: 0,
		evasionMagic: 0
	  });
		
	const [start_counts_things, set_start_counts_things] = useState({
		giveStrength: 0,
		giveConstitution: 0,
		giveDexterity: 0,
		giveIntelligence: 0,
		giveEndurance: 0,
		giveWill: 0,
		HP: 0,
		MP: 0,
		APexp: 0,
		AP: 0,
		attack: 0,
		defence: 0,
		armor: 0,
		critical: 0,
		resistance: 0,
		parry: 0,
		pierce: 0,
		fastness: 0,
		reaction: 0,
		MPreg: 0,
		HPreg: 0,
		summonFactor: 0,
		damageFactor: 0,
		charmFactor: 0,
		
		absorbtion: 0,
		poisonResist: 0,
		shieldBlock: 0,
		dualHit: 0,
		rage: 0,
		vamp_magical: 0,
		vamp_physical: 0,
		summons_ap_abs: 0,
		summons_ap_perc: 0,
		summons_ap_start: 0,
		ap_start: 0,
		rage_gain: 0,
		evasion: 0,
		reflectionResist: 0,
		exorcism: 0,
		manaExpDecrease_elem: 0,
		manaExpDecrease_mind: 0,
		manaExpDecrease_light: 0,
		manaExpDecrease_dark: 0,
		manaExpDecrease_faith: 0,
		manaExpDecrease_alch: 0,
		defiler: 0,
		counterstrike: 0,
		evasionMagic: 0
	  });
	
	const [filter_items, set_filter_items] = useState({
		'Шлемы': false,
		'Перчатки': false,
		'Доспехи': false,
		'Ботинки': false,
		'Амулеты': false,
		'Кольца': false,
		'Пояса': false,
		'Наручи': false,
		'Посохи': false,
		'Мечи': false,
		'Топоры': false,
		'Копья': false,
		'Щиты': false,
		'Дубины': false,
		'Арбалеты': false,
		'Луки': false,
		'Кловеры': false,
	  });
		
	const [count_items, set_count_items] = useState({
		'Шлемы': 0,
		'Перчатки': 0,
		'Доспехи': 0,
		'Ботинки': 0,
		'Амулеты': 0,
		'Кольца': 0,
		'Пояса': 0,
		'Наручи': 0,
		'Посохи': 0,
		'Мечи': 0,
		'Топоры': 0,
		'Копья': 0,
		'Щиты': 0,
		'Дубины': 0,
		'Арбалеты': 0,
		'Луки': 0,
		'Кловеры': 0,
		'Руны': 0,
	  });
			
	const [static_count_items, set_static_count_items] = useState({
		'Шлемы': 0,
		'Перчатки': 0,
		'Доспехи': 0,
		'Ботинки': 0,
		'Амулеты': 0,
		'Кольца': 0,
		'Пояса': 0,
		'Наручи': 0,
		'Посохи': 0,
		'Мечи': 0,
		'Топоры': 0,
		'Копья': 0,
		'Щиты': 0,
		'Дубины': 0,
		'Арбалеты': 0,
		'Луки': 0,
		'Кловеры': 0,
		'Руны': 0,
	  });
	
	const [filter_params, set_filter_params] = useState({
		HP: ['Здоровье', false],
		MP: ['Мана', false],
		APexp: ['ОД на действие', false],
		AP: ['Очки действий', false],
		attack: ['Атака', false],
		defence: ['Защита', false],
		armor: ['Броня', false],
		critical: ['Крит. удар', false],
		resistance: ['Сопротивление', false],
		parry: ['Парирование', false],
		pierce: ['Пробой брони', false],
		fastness: ['Устойчивость', false],
		reaction: ['Реакция', false],
		MPreg: ['Реген. маны', false],
		HPreg: ['Реген. здоровья', false],
		summonFactor: ['Призыватель', false],
		damageFactor: ['Разрушитель', false],
		charmFactor: ['Заклинатель', false],
		
		absorbtion: ['Поглощение урона', false],
		poisonResist: ['Сопрот. врем. эффектам', false],
		shieldBlock: ['Шанс блока щитом', false],
		dualHit: ['Двойной удар', false],
		rage: ['Стартовая ярость', false],
		vamp_magical: ['Магический вампиризм', false],
		vamp_physical: ['Физический вампиризм', false],
		summons_ap_abs: ['Сниж. ОД. призв. абс.', false],
		summons_ap_perc: ['Сниж. ОД. призв. проц.', false],
		summons_ap_start: ['Стартовое ОД призыва', false],
		ap_start: ['Стартовое ОД', false],
		rage_gain: ['Прирост ярости', false],
		evasion: ['Уклонение', false],
		reflectionResist: ['Снижение урона от отражений', false],
		exorcism: ['Урон по призванным', false],
		manaExpDecrease_elem: ['Сниж. затрат маны на магию Разрушения', false],
		manaExpDecrease_mind: ['Сниж. затрат маны на магию Разума', false],
		manaExpDecrease_light: ['Сниж. затрат маны на магию Света', false],
		manaExpDecrease_dark: ['Сниж. затрат маны на магию Тьмы', false],
		manaExpDecrease_faith: ['Сниж. затрат маны на магию Веры', false],
		manaExpDecrease_alch: ['Сниж. затрат маны на Алхимию', false],
		defiler: ['Осквернитель', false],
		counterstrike: ['Контрудар', false],
		evasionMagic: ['Уклонение от ударной магии', false]
	  });
	
	
	const [item_per_page, set_item_per_page] = useState(8);
	const [page, setPage] = useState(1);
	const [page_count, set_page_count] = useState(2);
	  const handleChangePage = (event, value) => {
		setPage(value);
		  window.scrollTo(0, 0)
	  };
	
	// Выйти из авторизации
	const handle_exit = () => {
		set_auth(false)
		// Очищаем
		localStorage.setItem('userHash', false);
		set_login('Rewad')
	}
	
	useEffect(() => {
		
		// Авторизуем, если есть Hash
		if (localStorage.getItem('userHash')) {
			auth_in_avatarium(localStorage.getItem('userHash'))
		}
		
		fetch('https://psychea.space/avatars/api/shop/getall/', {mode: 'cors'})
		.then(data=>data.text())
		.then(data=>{
			
			// Получили данные, обрабатываем
//			console.log(data);
//			console.log(data.replace(/'/g, '"'));
			data = eval(data);
			
			
			var arr = [];
			
			var complect = {
				"Белиара": 0,
				"Альматеи": 0,
				"Стальных небес": 0,
				"стража Солнца": 0,
				"стража лесов": 0,
				"стража зари": 0,
				"жреца лесов": 0,
				"жреца зари": 0,
				"жреца Солнца": 0,
			}
			var localItemsCount = {
				'Шлемы': 0,
				'Перчатки': 0,
				'Доспехи': 0,
				'Ботинки': 0,
				'Амулеты': 0,
				'Кольца': 0,
				'Пояса': 0,
				'Наручи': 0,
				'Посохи': 0,
				'Мечи': 0,
				'Топоры': 0,
				'Копья': 0,
				'Щиты': 0,
				'Дубины': 0,
				'Арбалеты': 0,
				'Луки': 0,
				'Кловеры': 0,
				'Руны': 0,
			}
			var localFilterChar = {
				giveStrength: 0,
				giveConstitution: 0,
				giveDexterity: 0,
				giveIntelligence: 0,
				giveEndurance: 0,
				giveWill: 0,
				HP: 0,
				MP: 0,
				APexp: 0,
				AP: 0,
				attack: 0,
				defence: 0,
				armor: 0,
				critical: 0,
				resistance: 0,
				parry: 0,
				pierce: 0,
				fastness: 0,
				reaction: 0,
				MPreg: 0,
				HPreg: 0,
				summonFactor: 0,
				damageFactor: 0,
				charmFactor: 0,
		
				absorbtion: 0,
				poisonResist: 0,
				shieldBlock: 0,
				dualHit: 0,
				rage: 0,
				vamp_magical: 0,
				vamp_physical: 0,
				summons_ap_abs: 0,
				summons_ap_perc: 0,
				summons_ap_start: 0,
				ap_start: 0,
				rage_gain: 0,
				evasion: 0,
				reflectionResist: 0,
				exorcism: 0,
				manaExpDecrease_elem: 0,
				manaExpDecrease_mind: 0,
				manaExpDecrease_light: 0,
				manaExpDecrease_dark: 0,
				manaExpDecrease_faith: 0,
				manaExpDecrease_alch: 0,
				defiler: 0,
				counterstrike: 0,
				evasionMagic: 0
			  }
			
			var modsValue = {
				
				'giveStrength': 1,
				'giveConstitution': 1,
				'giveDexterity': 1,
				'giveIntelligence': 1,
				'giveEndurance': 1.4,
				'giveWill': 1.3,
				'APexp': -0.1,
				'HP': 18,
				'MP': 18,
				'AP': 10,
				'minDamage': 7,
				'maxDamage': 7,
				'attack': 9,
				'defence': 9,
				'armor': 5,
				'critical': 3,
				'resistance': 6,
				'parry': 1.5,
				'pierce': 5,
				'fastness': 3,
				'reaction': 2,
				'MPreg': 4,
				'HPreg': 5,
				'damageFactor': 0.012,
				'charmFactor': 0.012,
				'summonFactor': 0.012,
		
				absorbtion: 1,
				poisonResist: 1,
				shieldBlock: 1,
				dualHit: 1,
				rage: 1,
				vamp_magical: 1,
				vamp_physical: 1,
				summons_ap_abs: 1,
				summons_ap_perc: 1,
				summons_ap_start: 1,
				ap_start: 1,
				rage_gain: 1,
				evasion: 1,
				reflectionResist: 1,
				exorcism: 1,
				manaExpDecrease_elem: 1,
				manaExpDecrease_mind: 1,
				manaExpDecrease_light: 1,
				manaExpDecrease_dark: 1,
				manaExpDecrease_faith: 1,
				manaExpDecrease_alch: 1,
				defiler: 1,
				counterstrike: 1,
				evasionMagic: 1
			}
			
			for (let i = 0; i<data.length-0; i++) {
				let x = data[i].replace(/'/g, '"')
				x = JSON.parse(x)
				
				x['typeName'] = item_type[x.type]
				
				// ООпределяем, соманы моды или нет
				var break_mods = false
//				console.log(x)
				if (x['type']*1 !== 32) {
					for (var k in x.params) {
						if ((k !== 'minDamage') && (k !== 'maxDamage')) {
							let l = filter_params[k][0]
							if (mods_item_set[x['typeName']].hasOwnProperty(l) && (x.params[k][1]*1 !== 0)) {
								break_mods = true
								break
							}
						}

					}
				}
				
				x['break_mods'] = break_mods
				
				
				// Считаем силу замодов на вещи
				var powerMods = 0;
				
				
				
				
				
				
				
				// Комплект елиара и альматеи
				if (x.name.indexOf("Белиара") !== -1) {
					complect["Белиара"] += 1
				}
				
				if (x.name.indexOf("Альматеи") !== -1) {
					complect["Альматеи"] += 1
				}
				if (x.name.indexOf("Стальных неб") !== -1) {
					complect["Стальных небес"] += 1
				}
				if (x.name.indexOf("стража Солнца") !== -1) {
					complect["стража Солнца"] += 1
				}
				if (x.name.indexOf("стража лесов") !== -1) {
					complect["стража лесов"] += 1
				}
				if (x.name.indexOf("стража зари") !== -1) {
					complect["стража зари"] += 1
				}
				if (x.name.indexOf("жреца лесов") !== -1) {
					complect["жреца лесов"] += 1
				}
				if (x.name.indexOf("жреца зари") !== -1) {
					complect["жреца зари"] += 1
				}
				if (x.name.indexOf("жреца Солнца") !== -1) {
					complect["жреца Солнца"] += 1
				}
				

				
				localItemsCount[x.typeName] += 1
				
				
				// Проверяем замоды и заполняем количества для state
				for (let k in localFilterChar) {
					if (x.chars.hasOwnProperty(k)) {
						if (x.chars[k][1]*1 !== 0) {
							localFilterChar[k] += 1
						}
						
						// Характеристики, считаем моды
						powerMods += x.chars[k][1]*1/modsValue[k]
						
					}
					if (x.params.hasOwnProperty(k)) {
						if (x.params[k][1]*1 !== 0) {
							localFilterChar[k] += 1
						}
						// Параметры, считаем моды
						powerMods += x.params[k][1]*1/modsValue[k]
						
					}
					
				}
				
				x['powerMods'] = Math.round(powerMods)		
				arr.push(x);
				
				
			};
			
			
//			console.log(arr)
			setIsLoaded(true);
			set_counts_things({...localFilterChar})
			
			set_start_counts_things({...localFilterChar})
			set_static_count_items({...localItemsCount})
			set_count_items({...localItemsCount})
			
			// Альма и бел
			set_complect_item_count({...complect})
			set_static_complect_item_count({...complect})
			
			set_item_for_sale([...arr]);
			set_items_in_base(data.length)
			set_page_count(Math.ceil(data.length/item_per_page))
			
		})
		.catch(e=>console.log(e));
		
	}, []);
	// оздаем текст для карточки
	
	const chars_create = (item) => {
		
		const chars = [{
			name: 'Сила',
			value: item.chars.giveStrength[1]*1
		},{
			name: 'Телосложение',
			value: item.chars.giveConstitution[1]*1
		},{
			name: 'Ловкость',
			value: item.chars.giveDexterity[1]*1
		},{
			name: 'Интеллект',
			value: item.chars.giveIntelligence[1]*1
		},{
			name: 'Выносливость',
			value: item.chars.giveEndurance[1]*1
		},{
			name: 'Воля',
			value: item.chars.giveWill[1]*1
		}]

		
		
		return chars
	}
	
	const create_text = (item) => {
		
		
		const params = [{
			name: 'Здоровье',
			value: item.params.HP[1]*1
		},{
			name: 'Мана',
			value: item.params.MP[1]*1
		},{
			name: 'Очки действий',
			value: item.params.AP[1]*1
		},{
			name: 'Атака',
			value: item.params.attack[1]*1
		},{
			name: 'Защита',
			value: item.params.defence[1]*1
		},{
			name: 'Броня',
			value: item.params.armor[1]*1
		},{
			name: 'Крит. удар',
			value: item.params.critical[1]*1
		},{
			name: 'Сопротивление',
			value: item.params.resistance[1]*1
		},{
			name: 'Парирование',
			value: item.params.parry[1]*1
		},{
			name: 'Пробой брони',
			value: item.params.pierce[1]*1
		},{
			name: 'Устойчивость',
			value: item.params.fastness[1]*1
		},{
			name: 'Реакция',
			value: item.params.reaction[1]*1
		},{
			name: 'Реген. маны',
			value: item.params.MPreg[1]*1
		},{
			name: 'реген. здоровья',
			value: item.params.HPreg[1]*1
		},{
			name: 'Призыватель',
			value: item.params.summonFactor[1]*1
		},{
			name: 'Разрушитель',
			value: item.params.damageFactor[1]*1
		},{
			name: 'Заклинатель',
			value: item.params.charmFactor[1]*1
		}, {
			name: 'Поглощение урона',
			value: item.params.hasOwnProperty('absorbtion') ? item.params.absorbtion[1]*1 : 0
		}, {
			name: 'Сопрот. врем. эффектам',
			value: item.params.hasOwnProperty('poisonResist') ? item.params.poisonResist[1]*1 : 0
		}, {
			name: 'Шанс блока щитом',
			value: item.params.hasOwnProperty('shieldBlock') ? item.params.shieldBlock[1]*1 : 0
		}, {
			name: 'Двойной удар',
			value: item.params.hasOwnProperty('dualHit') ? item.params.dualHit[1]*1 : 0
		}, {
			name: 'Стартовая ярость',
			value: item.params.hasOwnProperty('rage') ? item.params.rage[1]*1 : 0
		}, {
			name: 'Магический вампиризм',
			value: item.params.hasOwnProperty('vamp_magical') ? item.params.vamp_magical[1]*1 : 0
		}, {
			name: 'Физический вампиризм',
			value: item.params.hasOwnProperty('vamp_physical') ? item.params.vamp_physical[1]*1 : 0
		}, {
			name: 'Сниж. ОД. призв. абс.',
			value: item.params.hasOwnProperty('summons_ap_abs') ? item.params.summons_ap_abs[1]*1 : 0
		}, {
			name: 'Сниж. ОД. призв. проц.',
			value: item.params.hasOwnProperty('summons_ap_perc') ? item.params.summons_ap_perc[1]*1 : 0
		}, {
			name: 'Стартовое ОД призыва',
			value: item.params.hasOwnProperty('summons_ap_start') ? item.params.summons_ap_start[1]*1 : 0
		}, {
			name: 'Стартовое ОД',
			value: item.params.hasOwnProperty('ap_start') ? item.params.ap_start[1]*1 : 0
		}, {
			name: 'Прирост ярости',
			value: item.params.hasOwnProperty('rage_gain') ? item.params.rage_gain[1]*1 : 0
		}, {
			name: 'Уклонение',
			value: item.params.hasOwnProperty('evasion') ? item.params.evasion[1]*1 : 0
		}, {
			name: 'Снижение урона от отражений',
			value: item.params.hasOwnProperty('reflectionResist') ? item.params.reflectionResist[1]*1 : 0
		}, {
			name: 'Урон по призванным',
			value: item.params.hasOwnProperty('exorcism') ? item.params.exorcism[1]*1 : 0
		}, {
			name: 'Сниж. затрат маны на магию Разрушения',
			value: item.params.hasOwnProperty('manaExpDecrease_elem') ? item.params.manaExpDecrease_elem[1]*1 : 0
		}, {
			name: 'Сниж. затрат маны на магию Разума',
			value: item.params.hasOwnProperty('manaExpDecrease_mind') ? item.params.manaExpDecrease_mind[1]*1 : 0
		}, {
			name: 'Сниж. затрат маны на магию Света',
			value: item.params.hasOwnProperty('manaExpDecrease_light') ? item.params.manaExpDecrease_light[1]*1 : 0
		}, {
			name: 'Сниж. затрат маны на магию Тьмы',
			value: item.params.hasOwnProperty('manaExpDecrease_dark') ? item.params.manaExpDecrease_dark[1]*1 : 0
		}, {
			name: 'Сниж. затрат маны на магию Веры',
			value: item.params.hasOwnProperty('manaExpDecrease_faith') ? item.params.manaExpDecrease_faith[1]*1 : 0
		}, {
			name: 'Сниж. затрат маны на Алхимию',
			value: item.params.hasOwnProperty('manaExpDecrease_alch') ? item.params.manaExpDecrease_alch[1]*1 : 0
		}, {
			name: 'Осквернитель',
			value: item.params.hasOwnProperty('defiler') ? item.params.defiler[1]*1 : 0
		}, {
			name: 'Контрудар',
			value: item.params.hasOwnProperty('counterstrike') ? item.params.counterstrike[1]*1 : 0
		}, {
			name: 'Уклонение от ударной магии',
			value: item.params.hasOwnProperty('evasionMagic') ? item.params.evasionMagic[1]*1 : 0
		}]
		

		
		
		
		
		
		
		
		
		
		
		return params
	}
	
	const getCountOfChars = (chars) => {
		let c = 0
		
		for (let i in chars) {
			if (chars[i][1]*1 !== 0) {
				c++
			}
		}
		
		return c
	}
	
	// Количество непустых параметров
	const getCountOfParams = (params) => {
		let c = 0
		
		for (let i in params) {
			if (params[i][1]*1 !== 0) {
				c++
			}
		}
		
		return c
	}

	
	
	useEffect(() => {
		var g = item_for_sale
			  .filter((item, i)=>get_filter(item, i))
		
		set_page_count(Math.ceil(g.length/item_per_page))
		
		
		setPage(1)
		
		
			
	}, [filter_chars, filter_params, filter_items, complect_item, filter_break, category, filter_my]);
	
	
	useEffect(() => {
		var g = item_for_sale
			  .filter((item, i)=>get_filter(item, i))

		console.log(g)
		var localItemsCount = {
				'Шлемы': 0,
				'Перчатки': 0,
				'Доспехи': 0,
				'Ботинки': 0,
				'Амулеты': 0,
				'Кольца': 0,
				'Пояса': 0,
				'Наручи': 0,
				'Посохи': 0,
				'Мечи': 0,
				'Топоры': 0,
				'Копья': 0,
				'Щиты': 0,
				'Дубины': 0,
				'Арбалеты': 0,
				'Луки': 0,
				'Кловеры': 0,
			}
		var localFilterChar = {
				giveStrength: 0,
				giveConstitution: 0,
				giveDexterity: 0,
				giveIntelligence: 0,
				giveEndurance: 0,
				giveWill: 0,
				HP: 0,
				MP: 0,
				APexp: 0,
				AP: 0,
				attack: 0,
				defence: 0,
				armor: 0,
				critical: 0,
				resistance: 0,
				parry: 0,
				pierce: 0,
				fastness: 0,
				reaction: 0,
				MPreg: 0,
				HPreg: 0,
				summonFactor: 0,
				damageFactor: 0,
				charmFactor: 0,
		
				absorbtion: 0,
				poisonResist: 0,
				shieldBlock: 0,
				dualHit: 0,
				rage: 0,
				vamp_magical: 0,
				vamp_physical: 0,
				summons_ap_abs: 0,
				summons_ap_perc: 0,
				summons_ap_start: 0,
				ap_start: 0,
				rage_gain: 0,
				evasion: 0,
				reflectionResist: 0,
				exorcism: 0,
				manaExpDecrease_elem: 0,
				manaExpDecrease_mind: 0,
				manaExpDecrease_light: 0,
				manaExpDecrease_dark: 0,
				manaExpDecrease_faith: 0,
				manaExpDecrease_alch: 0,
				defiler: 0,
				counterstrike: 0,
				evasionMagic: 0
			  }
		// Количество вещей возле плашек лейблов
		if ((filter_logic) 
			|| (complect_item['Белиара']) 
			|| (complect_item['Альматеи']) 
			|| (complect_item['стража Солнца']) 
			|| (complect_item['стража лесов']) 
			|| (complect_item['стража зари']) 
			|| (complect_item['жреца лесов']) 
			|| (complect_item['жреца зари']) 
			|| (complect_item['жреца Солнца']) 
			|| (complect_item['Стальных небес']) 
			|| (!filter_break) 
			|| (category !== 'Вещи')) {

			for (let i = 0; i<g.length-0; i++) {

				localItemsCount[g[i].typeName] += 1
				// Проверяем замоды и заполняем количества для state
				for (let k in localFilterChar) {
					if (g[i].chars.hasOwnProperty(k)) {
						if (g[i].chars[k][1]*1 !== 0) {
							localFilterChar[k] += 1
						}
					}
					if (g[i].params.hasOwnProperty(k)) {
						if (g[i].params[k][1]*1 !== 0) {
							localFilterChar[k] += 1
						}
					}

				}


			};
			set_counts_things({...localFilterChar})
			set_count_items({...localItemsCount})
		} else {
			
			
			
			set_counts_things({...start_counts_things})
			set_count_items({...static_count_items})
		}
		
		

			
	}, [filter_chars, filter_params, filter_logic, filter_items, complect_item, filter_break, category]);
	
	
	

	
	
	const handleChange_chars = (event, name) => {
		set_filter_chars({ ...filter_chars, [event.target.name]: [name, event.target.checked] });
	  };

	
	const auth_in_avatarium = (event) => {
		fetch(`https://psychea.space/avatars/api/auth/${event}`)
			.then(d=>d.json())
			.then(d=> {
				console.log(d)
				if (d.status === 2) {
					set_auth(event)
					// Запомним Хеш
					localStorage.setItem('userHash', event);
					set_login(d.login)
				} else {
					console.log(d)
				}
			
			})
	  };
	
	const deleteItem = (itemId, owner) => {
		fetch('https://psychea.space/avatars/api/shop/delete/', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"id_thing": itemId,
						"ownerHash": auth,
					})

				})
				.then(e => {
					return e.json()
					// Устанавливаем имя на 0

				})
				.then(d => {

					// Получили ответ
//					console.log(d)
					if (d.status === 'Вещь успешно удалена из базы!') {
						
						const newList = item_for_sale.filter((item) => {
							
							if ((item.owner === owner) && ((item.id*1) === (1*itemId))) {
//								console.log(item.owner)
//								console.log(item.id)
								return false
							}
							return true
							});
						
    					set_item_for_sale(newList);
						
					}
					

				})
				.catch(e => console.log(e))
	  };

	// Фильтрация по комплектам
	const handleChange_filter_complect = (event, name) => {
		set_complect_item({ ...complect_item, [event.target.name]: event.target.checked });
	  };

	// Фильтрация по вещам
	const handleChange_filter_items = (event, name) => {
		set_filter_items({ ...filter_items, [event.target.name]: event.target.checked });
	  };

	const logicChange = (event) => {
		set_filter_logic(event.target.checked);
		
	  };
	

	const breakChange = (event) => {
		set_filter_break(event.target.checked);
		
	  };
	const filter_myChange = (event) => {
		set_filter_my(event.target.checked);
		
	  };

	const setting_sort_change = (event) => {
		set_setting_sort(event.target.value);
		
	  };

	const handleChange_params = (event, name) => {
		set_filter_params({ ...filter_params, [event.target.name]: [name, event.target.checked] });
		
	  };

	const handleClose = (event) => {
		setOpen(false);
	  };

	const copyToClipboard = (event) => {
		event.target.focus();
		event.target.select();
	   document.execCommand('copy');
		
		ym('reachGoal', 'copy_item_link');
		
    	setOpen(true)
	  };

	
	// Фильтрация
	const get_filter_page = (item, i) => {
		if ((i < (page * item_per_page)) && (i >= ((page - 1) * item_per_page))) {
			return true	
		} 
		return false
	}
	const get_filter = (item, i) => {

		//		Смотри пагинацию
		
		// Если Запрещены сломанные замоды
		if (!filter_break) {

			if (item.break_mods) {
				return false
			}
		}
		
		// Если включен режим моего магазина
		if (filter_my) {

			if (item.owner !== login) {
				return false
			}
		}

			// категория
			var out = {
				cat: false,
				chars: true,
				params: true,
				items: false,
				complect: true
			}


			// Пропускаем только вещи
			if (category === 'Вещи') {
				if ((item.type * 1 < 22) && (item.type * 1 !== 20)) {
					out.cat = true
				} else {
					out.cat = false
				}
			}// Пропускаем только руны
			if (category === 'Руны') {
				if (item.type * 1 === 32) {
					out.cat = true
				} else {
					out.cat = false
				}
			}
		    // Проверяем на комплектность
			var c = false
			for (var i in complect_item) {
				if ((complect_item[i]) 
					&& (item.name.indexOf(i) !== -1) 
					|| (
						(!complect_item["Стальных небес"])
					&& (!complect_item["стража Солнца"])
					&& (!complect_item["стража лесов"])
					&& (!complect_item["стража зари"])
					&& (!complect_item["жреца лесов"])
					&& (!complect_item["жреца зари"])
					&& (!complect_item["жреца Солнца"])
					&& (!complect_item["Альматеи"])
					&& (!complect_item["Белиара"]))
				   ) {
					c = true
				}
			}
			if (c) {
				out.complect = c
			} else {
				return false
			}


			// Сначала характеристики

			// Количество совпадений
			var counter = {
				mods: 0,
				item: 0
			};
			for (var i in filter_chars) {

				// Есть в фильтре и в вещи
				if ((item.chars[i][1] > 0) && (filter_chars[i][1])) {

					counter.mods += 1
					counter.item += 1
				} else if (filter_chars[i][1]) {
					// Если только в фильтре есть
					counter.mods += 1
				}
			}

			for (var i in filter_params) {
				// Есть в фильтре и в вещи
				
				if (item.params.hasOwnProperty(i) && (item.params[i][1] !== 0) && (filter_params[i][1])) {

					counter.mods += 1
					counter.item += 1
				} else if (filter_params[i][1]) {
					// Если только в фильтре есть
					counter.mods += 1
				}
			}

			
			// Смотрим, все ли вещи подключены
			c = false
			for (var i in filter_items) {
				if (filter_items[i]) {
					c = true
					break
				}
			}
		
			if (filter_items[item.typeName]) {
				out.items = true
			} else {
				if (c) {
					out.items = false
				} else {
					out.items = true
				}
			}
			

			// Четкий фильтр
			if (filter_logic) {
				if ((out.cat) && (counter.mods === counter.item) && (out.items)) {
					return true
				} else {
					return false
				}
			} else {
				if ((out.cat) && ((counter.item > 0) || (counter.mods === counter.item)) && (out.items)) {
					return true
				} else {
					return false
				}
			}
	

	}
	
	const handleRadioChange = (event) => {
		setCategory(event.target.value);
	  };
		
	const get_sort = (a, b, setting) => {
		
//		a[setting] = a[setting]*1
//		b[setting] = b[setting]*1
		
		
		var l = setting.split(', ')

		if (l.length === 1) {
			return a[setting]*1 > b[setting]*1 ? -1 : 1
		}
		
	  };
	
    return (
		<Container  maxWidth="xl">
       	<MetaTags>
            <title>Аватариум - магазин</title>
            <meta name="description" content="Магазин Аватариума - система, объединяющая продавцов и покупателей различных игровых предметов и услуг с удобной фильтрацией для поиска наиболее подходящего предложения" />
            <meta property="og:title" content="Аватариум - магазин" />
            <meta property="og:image" content="../images/favicon.ico" />
	  	</MetaTags>
        <div className={classes.root} >
		{!isLoaded && <LinearProgress />}
		  <Grid
			container
			  direction="row"
			  justify="flex-start"
			  alignItems="flex-start" 
			  spacing={1}
			>
			
				<Grid item xs={12} sm={4} md={3} lg={3}>
					<Scrollbars autoHeight autoHeightMin={`calc(100vh - 120px)`}>
						<Grid
							container
							  direction="column"
							  justify="flex-start"
								alignItems="flex-start"
								
							>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Typography variant="subtitle1" gutterBottom style={{fontWeight: 700}}>
									Категории
								  </Typography>
							  <Divider/>
								<FormControl component="fieldset"  style={{marginTop: 10}} className={classes.formControl}  >
									<RadioGroup  aria-label="quiz" name="quiz" value={category} onChange={handleRadioChange}>
									{['Вещи', 'Руны', 'Чертежи и Заклинания', 'Ресурсы', 'Редкие вещи', 'Услуги'].map(d=>{

											return (
												<FormControlLabel  key={d} value={d} control={<Radio disabled={((d === 'Вещи') || (d === 'Руны')) ? false : true} style={{padding: 3}}  size='small' />} label={d} />

											)

										})}
									</RadioGroup>
								  </FormControl>
							</Grid>
							<Grid  item xs={12} sm={12} md={12} lg={12}  style={{marginTop: 30}}>
								<Typography variant="h6" gutterBottom  style={{fontWeight: 700}}>
									<FilterListIcon color='primary' style={{marginRight: 10}}/>Фильтры
								  </Typography>
							</Grid>
							<Grid  item xs={12} sm={12} md={12} lg={12}  style={{marginLeft: 10}}>
							<Tooltip title="Выводит только те предметы, параметры которых одновременно удовлетворяют все состояния фильтра">
								<FormControlLabel
									control={
									  <Switch
										checked={filter_logic}
										onChange={logicChange}
										name="checkedB"
										color="primary"
									  />
									}
									label="Точное соответствие"
								  />
							 </Tooltip>
							</Grid>
							<Grid  item xs={12} sm={12} md={12} lg={12}  style={{marginLeft: 10}}>
							<Tooltip title="Замоды можно считать поломанными, если на вещи стоят модификации, увеличивающие цену доступных в кузнице замодов.">
								<FormControlLabel
									control={
									  <Switch
										checked={filter_break}
										onChange={breakChange}
										name="checkedC"
										color="primary"
									  />
									}
									label="Включая поломанные замоды"
								  />
							 </Tooltip>
							 <Tooltip title="Отображение только ваших вещей">
								<FormControlLabel
									control={
									  <Switch
										checked={filter_my}
										onChange={filter_myChange}
										name="checkedM"
										color="primary"
										disabled={!auth}
									  />
									}
									label="Только мои вещи"
								  />
							 </Tooltip>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: 20}}>
								<Typography variant="subtitle1" gutterBottom style={{fontWeight: 700}}>
									Комплект
								  </Typography>
								<Divider/>
								<FormControl component="fieldset" className={classes.formControl}>
										<FormGroup >
											{['Альматеи', 'Белиара', 'Стальных небес', 
											  'стража Солнца', 'стража лесов', 'стража зари',
											  'жреца лесов', 'жреца зари', 'жреца Солнца']
												.map((item, i)=>{

													return (
														<FormControlLabel 
															disabled={((category === 'Вещи')) ? false : true}
															key={item}
															control={<Checkbox style={{padding: 3}} color='primary' checked={complect_item[item]}  name={item} />}
															label={item.charAt(0).toUpperCase() + item.slice(1) + ' (' + complect_item_count[item] + ')'}
															onChange={(e) => handleChange_filter_complect(e, item)}
														  />

													)

											})}
										</FormGroup>
									  </FormControl>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}  style={{marginTop: 20}}>
								<Typography variant="subtitle1" gutterBottom style={{fontWeight: 700}}>
									Тип предмета
								  </Typography>
								<Divider/>
								<FormControl component="fieldset" className={classes.formControl}>
										<FormGroup >
											{Object.keys(filter_items)
												.map((item, i)=>{

													return (
														<FormControlLabel 
															disabled={count_items[item] === 0 ? true : (category === 'Вещи') ? false : true}
															key={item}
															control={<Checkbox style={{padding: 3}} color='primary' checked={filter_items[item]}  name={item} />}
															label={item + ' (' + count_items[item] + ')'}
															onChange={(e) => handleChange_filter_items(e, item)}
														  />

													)

											})}
										</FormGroup>
									  </FormControl>
							</Grid>
							<Grid  item xs={12} sm={12} md={12} lg={12} style={{marginTop: 20}}>
								<Typography variant="subtitle1" gutterBottom style={{fontWeight: 700}}>
									Характеристики
								  </Typography>
								  <Divider/>
								<FormControl component="fieldset" className={classes.formControl}  style={{marginTop: 10}}>
										<FormGroup >
											{Object.keys(filter_chars)
												.map((i)=>{

												return (
													<FormControlLabel 
													disabled={(counts_things[i] === 0) ? true : ((category === 'Вещи') || (category === 'Руны')) ? false : true}
														key={filter_chars[i][0]}
														control={<Checkbox style={{padding: 3}} color='primary' checked={filter_chars[i][1]}  name={i} />}
														label={filter_chars[i][0] + ' ' + '(' + counts_things[i] + ')'}
														onChange={(e) => handleChange_chars(e, filter_chars[i][0])}
													  />

												)

											})}
										</FormGroup>
									  </FormControl>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop: 20}}>
							<Typography variant="subtitle1" gutterBottom style={{fontWeight: 700}}>
									Параметры
								  </Typography>
								  <Divider/>
							<FormControl component="fieldset" className={classes.formControl} style={{marginTop: 10}}>
								<FormGroup >
									{Object.keys(filter_params)
										.map((i)=>{

										return (
											<FormControlLabel 
												disabled={(counts_things[i] === 0) ? true : ((category === 'Вещи') || (category === 'Руны')) ? false : true}
												key={filter_params[i][0]}
												control={<Checkbox style={{padding: 3}} color='primary' checked={filter_params[i][1]}  name={i} />}
												label={filter_params[i][0] + ' ' + '(' + counts_things[i] + ')'}
												onChange={(e) => handleChange_params(e, filter_params[i][0])}
											  />

										)

									})}
								</FormGroup>
							  </FormControl>
						</Grid>
						</Grid>

					</Scrollbars>

				</Grid>
			
			<Grid item xs={12} sm={8} md={9} lg={9} >
				<Scrollbars 
      				autoHeight autoHeightMin={`calc(100vh - 120px)`} autoHeightMax={`calc(100vh - 120px)`}
       				renderTrackHorizontal={props => <div {...props} 
       				className="track-horizontal" style={{display:"none"}}/>}
        
					renderThumbHorizontal={props => <div {...props} 
					className="thumb-horizontal" style={{display:"none"}}/>} 
										renderView={props => (
											<div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
										)}>
				<Grid
					container
					  direction="row"
					  justify="flex-start"
					  alignItems="flex-start" 
					spacing={1}
				>
				<Grid item  xs={12} sm={12} md={4} lg={4}>
					
					<Typography variant="h6" component="h6" gutterBottom>
						Витрина вещей и услуг Аватаров
					</Typography>
					<Typography variant="body2" gutterBottom>
						Агрегация предложений от игроков
					</Typography>
					<Typography variant="overline" display="block" gutterBottom>
						{'Всего предметов в базе: ' + items_in_base}
					</Typography>
					
				</Grid>
			
				<Grid item  xs={12} sm={12} md={4} lg={4}>
					{!auth && <TextField 
							onChange={(e) => auth_in_avatarium(e.target.value)}
							id="standard-basic"  
							label={'Войти в аккаунт'} 
							placeholder={'Введите hash'} />}
					{auth && <Typography variant="body2" gutterBottom>
								{`Привет, ${login}!`}
							</Typography>}
					{auth && <AddThingShop hash={auth}/>}
					{auth && <Button variant="outlined" color="secondary" onClick={handle_exit}>
								Выйти
							  </Button>}
					
					
				</Grid>
				<Grid item  xs={12} sm={12} md={4} lg={4}>
					
					<FormControl className={classes.formControlSelect}>
						<InputLabel id="demo-simple-select-label">Ранжировать по</InputLabel>
						<Select
						  labelId="demo-simple-select-label"
						  id="demo-simple-select"
						  value={setting_sort}
						  onChange={setting_sort_change}
						>
						  <MenuItem value={'powerMods'}>Mfp (сила замодов)</MenuItem>
						  <MenuItem value={'needLevel'}>Уровень вещи</MenuItem>
						  <MenuItem value={'price'}>Стоимость продажи</MenuItem>
						</Select>
					  </FormControl>
					
				</Grid>
			
				<Grid item  xs={12} sm={12} md={12} lg={12} >
			
					<Grid
					container
					  direction="row"
					  justify="flex-start"
					  alignItems="stretch"
						spacing={1}
						style={{padding: 20}}
					>
					
								{[].concat(item_for_sale)
    								.sort((a, b) => get_sort(a, b, setting_sort))
								  .filter((item, i)=>get_filter(item, i))
								  .filter((item, i)=>get_filter_page(item, i))
								  .map((item, i)=>{

								  return(
								  <Fade in={true} key={item.id} >
									<Grid item xs={12} sm={6} md={4} lg={3} >

									  <Card style={{height: "100%"}}>
										  <CardHeader
											style={{backgroundColor: item.break_mods ?  '#ff1d1d2b' : '#eee8ff' }}
											action={item.owner===login && 
													  <IconButton aria-label="delete" onClick={(e)=>deleteItem(item.id, item.owner)}>
														<DeleteIcon />
													  </IconButton>
													}
											avatar={
										  	<Tooltip title="Цена продажи">
											  <Badge
											  	  badgeContent={item.price} max={999} color="error"
												  anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right',
												  }}
												>
												  <Avatar aria-label="recipe" className={classes.avatar}>
													<img src={`https://chaosage.ru/images/${item.image}`}></img>
												  </Avatar>
											  </Badge>
										    </Tooltip>
											}
											title={item.name}
											subheader={
												  <Tooltip title="Продавец"><b style={{color: 'black'}}>{item.owner}</b></Tooltip>
												  
											  }
										  />
										  <Divider/>
										  <CardContent>
											
											  <Grid
												  container
												  direction="row"
												  justify="space-between"
												  alignItems="flex-start"
												>
												<Grid item className={classes.baseDescription}>
													<Typography variant="caption" display="block"  style={{marginTop: 0, fontSize: 13, fontWeight: 700}}>
														{item['typeName']}
													</Typography>
													{(((item.params.minDamage[1]*1+1*item.params.maxDamage[1])!==0)) && <Typography variant="caption" display="block"  style={{marginTop: 3, fontSize: 13}}>
														Урон
													</Typography>}
													{(item.params.APexp[1]*1!==0) && <Typography variant="caption" display="block"  style={{marginTop: 0, fontSize: 13, fontWeight: 700}}>
														ОД на действие
													</Typography>}
												</Grid>
												<Grid item className={classes.type}>
													<Typography variant="caption" display="block"  style={{marginTop: 0, color: '#a0a0a0', fontSize: 13}}>
														Вещь
													</Typography>
													{(((item.params.minDamage[1]*1+1*item.params.maxDamage[1])!==0)) && <Typography variant="caption" display="block"  style={{marginTop: 3, color: '#64cb5e', fontSize: 13}}>
														{item.params.minDamage[1]}-{item.params.maxDamage[1]}
													</Typography>}
													{(item.params.APexp[1]*1!==0) && <Typography variant="caption" display="block"  style={{fontWeight: 800, marginTop: 0, color: item.params.APexp[1]<0 ?'#64cb5e' : 'red', fontSize: 13}}>
														{item.params.APexp[1]>0?'+':''}{Math.round(10*item.params.APexp[1])/10}
													</Typography>}
												</Grid>
										  </Grid>
												{getCountOfChars(item.chars)!==0 &&
													  <Typography variant="caption" display="block" gutterBottom style={{marginTop: 10, color: '#a0a0a0'}}>
															Характеристики
														</Typography>
												  }
													{chars_create(item)
													  .filter(i=>(i.value!==0))
													  .map(c=>{
													  return(
														  <Typography key = {c.name}variant="caption" display="block" noWrap={true} style={{fontWeight: 700}}>
															<AdjustIcon style={{fontSize: 10}}/> {((c.value>0) ? (' +' +c.value) : (' -' +c.value)) + ' '  + c.name.toLowerCase()}
														  </Typography>
														)
												  })}
												  {getCountOfParams(item.params)!==0 &&
													  <Typography variant="caption" display="block" gutterBottom style={{marginTop: getCountOfChars()!==0 ? 0 : 10, color: '#a0a0a0'}}>
														Параметры
													  </Typography>
												}
												  {create_text(item)
													  .filter(i=>(i.value!==0))
													  .map(c=>{
													  return(
														  <Typography key = {c.name}variant="caption" display="block" noWrap={true} style={{fontWeight: 700}}>
															<AdjustIcon style={{fontSize: 10}}/> {((c.value>0) ? (' +' +c.value) : (' -' +c.value)) + ' '  + c.name.toLowerCase()}
														  </Typography>
														)
												  })}
												  <Grid
														style={{paddingTop: 20}}
													  container
													  direction="row"
													  justify="space-between"
													  alignItems="flex-start"
													>
													<Grid item>
														<Typography variant="caption" style={{color: '#d7a36c'}}>
																Уровень: {item.needLevel}
														</Typography>
													</Grid>
													<Grid item>
														<Typography variant="caption"  style={{color: 'black'}}>
																Вес: {item.weight*1}
														</Typography>
													</Grid>
												</Grid>
												<Grid
												  container
												  direction="row"
												  justify="space-between"
												  alignItems="center"
												>	
													<Grid item>
														<Tooltip title="Modification points - очки модификации вещи, расчитываются исходя из коэффициентов Кузни пламени">
															 <Typography variant="caption">
															 

																{item.powerMods} mfp
															  </Typography>
														</Tooltip>
													</Grid>
													<Grid item>
														<Typography variant="caption">
															Прочность: {item.durabilityCurrent}/{item.durabilityMax}
														</Typography>
													</Grid>
													<Grid item style={{marginTop: 20}}>
														<TextField onClick={copyToClipboard} 

															InputProps={{
																readOnly: true,
															  }} 
															size={'small'} 
															id={"outlined-basic-"+i} 
															label="id вещи" variant="outlined" defaultValue={':item' + item.id + ':'}/>
													</Grid>
											  </Grid>
											
										  </CardContent>
										</Card>

									</Grid>
									</Fade>
								  )
							  })}
							

						</Grid>
						<Pagination count={page_count} 
							color="primary" 
							page={page} 
							onChange={handleChangePage} 
							showFirstButton 
							showLastButton 
							style={{paddingBottom: 20}}/>

					</Grid>

				</Grid>
				</Scrollbars>
			</Grid>
			
			
			
		  </Grid>
		  <Snackbar
				open={open}
				onClose={handleClose}
				autoHideDuration={700}
				message="Успешно скопировано"
			  />
	   </div>
</Container>
    );
}
 
export default Shop;



