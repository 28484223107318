import React, {
	useState, useEffect
} from 'react';

import { HotKeys } from "react-hotkeys";

import ym from 'react-yandex-metrika';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { ButtonBase } from '@material-ui/core';
import MKCell from './additional/MKCell';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ColoredLinearProgress from './additional/ColoredLinearProgress';

import Badge from '@material-ui/core/Badge';

import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ClearIcon from '@material-ui/icons/Clear';

import { Scrollbars } from 'react-custom-scrollbars';

const useStyles = makeStyles(theme => ({
	main_field: {
		width: '100%',
		height: '100%',
		background: `url(${require('../images/mk_back2.PNG')})`,
		backgroundSize: 'cover',
		position: 'absolute',       
		top: 0,
		left: '50%',
		maxWidth: 1920,
		transform: 'translate(-50%, 0)'
		 
	},
	bites: {
		width: 200,
		height: 200,
		position: 'absolute',              
		right: 0,                        
		bottom: 0,                     
	},
	log: {
		top: 70,
		right: -250,
		width: 250,
		position: 'absolute',
		fontSize: 9,
		background: '#0000001c',
		height: '70vh',
		overflow: 'overlay',
		color: 'white',
		[theme.breakpoints.only('md')]: {
		  width: 240,
		  right: -240,
		},
	},
	deals: {
		top: 70,
		left: -250,
		width: 250,
		position: 'absolute',
		fontSize: 9,
		background: '#0000001c',
		height: '70vh',
		overflow: 'overlay',
		[theme.breakpoints.only('md')]: {
		  width: 240,
		  left: -240,
		},
	}, battle_field: {
		position: 'absolute',
		left: '50%',                        
		transform: 'translate(-50%, 0)',
		width: 900,
		height: 540,
		[theme.breakpoints.down('md')]: {                 
			transform: `translate(-${50*1}%, 0) scale(0.7)`,
		},
		[theme.breakpoints.only('md')]: {            
			transform: `translate(-${50*1}%, 0) scale(0.8)`,
		},
		[theme.breakpoints.only('lg')]: {                 
			transform: `translate(-${50*1}%, 0) scale(1)`,
		},
	}, start: {
		position: 'absolute',
		top: '50%',
		left: '50%',                        
		transform: 'translate(-50%, -50%)',
	}, spells: {
		background: `url(${require('../images/spell_book.png')})`,
		position: 'absolute',
		top: -15,
//		left: '50%',                        
		width: 50,
		height: 50,                        
		transform: 'translate(200%, 0)',
		backgroundSize: 'cover',
		boxShadow: 'rgb(192 255 0 / 46%) 0px 0px 3px 2px inset',
		borderRadius: 25
	}, active_bites: {
		color: 'white',
		fontSize: 11
	}, hood: {
		position: 'absolute',
		top: 7,
		left: -250,
		width: 250,
		height: 90,
//		left: '50%',                        
//		transform: 'translate(-50%, 0)',
		[theme.breakpoints.down('md')]: {                 
//			transform: `translate(-${50*1}%, 0) scale(0.7)`,
		},
		[theme.breakpoints.only('md')]: {            
//			transform: `translate(-${50*1}%, 0) scale(0.8)`,
		},
		[theme.breakpoints.only('lg')]: {                 
//			transform: `translate(-${50*1}%, 0) scale(1)`,
		},
	}
}));



const battle_field_const = [
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},
	{
		id: 4,
		x: 2,
		y: 1,
		side: 1,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	},{
		id: 4,
		x: 2,
		y: 1,
		side: 2,
		img: 'https://chaosage.ru/images/human_male_avatar.jpg',
		name: '',
	}
]
const magic_list = [{
		name: 'Экипирована Дубина',
		description: 'Каждый удар повышает од на действие цели на 1, время действия равно од на действие ударившего, складывается. Базовые атаки игнорируют 15% текущей брони цели.',
		img: 'https://chaosage.ru/images/conungHammer.gif',
		koeff: {
			armor_enemy: 0.85
		}
	},
	{
		name: 'Экипирован Меч',
		description: 'Не критические удары мечом наносят повышенный на 13% урон, Базовые атаки снижают собственное од на действие на 1 на 10 секунд, не складывается.',
		img: 'https://chaosage.ru/images/conungSword.gif',
		koeff: {
			damage_non_crit: 1.13
		}
	}, {
		name: 'Экипирован Топор',
		description: 'Критические атаки наносят на 8% больше урона и имеют +7% к шансу вызвать кровотечение. Не критические атаки снижают устойчивость цели на 15% на 20 секунд, не складывается.',
		img: 'https://chaosage.ru/images/conungAxe.gif',
		koeff: {
			damage_crit: 1.08
		}
}, {
		name: 'Прикосновение бога',
		description: 'Повышает атаку, защиту, броню и сопротивляемость цели на 30%, снижает ОД на 3',
		img: 'https://chaosage.ru/images/touchOfGod.gif',
		koeff: {
			atack: 1.3,
			defense: 1.3,
			armor: 1.3,
			resist: 1.3,
			od: -3,
		}
}, {
		name: 'Приказ атаки',
		description: 'На 70% снижаются параметры защита, броня, устойчивость, парирование и сопротивляемость но на 50% увеличиваются параметры урон, атака и критический удар',
		img: 'https://chaosage.ru/images/transformationAttack.gif',
		koeff: {
			atack: 1.5,
			damage: 1.5,
			crit: 1.5,
			defense: 0.3,
			armor: 0.3,
			stability: 0.3,
			parry: 0.3,
			resist: 0.3,
		}
}];


const bites_list = {
	'Простой удар': {
		od: 0
	},
	'Короткий удар': {
		od: -5,
		atack: 0.7,
		damage: 0.7
	},
	'Удар с замахом': {
		od: 3,
		crit: 0,
		atack: 0.6,
		damage: 2
	},
	'Тяжелый удар': {
		od: 3,
		atack: 0.6,
		damage: 1.3
	},
	'Открытый выпад': {
		od: 5,
		atack: 1.2,
		damage: 1.2
	},
	'Пронзающий удар': {
		od: 7
	},
	'Нацеленный выпад': {
		od: 8,
		atack: 1.4
	},
	'Рассекающий удар': {
		od: 6,
		stability: 0.6
	},
	'Оглушающий удар': {
		od: 8
	},
	'Удар щитом': {
		od: 7
	},
	'Прицельный выстрел': {
		od: 3,
		atack: 1.5,
		damage: 0.7
	},
	'Разделенный выстрел': {
		od: 9,
		atack: 0.7,
		damage: 0.7
	},
	'Сквозной выстрел': {
		od: 7
	}
}

var logs = ['Бой начался!']

const MortalKombat = () => {
	
	
	const classes = useStyles();
	
	const [battle_field, set_battle_field] = useState(battle_field_const)
	
	const [enemy_name, set_enemy_name] = useState('Хозяин глубин')
	
	const [started, set_started] = useState(false)
	const [seconds, set_seconds] = useState(0)
	
	const [choise_point, set_choise_point] = useState('Простой удар')
	
	const [show_additional_info, set_show_additional_info] = useState(false)
	
	const [magic_open, set_magic_open] = useState(false)
	
	
	const [enemy_ch_open, set_enemy_ch_open] = useState(false)
	const [gamer_data, set_gamer_data] = useState('{}')
	const [selected_place, set_selected_place] = useState({
		side: -1, selected_id: -1
	})
	
	const [checked_magic, set_checked_magic] = React.useState([]);
	
	// Выбираем активные заклинания на персонаже
	const handle_toggle_magic = (value) => () => {
		const currentIndex = checked_magic.indexOf(value);
		const newChecked = [...checked_magic];

		if (currentIndex === -1) {
		  newChecked.push(value);
		} else {
		  newChecked.splice(currentIndex, 1);
		}

		set_checked_magic(newChecked);
	  };

	const keyMap = {
	  SIMPLE_ATTACK: "1",
	  OPEN_ATTACK: "2",
	  DD_ATTACK: "3",
	  HIGH_ATTACK: "4",
	  LOW_ATTACK: "5",
	  CRIT_ATTACK: "6",
	  START: "z",
	  STOP: "x",
	  CTRL_DOWN: { sequence: "ctrl", action: "keydown" },
	  CTRL_UP: { sequence: "ctrl", action: "keyup" }
	};

	const handlers = {
		SIMPLE_ATTACK: event => set_choise_point("Простой удар"),
		OPEN_ATTACK: event => set_choise_point("Открытый выпад"),
		DD_ATTACK: event => set_choise_point("Удар с замахом"),
		HIGH_ATTACK: event => set_choise_point("Тяжелый удар"),
		LOW_ATTACK: event => set_choise_point("Короткий удар"),
		CRIT_ATTACK: event => set_choise_point("Рассекающий удар"),
		START: event => handle_starter(true),
		STOP: event => handle_starter(false),
		CTRL_DOWN: event => set_show_additional_info(true),
		CTRL_UP: event => set_show_additional_info(false),
	};

	const handle_starter = (t) => {
		ym('reachGoal', 'copy_item_link');
		set_started(t)
	}
	
	
	const randomInteger = (min, max) => {
	  let rand = min - 0.5 + Math.random() * (max - min + 1);
	  return Math.round(rand);
	}
	
	
	// Вернет id игрока в массиве целей
	const get_gamer_id = () => {
		for (let i in battle_field) {
			if (battle_field[i].type === 'gamer') {
				return i
			}
		}
		return -1
	}
	
	
	// Возвращает вероятность попадания и т.д.
	const get_probability = (atack, defense, type, additional_value) => {

		var div = 1
		if (defense > 0) {
			div = atack / defense
		} else {
			div = 5
		}


		let p = 50 + 30 * Math.log(div)

		if (type === 'Атака') {
			if (p > 97) {
				p = 97
			}
			if (p < 3) {
				p = 3
			}
		}

		if (type === 'Крит') {

			if (p > 100) {
				p = 100
			} 
			if (p< 0) {
				p = 0
			}
		}

		if (type === 'Парирование') {
			
			p = 100 - p
			
			if (p > defense) {
				p = defense
			}
			
			if (p > additional_value) {
				p = additional_value
			}
			
			if (p < 0) {
				return 0
			}
		}

		return p


	}
	
	
	const log = (text) => {
		if (false) {
			console.log(text)
		}
	}
	
	
	const calculate_damage_for_tooltips = (atacker_id, defenser_id, bite_name) => {

		var a_k_ = get_koeff_from_active_effects(atacker_id, defenser_id)
		
		let damage = []

		if (defenser_id === -1) {
			return '-'
		}
		
		if (atacker_id === -1) {
			return '-'
		}
		
		let i = atacker_id
		let gamer_id = defenser_id
//
//		console.log(battle_field[gamer_id])
//		console.log(battle_field[i])
//		console.log(i)
		
		if (battle_field[gamer_id].params === undefined) {
			return '-'
		}
		
		if (battle_field[i].params === undefined) {
			return '-'
		}
		
		
		// Двойная атака
		let p_double = battle_field[i].params.dualHit

		let dd = 1

		
			
		let damage_k = bites_list[bite_name].damage || 1
		let attack_k = bites_list[bite_name].atack || 1
		let crit_k = (bites_list[bite_name].crit === undefined) ? 1 : bites_list[bite_name].crit
		
		// СНИЖЕНИЕ стабилы
		let stability_k = bites_list[bite_name].stability || 1


		// Формируем урон
		damage[0] = battle_field[i].params.minDamage*damage_k*a_k_.damage*a_k_.damage_non_crit
		damage[1] = battle_field[i].params.maxDamage*damage_k*a_k_.damage*a_k_.damage_non_crit



		// Смотри атаку - попали или нет
		let p_attack = get_probability(battle_field[i].params.attack*attack_k*a_k_.atack, battle_field[gamer_id].params.defence, 'Атака')

		// Теперь смотрим броню
		let armor_absorbtion = battle_field[gamer_id].params.armor*a_k_.armor_enemy - battle_field[i].params.pierce
		if (armor_absorbtion < 0) {
			armor_absorbtion = 0
		}

		armor_absorbtion = Math.pow(armor_absorbtion, 0.49)

		if (armor_absorbtion > 90) {
			armor_absorbtion = 90
		}
		
		damage[0] = damage[0] - damage[0] * (armor_absorbtion / 100)
		damage[1] = damage[1] - damage[1] * (armor_absorbtion / 100)

		damage[0] = damage[0] - damage[0] * (battle_field[gamer_id].params.absorbtion / 100)
		damage[1] = damage[1] - damage[1] * (battle_field[gamer_id].params.absorbtion / 100)
		
		if (battle_field[i].params.criticalDamage*1 > 10) {
			battle_field[i].params.criticalDamage /= 100
		}
		
			// Крит или не крит
		let p_crit = get_probability(battle_field[i].params.critical*a_k_.crit*crit_k, battle_field[gamer_id].params.fastness*stability_k, 'Крит')

		let damage_crit = [damage[0]*battle_field[i].params.criticalDamage*a_k_.damage_crit/a_k_.damage_non_crit, damage[1]*battle_field[i].params.criticalDamage*a_k_.damage_crit/a_k_.damage_non_crit]
		
			// Парирование
		let p_parry = get_probability(battle_field[i].params.reaction, battle_field[gamer_id].params.parry, 'Парирование', battle_field[gamer_id].params.parryMax || 50)


		let shield_chanse_ = battle_field[gamer_id].params.shieldBlock - 20
		
		if (shield_chanse_<0) {
			shield_chanse_ = 0
		}

		let return_text = <div>
			{bite_name}: <br></br>
			Урон: {Math.round(damage[0])}-{Math.round(damage[1])}<br></br>
			<span style={{color: '#e8ae40'}}>Крит. шанс: {Math.floor(p_crit)}%, крит. урон: x{Math.round(battle_field[i].params.criticalDamage*100)/100} ({Math.round(damage_crit[0])}-{Math.round(damage_crit[1])})</span><br></br>
			Шанс попасть: {Math.round(p_attack)}%<br></br>
			Шанс парирования: {Math.round(p_parry)}%<br></br>
			Шанс отбития щитом: {Math.round(shield_chanse_)}%<br></br>
			
			<div style={{display: show_additional_info ? 'none' : 'inherit', textAlign: 'right'}}>
				<br></br>
				<em>Зажмите ctrl для доп. информации</em>
			</div>
			<div style={{display: show_additional_info ? 'inherit' : 'none'}}>
				<br></br>
				Броня поглощает: {Math.round(armor_absorbtion)}%<br></br>
				Поглощение урона цели: {battle_field[gamer_id].params.absorbtion}%<br></br>
				Общий шанс нанести урон: {Math.round((p_attack)*(1-(p_parry*(1-p_crit/100))/100)*(1-shield_chanse_*(1-p_crit/100)/100))}%<br></br><br></br>
				
				Вампиризм: {Math.round(0)} ед.<br></br>
				Отраж. урона: {Math.round(0)} ед.<br></br>
				Ожог: {Math.round(0)} ед.
			</div>
			
			
			
			
		</div>

		return return_text
	};
	
	// Наносим удар по цели
	const do_damage_in_mili = (atacker_id, defenser_id, bite_name) => {
		
		var a_k_ = get_koeff_from_active_effects(atacker_id, defenser_id)
		
		
		var return_text = `${seconds} `
		
		let damage = 0

		let i = atacker_id
		let gamer_id = defenser_id

		// Двойная атака
		let p_double = battle_field[i].params.dualHit
		let p_double_random = randomInteger(0, 99)

		let dd = 1

		if (p_double > p_double_random) {
			log('Двойная атака!')

			dd = 2
		}

		for (let i_ = 0; i_ < dd; i_++) {
			
			if (battle_field[gamer_id].hp_current < 0) {
				continue	
			}
			
			let damage_k = bites_list[bite_name].damage || 1
			let attack_k = bites_list[bite_name].atack || 1
			let crit_k = (bites_list[bite_name].crit === undefined) ? 1 : bites_list[bite_name].crit
		
			// СНИЖЕНИЕ стабилы
			let stability_k = bites_list[bite_name].stability || 1
			
			// Формируем урон
			damage = randomInteger(battle_field[i].params.minDamage, battle_field[i].params.maxDamage)*damage_k*a_k_.damage
			log(`Чистый урон: ${Math.round(damage)}`)

			

			// Смотри атаку - попали или нет
			let p = get_probability(battle_field[i].params.attack*attack_k*a_k_.atack, battle_field[gamer_id].params.defence*a_k_.defense, 'Атака')
			let p_random = randomInteger(0, 99)

			// Если попали
			if (p > p_random) {
				log(`Попали с шансами: ${p}/${p_random}`)

				return_text += `${battle_field[i].name} : ${bite_name} >> ${battle_field[gamer_id].name}, попал(${Math.round(p)}/${p_random}), `
				
				// Теперь смотрим броню
				let armor_absorbtion = battle_field[gamer_id].params.armor*a_k_.armor*a_k_.armor_enemy - battle_field[i].params.pierce
				if (armor_absorbtion < 0) {
					armor_absorbtion = 0
				}

				armor_absorbtion = Math.pow(armor_absorbtion, 0.49)

				if (armor_absorbtion > 90) {
					armor_absorbtion = 90
				}

				damage = damage - damage * (armor_absorbtion / 100)

				log(`Урон после брони: ${Math.round(damage)} (поглощено броней ${armor_absorbtion})`)

				// Поглот урона
				damage = damage - damage * (battle_field[gamer_id].params.absorbtion / 100)
				log(`Урон после поглота: ${Math.round(damage)} (поглощено ${battle_field[gamer_id].params.absorbtion})`)

				// Крит или не крит
				let p_crit = get_probability(battle_field[i].params.critical*a_k_.crit*crit_k, battle_field[gamer_id].params.fastness*a_k_.stability*stability_k, 'Крит')
				
				let p_crit_random = randomInteger(0, 99)

				let crit = false

				if (p_crit > p_crit_random) {
					crit = true
				}
				
				if (battle_field[i].params.criticalDamage*1 > 10) {
					battle_field[i].params.criticalDamage /= 100
				}
				

				if (crit) {
					damage *= battle_field[i].params.criticalDamage*a_k_.damage_crit
				} else {
					damage *= a_k_.damage_non_crit
				}
				

				log(`Урон после крита: ${Math.round(damage)}, Крит: ${crit} с шансом ${p_crit}`)


				// Парирование
				let p_parry = get_probability(battle_field[i].params.reaction, battle_field[gamer_id].params.parry*a_k_.parry, 'Парирование', battle_field[gamer_id].params.parryMax || 50)
				let p_parry_random = randomInteger(0, 99)

				// Было ли снижение урона в 2 раза от парирования
				let double_div = false

				// Атака была парирована
				if (p_parry > p_parry_random) {
					
					double_div = true
					
					if (crit) {
						// Есть крит, снижаем урон в 2 раза
						damage = damage / 2
						log(`Атака в парир: ${Math.round(damage)}, шанс парира: ${p_parry}`)

						
						
						return_text += `нанесен урон: ${Math.round(damage)}(x${battle_field[i].params.criticalDamage}) атака парирована`

					} else {
						damage = 0
						log(`Атака парирована: ${p_parry}`)
						
						return_text += ' атака парирована'
					}
				} else {
					log('Атака не парирована')
				}


				// Отбитие щитом

				if (!double_div) {
					let shieldBlock_random = randomInteger(0, 99)
					
					let shield_chanse_ = battle_field[gamer_id].params.shieldBlock - 20
					
					// Атака была отбита щитом
					if (shield_chanse_ > shieldBlock_random) {
						
						double_div = true

						if (crit) {
							// Есть крит, снижаем урон в 2 раза
							damage = damage / 2
							log(`Атака в щит: ${Math.round(damage)}, шанс щита: ${battle_field[gamer_id].params.shieldBlock}`)

							return_text += `нанесен урон: ${Math.round(damage)}(x${battle_field[i].params.criticalDamage}) атака отбита щитом`
						} else if (!crit) {
							damage = 0
							log(`Атака отбита щитом: ${p_parry}`)

							return_text += ' атака отбита щитом'
							
							
						}
					} else {
						log('Атака не отбита щитом')
					}
				}
				
				// Если не спарировали и не отбили щитом
				if (!double_div) {
					if (crit) {
						return_text += `нанесен урон: ${Math.round(damage)}(x${battle_field[i].params.criticalDamage})`
					} else if (!crit) {
						return_text += `нанесен урон: ${Math.round(damage)} `
					}
				}
				

			} else {
				// Если промахнулись
				log(`Промахнулись с шансами ${p}/${p_random}`)
				damage = 0
				
				return_text += ` ${battle_field[i].name} : ${bite_name} >> ${battle_field[gamer_id].name}, промах(${Math.round(p)}/${p_random})`
				
				
			}

			// Знаем урон, который нанесли по игроку
			// Наносим его
			
			if (damage > 0) {
				battle_field[gamer_id].hp_current -= damage
			
				let hp_1 = Math.round(battle_field[gamer_id].hp_current)

				return_text += ` [${hp_1}/${battle_field[gamer_id].hp_max}] `
			}
			
			
			
			if (battle_field[gamer_id].hp_current < 0) {
				
				return_text += ` ${battle_field[gamer_id].name} выбывает из боя`
				
				battle_field[gamer_id].name = ''
				battle_field[gamer_id].id = -1
			}
			
		}

		return return_text+'\n'
	}
	
	const do_battle_step = () => {
		
		
		for (let i in battle_field) {
			
			// Для живых игроков
			if (battle_field[i].name !== '') {
				
				// Сначала мы начисляем ОД всем игрокам на поле боя
				battle_field[i].od += 1
				
				if (battle_field[i].type === 'bot') {
					
					let need_od_ = battle_field[i].params.baseAP
					if (need_od_ < 5) {
						need_od_ = 5
					}
					
					// Это бот
					if (need_od_ <= battle_field[i].od) {
						
						let gamer_id = get_gamer_id()
						let text = do_damage_in_mili(i, gamer_id, 'Простой удар')
						
						logs.unshift(text)
						
						// Бот делает удар по игроку
						battle_field[i].od -= battle_field[i].params.baseAP
					}
					
				} else {
					// Это игрок
					// Берем первое действие в списке
					let l_ = battle_field[i].do_chain.length
					if (l_ === 0) {
						return
					}
					
					// Удаляет первый элемент
					let do_ = battle_field[i].do_chain.splice(0, 1)[0]
					
					let need_od_ = battle_field[i].params.baseAP*1
					
					if (do_[0] === 'Короткий удар') {
						need_od_ *= 0.7
					} else {
						need_od_ += do_[2]
					}
					
					// Временные эффекты (снижение/увеличение ОД)
					need_od_ += get_koeff_from_active_effects(i, do_[1]).od
					
					if (need_od_ < 5) {
						need_od_ = 5
					}
					
					if (need_od_ <= battle_field[i].od) {
						
						let text = do_damage_in_mili(i, do_[1], do_[0])
						logs.unshift(text)
						// Бот делает удар по игроку
						battle_field[i].od -= need_od_
					} else {
						battle_field[i].do_chain.unshift(do_)
					}
				}
				
			}
		}
		
	}
	
	
	const set_corretcs_hp_mp = () => {
		for (let i in battle_field) {
			if (battle_field[i].name === '') {
				continue
			}
			battle_field[i].hp_max = battle_field[i].params.maxHP
			battle_field[i].mp_max = battle_field[i].params.maxMP
			
			battle_field[i].hp_current = battle_field[i].params.maxHP
			battle_field[i].mp_current = battle_field[i].params.maxMP
		}
	}
	
	
	// Добавляет в список действий игрока действие
	const add_point = (enemy_id, side) => {
		
		// Добавляем действие только если бой идет, иначе открываем диалог
		
		if (started) {
			let gamer_id = get_gamer_id()
		
		
			if (enemy_id === -1) {
				console.log('Цель уже умерла')
				return false
			}

			if (enemy_id === gamer_id) {
				console.log('Нелья ударить самого себя')
				return false
			}
			if (choise_point === '') {
				console.log('Задача не выбрана')
				return false
			}


			if (battle_field[gamer_id] === undefined) {
				console.log('Пустая клетка')
				return false
			}


			let a_ = [choise_point, enemy_id, bites_list[choise_point].od]

			battle_field[gamer_id].do_chain.push(a_)

		} else {
			console.log('Откроем меню ввода нового врага')
			console.log(enemy_id, side)
			
			let selected_id = enemy_id
			
			set_selected_place({
				side, selected_id
			})
			handle_enemy_ch_open(true)
		}
		
	}
	const handle_magic_open = () => {
		set_magic_open(true);
	};

	const handle_magic_close = (value) => {
		set_magic_open(false);
	};
	
	
	const handle_enemy_ch_open = (value) => {
		set_enemy_ch_open(true);
	};
	
	const handle_enemy_ch_close = (value) => {
		set_enemy_ch_open(false);
	};
	
	
	// Обновит параметры игрока под активные эффекты
	const get_koeff_from_active_effects = (a_id, d_id) => {
		
		/*
			Если атакующее id a_id = игрок, то апаем ему атакующие параметры
			Если защищающееся id d_id = игрок, то апаем ему защищающие параметры
			
			// Если от, то вернем 1
		
		*/
		let gamer_id = get_gamer_id()
		
		let koeffs = {
			atack: 1,
			damage: 1,
			crit: 1,
			defense: 1,
			armor: 1,
			stability: 1,
			parry: 1,
			resist: 1,
			od: 0,
			damage_non_crit: 1,
			damage_crit: 1,
			armor_enemy: 1,
		}
		
		for (let i in magic_list) {
			
			let currentIndex = checked_magic.indexOf(i*1);
			
//			console.log(i)
//			console.log(magic_list[i])
//			console.log(currentIndex)
//			console.log(checked_magic)
			
			if (currentIndex !== -1) {
				
				
				// Такое заклинание есть
				koeffs.atack *= magic_list[i].koeff.atack || 1
				koeffs.damage *= magic_list[i].koeff.damage || 1
				koeffs.crit *= magic_list[i].koeff.crit || 1
				koeffs.defense *= magic_list[i].koeff.defense || 1
				koeffs.armor *= magic_list[i].koeff.armor || 1
				koeffs.stability *= magic_list[i].koeff.stability || 1
				koeffs.parry *= magic_list[i].koeff.parry || 1
				koeffs.resist *=magic_list[i].koeff.resist || 1
					
				koeffs.od += magic_list[i].koeff.od || 0
				
				koeffs.damage_non_crit = magic_list[i].koeff.damage_non_crit || 1
				koeffs.damage_crit = magic_list[i].koeff.damage_crit || 1
				koeffs.armor_enemy = magic_list[i].koeff.armor_enemy || 1
			}
		}
		
		koeffs.atack = ((a_id*1) === (gamer_id*1)) ? koeffs.atack : 1
		koeffs.damage = ((a_id*1) === (gamer_id*1)) ? koeffs.damage : 1
		koeffs.crit = ((a_id*1) === (gamer_id*1)) ? koeffs.crit : 1
		
		koeffs.defense = ((d_id*1) === (gamer_id*1)) ? koeffs.defense : 1
		koeffs.armor = ((d_id*1) === (gamer_id*1)) ? koeffs.armor : 1
		koeffs.stability = ((d_id*1) === (gamer_id*1)) ? koeffs.stability : 1
		koeffs.stability = ((d_id*1) === (gamer_id*1)) ? koeffs.stability : 1
		koeffs.parry = ((d_id*1) === (gamer_id*1)) ? koeffs.parry : 1
		koeffs.resist = ((d_id*1) === (gamer_id*1)) ? koeffs.resist : 1
		
		koeffs.od = ((a_id*1) === (gamer_id*1)) ? koeffs.od : 0
		
		koeffs.damage_non_crit = ((a_id*1) === (gamer_id*1)) ? koeffs.damage_non_crit : 1
		koeffs.damage_crit = ((a_id*1) === (gamer_id*1)) ? koeffs.damage_crit : 1
		koeffs.armor_enemy = ((a_id*1) === (gamer_id*1)) ? koeffs.armor_enemy : 1
		
		
		return koeffs
		
	}
	
	const set_gamer_data_handle = (data) => {
		// Устанавливаем значение параметров на конкретную клетку

		if ((selected_place.side*1) === 2) {
			

			let d = JSON.parse(data)
			battle_field[selected_place.selected_id+30] = {
				side: 2,
				img: 'https://chaosage.ru/images/human_male_avatar.jpg',
				name: 'Игрок',
				params: d,
				do_chain: [],
				type: 'gamer',
				od: 0,
				hp_max: d.maxHP,
				mp_max: d.maxMP,
				hp_current: d.maxHP,
				mp_current: d.maxMP,
			}
			
		}
		
	}
	
	
	const delete_deal = (id) => {
		battle_field[get_gamer_id()].do_chain.splice(id, 1)
	}
	
	const [enemy_level, set_enemy_level] = useState(77)
	const [new_enemy, set_new_enemy] = useState(0)
	const [tolmin_enemy_list, set_tolmin_enemy_list] = useState([])
	
	
	useEffect(() => {
		
		const timeOutId = setTimeout(() => {

			
			for (let i in tolmin_enemy_list) {
					
					// Нашли противника
					if (tolmin_enemy_list[i].name === enemy_name) {

						var data_enemy_params = JSON.parse(tolmin_enemy_list[i].data)
						console.log(data_enemy_params)
					}
			}
			
			if (tolmin_enemy_list.length > 0) {
				fetch(`https://chaosage.ru/sAPI2.php?request=bot_params&bot_id=${data_enemy_params['id']}&bot_level=${enemy_level}`)
					.then(d=>d.json())
					.then(d=>{

						// Добавляем бота на клетку
						battle_field[selected_place.selected_id] = {
							side: 1,
							img: 'https://chaosage.ru/images/human_male_avatar.jpg',
							name: `${enemy_name} (${enemy_level})`,
							params: d,
							type: 'bot',
							od: 0,
							hp_max: d.maxHP,
							mp_max: d.maxMP,
							hp_current: d.maxHP,
							mp_current: d.maxMP,
							
						}
						battle_field[selected_place.selected_id].params.criticalDamage /= 100
						handle_enemy_ch_close()
					})
					.catch(e => console.log(e))
			}
			

			
		}, 150);
		
		
		return () => clearTimeout(timeOutId);
		
		

	}, [new_enemy]);
	
	
	
	useEffect(() => {

		fetch('https://psychea.space/avatars/api/gettolminenemy')
				.then(e => {
					return e.json()
					// Устанавливаем имя на 0

				}).then(d=>{
//					console.log(d)
//					setIsLoaded(true)
					set_tolmin_enemy_list(d.data)
				})
				.catch(e => {
//					setIsLoaded(true)
					console.log(e)
				})

	}, []);
	
	
	
	useEffect(() => {
	  const intervalId = setInterval(() => { 
		  
		  // Установим для каждого игрока нужное здоровье и маны
//		  if (seconds === 0) {
//			  set_corretcs_hp_mp()
//		  }
		  if (started) {
			  do_battle_step()
		  } else {
			  console.log('Бой остановлен')
		  }
		  
		  set_seconds(seconds+1)
	  }, 500)

	  return () => clearInterval(intervalId);

	}, [started, seconds])
	
    return (
		<HotKeys keyMap={keyMap} handlers={handlers}>
			<div className={classes.main_field}>
				<div className={classes.battle_field}>
					<div>
						{battle_field.filter(c=>{
							if (c.side === 1) {
								return true
							}
							return false
						}).map((cell, i)=> {
							
							cell.id = i
							
							let d = calculate_damage_for_tooltips(get_gamer_id(), cell.id, choise_point)
							return (<MKCell tooltip_text={d} key={cell.name+i} content={cell} callback={add_point}></MKCell>)
						})}
					</div>
					<div style={{marginTop: 30}}>
						{battle_field.filter(c=>{
							if (c.side === 2) {
								return true
							}
							return false
						}).map((cell, i)=> {
							
							cell.id = i
							
							let d = calculate_damage_for_tooltips(get_gamer_id(), cell.id+30, choise_point)
							return (<MKCell tooltip_text={d} key={cell.name+i} content={cell} callback={add_point}></MKCell>)
						})}
					</div>
					<div className={classes.hood}>
						{(get_gamer_id() !== -1) && <>
							<div style={{textAlign: 'center', fontSize: '19px', color: 'white'}}>{choise_point}</div>
							<div style={{textAlign: 'center', fontSize: '15px', color: '#bebebe'}}>Накоплено ОД: {Math.round(battle_field[get_gamer_id()].od)}</div>
							<ColoredLinearProgress style={{height: 13}} variant="determinate"  value={100*battle_field[get_gamer_id()].od/200} />
						</>}

					</div>
					<div className={classes.deals}>
						
						<Scrollbars style={{ height: '70vh' }}>
						{(get_gamer_id() !== -1) && <List dense>
							{battle_field[get_gamer_id()].do_chain.map((text, i) => {
								  return(
									  <ListItem className={classes.active_bites} onClick={()=>delete_deal(i)} button key={text[0]+i}>
										<ListItemText primary={text[0]} secondary={
												  <span style={{color: '#b7b7b7', fontSize: 13}}>По цели {battle_field[text[1]].name}, ОД: {Math.round(text[2]+battle_field[get_gamer_id()].params.baseAP) < 5 ? 5 : Math.round(text[2]+battle_field[get_gamer_id()].params.baseAP)}</span>
											  } />
										<ListItemSecondaryAction>
											<ButtonBase>
											  <ClearIcon style={{color: 'white'}} onClick={()=>delete_deal(i)} />
											 </ButtonBase>
										</ListItemSecondaryAction>
									  </ListItem>
								  )
							  })}

						  </List>}
						 
						</Scrollbars>
					</div>
					
					<div className={classes.log}>

						<Scrollbars style={{ height: '70vh' }}>
						{logs.map((text, i) => {

							return <span key={text[0]+text[1]+text[2]+i}>{text}<br/></span>

						})}
						</Scrollbars>
					</div>
				</div>
				
				
				
				<div className={classes.bites}>
					<div className={classes.start}>
						<Tooltip title={started ? `Остановить бой (горячая клавиша "${keyMap.STOP}")` : `Начать бой (горячая клавиша "${keyMap.START}")`}>
							<ButtonBase onClick={() => handle_starter(!started)}>
								{!started && <PlayCircleOutlineIcon style={{ fontSize: 50, color: '#ffd8b6' }}/>}
								{started && <PauseCircleFilledIcon style={{ fontSize: 50, color: '#ffd8b6' }}/>}
							</ButtonBase>
							
						</Tooltip>
					</div>
					<Tooltip title={'Управление активными эффектами'}>
						<ButtonBase onClick={handle_magic_open}>
							<Badge badgeContent={checked_magic.length} color="primary" className={classes.spells} >
							</Badge>
						</ButtonBase>
					</Tooltip>
				</div>
				
			</div>
			<Dialog onClose={handle_enemy_ch_close} aria-labelledby="simple-dialog-title" open={enemy_ch_open}>
				
					{(selected_place.side*1 === 2) && <>
						<DialogTitle id="simple-dialog-title_1">Введите параметры игрока из Примерочной</DialogTitle>
						<TextField
							style={{margin: 5, minWidth: 50}}
							  label={'Ваши параметры'}
							  type="text"
							  size={'small'}
							  defaultValue={''}
							  onChange={(e) => set_gamer_data_handle(e.target.value)}
							/>
						
				
						<Button color='primary' onClick={handle_enemy_ch_close}>Готово!</Button>
					</>}
					
					{(selected_place.side*1 === 1) && <>
						<DialogTitle id="simple-dialog-title_1">Выберите вид и уровень бота</DialogTitle>
						<FormControl className={classes.formControl}>
							<InputLabel id="demo-simple-select-helper-label">Противник</InputLabel>
							<Select
							  labelId="demo-simple-select-helper-label"
							  id="demo-simple-select-helper"
							  value={enemy_name}
							  onChange={(e, value) => set_enemy_name(value.props.value)}
							>
							{tolmin_enemy_list.map(item => [item.name, item.data]).filter(d=> {
									let level_ = JSON.parse(d[1])
									level_ = level_.level
									
									if (level_ === undefined) {
										return false
									}
									return true
								}).map(d=>{

									let level_ = JSON.parse(d[1])
									level_ = level_.level
									if (level_ === undefined) {
										level_ = ''
									} else {
										level_ = ' (' + level_ + ')'
									}

									return(
										<MenuItem key={d[0]} value={d[0]}>{d[0] + level_}</MenuItem>
									)
								})}

							</Select>

					</FormControl>
					<TextField style={{margin: 5}} label="Уровень бота" type="number" size={'small'} value={enemy_level}
						  onChange={(e) => set_enemy_level(e.target.value)}/>
				
					<Button color='primary' onClick={()=>set_new_enemy(new_enemy+1)}>Добавить</Button>
				</>}
			</Dialog>
			<Dialog onClose={handle_magic_close} aria-labelledby="simple-dialog-title" open={magic_open}>
			  <DialogTitle id="simple-dialog-title">Выберите активные эффекты на персонаже (они будут действовать весь бой):</DialogTitle>
			  <List dense>
			  	{magic_list.map((spell, value) => {
					  const labelId = `checkbox-list-secondary-label-${value}`;
					  return(
						  <ListItem selected={checked_magic.indexOf(value) !== -1} onClick={handle_toggle_magic(value)} button key={spell.name}>
							<ListItemAvatar>
							  <Avatar>
								<img width={30} src={spell.img} alt={spell.name}></img>
							  </Avatar>
							</ListItemAvatar>
							<ListItemText primary={spell.name} secondary={spell.description} />
							<ListItemSecondaryAction>
							  <Checkbox
								edge="end"
								onChange={handle_toggle_magic(value)}
								checked={checked_magic.indexOf(value) !== -1}
								inputProps={{ 'aria-labelledby': labelId }}
							  />
							</ListItemSecondaryAction>
						  </ListItem>
					  )
				  })}
				  
			  </List>
			</Dialog>

		</HotKeys>
    );
}
 
export default MortalKombat;