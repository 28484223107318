import React from 'react';
import MetaTags from 'react-meta-tags';

const Home = () => {
    return (
       <div>
		<MetaTags>
            <title>Аватариум</title>
            <meta name="description" content="Аватариум - экосистема приложений для игры Аватары: Эпоха хаоса. Описание квестом, аналитика, статьи и многое другое" />
            <meta property="og:title" content="Аватариум" />
            <meta property="og:image" content="../images/favicon.ico" />
	  	</MetaTags>
		<h1>Главная</h1>
		<p>В разработке</p>
       </div>
    );
}
 
export default Home;